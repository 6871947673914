/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from './client'
import { IStateFields } from '@metaverse/types'

export const getStates = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<IStateFields>({
    content_type: 'state',
    include: 2,
    ...query,
  })

  return response
}

export const getState = async (
  slug: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getStates({
    'fields.slug': slug,
    limit: 1,
    ...query,
  })

  return response?.items?.[0] || null
}