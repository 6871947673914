import { EViewSize } from "@enums";
import { useCollection, useLotType } from "@hooks";
import {
  IContentfulLotData,
  IMojitoCollectionItem,
  IMojitoCollectionItemBuyNowLot,
} from "@interfaces";
import { formatDateToUS } from "@utils";
import Link from "next/link";
import React, { useRef } from "react";
import styled from "styled-components";
import { Image } from "../../image";
import { AuctionGridItemImageWrapper } from "../previewWrapper";

const A = styled.a`
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: block;
`;

const LotTitle = styled.div`
  font-family: "MercuryDisplay", serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 26px;
`;

const ItemSpecifics = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  font-family: "BentonSans", serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: ${EViewSize.mobile}) {
    white-space: normal;
  }
`;

const LotDesc = styled.div<{ $reversed: boolean }>`
  display: flex;
  flex-direction: ${({ $reversed }) =>
    $reversed ? "column-reverse" : "column"};
  margin-top: 20px;
  gap: 4px;
`;

export const BuyNowLotLink = styled(
  ({
    lot,
    single,
    collectionSlug,
    mojitoItem,
    ...props
  }: {
    lot: IContentfulLotData;
    mojitoItem: IMojitoCollectionItem<IMojitoCollectionItemBuyNowLot>;
    single: boolean;
    collectionSlug: string;
  }) => {
    const { isFakeAuction } = useCollection();
    const { title, author, imagesCollection, createdAt, gridPreviewImage } =
      lot;
    const imgRef = useRef<HTMLDivElement>(null);
    const { areImagesValid } = useLotType(lot);
    const firstImage = imagesCollection?.items[0];
    const hasPreviewImage = areImagesValid && firstImage;

    return (
      <div {...props}>
        <Link
          href={
            single && !isFakeAuction
              ? `/${collectionSlug}`
              : `/${collectionSlug}/lots/${mojitoItem.slug}`
          }
          passHref>
          <A>
            <AuctionGridItemImageWrapper
              ref={imgRef}
              $lotPreviewBackgroundColor={lot.lotPreviewBackgroundColor}
              $text={!hasPreviewImage ? "No Image :-(" : null}>
              {hasPreviewImage ? (
                <Image
                  src={gridPreviewImage?.url ?? firstImage?.url}
                  alt={firstImage?.title}
                  width={350}
                  height={350}
                  objectFit={"cover"}
                  initialQuality={80}
                  skeletonLoader
                />
              ) : null}
            </AuctionGridItemImageWrapper>
            <LotDesc $reversed={collectionSlug == "gifted-twitter-140"}>
              <LotTitle>{author.name}</LotTitle>
              <ItemSpecifics>{`${title}, ${
                createdAt ? formatDateToUS(createdAt, "YYYY") : ""
              }`}</ItemSpecifics>
            </LotDesc>
          </A>
        </Link>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
