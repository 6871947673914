import { useDeviceResponsive } from "@hooks";
import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode } from "react";
import styled from "styled-components";

const swipeConfidenceThreshold = 100;

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const variants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      y: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

interface IWithAnimatePresence {
  paginate: (direction: number) => void;
  direction: number;
  fillLayout?: boolean;
  id: string;
  children?: ReactNode;
  itemsAmount?: number;
}

export const WithAnimatePresence = styled(
  ({
    paginate,
    direction,
    fillLayout,
    id,
    itemsAmount,
    ...props
  }: IWithAnimatePresence) => {
    const { isMobile } = useDeviceResponsive();

    return isMobile ? (
      <div
        {...props}
        style={{
          display: "inline-block",
          width: "100%",
          height: fillLayout ? "100%" : "auto",
          position: "absolute",
        }}>
        {props.children}
      </div>
    ) : (
      <AnimatePresence initial={false} custom={direction} {...props}>
        <motion.div
          style={{
            display: "inline-block",
            width: "100%",
            height: fillLayout ? "100%" : "auto",
            position: "absolute",
          }}
          key={id}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag={itemsAmount ? "y" : undefined}
          dragConstraints={{ top: 0, bottom: 0 }}
          dragElastic={1}
          onDragEnd={(e: any, { offset, velocity }: any) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}>
          {props.children}
        </motion.div>
      </AnimatePresence>
    );
  }
)``;
