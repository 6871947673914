import isBrowser from "@utils/isBrowser";
import { useSize } from "ahooks";
import { useMemo } from "react";
import {
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isIOS,
  isMobileSafari,
  isOpera,
  isSafari,
  isWindows,
  isYandex,
} from "react-device-detect";

const r = {
  outRanged: 0,
  isMobile: 576,
  isTablet: 768,
  isDesktop: 992,
  isLarge: 1200,
};

export const useDeviceResponsive = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLarge: boolean;
  isMobileSafari: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  isChrome: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isOpera: boolean;
  isIE: boolean;
  isEdge: boolean;
  isYandex: boolean;
  isWindows: boolean;
  isEqualOrAbove: (keys: "mobile" | "tablet" | "desktop" | "large") => boolean;
  isEqualOrBelow: (keys: "mobile" | "tablet" | "desktop" | "large") => boolean;
} => {
  const { width } = useSize(
    isBrowser ? document.querySelector("body") : null
  ) ?? {
    width: 0,
  };

  return useMemo(() => {
    return {
      isMobile: !!width && width >= r.outRanged && width < r.isMobile,
      isTablet: !!width && width >= r.isMobile && width < r.isTablet,
      isDesktop: !!width && width >= r.isTablet && width < r.isDesktop,
      isLarge: !!width && width >= r.isDesktop,
      isMobileSafari,
      isAndroid,
      isIOS,
      isChrome,
      isFirefox,
      isSafari,
      isOpera,
      isIE,
      isEdge,
      isYandex,
      isWindows,
      isEqualOrAbove: (key) => {
        switch (key) {
          case "large":
            return !!width && width >= r.isLarge;
          case "desktop":
            return !!width && width >= r.isDesktop;
          case "tablet":
            return !!width && width >= r.isTablet;
          case "mobile":
            return !!width && width >= r.isMobile;
        }
      },
      isEqualOrBelow: (key) => {
        switch (key) {
          case "large":
            return !!width && width <= r.isLarge;
          case "desktop":
            return !!width && width <= r.isDesktop;
          case "tablet":
            return !!width && width <= r.isTablet;
          case "mobile":
            return !!width && width <= r.isMobile;
        }
      },
    };
  }, [width]);
};
