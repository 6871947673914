import { EViewSize } from "@enums";
import { useDeviceResponsive } from "@hooks";
import { mod } from "@utils";
import React, { useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import SVG from "react-inlinesvg";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${EViewSize.mobile}) {
    width: auto;
    margin-right: -16px;
    margin-left: -16px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  max-height: 600px;
  height: 100%;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${EViewSize.mobile}) {
    max-height: 100%;
  }
`;

const Slides = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 1280px) {
    width: 80%;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    width: 100%;
    overflow: hidden;
  }
`;

const SliderNav = styled.div`
  display: none;
  z-index: 2;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: ${EViewSize.mobile}) {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 0 16px;
  }

  svg {
    fill: var(--text-color);
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    display: flex;
  }
`;

const Control = styled.div`
  display: flex;
  align-items: center;
  z-index: 3;
`;

const ControlText = styled.div`
  padding: 0 14px;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--text-color);
  z-index: 4;
`;

const SlideOuterSlide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SlideOuter = styled.div<{
  position: string;
  collapse?: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 582px;
  transition: transform 450ms
      ${({ collapse }) => (collapse ? "ease-in-out" : "ease-out")},
    z-index 0ms 125ms, filter 0ms 125ms;
  transform: translateX(
      ${({ position, collapse }) =>
        collapse
          ? "0%"
          : position === "left"
          ? "-33%"
          : position === "right"
          ? "33%"
          : "0%"}
    )
    scale(
      ${({ position }) =>
        position === "center"
          ? 1
          : position === "right" || position === "left"
          ? 0.6
          : 0}
    );

  z-index: ${({ position }) =>
    position === "center" ? 10 : position === "hidden" ? -1 : 5};

  ${SlideOuterSlide} {
    ${({ position }) => position !== "center" && "filter: blur(3px);"};
  }

  ${ControlText} {
    position: absolute;
    top: -50px;
    font-size: 20px;
    letter-spacing: 0.0025em;
    text-transform: uppercase;
    ${({ position }) =>
      position == "left"
        ? "left: -15px;"
        : position == "right"
        ? "right: -15px;"
        : ""};
    opacity: ${({ position }) => (position === "center" ? 0 : 1)};
    transition: opacity
      ${({ collapse, position }) =>
        `${position == "center" ? "0ms" : "450ms"} ${
          collapse ? "ease-in-out" : "ease-out"
        }`};
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    height: 100%;
    transition: none;
    padding-bottom: ${() => (isSafari ? "70px" : "50px")};
  }
`;

const SlideBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  cursor: pointer;
  z-index: 7;
  user-select: none;
  background: none;

  @media screen and (max-width: ${EViewSize.mobile}) {
    height: ${() => "calc(100vh - var(--style-header-height))"};
    top: 0;
  }
`;

const SlideBackdropRight = styled(SlideBackdrop)`
  left: 50%;
`;

const SlideBackdropLeft = styled(SlideBackdrop)`
  right: 50%;
`;

export const Carousel: React.FunctionComponent<{
  slides: JSX.Element[];
  slide?: number;
  onSlideChange?(i: number): void;
  collapse?: boolean;
}> = ({ slides, slide, onSlideChange, collapse }) => {
  const [activeSlide, setActiveSlide] = useState(slide ?? 0);
  const { isMobile } = useDeviceResponsive();
  const prevSlide = () => setActiveSlide(mod(activeSlide - 1, slides.length));
  const nextSlide = () => setActiveSlide(mod(activeSlide + 1, slides.length));
  const swipeHandlers = useSwipeable({
    onSwipedRight: prevSlide,
    onSwipedLeft: nextSlide,
    preventDefaultTouchmoveEvent: true,
  });
  const slideCardNames = ["Preview", "Details", "Bidding"];

  useEffect(() => onSlideChange?.(activeSlide), [activeSlide, onSlideChange]);

  useEffect(() => {
    if (slide) {
      setActiveSlide(slide);
    }
  }, [slide]);

  const slidePosition = (slideIdx: number): string =>
    slideIdx === activeSlide
      ? "center"
      : slideIdx === activeSlide - 1 ||
        (activeSlide === 0 && slideIdx === slides.length - 1)
      ? "left"
      : slideIdx === activeSlide + 1 ||
        (activeSlide === slides.length - 1 && slideIdx === 0)
      ? "right"
      : "hidden";

  return (
    <Wrapper {...swipeHandlers}>
      <Container>
        <Slides>
          {isMobile && (
            <SliderNav>
              <Control key="left-control">
                <SVG src="/icons/arrow-left.svg/" />
                <ControlText>
                  {slideCardNames[mod(activeSlide - 1, slides.length)]}
                </ControlText>
              </Control>
              <Control key="right-control">
                <ControlText>
                  {slideCardNames[mod(activeSlide + 1, slides.length)]}
                </ControlText>
                <SVG src="/icons/arrow-right.svg/" />
              </Control>
            </SliderNav>
          )}
          {slides.map((slide, i) => (
            <SlideOuter key={i} position={slidePosition(i)} collapse={collapse}>
              <ControlText>{slideCardNames[i]}</ControlText>
              <SlideOuterSlide>{slide}</SlideOuterSlide>
            </SlideOuter>
          ))}
          <SlideBackdropLeft onClick={prevSlide} />
          <SlideBackdropRight onClick={nextSlide} />
        </Slides>
      </Container>
    </Wrapper>
  );
};
