import styled from "styled-components";

export const ContactInfo = styled((props) => {
  return (
    <div {...props}>
      For all enquiries please contact:
      <br />
      <br />
      <b>Cassandra Hatton </b>
      <br />
      VP, <i>Global Head of Department & Senior Specialist</i>
      <br />
      Science & Popular Culture
      <br />
      <a href="tel:+12128942342">+1 212-894-2342</a>
      <br />
      <a href="mailto:cassandra.hatton@sothebys.com">
        CASSANDRA.HATTON@SOTHEBYS.COM
      </a>
      <br />
      <br />
      <b>Sarah Hassan</b>
      <br />
      Cataloguer, <i>Science & Popular Culture</i>
      <br />
      <a href="tel:+12128942344">+1 212-894-2344</a>
      <br />
      <a href="mailto:sarah.hassan@sothebys.com">SARAH.HASSAN@SOTHEBYS.COM</a>
    </div>
  );
})`
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 14px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
