// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BrightcovePlayer from "@brightcove/react-player-loader";
import { IBrightcovePlayer } from "@interfaces";
import styled from "styled-components";
import { useTrackingEventFactory } from "@hooks";
import { EEventNames } from "@enums";

interface IBrightcoveVideo {
  accountId?: number;
  videoTitle?: string;
  videoId: number;
  playerId?: string;
  hideControls?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  disableClickToStop?: boolean;
  onEnd?: () => void;
  loop?: boolean;
  player?: (a: IBrightcovePlayer) => void;
}
//https://docs.brightcove.com/brightcove-player/1.x/Player.html (not sure that it's right version, but most of methods works fine)
export const BrightcoveVideo = styled(
  ({
    accountId,
    videoTitle = "",
    playerId,
    videoId,
    hideControls,
    autoplay,
    muted,
    disableClickToStop,
    player,
    onEnd,
    loop = true,
    ...props
  }: IBrightcoveVideo) => {
    const trackEvent = useTrackingEventFactory();

    const handlePlayEvent = () => {
      trackEvent(EEventNames.PlayVideo, {
        videoTitle,
      });
    };

    const onSuccessHandler = (success: any) => {
      const brightcove = success.ref;
      const brightcovePLayer = success.ref.player();
      brightcovePLayer.title.hide();
      if (hideControls) {
        brightcovePLayer.controlBar.hide();
      }
      if (disableClickToStop) {
        brightcove.controls_ = false;
      }
      if (onEnd) {
        brightcove.on("ended", onEnd);
      }
      if (player) {
        player(brightcovePLayer);
      }
      brightcove.one("play", () => handlePlayEvent());
      brightcovePLayer.options_.loop = loop;
    };

    return (
      <div {...props}>
        <BrightcovePlayer
          accountId={accountId ?? 104524641001}
          onSuccess={onSuccessHandler}
          videoId={videoId}
          playerId={playerId ?? "vuzlAHzz3"}
          embedOptions={{ responsive: true }}
          options={{
            autoplay: autoplay ?? false,
            muted: autoplay ?? muted ?? false,
          }}
        />
      </div>
    );
  }
)`
  .video-js {
    .vjs-big-play-button {
      background-color: transparent;
      width: 40px;
      height: 40px;
      margin-left: -0.5em;
      margin-top: -0.5em;

      .vjs-icon-placeholder {
        &:before {
          content: "";
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-image: url("/icons/play.svg");
          box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center;
          filter: grayscale(100%) brightness(1);
          transition: all 0.4s;
        }
      }
    }

    &:hover {
      .vjs-big-play-button {
        background-color: transparent;

        .vjs-icon-placeholder:before {
          filter: grayscale(0%);
        }
      }
    }
  }
`;
