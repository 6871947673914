import { EViewSize } from "@enums";
import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import { formatCurrency } from "@utils";
import classNames from "classnames";
import styled from "styled-components";
import { useCollectionItemCurrentBids } from "@hooks";

export const BidStatus = styled(
  ({
    mojitoItem,
    className,
    ...props
  }: {
    mojitoItem: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
    className?: string;
  }) => {
    const { currentBids } = useCollectionItemCurrentBids(mojitoItem.id);

    return currentBids?.details?.currentBid &&
      !currentBids.details.saleView.isPreSale &&
      !currentBids.details.currentBid?.isSold ? (
      <div
        {...props}
        className={classNames(
          className,
          currentBids.details.currentBid?.isHold && "high",
          currentBids.details.currentBid?.isOutbid && "outbid",
          currentBids.details.currentBid?.isLost && "lost",
          currentBids.details.currentBid?.isWin && "win",
          currentBids.details.currentBid?.isCurrent && "current",
          currentBids.details.currentBid?.isStart && "starting"
        )}>
        {currentBids.details.currentBid?.isHold && (
          <>
            THE BID IS WITH YOU:
            <span>{formatCurrency(currentBids.details.currentBid.amount)}</span>
          </>
        )}
        {currentBids.details.currentBid?.isOutbid && (
          <>
            YOU HAVE BEEN OUTBID:
            <span>{formatCurrency(currentBids.details.currentBid.amount)}</span>
          </>
        )}
        {currentBids.details.currentBid?.isLost && <>YOU HAVE LOST THIS LOT</>}
        {currentBids.details.currentBid?.isWin && <>YOU HAVE WON THIS LOT</>}
        {currentBids.details.currentBid?.isCurrent && (
          <>{`BID: ${formatCurrency(currentBids.details.currentBid.amount)}`}</>
        )}
        {currentBids.details.currentBid?.isStart &&
          !currentBids.details.saleView.isPostSale && (
            <>{`STARTING BID: ${formatCurrency(
              currentBids.details.currentBid.amount
            )}`}</>
          )}
      </div>
    ) : null;
  }
)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
  font-family: "BentonSans";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--bid-text-status);
  background: var(--lot-bid-status-current);

  &.high {
    background: var(--lot-bid-status-high);
  }

  &.outbid {
    background: var(--lot-bid-status-outbid);
  }

  &.lost {
    background: var(--lot-bid-status-lost);
  }

  &.sold {
    background: var(--lot-bid-status-sold);
  }

  &.win {
    background: var(--lot-bid-status-win);
  }

  &.high,
  &.outbid,
  &.lost,
  &.win {
    padding: 4px 8px;
  }

  span {
    margin-left: 6px;
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    flex-direction: row;
  }
`;

export default BidStatus;
