import { EColorScheme } from "@enums/colorScheme.enum";
import {
  EColorSchemeContextActionTypes,
  useColorSchemeContext,
} from "@state-context/colorScheme.contex";
import isBrowser from "@utils/isBrowser";
import { useEffect, useMemo } from "react";

export function useColorScheme(): {
  isDark: boolean;
  setIsDark: () => void;
  setIsLight: () => void;
} {
  const { state, dispatch } = useColorSchemeContext();

  const isDark = useMemo(
    () =>
      state.colorScheme == EColorScheme.dark ||
      (isBrowser && document?.body?.classList?.contains("dark")),
    [state.colorScheme]
  );

  const setColorScheme = (value: EColorScheme) => {
    dispatch({
      type: EColorSchemeContextActionTypes.setColorScheme,
      colorScheme: value,
    });
  };

  const setIsDark = () => setColorScheme(EColorScheme.dark);
  const setIsLight = () => setColorScheme(EColorScheme.light);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDark]);

  return {
    isDark,
    setIsDark,
    setIsLight,
  };
}
