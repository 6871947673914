import { gql } from "graphql-request";

export enum EContentfulQueries {
  fullLot,
  shortLots,
  lotsImages,
  auctionBySlug,
  auctionsSlugList,
  authors,
  organizations,
  lotsAction,
  additionalSales,
  additionalSalesBySlug,
  collectionOverview,
  collectionOverviewBySlug,
  lotOverview,
  collectionTraits,
  collectionTraitsBySlug,
  disableAuctionBySlug,
  overviewBySlug,
  auctionsBySlug,
  pageHeaders,
  lotDetailsBySlug,
  lotDetails,
  textSectionBySlug,
  lotGridBySlug,
  lotDetailSummaryPostMintBySlug,
  auctionDetailsBySlug,
  quoteBySlug
}

export const contentfulQueries = {
  [EContentfulQueries.fullLot]: gql`
    query lot($mojitoId: String) {
      lotCollection(where: { mojitoId: $mojitoId }) {
        items {
          lotId
          sys {
            publishedAt
          }
          title
          richTitle
          subtitle
          imagesCollection {
            items {
              url
              title
              contentType
            }
          }
          createdAt
          startDate
          endDate
          estimatePrice
          estimatedPriceRange
          purchasedAt
          smartContractAddress
          tokenId
          mojitoId
          author {
            about
            name
            slug
            avatar {
              url
              title
            }
          }
          collaboratorAuthorsCollection(limit: 2) {
            items {
              name
              about
              avatar {
                url
              }
            }
          }
          aboutLot
          note
          history
          videoSectionHeadline
          video
          video2
          video3
          conditionReportText
          shortCollectorDescription
          nftLink
          slug
          nftVideoId
          nftVideoIds
          soldOut
          lotPreviewBackgroundColor
          gridPreviewImage {
            url
            title
            width
            height
          }
          showAuthorModule
        }
      }
    }
  `,
  [EContentfulQueries.shortLots]: gql`
    query lots($mojitoIds: [String]) {
      lotCollection(order: lotId_ASC, where: { mojitoId_in: $mojitoIds }) {
        items {
          sys {
            publishedAt
          }
          lotId
          title
          richTitle
          subtitle
          mojitoId
          author {
            name
            slug
            avatar {
              url
            }
          }
          slug
          gridPreviewImage {
            url
            title
            width
            height
          }
          imagesCollection(limit: 1) {
            items {
              url
              title
            }
          }
          nftVideoId
          soldOut
          estimatePrice
          estimatedPriceRange
          purchasedAt
          createdAt
          startDate
          endDate
          shortCollectorDescription
        }
      }
    }
  `,
  [EContentfulQueries.lotsImages]: gql`
    query lots($mojitoIds: [String]) {
      lotCollection(order: lotId_ASC, where: { mojitoId_in: $mojitoIds }) {
        items {
          sys {
            publishedAt
          }
          mojitoId
          gridPreviewImage {
            url
            title
            width
            height
          }
          lotPreviewBackgroundColor
          imagesCollection(limit: 1) {
            items {
              url
              title
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.auctionBySlug]: gql`
    query Auction($slug: String) {
      auctionCollection(order: sys_publishedAt_DESC, where: { slug: $slug }) {
        items {
          name
          title
          subtitle
          duration
          description
          data
          startDate
          endDate
          videoId
          slug
          saleId
          footer
          enquireEmail
          claimItemId
          claimSetId
          contractId
          rangeStart
          rangeEnd
          overviewCurrency
          overviewSaleClosed
          overviewTotalTransacted
          overviewLotsSold
          departmentCode
          headerBg {
            url
            title
            width
            height
          }
          headerCta
          headerCtaLink
          faqCollection {
            items {
              title
              content
            }
          }
          author {
            about
            name
            avatar {
              url
              title
            }
          }
          organization {
            about
            name
            avatar {
              url
              title
            }
          }
          mojitoIsSource
        }
      }
    }
  `,
  [EContentfulQueries.auctionDetailsBySlug]: gql`
    query Auction($slug: String) {
      auctionCollection(order: sys_publishedAt_DESC, where: { slug: $slug }) {
        items {
          name
          title
          superHeadline
          subtitle
          duration
          description
          data
          startDate
          endDate
          videoId
          slug
          saleId
          footer
          enquireEmail
          claimItemId
          claimSetId
          contractId
          rangeStart
          rangeEnd
          overviewCurrency
          overviewSaleClosed
          overviewTotalTransacted
          overviewLotsSold
          departmentCode
          headerBg {
            url
            title
            width
            height
          }
          headerCta
          headerCtaLink
          faqCollection {
            items {
              title
              content
            }
          }
          author {
            about
            name
            superHeadline
            avatar {
              url
              title
            }
          }
          organization {
            about
            name
            superHeadline
            avatar {
              url
              title
            }
          }
          mojitoIsSource
          artblocksCoreContractAddress
          artblocksMintContractAddress
          artblocksProjectId
        }
      }
    }
  `,
  [EContentfulQueries.auctionsSlugList]: gql`
    query Auction {
      auctionCollection(order: sys_publishedAt_DESC) {
        items {
          slug
        }
      }
    }
  `,
  [EContentfulQueries.authors]: gql`
    query Author {
      authorCollection {
        items {
          sys {
            publishedAt
          }
          about
          name
          slug
          avatar {
            url
            title
          }
          linkedFrom {
            lotCollection {
              items {
                mojitoId
                title
                slug
              }
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.organizations]: gql`
    query Organizations {
      organizationCollection {
        items {
          homepageRedirect {
            name
            slug
          }
        }
      }
    }
  `,
  [EContentfulQueries.lotsAction]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          lotsCollection {
            items {
              mojitoId
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.additionalSales]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          additionalSalesCollection {
            items {
              slug
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.additionalSalesBySlug]: gql`
    query offLineAdditionalSale($slug: [String]) {
      offLineAdditionalSaleCollection(where: { slug_in: $slug }) {
        items {
          title
          subCopy
          description
          buttonCta
          image {
            url
            title
            width
            height
          }
          videoId
          saleLink
        }
      }
    }
  `,
  [EContentfulQueries.lotDetails]: gql`
    query LotDetails($slug: String) {
      lotDetailMintCtaCollection(where: { slug: $slug }) {
        items {
          slug
        }
      }
    }
  `,
  [EContentfulQueries.lotDetailsBySlug]: gql`
    query LotDetails($slug: [String]) {
      lotDetailMintCtaCollection(where: { slug_in: $slug }) {
        items {
          title
          superHeadline
          description
          slug
          image {
            url
            title
            width
            height
          }
          secondaryMarketplaceUrl
        }
      }
    }
  `,
  [EContentfulQueries.quoteBySlug]: gql`
    query Quote($slug: String) {
      quoteBlockCollection(where: { slug: $slug }) {
        items{
          quote
          quoteAuthor
        }
      }
    }
  `,
  [EContentfulQueries.lotDetailSummaryPostMintBySlug]: gql`
  query Auction($slug: [String]) {
    lotDetailSummaryPostMintCollection(where: { slug_in: $slug }) {
      items {
        title
        slug
        description
        artblocksCoreContractAddress
        artblocksMintContractAddress
        projectId
        creativeCredits
        additionalDetails
        lotGrid{
          title
          description
        }
        textSectionsCollection(limit: 4){
          items{
            title
            richTitle
            slug
            description
            buttonCta
            saleLink
            itemId
            imagesCollection{
              items{
                title
                url
              }
            }
            videoId
          }
        }
      }
    }
  }
  `,
  [EContentfulQueries.textSectionBySlug]: gql`
  query Auction($slug: String) {
    auctionCollection(where: { slug: $slug }) {
      items {
        title
        slug
        textSectionsCollection(limit: 0){
          items {
            title
            superHeadline
            richTitle
            slug
            description
            buttonCta
            saleLink
            itemId
            image{
              title
              url
            }
            videoId
            imagesCollection{
              items{
                description
                url
              }
            }
          }
        }

      }
    }
  }
  `,
  [EContentfulQueries.lotGridBySlug]: gql`
    query LotGridDetails($slug: [String]) {
      lotGridArtBlocksCollection(where: { slug_in: $slug }) {
        items{
          title
          slug
          description
        }
      }
    }
  `,
  [EContentfulQueries.collectionOverview]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          collectionOverviewCollection {
            items {
              slug
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.collectionOverviewBySlug]: gql`
    query collectionOverviewItem($slug: [String]) {
      collectionOverviewItemCollection(where: { slug_in: $slug }) {
        items {
          title
          description
          image {
            url
            title
          }
          slug
        }
      }
    }
  `,
  [EContentfulQueries.lotOverview]: gql`
    query lot($mojitoId: String) {
      lotCollection(where: { mojitoId: $mojitoId }) {
        items {
          lotOverviewsCollection {
            items {
              slug
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.collectionTraits]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          collectionTraitsCollection {
            items {
              slug
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.collectionTraitsBySlug]: gql`
    query traits($slug: [String]) {
      traitsCollection(where: { slug_in: $slug }) {
        items {
          title
          content
          image {
            url
            title
          }
        }
      }
    }
  `,
  [EContentfulQueries.disableAuctionBySlug]: gql`
    query Auction($slug: String) {
      auctionCollection(where: { slug: $slug }) {
        items {
          disableAuction
        }
      }
    }
  `,
  [EContentfulQueries.overviewBySlug]: gql`
    query Overview($slug: String) {
      overviewCollection(where: { slug: $slug }) {
        items {
          name
          title
          slug
          videoId
          description
          blockquote
          highlightLink
          highlightDescription
          highlightBanner {
            url
            title
          }
          heroBanner {
            url
          }
          featuredCollection {
            items {
              slug
            }
          }
        }
      }
    }
  `,
  [EContentfulQueries.auctionsBySlug]: gql`
    query Auction($slug: [String]) {
      auctionCollection(where: { slug_in: $slug }) {
        items {
          name
          title
          endDate
          description
          slug
          headerBg {
            url
          }
          author {
            name
          }
        }
      }
    }
  `,
  [EContentfulQueries.pageHeaders]: gql`
    query GetPageHeaders($pathname: String!) {
      pageHeaderCollection(
        where: { targetPathname_contains_some: [$pathname] }
      ) {
        items {
          title
          subTitle
          content
          backgroundImage {
            url
            width
            height
          }
          buttonLabel
          buttonLink
          backgroundColor
        }
      }
    }
  `,
};
// 6267c3d0-bdba-45a2-bcca-6c7ff10fd193
// 6678e79a-ae68-450f-bd7f-21e47bed3e5f
// lotCollection(order: lotId_ASC, where: {OR: [{mojitoId: "6678e79a-ae68-450f-bd7f-21e47bed3e5f"}, {mojitoId: "6267c3d0-bdba-45a2-bcca-6c7ff10fd193"}]}) {
