import styled from "styled-components";
import { EViewSize } from "@enums";

export const InfoContainer = styled.div`
  max-width: 1030px;
  margin: 60px auto;
  padding: 0 15px;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-color);

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin: 0 auto 20px;
    padding: 0 15px;
  }

  > p {
    margin: 1em 0;
    display: block;

    .section {
      display: block;
    }
  }

  .indent {
    margin-left: 10px;
    display: block;

    b {
      margin-right: 3px;
    }
  }

  h1 {
    font-family: "MercuryDisplay", serif;
    font-size: 32px;
    line-height: 38px;
    font-weight: normal;

    @media screen and (max-width: ${EViewSize.mobile}) {
      font-size: 25px;
    }
  }

  h2 {
    margin: 45px 0 14px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    margin: 8px 0;
    font-size: 14px;
    line-height: 18px;
  }

  a {
    text-decoration: underline;
  }

  ul {
    list-style-type: none;
  }

  table,
  td,
  th {
    border: 1px solid var(--text-color);
    padding: 5px;
  }

  table {
    border-collapse: collapse;
  }

  strong{
    font-weight: bold;
  }
`;
