/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from './client'
import { ICollectionFields } from '@metaverse/types'

export const getCollections = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<ICollectionFields>({
    content_type: 'collection',
    ...query,
  })

  return response
}

export const getCollectionPage = async (
  slug: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getCollections({
    'fields.slug': slug,
    limit: 1,
    ...query,
  })

  return response?.items?.[0] || null
}

export const getAllCollections = async (query?: Record<string, any>) => {
  const response = await getCollections({
    ...query,
  });

  return response?.items || [];
};

export const getCollectionPageByID = async (
  id: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getCollections({
    'sys.id': id,
    limit: 1,
    ...query,
  })

  return response?.items?.[0] || null
}
