export function formatCurrency(amount: number, currency = "USD"): string {
  return (
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    })
      .format(amount)
      .replace(/\D00(?=\D*$)/, "")
      .replace("$", "") + " USD"
  );
}
