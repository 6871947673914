import { EViewSize } from "@enums";
import { useMojitoMutation, useOrganization } from "@hooks";
import { EMojitoMutations } from "@state";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BaseButton } from "./button";

interface IAliasInput {
  avatar?: string | null;
  onSuccess?: (alias: string, status: "sameAlias" | "success") => void;
  onFailure?: (e: Error) => void;
  organization: any;
}

export const AliasInputError = styled.div`
  position: absolute;
  bottom: -25px;
  color: #fe15bc;
  font-size: 12px;
`;

export const AliasInput = styled(
  ({ organization, onSuccess, onFailure, avatar, ...props }: IAliasInput) => {
    //todo wrap to memo
    const { refetch } = useOrganization(); //todo fix cache in gql and use organisation from this request

    const [updateUserSettings] = useMojitoMutation<{
      userOrgId: string;
      username: string;
      avatar: string;
    }>(EMojitoMutations.updateUserOrgSettings);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const errorTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
    function isValid(value: string) {
      return value.match(/[a-zA-Z0-9]{4,20}/);
    }

    function onSubmitHandler() {
      setLoading(true);
      errorTimer?.current && clearTimeout(errorTimer?.current);
      setError(null);
      const value = inputRef?.current?.value;
      if (!value || organization?.username === value) {
        if (value && organization?.username === value) {
          onSuccess?.(value, "sameAlias");
        }
        setLoading(false);
        setError("please use only alphanumeric characters, min length - 4");
        return;
      } else if (isValid(value) && organization?.id) {
        const variables: {
          userOrgId: string;
          username: string;
          avatar?: string;
        } = {
          userOrgId: organization?.id,
          username: value,
        };
        if (avatar) variables.avatar = avatar;
        updateUserSettings({
          ...variables,
        })
          .then(async () => {
            await refetch();
            onSuccess?.(value, "success");
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            setError(e.message);
            errorTimer.current = setTimeout(() => {
              setError(null);
            }, 8000);
            onFailure?.(e);
          });
      } else if (!organization?.id) {
        setLoading(false);
      } else {
        setLoading(false);
        setError("please use only alphanumeric characters, min length - 4");
      }
    }
    useEffect(() => {
      if (inputRef.current && organization?.username) {
        inputRef.current.value = organization?.username;
        inputRef.current.focus();
      }
    }, [organization?.username]);

    return organization?.id ? (
      <div {...props}>
        <input
          type="text"
          ref={inputRef}
          placeholder={organization.username ?? "Example: MetaSurfer"}
        />
        <BaseButton isLoading={loading} onClick={onSubmitHandler}>
          Submit
        </BaseButton>
        {error && <AliasInputError>{error}</AliasInputError>}
      </div>
    ) : null;
  }
)`
  position: relative;
  margin: 15px 0;
  input {
    border: 1px solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    padding: 0 10px;
    height: 40px;
    width: 100%;
    text-align: center;
  }

  input::placeholder {
    opacity: 0.4;
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    // margin: auto;
    max-width: 90vw;
    ${BaseButton} {
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 10px !important;
      max-width: 90vw !important;
    }
  }
`;
