import { EViewSize } from "@enums";
import { Grid } from "@material-ui/core";
import { mod } from "@utils";
import { useIsomorphicLayoutEffect, useSize } from "ahooks";
import classNames from "classnames";
import { motion, PanInfo, useAnimation } from "framer-motion";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import styled, { css } from "styled-components";
import { IconButton } from "./button";
import { IImage, Image } from "./image";

export interface ISlideshowItem<T = React.ReactNode> {
  image?: {
    src: IImage["src"];
    alt: string;
  };
  note?: T;
  sortByKey?: string | number;
}

interface ISlideshowItemInnerProps extends ISlideshowItem {
  duplicate?: boolean;
  dynamicClassName?: Set<string>;
}

interface ISlideshow {
  generalNote?: React.ReactChild;
  itemsPerView?: number;
  itemsPerSlide?: number;
  items: ISlideshowItem[];
  spaceBetween?: number;
  navigation?: boolean;
  pagination?: boolean;
  speed?: number;
  swipeable?: boolean;
  swipeThreshold?: number; // in % to the size of the slider
  updateOnWindowResize?: boolean;
  itemVisibleClass: string;
  itemDuplicateClass: string;
  itemNextClass: string;
  itemPrevClass: string;
  itemClass: string;
  itemNote: string;
  itemImage: string;
  itemActiveClass: string;
  slideClass: string;
  orderBy?: (a: ISlideshowItem, b: ISlideshowItem) => -1 | 1 | 0;
}

export const GeneralSlideshow = styled(
  ({
    items,
    orderBy,
    generalNote,
    itemsPerSlide,
    itemsPerView = 2,
    spaceBetween = 30,
    speed = 600,
    navigation = true,
    pagination = true,
    swipeable = true,
    swipeThreshold = 20,
    updateOnWindowResize = true,
    itemVisibleClass,
    itemDuplicateClass,
    itemClass,
    itemActiveClass,
    itemNote,
    itemImage,
    slideClass,
    itemNextClass,
    itemPrevClass,
    ...props
  }: ISlideshow) => {
    const [[slideIdx, shouldAnimate], setPage] = useState([
      itemsPerView,
      false,
    ]);
    const [itemsList, setItemsList] =
      useState<ISlideshowItemInnerProps[]>(items);
    const [itemWidth, setItemWidth] = useState(0);
    const [navAmount] = useState(itemsPerSlide ?? itemsPerView);
    const ref = useRef<HTMLDivElement>(null);
    const size = useSize(ref);
    const controls = useAnimation();
    const ignorePagination = useRef(false);

    console.assert(
      mod(items.length, itemsPerView) == 0,
      "The amount of images modulo images pre slide should be zero! [ items.length % itemsPerView === 0 ]"
    );

    useIsomorphicLayoutEffect(() => {
      if (updateOnWindowResize && size?.width)
        setItemWidth(
          (size.width - (itemsPerView - 1) * spaceBetween) / itemsPerView
        );
    }, [size?.width]);

    useIsomorphicLayoutEffect(() => {
      let _items = [
        ...items.slice(-itemsPerView).map((item) =>
          Object.assign({}, item, {
            duplicate: true,
            dynamicClassName: new Set<string>().add(itemDuplicateClass),
          })
        ),
        ...items,
        ...items.slice(0, itemsPerView).map((item) =>
          Object.assign({}, item, {
            duplicate: true,
            dynamicClassName: new Set<string>().add(itemDuplicateClass),
          })
        ),
      ];

      if (orderBy) {
        _items = _items.sort(orderBy);
      }

      setItemsList(_items);
    }, [items.length, orderBy]);

    useEffect(() => {
      if (shouldAnimate)
        controls.start({
          left: -1 * (itemWidth + spaceBetween) * navAmount * slideIdx,
          transition: { duration: speed * 0.001, ease: "easeInOut" },
        });
      else controls.set({ left: -1 * (itemWidth + spaceBetween) * slideIdx });

      return controls.stop;
    }, [itemWidth, slideIdx]);

    const swipePower = (offset: number, absDistance: number) => {
      return (offset / absDistance) * 100;
    };

    const handleDragEnd = async (
      _: MouseEvent | TouchEvent | PointerEvent,
      { offset }: PanInfo
    ) => {
      const power = swipePower(offset.x, itemWidth);
      if (power > swipeThreshold) {
        paginate(-navAmount);
      } else if (power < -swipeThreshold) {
        paginate(navAmount);
      }
    };

    const updateActiveSlide = (_newSlideIdx: number) => {
      if (slideIdx == 0) {
        controls.set({
          left:
            -1 *
            (itemWidth + spaceBetween) *
            (itemsList.length - itemsPerView * 2),
        });
        setPage([itemsList.length - itemsPerView * 2, false]);
      } else if (slideIdx == itemsList.length - itemsPerView) {
        controls.set({
          left: -1 * (itemWidth + spaceBetween) * itemsPerView,
        });
        setPage([itemsPerView, false]);
      } else if (_newSlideIdx != slideIdx) {
        setPage([_newSlideIdx, true]);
      }
    };

    const paginate = (newDirection: number) => {
      if (ignorePagination.current) return;
      const _newSlideIdx = mod(slideIdx + newDirection, itemsList.length);
      updateActiveSlide(_newSlideIdx);
    };

    const startAnimation = () => {
      ignorePagination.current = true;
    };

    const completeAnimation = () => {
      ignorePagination.current = false;

      updateActiveSlide(slideIdx);
    };

    return (
      <div {...props} ref={ref}>
        <div className="container">
          <motion.div
            className={slideClass}
            animate={controls}
            drag={swipeable && "x"}
            dragDirectionLock
            dragMomentum={false}
            dragElastic={0}
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={handleDragEnd}
            onAnimationStart={startAnimation}
            onAnimationComplete={completeAnimation}
            style={{
              width: itemsList.length * (itemWidth + spaceBetween),
            }}>
            {itemsList.map((_item, i) => {
              const _activeIdx = slideIdx + Math.floor(itemsPerView / 2);
              const isActive =
                _activeIdx <= i &&
                i <= _activeIdx + (itemsPerView % 2 == 0 ? 1 : 0);

              return (
                <Grid
                  item
                  className={classNames(
                    itemClass,
                    slideIdx <= i &&
                    i < slideIdx + itemsPerView &&
                    itemVisibleClass,
                    isActive && itemActiveClass,
                    slideIdx == i && !isActive && itemPrevClass,
                    slideIdx + itemsPerView - 1 == i &&
                    !isActive &&
                    itemNextClass,
                    Array.from(_item.dynamicClassName ?? [])
                  )}
                  style={{
                    width: itemWidth,
                    marginRight: spaceBetween,
                  }}
                  key={i}>
                  {_item.image && (
                    <Image
                      className={itemImage}
                      alt={_item.image.alt}
                      src={_item.image.src}
                      width={600}
                      height={600}
                      objectFit="cover"
                      objectPosition="center  top"
                    />
                  )}
                  {_item.note && <div className={itemNote}>{_item.note}</div>}
                </Grid>
              );
            })}
          </motion.div>
          {pagination && (
            <>
              <IconButton className="next" onClick={() => paginate(navAmount)}>
                <SVG src="/icons/arrow-right.svg" />
              </IconButton>
              <IconButton className="prev" onClick={() => paginate(-navAmount)}>
                <SVG src="/icons/arrow-left.svg" />
              </IconButton>
            </>
          )}
        </div>
        {generalNote && <div className="general_note">{generalNote}</div>}
        {navigation && (
          <div className="navigation">
            {items.map((_, index) => {
              const _activeSlideIdx =
                slideIdx < itemsPerView
                  ? slideIdx + itemsPerView
                  : slideIdx - itemsPerView;

              return (
                mod(index, navAmount as number) == 0 && (
                  <div
                    key={index}
                    className={classNames(
                      "navigation-item",
                      _activeSlideIdx == index && "active"
                    )}
                    onClick={() =>
                      slideIdx != index &&
                      paginate(
                        slideIdx > index
                          ? -(slideIdx - index)
                          : index - slideIdx
                      )
                    }
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    );
  }
).attrs((props) => ({
  itemVisibleClass: props.itemVisibleClass || "item-visible",
  itemDuplicateClass: props.itemDuplicateClass || "item-duplicate",
  itemNextClass: props.itemNextClass || "item-next",
  itemPrevClass: props.itemPrevClass || "item-prev",
  itemClass: props.itemClass || "carousel-item",
  itemActiveClass: props.itemActiveClass || "item-active",
  slideClass: props.slideClass || "carousel-slide",
  itemNote: props.itemNote || "item-note",
  itemImage: props.itemImage || "item-image",
}))`
  width: 100%;

  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 376px;
    overflow: hidden;

    ${IconButton} {
      position: absolute;
      top: calc(50% - 20px);
      z-index: 100;
      border-color: rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.15);

      svg {
        fill: #fff;
      }

      &.prev {
        left: 32px;
      }

      &.next {
        right: 32px;
      }
    }
  }

  ${({ slideClass }) => css`
    .${slideClass} {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  `}

  ${({
  itemClass,
  itemVisibleClass,
  itemActiveClass,
  itemNextClass,
  itemImage,
  itemNote,
}) => css`
    .${itemClass} {
      display: flex;
      flex-direction: column;
      align-items: center;
      float: left;
      height: 100%;
      position: relative;
      z-index: 5;

      

      .${itemNote} {
        margin-top: 20px;
      }

      .${itemImage} {
        position: relative;
        width: 100%;
        height: 100%;
        span {
          margin: auto !important;
          display: flex !important;
        }
      }

      &.${itemVisibleClass} {
        z-index: 25;
      }

      &.${itemActiveClass} {
        z-index: 50;
      }

      &.${itemNextClass} + .${itemClass} {
        z-index: 10;
      }
    }
  `}

  .general_note {
    font-style: italic;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    z-index: 100;

    &-item {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      margin: 6px;
      cursor: pointer;

      &.active {
        background: #55e0ff;
      }
    }
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    .container {
      ${IconButton} {
        &.prev {
          left: 20px;
        }

        &.next {
          right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    .container {
      ${IconButton} {
        &.prev {
          left: 10px;
        }

        &.next {
          right: 10px;
        }
      }
    }
  }
`;
