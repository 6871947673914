import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import { createContext } from "react";

interface IAuctionItem {
  item: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
  isActiveLot: boolean;
}

export const CollectionItemContext = createContext<IAuctionItem>(
  {} as IAuctionItem
);
