export const bidIncrement = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
  1500, 1600, 1700, 1800, 1900, 2000, 2200, 2400, 2600, 2800, 3000, 3200, 3500,
  3800, 4000, 4200, 4500, 4800, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500,
  9000, 9500, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000,
  19000, 20000, 22000, 24000, 26000, 28000, 30000, 32000, 35000, 38000, 40000,
  42000, 45000, 48000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000,
  90000, 95000, 100000, 110000, 120000, 130000, 140000, 150000, 160000, 170000,
  180000, 190000, 200000, 220000, 240000, 260000, 280000, 300000, 320000,
  350000, 380000, 400000, 420000, 450000, 480000, 500000, 550000, 600000,
  650000, 700000, 750000, 800000, 850000, 900000, 950000, 1000000, 1100000,
  1200000, 1250000, 1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000,
  2000000, 2200000, 2400000, 2600000, 2800000, 3000000, 3200000, 3500000,
  3800000, 4000000, 4200000, 4500000, 4800000, 5000000, 5500000, 6000000,
  6500000, 7000000, 7500000, 8000000, 8500000, 9000000, 9500000, 10000000,
  10000000, 11000000, 12000000, 13000000, 14000000, 15000000, 16000000,
  17000000, 18000000, 19000000, 20000000, 21000000, 22000000, 23000000,
  24000000, 25000000, 26000000, 27000000, 28000000, 29000000, 30000000,
  31000000, 32000000, 33000000, 34000000, 35000000, 36000000, 37000000,
  38000000, 39000000, 40000000, 41000000, 42000000, 43000000, 44000000,
  45000000, 46000000, 47000000, 48000000, 49000000, 50000000, 51000000,
  52000000, 53000000, 54000000, 55000000, 56000000, 57000000, 58000000,
  59000000, 60000000, 61000000, 62000000, 63000000, 64000000, 65000000,
  66000000, 67000000, 68000000, 69000000, 70000000, 71000000, 72000000,
  73000000, 74000000, 75000000, 76000000, 77000000, 78000000, 79000000,
  80000000, 81000000, 82000000, 83000000, 84000000, 85000000, 86000000,
  87000000, 88000000, 89000000, 90000000, 91000000, 92000000, 93000000,
  94000000, 95000000, 96000000, 97000000, 98000000, 99000000, 100000000,
  101000000, 102000000, 103000000, 104000000, 105000000, 106000000, 107000000,
  108000000, 109000000, 110000000,
];
