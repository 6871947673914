import { BrightcoveVideo, ImageMemo } from "@components";
import { ILotType, useLotType } from "@hooks";
import {
  IBrightcovePlayer,
  IContentfulImageItem,
  IContentfulLotData,
} from "@interfaces";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import { mod } from "@utils";
import React, { useContext, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { IconButton } from "../../button";
import { FullScreenView } from "../../fullScreenView";
import { CollectionItemContext } from "../state/auctionItemContext";
import { PreviewContentItemsNavigations } from "./previewItemsNavigation";
import { WithAnimatePresence } from "./withAnimationPresence";

const FullScreenPreview = styled(FullScreenView)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 80%;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  max-height: 85%;
  margin: 40px auto;
  border-radius: 10px;
  overflow: hidden;
`;

const FullScreenButton = styled((props) => <IconButton {...props} />)`
  && {
    position: absolute;
    z-index: 11;
    top: 10px;
    right: 10px;
    background-color: var(--app-bg);
    svg {
      min-width: 18px;
    }
  }
`;

interface INFTLinkPreview {
  src: string;
}

const NFTLinkPreview = styled(({ src, ...props }: INFTLinkPreview) => {
  return (
    <iframe src={src} frameBorder="0" width="100%" height="100%" {...props} />
  );
})`
  background: #000;
`;

interface IVideoIdPreview {
  activeItem: string;
  player: (p: IBrightcovePlayer) => void;
}

export const VideoIdPreview = styled(
  ({ activeItem, player, ...props }: IVideoIdPreview) => {
    return (
      <BrightcoveVideo
        {...props}
        autoplay={true}
        player={player}
        videoId={+activeItem}
      />
    );
  }
)`
  background: #000;
`;

interface IImagesPreview {
  activeItem: IContentfulImageItem;
}

const ImagesPreview = styled(({ activeItem, ...props }: IImagesPreview) => {
  return (
    <ImageMemo
      {...props}
      src={activeItem?.url}
      alt={activeItem?.title}
      layout="fill"
    />
  );
})`
  background: #000;
`;

export const PreviewContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ILotPreviewContent {
  lotData: IContentfulLotData;
  activeSlide: number;
}

export const LotPreviewContent = styled(
  ({ lotData, activeSlide }: ILotPreviewContent) => {
    const { dispatch } = useOverlayContext();

    const { isNFTLink, isVideo, isImages, isUnknownType }: ILotType =
      useLotType(lotData);

    const { isActiveLot } = useContext(CollectionItemContext);
    const player = useRef<IBrightcovePlayer>();
    const imageItems = lotData.imagesCollection.items;
    const nftVideos = lotData.nftVideoIds;

    const [[activeIndex, direction], setActive] = useState([0, 0]);

    const paginate = (newDirection: number) => {
      setActive([
        mod(activeIndex + newDirection, imageItems.length),
        newDirection,
      ]);
      if (isVideo) player?.current?.pause();
    };

    useEffect(() => {
      if (isVideo) player?.current?.pause();
    }, [activeSlide]);

    let previewContent: React.ReactNode = null;

    useEffect(() => {
      if (isVideo) {
        isActiveLot && activeSlide === 0
          ? player?.current?.play() && player?.current?.muted(true)
          : player?.current?.pause();
      }
    }, [isActiveLot]);

    switch (true) {
      case isNFTLink:
        previewContent = <NFTLinkPreview src={lotData.nftLink} />;
        break;

      case isVideo:
        previewContent = (
          <WithAnimatePresence
            paginate={paginate}
            direction={direction}
            id={nftVideos[activeIndex]}
            itemsAmount={nftVideos.length}>
            <VideoIdPreview
              activeItem={nftVideos[activeIndex]}
              player={(c: IBrightcovePlayer) => (player.current = c)}
            />
          </WithAnimatePresence>
        );
        break;

      case isImages:
        previewContent = (
          <WithAnimatePresence
            paginate={paginate}
            fillLayout={true}
            direction={direction}
            id={imageItems[activeIndex].url}
            itemsAmount={imageItems.length}>
            <ImagesPreview activeItem={imageItems[activeIndex]} />
          </WithAnimatePresence>
        );
        break;
    }

    const openFullscreen = () => {
      if (isVideo) {
        player?.current?.pause();
      }

      dispatch({
        type: EOverlayContextActionTypes.show,
        child: <FullScreenPreview>{previewContent}</FullScreenPreview>,
      });
    };

    return (
      <>
        {!isUnknownType && !isVideo && (
          <FullScreenButton onClick={openFullscreen}>
            <SVG src="/icons/full-view.svg" />
          </FullScreenButton>
        )}
        <PreviewContentItemsNavigations
          contentItems={imageItems}
          paginate={paginate}
          activeIndex={activeIndex}
          setActive={setActive}
        />
        <PreviewContentWrapper>{previewContent}</PreviewContentWrapper>
      </>
    );
  }
)``;
