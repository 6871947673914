import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@constants";
import {
  IMojitoCollection,
  IMojitoCollectionItem,
  IMojitoCollectionItemDetailsBid,
  IUseMojitoCollectionSubscription,
  IUseMojitoOneLotSubscription,
} from "@interfaces";
import { EMojitoQueries } from "@state";
import { queryClient } from "@utils";
import { mojitoNormalizer } from "@utils/gqlDataNormalizer.util";
import { SubscriptionClient } from "graphql-subscriptions-client";
import { useEffect, useRef } from "react";
import {
  EMojitoSubscriptions,
  mojitoSubscriptions,
} from "../data/graph_ql/subscriptions";
import isBrowser from "@utils/isBrowser";
import { SaleType } from "@enums";

let client: SubscriptionClient | null = null;

function getGqlSubscriptionClient(token?: string): SubscriptionClient | null {
  if (!client)
    client = isBrowser
      ? new SubscriptionClient(config.MOJITO_API_URL.replace("https", "wss"), {
          reconnect: true,
          lazy: true,
          connectionCallback: (error: any) => {
            error && console.error(error);
          },
          ...(token
            ? {
                connectionParams: {
                  authorization: `Bearer ${token}`,
                },
              }
            : {}),
        })
      : null;

  return client;
}

function useSubscription(
  cb: (token?: string) => void,
  subscriptionRef: { unsubscribe: () => void } | undefined
): void {
  const { isAuthenticated, getIdTokenClaims, isLoading } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!isLoading) {
        const token = isAuthenticated ? await getIdTokenClaims() : null;
        subscriptionRef?.unsubscribe?.();
        cb(token?.__raw);
      }
    })();
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    return () => {
      subscriptionRef?.unsubscribe?.();
    };
  }, []);
}

export function useMojitoOneLotSubscription(
  item: IMojitoCollectionItem,
  collectionSlug: string
): void {
  const subscription = useRef<{ unsubscribe: () => void }>();
  useSubscription(subscribe, subscription.current);

  function subscribe(token?: string) {
    if (item && item?.saleType == SaleType.Auction)
      subscription.current = getGqlSubscriptionClient(token)
        ?.request({
          query:
            mojitoSubscriptions[EMojitoSubscriptions.getMarketplaceAuctionLot],
          variables: { marketplaceAuctionLotId: item.details.id },
        })
        .subscribe(
          {
            next({
              data: { getMarketplaceAuctionLot: newLotDetails },
            }: IUseMojitoOneLotSubscription) {
              if (newLotDetails) {
                queryClient.setQueryData<IMojitoCollection>(
                  [
                    `Mojito ${
                      EMojitoQueries[EMojitoQueries.collectionBySlugCurrentBids]
                    }`,
                    {
                      marketplaceID: config.MARKETPLACE_ID,
                      slug: collectionSlug,
                    },
                  ],
                  (data) => {
                    if (!data) return {} as IMojitoCollection;
                    Object.assign(
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      data?.items?.find(
                        (e) => e.details.id === newLotDetails.id
                      )?.details,
                      newLotDetails
                    );
                    return mojitoNormalizer(
                      { collectionBySlug: data },
                      { slug: collectionSlug }
                    );
                  }
                );
              }
            },
          },
          (e: any) => console.error(e)
        );
    else if (config.ENVIRONMENT === "dev")
      console.error("Item should be on Auction type to subscribe", item);
  }
}

export function useCollectionItemBidsSubscription(
  item: IMojitoCollectionItem,
  collectionSlug: string
): any {
  const subscription = useRef<{ unsubscribe: () => void }>();
  useSubscription(subscribe, subscription.current);

  function subscribe(token?: string) {
    if (item && item?.saleType == SaleType.Auction)
      subscription.current = getGqlSubscriptionClient(token)
        ?.request({
          query: mojitoSubscriptions[EMojitoSubscriptions.collectionItemBids],
          variables: { marketplaceAuctionLotId: item.details.id },
        })
        .subscribe(
          {
            next({
              data: { getMarketplaceAuctionLot: newLotDetails },
            }: IUseMojitoOneLotSubscription) {
              if (newLotDetails) {
                queryClient.setQueryData<IMojitoCollectionItemDetailsBid[]>(
                  [
                    `Mojito ${
                      EMojitoQueries[EMojitoQueries.collectionItemByIdBidsList]
                    }`,
                    {
                      id: item.id,
                      slug: collectionSlug,
                    },
                  ],
                  () => {
                    return mojitoNormalizer(
                      {
                        collectionItemById: {
                          id: item.id,
                          details: newLotDetails,
                        },
                      },
                      { slug: collectionSlug }
                    );
                  }
                );
              }
            },
          },
          (e: any) => console.error(e)
        );
    else if (config.ENVIRONMENT === "dev")
      console.error("Item should be on Auction type to subscribe", item);
  }
}

export function useMojitoCollectionSubscription(
  collectionSlug: string,
  collectionId: string
): void {
  const subscription = useRef<{ unsubscribe: () => void }>();
  useSubscription(subscribe, subscription.current);

  function subscribe(token?: string) {
    subscription.current = getGqlSubscriptionClient(token)
      ?.request({
        query:
          mojitoSubscriptions[
            EMojitoSubscriptions.marketplaceCollectionLotsUpdates
          ],
        variables: { slug: collectionSlug, collectionId },
      })
      .subscribe(
        {
          next({
            data: { marketplaceCollectionLotsUpdates: newLotDetails },
          }: IUseMojitoCollectionSubscription) {
            if (newLotDetails) {
              queryClient.setQueryData<IMojitoCollection>(
                [
                  `Mojito ${
                    EMojitoQueries[EMojitoQueries.collectionBySlugCurrentBids]
                  }`,
                  {
                    slug: collectionSlug,
                    marketplaceID: config.MARKETPLACE_ID,
                  },
                ],
                (data) => {
                  if (!data) return {} as IMojitoCollection;
                  Object.assign(
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data?.items?.find((e) => e.details.id === newLotDetails.id)
                      ?.details,
                    newLotDetails
                  );
                  return mojitoNormalizer(
                    { collectionBySlug: data },
                    { slug: collectionSlug }
                  );
                }
              );
            }
          },
        },
        (e: any) => console.error(e)
      );
  }
}
