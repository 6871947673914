import { useCallback, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@constants";
import {
  IMojitoCollection,
  IMojitoCollectionItem,
  IMojitoCollectionItemCurrentBids,
  IMojitoCollectionItemDetailsBid,
} from "@interfaces";
import {
  EMojitoMutations,
  EMojitoQueries,
  IUseQueryResult,
  mojitoMutations,
  mojitoQueries,
  secondaryWalletQuery,
  secondaryWalletMutation,
  EMojitoSecondaryWalletQueries,
  EMojitoSecondaryWalletMutation,
} from "@state";
import { gqlRequest, queryClient } from "@utils";
import {
  mojitoNormalizer,
  SecondarymojitoNormalizer,
} from "@utils/gqlDataNormalizer.util";
import { useIsomorphicLayoutEffect, usePrevious } from "ahooks";
import { GraphQLClient } from "graphql-request";
import { Variables } from "graphql-request/dist/types";
import { useMutation, useQuery, useQueries } from "react-query";
import {
  UseMutationResult,
  UseQueryOptions,
} from "react-query/types/react/types";
import { useCollection } from "@hooks/useCollection";
import { useRouter } from "next/router";
import { isEmpty } from "@utils/wallet/numberFormet.util";

export const mojitoGqlClient = new GraphQLClient(config.MOJITO_API_URL);

export interface IUseMojitoOptions<T = any, V = Variables> {
  query: EMojitoQueries;
  variables?: V;
  options?: UseQueryOptions<T>;
  force?: boolean;
  onlyAuthenticated?: boolean;
  checkEmptyData?: boolean;
}

export interface UseMojitoSecondaryWalletOptions<T = any, V = Variables> {
  query: EMojitoSecondaryWalletQueries;
  variables?: V;
  ready?: boolean;
  options?: UseQueryOptions<T>;
  force?: boolean;
  onlyAuthenticated?: boolean;
}

export interface UseMojitoSecondaryWalletOptionsArr<T = any> {
  queries: any[];
  ready?: boolean;
  options?: UseQueryOptions<T>;
  force?: boolean;
  onlyAuthenticated?: boolean;
}

function mergeResponses(responses: any[]) {
  if (!responses.length) return null;

  const mergedResponse: any = {
    data: [],
    totalCount: 0,
    highestSoldPrice: null,
    latestSoldPrice: null,
    lowestSoldPrice: null,
    offsets: [],
    user: null,
  };

  const totalCountPages = responses.map((response) => {
    return response.data?.getAllRegistryTokens?.totalCount ?? 0;
  });

  const totalCountItems = responses.map((response) => {
    return response.data?.getAllRegistryTokens?.data?.length ?? 0;
  });

  function getItemSize(sizes: number[]): number[] {
    const totalItems = 20;
    let itemsToDistribute = totalItems;

    const initialDistribution: number[] = sizes.map((size) => {
      if (itemsToDistribute > 0 && size > 0) {
        itemsToDistribute--;
        return 1;
      }
      return 0;
    });

    const totalSizes: number = sizes.reduce((a, b) => a + b, 0);

    const proportionalDistribution: number[] = sizes.map((size) => {
      if (size === 0 || itemsToDistribute <= 0) {
        return 0;
      }

      const proportion = size / totalSizes;

      const itemsForThisArray = Math.min(
        itemsToDistribute,
        Math.floor(proportion * totalItems)
      );
      itemsToDistribute -= itemsForThisArray;

      return itemsForThisArray;
    });

    return initialDistribution.map(
      (val, idx) => val + proportionalDistribution[idx]
    );
  }

  const sizes: number[] = getItemSize(totalCountItems);

  const totalPagesPerCollection = totalCountPages.map((total, index) => {
    const size = sizes[index];

    if (typeof size !== "number" || size === 0) return 0;

    return Math.ceil(total / size);
  });

  mergedResponse.offsets = [];

  responses.forEach((response: any, index: number) => {
    if (response.data?.getAllRegistryTokens?.data) {
      const dataToPush = response.data.getAllRegistryTokens.data.slice(
        0,
        sizes[index]
      );

      mergedResponse.offsets.push({
        offset: sizes[index],
        limit: totalPagesPerCollection[index],
        total: totalCountPages[index],
      });
      mergedResponse.data.push(...dataToPush);
      mergedResponse.totalCount +=
        response.data.getAllRegistryTokens.totalCount;
    }
  });

  return mergedResponse;
}

export function UseSecondaryWalletMojitoArr<T = any>({
  queries,
  ready,
  options,
  onlyAuthenticated,
}: UseMojitoSecondaryWalletOptionsArr<T>): any {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const notIsAuthenticated = usePrevious(isAuthenticated);
  const router = useRouter();

  const queryFn = useCallback(
    async (queryConfig: any) => {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        if (token) {
          mojitoGqlClient.setHeader("authorization", `Bearer ${token.__raw}`);
        }
      } else if (onlyAuthenticated) {
        return null;
      }

      if (
        Object.values(queryConfig.variables ?? {}).some(
          (e: any) => isEmpty(e) && e !== 0
        )
      ) {
        return null;
      }

      return await gqlRequest({
        query:
          secondaryWalletQuery[
            queryConfig.query as keyof typeof secondaryWalletQuery
          ],
        variables: queryConfig.variables,
        normalizerFn: SecondarymojitoNormalizer,
        gqlClient: mojitoGqlClient,
        pathName: router.pathname,
      });
    },
    [isAuthenticated]
  );

  const queryConfigs = useMemo(() => {
    return queries.map((queryConfig, index) => ({
      queryKey: [
        `Mojito ${EMojitoSecondaryWalletQueries[queryConfig.query]}`,
        queryConfig.variables,
        index,
      ],
      queryFn: () => queryFn(queryConfig),
      ...options,
      meta: { authorization: isAuthenticated },
      enabled: ready,
    }));
  }, [queries, queryFn, ready, options]);

  // Run the queries
  const results = useQueries(queryConfigs);

  const refetchAll = async () => {
    await Promise.all(results.map((result) => result.refetch()));
  };

  const isLoading = results.some((result) => result.isLoading);
  const isSuccess = results.every((result) => result.status === "success");
  const errors = results.map((result) => result.error).filter(Boolean);

  let mergedData = null;

  if (isSuccess && !isLoading) {
    mergedData = mergeResponses(results);
  }

  useIsomorphicLayoutEffect(() => {
    if (isAuthenticated && !notIsAuthenticated) {
      if (onlyAuthenticated) {
        refetchAll();
      }
    }
  }, [isAuthenticated]);

  return {
    data: mergedData,
    loading: isLoading,
    error: errors,
  };
}

export function UseSecondaryWalletMojito<T = any>({
  query,
  variables,
  ready,
  options,
  force = false,
  onlyAuthenticated,
}: UseMojitoSecondaryWalletOptions<T, Variables>): IUseQueryResult {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const router = useRouter();
  const notIsAuthenticated = usePrevious(isAuthenticated);
  const queryKey: any = [
    `Mojito ${EMojitoSecondaryWalletQueries[query]}`,
    variables,
  ];
  const result = useQuery<T>(
    queryKey,

    async () => {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        if (token) {
          mojitoGqlClient.setHeader("authorization", `Bearer ${token.__raw}`);
        }
      } else if (onlyAuthenticated) {
        return null;
      }

      if (Object.values(variables ?? {}).some((e) => isEmpty(e) && e !== 0)) {
        return null;
      }

      return await gqlRequest<T>({
        query: secondaryWalletQuery[query],
        variables,
        normalizerFn: SecondarymojitoNormalizer,
        gqlClient: mojitoGqlClient,
        pathName: router.pathname,
      });
    },
    {
      ...options,
      meta: { authorization: isAuthenticated },
      enabled: ready,
    }
  );

  useIsomorphicLayoutEffect(() => {
    if (force) {
      queryClient.removeQueries(queryKey);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isAuthenticated && !notIsAuthenticated) {
      if (
        onlyAuthenticated &&
        queryClient.getQueryData(queryKey) == undefined
      ) {
        result.refetch();
      }
    }
  }, [isAuthenticated]);

  return {
    loading: result.isLoading,
    ...result,
  };
}

export function UseSecondaryWalletMojitoMutation<T = any>(
  query: EMojitoSecondaryWalletMutation,
  onlyAuthenticated = false
): [
  UseMutationResult<T, any, Variables, any>["mutateAsync"],
  UseMutationResult<T, any, Variables, any>
] {
  const { getIdTokenClaims } = useAuth0();
  const router = useRouter();

  const res = useMutation<T, any, Variables, any>(
    async (variables: Variables) => {
      const token = await getIdTokenClaims();

      if (token) {
        mojitoGqlClient.setHeader("authorization", `Bearer ${token.__raw}`);
      } else if (onlyAuthenticated) {
        return null;
      }

      if (Object.values(variables ?? {}).some((e) => isEmpty(e) && e !== 0)) {
        return null;
      }

      return await gqlRequest<T>({
        query: secondaryWalletMutation[query],
        variables,
        normalizerFn: mojitoNormalizer,
        gqlClient: mojitoGqlClient,
        pathName: router.pathname,
      });
    }
  );
  return [res.mutateAsync, res];
}

export function useMojito<T = any>({
  query,
  variables,
  options,
  force = false,
  checkEmptyData = true,
  onlyAuthenticated,
}: IUseMojitoOptions<T, Variables>): IUseQueryResult {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const notIsAuthenticated = usePrevious(isAuthenticated);
  const queryKey: any = [`Mojito ${EMojitoQueries[query]}`, variables];

  const result = useQuery<T>(
    queryKey,
    async () => {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        if (token) {
          mojitoGqlClient.setHeader("authorization", `Bearer ${token.__raw}`);
        }
      } else if (onlyAuthenticated) {
        return null;
      }

      if (checkEmptyData) {
        if (Object.values(variables ?? {}).some((e) => !e)) {
          return null;
        }
      }

      return await gqlRequest<T>({
        query: mojitoQueries[query],
        variables,
        normalizerFn: mojitoNormalizer,
        gqlClient: mojitoGqlClient,
      });
    },
    {
      ...options,
      meta: { authorization: isAuthenticated },
      enabled: !onlyAuthenticated,
    }
  );

  useIsomorphicLayoutEffect(() => {
    if (force) {
      queryClient.removeQueries(queryKey);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isAuthenticated && !notIsAuthenticated) {
      if (
        onlyAuthenticated &&
        queryClient.getQueryData(queryKey) == undefined
      ) {
        result.refetch();
      }
    }
  }, [isAuthenticated]);

  return {
    loading: result.isLoading,
    ...result,
  };
}

export function useLazyMojito<T = any>({
  query,
  variables,
  options,
  force = false,
  onlyAuthenticated,
}: IUseMojitoOptions<T, Variables>): [
  (options?: UseQueryOptions<T>) => void,
  IUseQueryResult
] {
  options = {
    enabled: false,
    ...options,
  };
  const result = useMojito({
    query,
    variables,
    options,
    force,
    onlyAuthenticated,
  });

  return [
    result.refetch,
    {
      ...result,
    },
  ];
}

export function useMojitoMutation<T = any>(
  query: EMojitoMutations,
  onlyAuthenticated = false
): [
  UseMutationResult<T, any, Variables, any>["mutateAsync"],
  UseMutationResult<T, any, Variables, any>
] {
  const { getIdTokenClaims } = useAuth0();
  const res = useMutation<T, any, Variables, any>(
    async (variables: Variables) => {
      const token = await getIdTokenClaims();
      if (token) {
        mojitoGqlClient.setHeader("authorization", `Bearer ${token.__raw}`);
      } else if (onlyAuthenticated) {
        return null;
      }

      if (Object.values(variables ?? {}).some((e) => !e)) {
        return null;
      }
      return await gqlRequest<T>({
        query: mojitoMutations[query],
        variables,
        normalizerFn: mojitoNormalizer,
        gqlClient: mojitoGqlClient,
      });
    }
  );
  return [res.mutateAsync, res];
}

export function useCollectionLotsIdList(slug: string): {
  collectionLotsIdList: Pick<IMojitoCollectionItem<any>, "id" | "name">[];
  collectionLoading: boolean;
  collectionError: IUseQueryResult["error"];
} {
  const { data, error, loading } = useMojito({
    query: EMojitoQueries.collectionLotsIdList,
    variables: { slug, marketplaceID: config.MARKETPLACE_ID },
  });

  return {
    collectionLotsIdList: data?.items ?? [],
    collectionLoading: loading,
    collectionError: error,
  };
}

export function useMarketplaceCollectionsSlugWithItemsId(): {
  marketplaceCollectionsSlugWithItemsId: IMojitoCollection[];
  marketplaceCollectionsSlugWithItemsIdLoading: boolean;
  marketplaceCollectionsSlugWithItemsIdError: IUseQueryResult["error"];
} {
  const { data, error, loading } = useMojito({
    query: EMojitoQueries.marketplaceCollectionsInfoWithItemsIdAndSlug,
    variables: { id: config.MARKETPLACE_ID },
  });

  return {
    marketplaceCollectionsSlugWithItemsId: data?.marketplace?.collections,
    marketplaceCollectionsSlugWithItemsIdLoading: loading,
    marketplaceCollectionsSlugWithItemsIdError: error,
  };
}

export function useCollectionItemCurrentBids(
  id?: string,
  _slug?: string
): {
  allCurrentBids: IMojitoCollectionItemCurrentBids[];
  currentBids: IMojitoCollectionItemCurrentBids;
  currentBidsLoading: boolean;
  currentBidsError: IUseQueryResult["error"];
  currentBidsRefetch: () => void;
} {
  const { slug } = useCollection();
  const { data, error, loading, refetch } = useMojito({
    query: EMojitoQueries.collectionBySlugCurrentBids,
    variables: { slug: _slug ?? slug, marketplaceID: config.MARKETPLACE_ID },
  });

  return {
    allCurrentBids: data?.items,
    currentBids: id
      ? data?.items?.find(
          (item: IMojitoCollectionItemCurrentBids) => item.id == id
        )
      : undefined,
    currentBidsLoading: loading,
    currentBidsError: error,
    currentBidsRefetch: refetch,
  };
}

export function useCollectionItemBidsList(
  id: string,
  _slug?: string
): {
  bids: IMojitoCollectionItemDetailsBid[];
  bidsLoading: boolean;
  bidsError: IUseQueryResult["error"];
  bidsRefetch: () => void;
} {
  const { slug } = useCollection();
  const { data, error, loading, refetch } = useMojito({
    query: EMojitoQueries.collectionItemByIdBidsList,
    variables: { id, slug: _slug ?? slug },
  });

  return {
    bids: data?.details?.bids,
    bidsLoading: loading,
    bidsError: error,
    bidsRefetch: refetch,
  };
}

export function useCollectionItemRemainingCount(
  id: string,
  _slug?: string
): {
  remainingCount: number;
  remainingCountLoading: boolean;
  remainingCountError: IUseQueryResult["error"];
  remainingCountRefetch: () => void;
} {
  const { slug } = useCollection();
  const { data, error, loading, refetch } = useMojito({
    query: EMojitoQueries.collectionItemByIdRemainingCount,
    variables: { id, slug: _slug ?? slug },
    onlyAuthenticated: true,
  });

  return {
    remainingCount: data?.details?.remainingCount,
    remainingCountLoading: loading,
    remainingCountError: error,
    remainingCountRefetch: refetch,
  };
}
