import {
  useCollection,
  useMarketplaceCollectionsSlugWithItemsId,
} from "@hooks";
import { IMojitoCollectionItem } from "@interfaces";
import { useRouter } from "next/router";
import { useMemo } from "react";

export interface IPageType {
  isProfileListings: boolean;
  isHome: boolean;
  isLots: boolean;
  isOneLot: boolean;
  is404: boolean;
  isFAQ: boolean;
  isSearch: boolean;
  isOnboarding: boolean;
  isOnboardingCompleted: boolean;
  isProfile: boolean;
  isProfileActivity: boolean;
  isProfileInvoices: boolean;
  isProfileSettings: boolean;
  isProfileWallet: boolean;
  isProfileSave: boolean;
  isProfileWalletItem: boolean;
  isProfileSupport: boolean;
  isAuction: boolean;
  isAuctionPath: boolean;
  isOneAuthor: boolean;
  isPrivacyPolicy: boolean;
  isBusinessConditions: boolean;
  isTerms: boolean;
  isAcceptableUse: boolean;
  isTakeDownPolicy: boolean;
  isGuideToBuying: boolean;
  _path: string[];
  _resolvedUrl: string;
  _auctionSlug: string;
  _lotSlug: string;
}

export function usePageType(props?: { url?: string }): IPageType {
  const router = useRouter();
  const path =
    (props?.url ?? router.asPath).split("#")[0].split("?")[0].split("/") || [];
  const { marketplaceCollectionsSlugWithItemsId } =
    useMarketplaceCollectionsSlugWithItemsId();
  const { isAuction, isFakeAuction, collection } = useCollection();

  return useMemo(() => {
    const lotsSlugList =
      collection?.items
        ?.map((e: IMojitoCollectionItem<any>) => [
          e?.slug,
          e?.contentfulData?.slug,
        ])
        ?.flat() || [];

    const pageTypes: any = {
      isHome: path[0] == "" && path[1] == "",
      isAuctionPath: !!marketplaceCollectionsSlugWithItemsId?.find(
        (collection) => collection.slug == path[1]
      ),
      isAuction: path.length === 2 && (isAuction || isFakeAuction),
      isLots:
        (isAuction || isFakeAuction) &&
        (path[2] === "lots" || path[2] === "auction") &&
        path.length === 3,
      isOneLot:
        (isAuction || isFakeAuction) &&
        (path[2] === "lots" || path[2] === "auction") &&
        lotsSlugList.includes(path[3]) &&
        path.length === 4,
      isSearch: path[1] === "search" && path.length === 2,
      isOneAuthor: path[1] === "authors" && path.length === 3,
      isOnboarding: path[1] === "onboarding" && path.length === 2,
      isOnboardingCompleted: path[1] === "onboarding" && path.length === 3,
      isPrivacyPolicy: path[1] === "privacy-policy" && path.length === 2,
      isBusinessConditions:
        path[1] === "conditions-of-business" && path.length === 2,
      isFAQ: path[1] === "faq" && path.length === 2,
      isTerms: path[1] === "terms-of-service" && path.length === 2,
      isAcceptableUse: path[1] === "acceptable-use-policy" && path.length === 2,
      isGuideToBuying: path[1] === "guide-to-buying" && path.length === 2,
      isTakeDownPolicy: path[1] === "take-down-policy" && path.length === 2,
      isProfile: path[1] === "profile" && path.length === 2,
      isProfileActivity:
        path[1] === "profile" && path[2] === "activity" && path.length === 3,
      isProfileWallet:
        path[1] === "profile" && path[2] === "wallet" && path.length === 3,
      isProfileSave:
        path[1] === "profile" && path[2] === "saved" && path.length === 3,
      isProfileListings:
        path[1] === "profile" && path[2] === "listings" && path.length === 3,
      isProfileWalletItem:
        path[1] === "profile" &&
        path[2] === "wallet" &&
        !!path[3]?.length &&
        !!path[4]?.length &&
        path.length === 5,
      isProfileInvoices:
        path[1] === "profile" && path[2] === "invoices" && path.length === 3,
      isProfileSettings:
        path[1] === "profile" && path[2] === "settings" && path.length === 3,
      isProfileSupport:
        path[1] === "profile" && path[2] === "support" && path.length === 3,
      is404: false,
    };
    pageTypes.is404 = !Object.values(pageTypes).some((e) => e);
    pageTypes._path = path;
    pageTypes._resolvedUrl = path.join("/");
    pageTypes._auctionSlug = pageTypes.isAuction && path[1];
    pageTypes._lotSlug = pageTypes.isOneLot && path[3];

    return pageTypes;
  }, [router.asPath, !!collection]);
}
