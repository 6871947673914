import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";

export const FullScreenView = styled(({ children, ...props }) => {
  const { dispatch } = useOverlayContext();
  const node = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (node?.current && node.current.contains(e.target)) {
      return;
    }
    dispatch({ type: EOverlayContextActionTypes.hide });
  };

  return (
    <div ref={node} {...props}>
      {children}
    </div>
  );
})``;
