import { EViewSize } from "@enums";
import { createGlobalStyle } from "styled-components";

export const ThemeStyles = createGlobalStyle`
  body {
    --app-bg: rgb(240, 240, 240, 240);
    --text-color: rgb(10, 10, 10);
    --text-reversed: rgb(240, 240, 240);

    --text-black: rgb(10, 10, 10);
    --text-white: rgb(240, 240, 240);
    --text-error: rgb(254, 21, 188);
    --bid-text-status: #292929;

    --auction-active-status: #55E0FF;

    --link-color: #e6c3ff;

    --base-btn-primary-bg: linear-gradient(0deg, rgba(66, 0, 96, 0.4), rgba(66, 0, 96, 0.4)), linear-gradient(301.04deg, #55FFBC -66.81%, #BA71DB 90.61%);
    --base-btn-secondary-bg: linear-gradient(301.04deg, rgba(85, 255, 188, 0.2) -66.81%, rgba(186, 113, 219, 0.2) 90.61%);
    --base-btn-outlined-bg: linear-gradient(301.04deg, rgba(85, 255, 188, 0.15) -66.81%, rgba(186, 113, 219, 0.15) 90.61%);
    --base-btn-primary-active-bg: linear-gradient(0deg, rgba(62, 161, 183, 0.4), rgba(62, 161, 183, 0.4)), linear-gradient(301.04deg, #55FFBC -66.81%, #BA71DB 90.61%);
    --base-btn-border-color: rgba(255, 255, 255, 0.25);
    --base-btn-box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.207441), 1px 1px 2px rgba(0, 0, 0, 0.02), -12px -12px 21px rgba(255, 255, 255, 0.1), 15px 15px 21px rgba(0, 0, 0, 0.08), inset 1px 1px 1px rgba(255, 255, 255, 0.141013);
    --base-btn-hover-bg: rgba(240, 240, 240, 0.1);

    --outlined-btn-border-color: rgba(0, 0, 0, 0.0 .08);

    --icon-btn-color: rgb(0, 0, 0);
    --icon-btn-bg: rgba(240, 240, 240, 0.1);
    --icon-btn-border-color: rgba(255, 255, 255, 0.25);
    --icon-btn-hover-bg: rgba(240, 240, 240, 0.05);

    --input-color: rgba(0, 0, 0, 0.25);
    --input-border-color: rgba(228, 228, 228, 1);
    --input-border-error-color: rgba(254, 21, 188, 1);
    --input-border-radius: 10px;

    --notification-bg-color: #f392d9;

    // @media (prefers-color-scheme: dark)
    --outbid-text-color: rgba(245, 177, 0, 1);
    --bid-text-color: rgba(106, 161, 63, 1);
    --auth-menu-bg: rgba(226, 224, 247, 0.2);


    --lot-bid-status-high: rgba(0, 255, 13, 0.4);
    --lot-bid-status-outbid: rgba(255, 155, 0, 0.3);
    --lot-bid-status-lost: rgba(255, 16, 0, 0.4);
    --lot-bid-status-win: linear-gradient(90deg, rgba(191, 135, 232, 0.4) 0%, rgba(85, 224, 255, 0.4) 100%);
    --lot-bid-status-sold: transparent;
    --lot-bid-status-current: transparent;

    --skeleton-color: rgba(0, 0, 0, 0.1);

    --avatar-box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.207441), 1px 1px 2px rgba(0, 0, 0, 0.02), -12px -12px 21px rgba(255, 255, 255, 0.1), 5px 5px 21px rgba(0, 0, 0, 0.08), inset 1px 1px 1px rgba(255, 255, 255, 0.141013);

    --header-note-height: 30px;
    --header-nav-height: 72px;

    --style-header-height: ${() =>
      "calc(var(--header-note-height) + var(--header-nav-height))"}; // 72 + 30 for notification
    --style-footer-height: 50px;

    &.dark {
      --app-bg: #fff;
      --text-color: rgb(10, 10, 10);
      --text-reversed: #fff;
      --bid-text-status: #fff;

      --link-color: #5a2a7c;

      --base-btn-primary-bg: linear-gradient(0deg, rgba(66, 0, 96, 0.4), rgba(66, 0, 96, 0.4)), linear-gradient(301.04deg, #55FFBC -66.81%, #BA71DB 90.61%);
      --base-btn-secondary-bg: linear-gradient(301.04deg, rgba(85, 255, 188, 0.2) -66.81%, rgba(186, 113, 219, 0.2) 90.61%);
      --base-btn-border-color: rgba(255, 255, 255, 0.25);
      --base-btn-hover-bg: rgba(240, 240, 240, 0.1);

      --outlined-btn-border-color: rgba(255, 255, 255, 0.35);

      --icon-btn-color: rgb(255, 255, 255);
      --icon-btn-bg: rgba(240, 240, 240, 0.03);
      --icon-btn-border-color: rgba(255, 255, 255, 0.25);
      --icon-btn-hover-bg: rgba(240, 240, 240, 0.05);
      --auth-menu-bg: rgba(24, 13, 21, 0.2);

      --input-color: rgba(255, 255, 255, 0.25);

      --notification-bg-color: #7c1663;

      --skeleton-color: rgba(240, 240, 240, 0.1);

      --light-gray: #EEEEEE;
      --navy-blue: #042439;
      --black: #000;
      --light-black: #E3E3E3;
      --gray-black: #838383;
      --slight-gray: #B3B3B3;
      --slight-red: #805D5D;
      --scrollbar: #c1c1c1;
    }


    @media screen and (max-width: ${EViewSize.mobile}) {
      --header-nav-height: 50px;
      --style-header-height: ${() =>
        "calc(var(--header-note-height) + var(--header-nav-height))"}; // 72 + 30 for notification
    }
  }
`;
