import { gql } from "graphql-request";

export enum EMojitoMutations {
  saveCollectionItemToFavorites,
  removeCollectionItemFromFavorites,
  createBid,
  updateUserOrgSettings,
  transferToken,
  redeemPromoCode,
  redeemClaimableItem,
  checkTokenOwners,
}

export const mojitoMutations = {
  [EMojitoMutations.saveCollectionItemToFavorites]: gql`
    mutation AddCollectionItemToUserFavorites($collectionItemId: UUID1!) {
      addCollectionItemToUserFavorites(collectionItemId: $collectionItemId)
    }
  `,
  [EMojitoMutations.removeCollectionItemFromFavorites]: gql`
    mutation RemoveCollectionItemFromUserFavorites($collectionItemId: UUID1!) {
      deleteCollectionItemFromUserFavorites(collectionItemId: $collectionItemId)
    }
  `,
  [EMojitoMutations.createBid]: gql`
    mutation CreateMarketplaceAuctionBid(
      $marketplaceAuctionLotId: UUID!
      $amount: Float!
    ) {
      createMarketplaceAuctionBid(
        marketplaceAuctionBid: {
          marketplaceAuctionLotId: $marketplaceAuctionLotId
          amount: $amount
        }
      ) {
        id
        amount
        marketplaceAuctionLotId
        userId
      }
    }
  `,
  [EMojitoMutations.updateUserOrgSettings]: gql`
    mutation (
      $userOrgId: String!
      $username: String
      $avatar: String
      $settingsJson: String
    ) {
      updateUserOrgSettings(
        params: {
          userOrgId: $userOrgId
          username: $username
          avatar: $avatar
          settingsJson: $settingsJson
        }
      ) {
        id
      }
    }
  `,
  [EMojitoMutations.transferToken]: gql`
    mutation (
      $walletId: UUID1!
      $tokenOnChainId: Int!
      $contractAddress: String!
      $transferTo: String!
      $tokenType: TokenType
      $orgId: UUID1!
    ) {
      transferToken(
        walletId: $walletId
        tokenOnChainId: $tokenOnChainId
        contractAddress: $contractAddress
        transferTo: $transferTo
        tokenType: $tokenType
        orgId: $orgId
      )
    }
  `,
  [EMojitoMutations.redeemPromoCode]: gql`
    mutation redeemClaimableCode($code: String!, $destAddr: String) {
      redeemClaimableCode(code: $code, destAddr: $destAddr)
    }
  `,
  [EMojitoMutations.redeemClaimableItem]: gql`
    mutation redeemClaimableItem($claimableItemId: UUID1!, $destAddr: String) {
      redeemClaimableItem(claimableItemId: $claimableItemId, destAddr: $destAddr)
    }
  `,
  [EMojitoMutations.checkTokenOwners]: gql`
    mutation checkTokenOwners($contractId: UUID1!, $walletAddress: String!, $rangeStart: Int!, $rangeEnd: Int!) {
      checkTokenOwners(contractId: $contractId, walletAddress: $walletAddress, rangeStart: $rangeStart, rangeEnd: $rangeEnd)
    }
  `,
};
