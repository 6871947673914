import { EViewSize } from "@enums";
import classNames from "classnames";
import styled from "styled-components";
import { Image } from "./image";

export const ParagraphTitle = styled.div`
  font-family: "MercuryDisplay", serif;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;

  ${Image} {
    float: left;
    display: flex;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
  }

  @media screen and (max-width: ${EViewSize.large}) {
    font-size: calc(32px * 0.95);
  }

  @media screen and (max-width: ${EViewSize.desktop}) {
    font-size: calc(32px * 0.9);
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    font-size: calc(32px * 0.85);
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    font-size: calc(32px * 0.8);
  }
`;

export const Paragraph = styled(({ full = false, ...props }) => (
  <div className={classNames(props.className, full && "full")}>
    {props.children}
  </div>
))`
  width: 100%;
  font-size: 18px;
  max-width: 668px;

  &.full {
    max-width: 100%;
  }

  @media screen and (max-width: ${EViewSize.large}) {
    p,
    .paragraph {
      font-size: 0.95em;
    }
  }

  @media screen and (max-width: ${EViewSize.desktop}) {
    p,
    .paragraph {
      font-size: 0.9em;
    }
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    p,
    .paragraph {
      font-size: 0.85em;
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    p,
    .paragraph {
      font-size: 0.8em;
    }
  }
`;
