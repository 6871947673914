import { useAuth0 } from "@auth0/auth0-react";
import { BaseButton, Image, Loader } from "@components";
import { EEventNames, EPagePath, EViewSize } from "@enums";
import Link from "next/link";
import {
  useCollection,
  useCollectionItemBidsList,
  useCollectionItemCurrentBids,
  useDeviceResponsive,
  usePageType,
  usePlaceBidMutation,
  useProfile,
  useTrackingEventFactory,
} from "@hooks";
import {
  IContentfulLotData,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import { Checkbox, Grid } from "@material-ui/core";
import { formatCurrency, premiumCalculator } from "@utils";
import { useIsomorphicLayoutEffect, useLocalStorageState } from "ahooks";
import React, { memo, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { bidIncrement } from "src/utils/bidIncrement";
import styled from "styled-components";
import { LotTimerAndStatus } from "@shared/lotTimerAndStatus";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";

const BidModalLotImage = styled(
  ({
    src = "/images/image-placeholder.jpg",
    alt = "",
    ...props
  }: {
    src: string;
    alt: string;
  }) => {
    return (
      <div {...props}>
        <Image
          src={src}
          alt={alt}
          width={405}
          layout={"fill"}
          objectFit={"cover"}
          quality={75}
        />
      </div>
    );
  }
)`
  border-radius: 5px;
  overflow: hidden;
  height: 405px;
  width: 405px;
  margin-right: 30px;
  position: relative;
  flex: 0 0 405px;

  @media screen and (max-width: ${EViewSize.desktop}) {
    height: 120px;
    width: 100%;
    margin: 0 0 20px;
    flex: 0 0 120px;
  }
`;

const Error = styled.div`
  color: #fe15bc;
  font-size: 12px;
  margin-top: 2px;
`;

const BidModalTitle = styled.div`
  font-family: "Mercury Text G1";
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.0025em;
  margin-bottom: 20px;
`;

const BidModalText = styled.div`
  font-family: "BentonSans", serif;
  font-size: 12px;
  line-height: 14.5px;
  letter-spacing: 0.0025em;
  label {
    cursor: pointer;

    a {
      text-decoration: underline;
    }
  }
`;

const BidModalCurrent = styled((props) => <BidModalText {...props} />)`
  && {
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;
const StyledSelect = styled((props) => <Select {...props} />)`
  && {
    width: 195px;
    margin-bottom: 14px;

    input {
      text-align: center;
    }

    &:before,
    &:after {
      display: none;
    }
  }
`;

const BidPremium = styled.div`
  background: #f8f8f8;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 16px 20px;
`;

const BidPremiumRow = styled.div<{ $bold?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: "BentonSans";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0025em;

  ${({ $bold }) => $bold && "font-weight: 600"}
`;

const BidPremiumLine = styled.div`
  border: 1px solid #e4e4e4;
  margin: 20px -0;
`;

const AgreementCheckbox = styled((props) => <Checkbox {...props} />)`
  && {
    width: 14px;
    padding: 0 20px;
  }
`;

interface IBidModal {
  lotData: IContentfulLotData;
  minBid: number;
  availableOptions: {
    value: number;
    label: string;
  }[];
  mojitoItemDetails?: IMojitoCollectionItemAuctionLot;
}

const BidModalMemo = styled(
  memo(
    ({
      lotData,
      minBid,
      mojitoItemDetails,
      availableOptions,
      ...props
    }: IBidModal) => {
      const { slug } = useCollection();
      const { user } = useAuth0();
      const modalTimerMemo = useRef<ReturnType<typeof setTimeout> | null>(null);
      const { dispatch } = useOverlayContext();
      const [bidAgreementSigned, setBidAgreementSigned] = useLocalStorageState<any>(
        `bid-agreement-signed_${slug}_${user?.id}`,
        { defaultValue: false }
      );
      const { currentBids } = useCollectionItemCurrentBids(lotData.mojitoId);
      const currentBid = useRef<number>(
        currentBids?.details.currentBid?.amount ?? 0
      );
      const submittedAmount = useRef<number | null>(null);
      const [conditionsSigned, setConditionsSigned] =
        useState<boolean>(bidAgreementSigned);
      const [selfPurchaseSigned, setSelfPurchaseSigned] =
        useState<boolean>(bidAgreementSigned);
      const [error, setError] = useState<any>(null);
      const [placeBid] = usePlaceBidMutation(lotData);
      const { isMobile } = useDeviceResponsive();
      const [bidAmount, setBidAmount] = useState<number>(
        availableOptions[0].value
      );

      const [fee, setFee] = useState({
        buyersPremiumRate: 0,
        overheadPremiumRate: 0,
      });
      const trackEvent = useTrackingEventFactory();

      useEffect(() => {
        if (mojitoItemDetails?.feeStructure)
          setFee(premiumCalculator(bidAmount, mojitoItemDetails?.feeStructure));
      }, [mojitoItemDetails?.feeStructure, bidAmount]);

      const canSubmit =
        conditionsSigned &&
        selfPurchaseSigned &&
        bidAmount != null &&
        bidAmount != undefined &&
        bidAmount >= minBid &&
        bidAmount <= bidIncrement[bidIncrement.length - 1];

      const closeOverlay = () => {
        modalTimerMemo?.current && clearTimeout(modalTimerMemo?.current);

        if (!bidAgreementSigned && submittedAmount?.current)
          setBidAgreementSigned(true);

        dispatch({
          type: EOverlayContextActionTypes.hide,
        });
      };

      const onSubmit = async () => {
        modalTimerMemo?.current && clearTimeout(modalTimerMemo?.current);
        submittedAmount.current = bidAmount;
        if (!canSubmit) return;
        if (!bidAgreementSigned) {
          trackEvent(EEventNames.TermsAccepted, { terms: "auction terms" });
        }
        try {
          return await placeBid({
            amount: bidAmount,
            marketplaceAuctionLotId: mojitoItemDetails?.id,
          }).then(() => {
            modalTimerMemo.current = setTimeout(() => {
              closeOverlay();
            }, 4000);
          });
        } catch (e: any) {
          setError(e?.message);
          setTimeout(() => setError(null), 4000);
          return null;
        }
      };

      useIsomorphicLayoutEffect(() => {
        setBidAmount(availableOptions[0].value);
      }, [availableOptions[0].value]);

      const bidOnChange = (e: any) => {
        const value = e.value;
        if (parseFloat(value) < minBid) {
          setError("Bid amount can't be less than " + minBid.toString());
        } else {
          setError(null);
        }
        setBidAmount(value);
      };

      return lotData?.author ? (
        <div {...props}>
          <BidModalLotImage
            src={lotData.imagesCollection.items[0].url}
            alt={lotData.title}
          />
          <div style={isMobile ? { overflowY: "scroll" } : {}}>
            <BidModalTitle>{`Confirm your bid of ${formatCurrency(
              submittedAmount?.current ?? bidAmount
            )}`}</BidModalTitle>
            <LotTimerAndStatus onlyTimer mojitoId={lotData.mojitoId} />
            <BidModalCurrent>
              {currentBid?.current
                ? `Current bid ${formatCurrency(currentBid?.current)}`
                : `Starting bid ${formatCurrency(
                    mojitoItemDetails?.startingBid ?? 0
                  )}`}
            </BidModalCurrent>
            <BidModalText style={{ marginBottom: 10 }}>
              If you place your maximum limit, the system will automatically
              keep you at the top within the next increment until your limit is
              met.
            </BidModalText>
            <BidPremium>
              <BidPremiumRow>
                Your max. bid
                <StyledSelect
                  components={{ IndicatorSeparator: () => null }}
                  onChange={bidOnChange}
                  menuShouldScrollIntoView={true}
                  isSearchable={false}
                  isDisabled={
                    !!submittedAmount?.current ||
                    bidAmount > bidIncrement[bidIncrement.length - 1]
                  }
                  value={
                    submittedAmount?.current
                      ? {
                          value: submittedAmount?.current,
                          label: formatCurrency(submittedAmount?.current),
                        }
                      : bidAmount
                      ? { value: bidAmount, label: formatCurrency(bidAmount) }
                      : {
                          value: availableOptions[0].value,
                          label: formatCurrency(availableOptions[0].value),
                        }
                  }
                  options={availableOptions}
                />
              </BidPremiumRow>
              <BidPremiumRow>
                Max buyer’s premium
                <span>{formatCurrency(fee.buyersPremiumRate)}</span>
              </BidPremiumRow>
              <BidPremiumRow>
                Overhead premium
                <span>{formatCurrency(fee.overheadPremiumRate)}</span>
              </BidPremiumRow>
              <BidPremiumLine />
              <BidPremiumRow $bold>
                Max. Total
                <span>
                  {formatCurrency(
                    bidAmount + fee.buyersPremiumRate + fee.overheadPremiumRate
                  )}
                </span>
              </BidPremiumRow>
              <BidPremiumRow style={{ color: "#767676" }}>
                Total price excludes any applicable tax
              </BidPremiumRow>
            </BidPremium>
            {!bidAgreementSigned && (
              <>
                <div className="agreement">
                  <AgreementCheckbox
                    id={"bidAgreement"}
                    disabled={!!submittedAmount?.current}
                    checked={conditionsSigned}
                    onChange={() => setConditionsSigned(!conditionsSigned)}
                  />
                  <BidModalText>
                    <label htmlFor={"bidAgreement"}>
                      By checking this box you confirm that you have read and
                      agree to the{" "}
                      <Link href={EPagePath.PrivacyPolicy} passHref>
                        <a onClick={closeOverlay}>Privacy Policy</a>
                      </Link>{" "}
                      and the{" "}
                      <Link href={EPagePath.ConditionsOfBusiness} passHref>
                        <a onClick={closeOverlay}>Conditions of Business</a>
                      </Link>
                    </label>
                  </BidModalText>
                </div>
                <div className="agreement">
                  <AgreementCheckbox
                    id={"selfPurchaseAgreement"}
                    disabled={!!submittedAmount?.current}
                    checked={selfPurchaseSigned}
                    onChange={() => setSelfPurchaseSigned(!selfPurchaseSigned)}
                  />
                  <BidModalText>
                    <label htmlFor={"selfPurchaseAgreement"}>
                      Due to Anti-Money Laundering & Sanction regulations
                      Sotheby’s is required to confirm that in this sale, you
                      confirm that you are purchasing for yourself, and not
                      acting as an agent. If you are acting as agent, please
                      email bids.newyork@sothebys.com with details of your
                      principal and a copy of their ID
                    </label>
                  </BidModalText>
                </div>
              </>
            )}
            {submittedAmount?.current ? (
              <BaseButton
                className="bidPlaced"
                secondary
                onClick={closeOverlay}>
                {formatCurrency(submittedAmount?.current)} Bid Placed
              </BaseButton>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs display={"flex"}>
                    <BaseButton disabled={!canSubmit} onClick={onSubmit}>
                      {currentBids?.details?.currentBid?.isHold
                        ? "Increase Bid"
                        : "Bid now"}
                    </BaseButton>
                  </Grid>
                  <Grid item className="cancel-bid">
                    <BidModalText onClick={closeOverlay}>Cancel</BidModalText>
                  </Grid>
                </Grid>
                <Error>{error}</Error>
              </>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      );
    },
    (p, n) => {
      const isStartingBidSame =
        p.mojitoItemDetails?.startingBid === n.mojitoItemDetails?.startingBid;
      const isAvailableOptionsSame =
        p.availableOptions.length === n.availableOptions.length;

      return isAvailableOptionsSame && isStartingBidSame;
    }
  )
)`
  ${LotTimerAndStatus} {
    font-family: "BentonSans", serif;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: left;
  }
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  max-width: 900px;

  .agreement {
    display: flex;
    align-items: start;
    margin-bottom: 20px;

    ${BidModalText} {
      user-select: none;
    }
  }

  .cancel-bid {
    display: flex;
    align-items: center;

    ${BidModalText} {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${EViewSize.desktop}) {
    width: 100%;
    flex-direction: column;
  }
`;

export const BidModal = styled(
  memo(
    ({
      lotData,
      minBid,
      mojitoItemDetails,
      ...props
    }: {
      lotData: IContentfulLotData;
      minBid: number;
      mojitoItemDetails: IMojitoCollectionItemAuctionLot;
    }) => {
      const { profile } = useProfile();
      const { _path } = usePageType();
      const { bids } = useCollectionItemBidsList(
        mojitoItemDetails.marketplaceCollectionItemId,
        _path[1]
      );
      const [availableOptions, setAvailableOptions] = useState<
        { value: number; label: string }[]
      >([]);
      const { currentBids } = useCollectionItemCurrentBids(lotData.mojitoId);

      useIsomorphicLayoutEffect(() => {
        if (profile.id && bids) {
          let userMaxBid = 0;
          let userAvailableMinBid = minBid ?? 0;

          bids?.forEach((bid: any) => {
            bid.isYou = profile.id == bid.marketplaceUser.id;
            if (bid.maximumBid && bid.maximumBid >= userMaxBid)
              userMaxBid = bid.maximumBid;
            return bid;
          });

          if (userMaxBid && bids?.length) {
            const nextIncrementID =
              bidIncrement.findIndex((e) => e === userMaxBid) + 1;
            if (nextIncrementID === bidIncrement.length) {
              userAvailableMinBid = Infinity;
            } else if (
              currentBids?.details?.currentBid &&
              currentBids?.details?.currentBid?.nextBidIncrement <
                bidIncrement[nextIncrementID] // todo here possible to set max price 110m
            ) {
              userAvailableMinBid = bidIncrement[nextIncrementID];
            } else {
              userAvailableMinBid =
                currentBids?.details?.currentBid?.nextBidIncrement ?? 0;
            }
          } else {
            userAvailableMinBid =
              currentBids?.details?.currentBid?.nextBidIncrement ?? 0;
          }

          const options = bidIncrement.reduce(
            (
              arr: {
                value: number;
                label: string;
              }[],
              e
            ) => {
              if (
                e >= userAvailableMinBid &&
                e >= (mojitoItemDetails?.startingBid || 0)
              ) {
                arr.push({ value: e, label: formatCurrency(e) });
              }
              return arr;
            },
            []
          );

          if (!options.length) {
            options.push({
              value: userAvailableMinBid,
              label: formatCurrency(userAvailableMinBid),
            });
          }

          if (
            !availableOptions ||
            (availableOptions && availableOptions.length !== options.length)
          ) {
            setAvailableOptions(options);
          }
        }
      }, [
        bids?.length,
        mojitoItemDetails?.startingBid,
        currentBids?.details?.currentBid?.id,
      ]);

      return availableOptions?.length ? (
        <BidModalMemo
          lotData={lotData}
          minBid={minBid}
          availableOptions={availableOptions}
          mojitoItemDetails={mojitoItemDetails}
          {...props}
        />
      ) : null;
    },
    (p, n) =>
      p.minBid == n.minBid &&
      p.lotData.mojitoId == n.lotData.mojitoId &&
      p.mojitoItemDetails != n.mojitoItemDetails
  )
)``;
