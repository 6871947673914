import styled from "styled-components";
import { formatDateToUS } from "@utils";

interface IItemInfo {
  itemName: string;
  createdDate?: string;
  $uppercase?: boolean;
}

export const ItemInfo = styled(
  ({ itemName, createdDate, ...props }: IItemInfo) => {
    return itemName ? (
      <div {...props}>
        {itemName}
        {createdDate && ",  " + formatDateToUS(createdDate, "YYYY")}
      </div>
    ) : null;
  }
)`
  font-size: 12px;
  white-space: normal;

  ${({ $uppercase }) => $uppercase && "text-transform: uppercase;"}
`;
