import { EViewSize } from "@enums";
import { useDeviceResponsive } from "@hooks";
import {
  useDebounce,
  useIsomorphicLayoutEffect,
  useSize,
  useUpdate,
} from "ahooks";
import { useRouter } from "next/router";
import { memo, ReactNode, useEffect, useRef } from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars-2";
import styled from "styled-components";

export const Scrollable = styled(
  ({
    isPageScroll,
    ...props
  }: ScrollbarProps & {
    children: ReactNode | ReactNode[];
    isPageScroll?: boolean;
  }) => {
    const { isMobile } = useDeviceResponsive();
    const router = useRouter();
    const _scrollbars = useRef<any>();
    const _child = useRef<any>();
    const update = useUpdate();
    const { height } = useDebounce(useSize(_child) ?? { height: 0 }, {
      wait: 50,
    });

    useIsomorphicLayoutEffect(() => {
      if (
        isPageScroll &&
        Number.isInteger(height) &&
        height != _scrollbars.current.getScrollHeight()
      ) {
        update();
      }
    }, [height]);

    useEffect(() => {
      if (isPageScroll)
        router.events.on("routeChangeComplete", _scrollTopOnRouteUpdate);

      return () => {
        if (isPageScroll)
          router.events.off("routeChangeComplete", _scrollTopOnRouteUpdate);
      };
    }, []);

    const _scrollTopOnRouteUpdate = () => {
      _scrollbars.current?.scrollToTop();
    };

    return (
      <Scrollbars
        ref={_scrollbars}
        {...props}
        renderThumbVertical={(props) =>
          !isMobile ? <div className="scrollThumb" {...props} /> : <div />
        }
        renderTrackVertical={() =>
          !isMobile ? <div className="scrollTrack" /> : <div />
        }
        universal
        hideTracksWhenNotNeeded>
        <div ref={_child} style={{ height: isMobile ? "auto" : "100%" }}>
          {props.children}
        </div>
      </Scrollbars>
    );
  }
)`
  > div:first-child {
    //padding: 0 40px;
    font-size: var(--tablet);
    scrollbar-color: #c1c1c1 rgba(255, 255, 255);
    transform: translateZ(0);

    > div:first-child {
      display: flex;
      flex-direction: column;
    }
  }

  *:not(.swiper-slide):not(.swiper-wrapper):not(.swiper) {
    transition: opacity 0.45s;
  }

  .scrollThumb {
    background: #c1c1c1;
    border-radius: 3px;
  }

  .scrollTrack {
    position: absolute;
    width: 5px;
    right: 0;
    height: 100%;
    border-radius: 3px;
    opacity: 1;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    overflow: unset !important;
  }
`;

const Static = styled.div`"@segment/snippet":
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Scrollbar = styled(
  memo(
    ({
      isStatic = false,
      isPageScroll = false,
      ...props
    }: {
      collection?: string;
      path?: string;
      isStatic?: boolean;
      isPageScroll?: boolean;
      children: ReactNode | ReactNode[];
    }) => {
      return isStatic ? (
        <Static {...props} />
      ) : (
        <Scrollable {...props} isPageScroll={isPageScroll} />
      );
    }
  )
)`
  flex-grow: 1;
  ${({ isPageScroll }) => {
    return isPageScroll
      ? `
     > .scrollTrack {
        top: 0;
        height:calc(100% - 5px);
      }`
      : "";
  }}

  ${({ path }) => {
    if (path == "sales") {
      return `
        background: white;

        > div:first-child {
          box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.7);
          scrollbar-color: rgb(254, 21, 188) rgba(254, 21, 188, 0.2);
        }

        .scrollTrack {
          background: #d9d9d9;

          .scrollThumb {
            background: rgba(0, 0, 0, 0.5);
          }
        }
      `;
    }
  }}

  ${({ collection }) => {
    if (collection == "lfc") {
      return `
        background: white;

        > div:first-child {
          box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.7);
          scrollbar-color: rgb(254, 21, 188) rgba(254, 21, 188, 0.2);
        }

        .scrollTrack {
          background: #d9d9d9;

          .scrollThumb {
            background: #d9d9d9;
          }
        }
      `;
    }

    if (collection == "salgado" || collection == "aegirls") {
      return `
        background: white;

        > div:first-child {
          box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.7);
          scrollbar-color: rgb(254, 21, 188) rgba(254, 21, 188, 0.2);
        }

        .scrollTrack {
          background: #d9d9d9;

          .scrollThumb {
            background: rgba(0, 0, 0, 0.5);
          }
        }
      `;
    }
  }}
`;