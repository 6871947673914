import { Scrollbar } from "@components";
import { EViewSize } from "@enums";
import {
  useCollection,
  useCollectionItemBidsList,
  useCollectionItemBidsSubscription,
} from "@hooks";
import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
  IMojitoCollectionItemDetailsBid,
} from "@interfaces";
import { Box, ClickAwayListener, Grid, Popper } from "@material-ui/core";
import { formatCurrency, formatDateToUS } from "@utils";
import classNames from "classnames";
import moment from "moment";
import Link from "next/link";
import React, { memo, useMemo } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { BaseButton } from "../../button";
import { UserAvatar } from "../../userAvatar";
import { BidCardHeader } from "./bidCardHeader";
import { CardHeader } from "./cardHeader";
import { LotWrapper } from "./general";
import { PlaceBidButton } from "./placeBidButton";
import { LotTimerAndStatus } from "@shared/lotTimerAndStatus";
import { useMojitoItem } from "@hooks/useMojitoItem";

const BiddingStatus = styled.div`
  font-size: 12px;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: 400;
  text-transform: uppercase;
`;

const AuctionTime = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`;

const BiddingAnnouncement = styled((props) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const BiddingButtonsWrapper = styled.div`
  display: flex;

  & > ${BaseButton} {
    flex-grow: 1;

    &:first-child {
      max-width: 330px;
    }
  }
  @media screen and (max-width: ${EViewSize.mobile}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InfoIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  max-width: 24px;
  cursor: pointer;

  svg {
    fill: black;
    width: 24px;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    position: relative;
    margin-left: auto;
    right: 0;
  }
`;

const PreSaleBidView = styled((props) => {
  const { mojitoItem } = useMojitoItem<IMojitoCollectionItemAuctionLot>();

  return useMemo(
    () =>
      mojitoItem ? (
        <div {...props}>
          <Grid item xs={12}>
            <BiddingStatus>Bidding has not yet started</BiddingStatus>
          </Grid>
          <Grid item xs={12}>
            <BiddingButtonsWrapper>
              <PlaceBidButton mojitoItem={mojitoItem} />
            </BiddingButtonsWrapper>
          </Grid>
          <BiddingAnnouncement item xs={12}>
            <AuctionTime>
              Starts: {formatDateToUS(mojitoItem.details.startDate, "LLL")}
            </AuctionTime>
            <AuctionTime>
              Ends: {formatDateToUS(mojitoItem.details.endDate, "LLL")}
            </AuctionTime>
          </BiddingAnnouncement>
        </div>
      ) : null,
    [mojitoItem?.id]
  );
})`
  ${BaseButton} {
    flex: 0 1 328px;
  }
`;

const BidFeedTitle = styled.div`
  font-family: "Mercury Text G1", serif;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BidAuthor = styled.div`
  font-family: "Mercury Text G1", serif;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-right: 20px;
  word-wrap: break-word;
  flex: 0 0 400px;

  @media (max-width: ${EViewSize.desktop}) {
    flex-basis: 320px;
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    flex: 1;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding-right: 0;
  }
`;

const BidItemAvatar = styled((props) => <UserAvatar {...props} />)`
  && {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    background: #e4e4e4;
    min-width: 55px;
    width: 55px;
    height: 55px;
    margin-right: 20px;

    svg {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: ${EViewSize.mobile}) {
      min-width: 42px;
      width: 42px;
      height: 42px;
    }
  }
`;

const BidTime = styled.div`
  font-family: "BentonSans", serif;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  letter-spacing: 0.0025em;
  text-transform: uppercase;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-bottom: 10px;
    text-align: right;
  }
`;

const BidPrice = styled.div`
  font-family: "BentonSans", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;

  .max {
    text-transform: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
  }
`;

interface IBidItem {
  bid: IMojitoCollectionItemDetailsBid;
  hasWinner?: boolean;
  index: number;
  $longName?: boolean;
}

const UserOutBid = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: rgba(255, 155, 0, 0.7);
  border-radius: 2px;
  font-family: "BentonSans";
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.0025em;
  color: var(--text-reverse);
  white-space: nowrap;

  svg {
    margin-left: 5px;
    fill: var(--text-reverse);
  }
`;

const BidInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  @media screen and (max-width: ${EViewSize.mobile}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const BidStatus = styled.div<{ $hold: boolean; }>`
  display: flex;
  flex-direction: column;
  flex: 0 0 140px;
  max-width: 140px;
  font-family: "Mercury Text G1", serif;
  ${UserOutBid} {
    margin-top: ${({ $hold }) => ($hold ? "10px" : "6px")};
  }
`;

const PopperBox = styled((props) => <Box {...props} />)`
  && {
    background: #292929;
    width: 270px;
    padding: 8px;
    color: var(--text-color);
    border-radius: 5px;
    font-family: "BentonSans", serif;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 15px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 5px);
      bottom: 7px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      border-top: 10px solid #292929;
    }

    a {
      text-decoration: underline;
    }
  }
`;

const BidItem = styled(({ hasWinner, bid, ...props }: IBidItem) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const bidTime =
    moment().diff(bid.createdAt, "s") <= 44
      ? "a few seconds ago"
      : moment(bid.createdAt).fromNow();
  return (
    <div
      {...props}
      className={classNames(
        (props as any).className,
        bid.isHold && "hold",
        bid.isOutbid && "outbid"
      )}
      onMouseLeave={() => setAnchorEl(null)}>
      <BidAuthor>
        <BidItemAvatar avatarKey={bid.marketplaceUser?.avatar} />

        <BidStatus $hold={!!bid.isHold}>
          {bid.isMine
            ? hasWinner && bid.isHold
              ? "Winner: You"
              : "You"
            : bid.marketplaceUser?.username}

          {bid.isOutbid && (
            <UserOutBid>
              You have been outbid{" "}
              {bid.isInfo && (
                <>
                  <SVG
                    src="/icons/info.svg"
                    width="13"
                    height="13"
                    aria-describedby="outbid-popper"
                    onMouseEnter={(e: any) => setAnchorEl(e.currentTarget)}
                  />
                  <Popper
                    id="outbid-popper"
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="top">
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                      <PopperBox>
                        You don’t hold the leading bid because you placed your
                        responsive high bid after the leading user. For more
                        information on responsive bidding,{" "}
                        <Link href="/faq">see our FAQ</Link>.
                      </PopperBox>
                    </ClickAwayListener>
                  </Popper>
                </>
              )}
            </UserOutBid>
          )}
        </BidStatus>
      </BidAuthor>
      <BidInfo>
        <BidTime>{bidTime}</BidTime>
        <BidPrice>
          {formatCurrency(bid.amount)}
          {bid.isMine && bid.isHold && bid.maximumBid && (
            <div className="max">
              {`Maximum bid: ${formatCurrency(bid.maximumBid)}`}
            </div>
          )}
        </BidPrice>
      </BidInfo>
    </div>
  );
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(228, 228, 228, 0.6);

  &:first-child {
    background: linear-gradient(
      301.04deg,
      rgba(85, 255, 188, 0.2) -66.81%,
      rgba(186, 113, 219, 0.2) 90.61%
    );
    filter: drop-shadow(-5px 0px 4px rgba(255, 255, 255, 0.25));
    border-radius: 10px;
    border-bottom: 0;
    padding: 20px 15px;

    @media screen and (max-width: ${EViewSize.mobile}) {
      padding: 15px 10px;

      ${({ $longName }) =>
    $longName ? `flex-direction: column; align-items: start;` : ""}
      ${BidPrice} {
        ${({ $longName }) => ($longName ? `margin-left:10px;` : "")}
      }
    }

    ${BidItemAvatar} {
      outline: none;
      min-width: 80px;
      width: 80px;
      height: 80px;

      svg {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: ${EViewSize.mobile}) {
        min-width: 42px;
        width: 42px;
        height: 42px;
      }
    }

    ${BidInfo} {
      @media screen and (max-width: ${EViewSize.mobile}) {
        ${({ $longName }) =>
    $longName
      ? `
             flex-direction: row;
             align-items: baseline;
             justify-content: start;
             width: 80%;
             margin-top: 5px;
             margin-left: 70px;`
      : ""}
      }
    }
    ${UserAvatar} {
      @media screen and (max-width: ${EViewSize.mobile}) {
        ${({ $longName }) =>
    $longName
      ? `
              margin-bottom: -30px;
              min-width: 50px;
              width: 50px;
              height: 50px;
            `
      : ""}
      }
    }

    ${BidAuthor} {
      font-size: 32px;
      line-height: 38px;
      flex: 0 0 395px;

      @media screen and (max-width: ${EViewSize.mobile}) {
        font-size: 24px;
        line-height: 29px;
      }

      @media (max-width: 1024px) {
        flex-basis: 320px;
      }

      @media screen and (max-width: ${EViewSize.tablet}) {
        flex: 1;
      }
    }

    ${BidPrice} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &.hold,
  &.win {
    background: linear-gradient(
      91.04deg,
      rgba(147, 219, 113, 1) -66.81%,
      rgba(85, 224, 255, 0.2) 90.61%
    );
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding: 15px 0;
  }
`;

interface IBidsList {
  hasWinner?: boolean;
  mojitoItem: IMojitoCollectionItem;
  limit?: number;
}

const BidsList = styled(
  memo(({ hasWinner, mojitoItem, limit, ...props }: IBidsList) => {
    const { slug } = useCollection();
    const { bids } = useCollectionItemBidsList(mojitoItem.id, slug);
    useCollectionItemBidsSubscription(mojitoItem, slug);

    return (
      <div {...props}>
        {bids?.slice(0, limit ? limit : 6)?.length
          ? bids?.slice(0, limit ? limit : 6).map((bid: any, index: number) => {
            return (
              <BidItem
                key={bid.id + bid.amount}
                bid={bid}
                $longName={
                  bid?.marketplaceUser?.username?.length + bid.amount >= 20
                }
                index={index}
                hasWinner={hasWinner}
              />
            );
          })
          : null}
      </div>
    );
  })
)``;

const DuringSaleBidView = styled(
  ({
    mojitoItem,
    ...props
  }: {
    mojitoItem: IMojitoCollectionItem;
  }) => {
    return (
      <div {...props}>
        <Grid item xs={12}>
          <BidFeedTitle>
            Bid Feed
            <LotTimerAndStatus
              mojitoId={mojitoItem?.id}
              onlyTimer
            />
          </BidFeedTitle>
          <BidsList
            mojitoItem={mojitoItem}
          />
        </Grid>
      </div>
    );
  }
)`
  margin-top: 35px;
`;

const PostSaleBidView = styled(
  ({
    mojitoItem,
    ...props
  }: {
    mojitoItem: IMojitoCollectionItem;
  }) => {
    return (
      <div {...props}>
        <Grid item xs={12}>
          <BidFeedTitle>Bid Feed</BidFeedTitle>
          <BidsList
            hasWinner
            mojitoItem={mojitoItem}
          />
        </Grid>
      </div>
    );
  }
)`
  margin-top: 35px;
`;

export const BidFeed = styled(
  ({
    mojitoItem,
    ...props
  }: {
    mojitoItem?: IMojitoCollectionItem;
  }) => {
    return mojitoItem ? (
      <div {...props}>
        {mojitoItem?.details?.saleView?.isPreSale && <PreSaleBidView />}
        {mojitoItem?.details?.saleView?.isDuringSale && (
          <DuringSaleBidView mojitoItem={mojitoItem} />
        )}
        {mojitoItem?.details?.saleView?.isPostSale && (
          <PostSaleBidView mojitoItem={mojitoItem} />
        )}
      </div>
    ) : null;
  }
)`
  position: relative;
`;

export const BidCard = styled(
  ({
    mojitoItem,
    ...props
  }: {
    mojitoItem?: IMojitoCollectionItem;
  }) => {
    return (
      <LotWrapper>
        <Scrollbar>
          <div {...props}>
            <CardHeader />
            <BidCardHeader mojitoItemDetails={mojitoItem?.details} />
            <BidFeed mojitoItem={mojitoItem} />
          </div>
        </Scrollbar>
        <InfoIcon>
          <Link href="/faq" passHref>
            <SVG src="/icons/question.svg" />
          </Link>
        </InfoIcon>
      </LotWrapper>
    );
  }
)`
  .switch {
    position: absolute;
    right: 200px;
  }
`;
