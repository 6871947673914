import { EViewSize } from "@enums";
import { useCollection } from "@hooks";
import { Checkbox } from "@material-ui/core";
import { EOverlayContextActionTypes, useOverlayContext } from "@state-context/overlay.context";
import { formatCurrency, stateTaxRates } from "@utils";
import { useLocalStorageState } from "ahooks";
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import Select, { components, ControlProps, DropdownIndicatorProps } from "react-select";
import styled, { css } from "styled-components";
import { BaseButton } from "./button";
import { Image } from "./image";
import { ModalContinueButton } from "./modalContinueButton";
import { ModalFooterInfo } from "./modalFooterInfo";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { getCodeList } = require("country-list");

interface ITaxes {
  "united states of america": number;
  "united kingdom of great britain and northern ireland": number;
  switzerland: number;
  australia: number;
  turkey: number;
}

const countriesObject = getCodeList();

const AgreementCheckbox = styled(Checkbox)`
  width: 14px;
  padding: 0 20px;
`;

const BidModalText = styled.div`
  font-family: "BentonSans", serif;
  font-size: 12px;
  line-height: 14.5px;
  letter-spacing: 0.0025em;
  label {
    cursor: pointer;

    a {
      text-decoration: underline;
    }
  }
`;

const AvatarDescription = styled.span`
  margin-top: 8px;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
`;

const CheckoutTitle = styled.p`
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0025em;

  @media screen and (max-width: ${EViewSize.mobile}) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const PurchaseRow = styled.div<{ $gray?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  color: ${({ $gray }) => ($gray ? "#767676" : "#292929")};

  &:first-child {
    margin-bottom: 8px;
  }
`;

const Total = styled.div`
  margin-top: 24px;
  text-align: right;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-top: 16px;
  }
`;

const TotalTitle = styled.p`
  font-size: 12px;
  line-height: 24px;
  color: #000;
`;

const TotalAmount = styled.p`
  margin-top: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #31a136;
`;

const PurchaseInfo = styled(({ children, ...props }) => {
  return <div {...props}>{children}</div>;
})`
  padding: 16px 18px;
  background-color: #f8f8f8;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

const CancelButton = styled(BaseButton)`
  width: auto !important;
  background: none !important;
  text-decoration: underline;
  color: #292929 !important;
`;

const CheckoutFooter = styled.div`
  margin-top: 20px;
  display: flex;

  @media screen and (max-width: ${EViewSize.mobile}) {
    flex-direction: column;

    ${CancelButton} {
      margin-top: 10px;
    }
  }
`;

const CheckoutButton = styled.div`
  display: flex;
  width: 100%;
  margin-right: 10px;

  ${BaseButton} {
    border-radius: 0 10px 10px 0;
  }
`;

const CheckoutInput = styled.input`
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-radius: 10px 0 0 10px;
  outline: none;
`;

const SelectTitle = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 17px;
  color: #333;
`;

const SelectLabel = styled.div`
  position: absolute;
  left: 16px;
  top: 20px;
  font-size: 16px;
  color: #292929;
  z-index: 1;
  transition: 300ms ease all;

  @media screen and (max-width: ${EViewSize.mobile}) {
    top: 14px;
  }
`;

const CountryValueContainer = styled((props: ControlProps) => (
  <>
    <components.ValueContainer {...props} />
    <SelectLabel>Country</SelectLabel>
  </>
))``;

const StateValueContainer = styled((props: ControlProps) => (
  <>
    <components.ValueContainer {...props} />
    <SelectLabel>State</SelectLabel>
  </>
))``;

const DropdownIndicator = styled((props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <SVG width="12" fill="#00253E" src="/icons/down.svg/" />
    </components.DropdownIndicator>
  );
})``;

const SelectLabelActive = css`
  ${SelectLabel} {
    top: 6px;
    font-size: 12px;
    line-height: 24px;
    color: #6b6b6b;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;

  .select__control {
    border: 1px solid #d9d9d9;
    box-shadow: 0 0 1px #d9d9d9;
    background-color: #fff;
    border-radius: 2px;

    &:hover {
      border-color: #999;
    }

    &--is-focused {
      ${SelectLabelActive}
    }

    &--is-disabled {
      ${SelectLabel} {
        display: none;
      }

      ${DropdownIndicator} {
        svg {
          fill: #6b6b6b;
        }
      }

      .select__placeholder,
      .select__single-value {
        margin-top: -20px;
      }
    }
  }

  .select__value-container {
    padding-top: 26px;
    padding-left: 14px;

    &--has-value ~ {
      ${SelectLabelActive}
    }

    @media screen and (max-width: ${EViewSize.mobile}) {
      padding-top: 16px;
    }
  }

  .select__menu {
    z-index: 2;
  }

  .select__indicator {
    padding: 0 27px;
  }

  .select__input-container {
    overflow: hidden;
  }

  &:before,
  &:after {
    display: none;
  }
`;

const CheckoutImageWrapper = styled.div`
  position: relative;
`;

interface ICheckout {
  avatarImage: string;
  checkoutPrice: number;
  onSwitchToConfirm: () => void;
  setCheckoutAmount: (_amount: number) => void;
}

const Checkout = styled(
  ({ avatarImage, checkoutPrice, onSwitchToConfirm, ...props }: ICheckout) => {
    const { dispatch } = useOverlayContext();

    const closeOverlay = () => {
      dispatch({
        type: EOverlayContextActionTypes.hide,
      });
    };

    return (
      <div {...props}>
        <CheckoutImageWrapper>
          <Image
            alt={avatarImage}
            src={avatarImage}
            width={440}
            height={440}
            objectFit="cover"
          />
        </CheckoutImageWrapper>
        <AvatarDescription>
          Each purchased avatar is uniquely generated and randomly assigned
        </AvatarDescription>
        <CheckoutTitle>
          Confirm 1 Queens+Kings for {formatCurrency(checkoutPrice)}
        </CheckoutTitle>
        <PurchaseInfo>
          <PurchaseRow>
            <span>Your purchase</span>
            <span>1 x {formatCurrency(checkoutPrice)}</span>
          </PurchaseRow>
          <PurchaseRow $gray>
            <span>Taxes will be calculated in the next step</span>
          </PurchaseRow>
        </PurchaseInfo>
        <CheckoutFooter>
          <CheckoutButton>
            <CheckoutInput
              type="text"
              value={formatCurrency(checkoutPrice)}
              readOnly
            />
            <BaseButton onClick={onSwitchToConfirm}>Checkout</BaseButton>
          </CheckoutButton>
          <CancelButton className="close" onClick={closeOverlay}>
            Cancel
          </CancelButton>
        </CheckoutFooter>
      </div>
    );
  }
)``;

interface ICheckoutConfirmation {
  checkoutAmount: number;
  checkoutPrice: number;
}

const CheckoutConfirmation = styled(
  ({ checkoutAmount, checkoutPrice, ...props }: ICheckoutConfirmation) => {
    const { collection } = useCollection();
    const [tax, setTax] = useState(0);
    const [disabledState, setDisabledState] = useState(true);
    const onCountryChange = (option: {
      label: string;
      value: keyof ITaxes;
    }) => {
      setDisabledState(option.value !== "united states of america");
      setTax(taxes[option.value] ?? 0);
      setAdyenLink(
        adyenLinks[
          option.value as keyof Omit<ITaxes, "united states of america">
        ] ?? adyenLinks["other"]
      );
    };

    const [bidAgreementSignedLS, setBidAgreementSignedLS] =
      useLocalStorageState<any>(`bid-agreement-signed_hackatao`, {
        defaultValue: false,
      });
    const [agreementSigned, setAgreementSigned] =
      useState<boolean>(bidAgreementSignedLS);
    const adyenLinks = {
      other: "https://eu.adyen.link/PL9D3D5D76E538A714",
      "united kingdom of great britain and northern ireland":
        "https://eu.adyen.link/PLA4C4219633243FDE",
      turkey: "https://eu.adyen.link/PL8244E293A23678CC",
      australia: "https://eu.adyen.link/PL8422A900D06918BF",
      switzerland: "https://eu.adyen.link/PLCAEAE03B4EE9CB89",
      TX: "https://eu.adyen.link/PL22DAB923136280E4",
      AK: "https://eu.adyen.link/PL9D3D5D76E538A714",
      AL: "https://eu.adyen.link/PL8E28BB31DF91EAAB",
      AR: "https://eu.adyen.link/PL9D3D5D76E538A714",
      AZ: "https://eu.adyen.link/PL9D3D5D76E538A714",
      CA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      CO: "https://eu.adyen.link/PL9D3D5D76E538A714",
      UT: "https://eu.adyen.link/PLFE32C1F3ECBA7456",
      DE: "https://eu.adyen.link/PL9D3D5D76E538A714",
      FL: "https://eu.adyen.link/PL9D3D5D76E538A714",
      GA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      HI: "https://eu.adyen.link/PL9D3D5D76E538A714",
      IA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      ID: "https://eu.adyen.link/PL9D3D5D76E538A714",
      IL: "https://eu.adyen.link/PL9D3D5D76E538A714",
      IN: "https://eu.adyen.link/PLBF1BFA0F08ED8D4C",
      KS: "https://eu.adyen.link/PL9D3D5D76E538A714",
      RI: "https://eu.adyen.link/PLBF1BFA0F08ED8D4C",
      LA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      MA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      NJ: "https://eu.adyen.link/PL3BDF21C8712D5218",
      CT: "https://eu.adyen.link/PLD2D8244333FAFF7E",
      MI: "https://eu.adyen.link/PL9D3D5D76E538A714",
      MN: "https://eu.adyen.link/PL9D3D5D76E538A714",
      MO: "https://eu.adyen.link/PL9D3D5D76E538A714",
      MS: "https://eu.adyen.link/PL9D3D5D76E538A714",
      MT: "https://eu.adyen.link/PL9D3D5D76E538A714",
      NC: "https://eu.adyen.link/PL9D3D5D76E538A714",
      ND: "https://eu.adyen.link/PL9D3D5D76E538A714",
      NE: "https://eu.adyen.link/PL9D3D5D76E538A714",
      NV: "https://eu.adyen.link/PL9D3D5D76E538A714",
      NH: "https://eu.adyen.link/PL9D3D5D76E538A714",
      DC: "https://eu.adyen.link/PL8769F4D272FEC0FB",
      NM: "https://eu.adyen.link/PL9D3D5D76E538A714",
      NY: "https://eu.adyen.link/PL9D3D5D76E538A714",
      OH: "https://eu.adyen.link/PL9D3D5D76E538A714",
      OK: "https://eu.adyen.link/PL9D3D5D76E538A714",
      OR: "https://eu.adyen.link/PL9D3D5D76E538A714",
      KY: "https://eu.adyen.link/PL8769F4D272FEC0FB",
      MD: "https://eu.adyen.link/PL8769F4D272FEC0FB",
      SC: "https://eu.adyen.link/PL9D3D5D76E538A714",
      SD: "https://eu.adyen.link/PL9D3D5D76E538A714",
      TN: "https://eu.adyen.link/PL9D3D5D76E538A714",
      PA: "https://eu.adyen.link/PL8769F4D272FEC0FB",
      ME: "https://eu.adyen.link/PL4C1FB3F3D00BB20D",
      VT: "https://eu.adyen.link/PL9D3D5D76E538A714",
      VA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      WA: "https://eu.adyen.link/PL9D3D5D76E538A714",
      WI: "https://eu.adyen.link/PL9D3D5D76E538A714",
      WV: "https://eu.adyen.link/PL9D3D5D76E538A714",
      WY: "https://eu.adyen.link/PL9D3D5D76E538A714",
    };
    const [adyenLink, setAdyenLink] = useState(adyenLinks["other"]);
    const taxes: ITaxes = {
      "united states of america": 0,
      "united kingdom of great britain and northern ireland": 20,
      switzerland: 7,
      australia: 10,
      turkey: 18,
    };
    const countriesWithTaxesOptions: {
      label: string;
      value: keyof ITaxes;
    }[] = Object.keys(countriesObject)
      .sort()
      .map((code) => ({
        label: countriesObject[code],
        value: countriesObject[code].toLowerCase(),
      }));
    return (
      <div {...props}>
        <CheckoutTitle>
          Confirm{` ${checkoutAmount} `}
          {collection?.name} for
          {` ${formatCurrency(checkoutPrice)}`}
        </CheckoutTitle>
        <PurchaseInfo>
          <SelectTitle>Please select your country of residence</SelectTitle>
          <StyledSelect<any>
            components={{
              IndicatorSeparator: () => null,
              ValueContainer: CountryValueContainer,
              DropdownIndicator,
            }}
            placeholder={""}
            classNamePrefix="select"
            menuShouldScrollIntoView={true}
            options={countriesWithTaxesOptions}
            onChange={onCountryChange}
          />
          <StyledSelect
            components={{
              IndicatorSeparator: () => null,
              ValueContainer: StateValueContainer,
              DropdownIndicator,
            }}
            placeholder={disabledState ? "Not applicable" : ""}
            isDisabled={disabledState}
            classNamePrefix="select"
            menuShouldScrollIntoView={true}
            options={stateTaxRates}
            isOptionSelected={(option, selectValue) =>
              selectValue.some((i) => i === option)
            }
            onChange={(value: any) => setTax(value.value)}
          />
          <PurchaseRow>
            <span>Your purchase</span>
            <span>{`1 x ${formatCurrency(checkoutPrice)}`}</span>
          </PurchaseRow>
          <PurchaseRow $gray>
            <span>Taxes</span>
            <span>{formatCurrency((checkoutPrice * tax) / 100)}</span>
          </PurchaseRow>
          {/*<PurchaseRow $gray>*/}
          {/*  <span>Fees</span>*/}
          {/*  <span>0.00</span>*/}
          {/*</PurchaseRow>*/}
          <Total>
            <TotalTitle>Total Amount (USD)</TotalTitle>
            <TotalAmount>
              {formatCurrency(checkoutPrice + (checkoutPrice * tax) / 100)}
            </TotalAmount>
          </Total>
        </PurchaseInfo>
        {!bidAgreementSignedLS && (
          <div className="agreement">
            <AgreementCheckbox
              id={"bidAgreement"}
              checked={agreementSigned}
              onChange={() => {
                setAgreementSigned(!agreementSigned);
              }}
            />
            <BidModalText>
              <label htmlFor={"bidAgreement"}>
                By checking this box you confirm that you have read and agree to
                be bound by the{" "}
                <a
                  href="https://metaverse.sothebys.com/conditions-of-business"
                  target="_blank"
                  rel="noopener noreferrer">
                  Conditions of Business
                </a>{" "}
                and any applicable Terms of Guarantee for this bid and any later
                bids you place on this or any other lot in this sale.{" "}
                <b>
                  Bids placed in Online-Only sales cannot be cancelled except
                  for the limited reasons set out in the{" "}
                  <a
                    href="https://metaverse.sothebys.com/conditions-of-business"
                    target="_blank"
                    rel="noopener noreferrer">
                    Conditions of Business
                  </a>
                  .
                </b>
              </label>
            </BidModalText>
          </div>
        )}
        <ModalContinueButton
          disabled={!agreementSigned}
          returnTo="Marketplace"
          onClick={() => {
            setBidAgreementSignedLS(agreementSigned);
            window.location.href = adyenLink;
          }}>
          <a>
            Continue to Payment
            <SVG src="/icons/lock.svg" width={12} />
          </a>
        </ModalContinueButton>
        <ModalFooterInfo>
          By placing an order you affirm that you have read, understood, and
          consent to the
        </ModalFooterInfo>
      </div>
    );
  }
)`
  ${CheckoutTitle} {
    @media screen and (max-width: ${EViewSize.mobile}) {
      margin: 0;
    }
  }
`;

export const CheckoutModal = styled(({ avatarImage, ...props }) => {
  const [view, setView] = useState<"checkout" | "confirmation">("checkout");
  const [amount, setAmount] = useState(1);
  const _price = 1800;
  let _layout;

  switch (view) {
    case "checkout":
      _layout = (
        <Checkout
          avatarImage={avatarImage}
          checkoutPrice={_price}
          setCheckoutAmount={(_amount: number) => setAmount(_amount)}
          onSwitchToConfirm={() => setView("confirmation")}
        />
      );
      break;
    case "confirmation":
      _layout = (
        <CheckoutConfirmation checkoutAmount={amount} checkoutPrice={_price} />
      );
      break;
  }
  return <div {...props}>{_layout}</div>;
})`
  width: calc(100% - 20px);
  max-width: 500px;
  background: #fff;
  border-radius: 10px;
  margin: 20px 10px;
  padding: 30px;

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding: 20px;
  }
  .agreement {
    margin-top: 20px;
    display: flex;
    align-items: start;
    margin-bottom: 20px;

    ${BidModalText} {
      user-select: none;
    }
  }
`;
