import { createClient } from 'contentful'
import { config } from "@constants";

const spaceId = config.CONTENTFUL_SM_SPACES_ID;
const accessToken = config.CONTENTFUL_SM_AUTH_TOKEN;
const environment = config.CONTENTFUL_ENVIRONMENT;

const client = createClient({
  space: spaceId || '',
  accessToken: accessToken || '',
  environment: environment || '',
})

export default client
