import { useColorScheme } from "@hooks";
import Link from "next/link";
import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";

interface ILogo {
  withoutLink?: boolean;
  color?: string;
}

export const Logo = styled(({ color, ...props }: ILogo) => {
  const { isDark } = useColorScheme();

  return (
    <Link href="/" passHref>
      <SVG
        fill={color || (isDark ? "black" : "white")}
        src="/icons/sothebys-metaverse-logo-solid.svg"
        width="auto"
        height="22px"
        {...props}
      />
    </Link>
  );
})`
  display: flex;
  cursor: pointer;
  flex: 0 0 220px;
`;
