import { useColorScheme } from "@hooks";
import React from "react";
import styled from "styled-components";
import { ToggleBody, ToggleButton, ToggleWrapper } from "./toggleButton";

const ThemeToggleButton = styled(ToggleButton)`
  ${ToggleWrapper} {
    width: 72px;
    height: 42px;
    border-radius: 7px;
    box-shadow: none;
  }

  ${ToggleBody} {
    &:before {
      background: linear-gradient(
        270deg,
        #ba71db 0%,
        rgba(85, 224, 255, 0) 100%
      );
    }
  }
`;

export const ThemeToggle = styled((props) => {
  const { isDark, setIsDark, setIsLight } = useColorScheme();

  return (
    <div {...props}>
      <ThemeToggleButton
        handleToggle={() => (isDark ? setIsLight : setIsDark)()}
        alwaysShowGradient
      />
    </div>
  );
})``;
