import { BaseButton, IButton } from "@components";
import { config } from "@constants";
import { useCollection, useOrganization } from "@hooks";
import { IMojitoProfileUserOrg } from "@interfaces";
import isBrowser from "@utils/isBrowser";
import { HTMLProps, useEffect } from "react";

interface NotificationProps {
  notificationText: string;
  onboardingText: string;
  link: string;
  linkText: string;
  buttonText: string;
}

export interface INotificationByType {
  completeYourProfile: NotificationProps;
  uploadID: NotificationProps;
  contactUs: NotificationProps;
}

interface UserRoleContext {
  notificationContext: NotificationProps & {
    component: React.FunctionComponent<any>;
  };
  buttonContext: NotificationProps & {
    component: React.FunctionComponent<any>;
  };
  onboardingContext: NotificationProps & {
    component: React.FunctionComponent<any>;
  };
}

export const notificationsByType: {
  [key: string]: NotificationProps;
} = {
  completeYourProfile: {
    notificationText:
      "",
    onboardingText: "You aren’t able to bid in auctions yet.",
    linkText: "Click here",
    buttonText: "to complete your profile. This is required to buy and sell more than 10K worth of NFT.",
    link:
      config.SOTHEBYS_DOMAIN +
      "/buy/register/personal-information?origin=sothebys-metaverse&redirect=" +
      (isBrowser && encodeURIComponent(window.location.href)),
  },
  uploadID: {
    notificationText:
      "",
    onboardingText:
      "You’ll need to upload your ID to bid in auctions in Sotheby’s Metaverse. %br% You can do this at a later time from your Profile settings.",
    linkText: "Click here",
    buttonText: "to complete your profile. This is required to buy and sell more than 10K worth of NFT.",
    link:
      config.SOTHEBYS_DOMAIN +
      "/buy/register/how-to-upload-id?redirect=" +
      (isBrowser && encodeURIComponent(window.location.href)),
  },
  contactUs: {
    notificationText:
      "Please contact us.",
    onboardingText:
      "There is a problem retrieving your account information, you aren’t able to bid in auctions yet.",
    linkText: "",
    buttonText: "There is a problem retrieving your account information, you aren’t able to bid in auctions or buy any NFT.",
    link: config.SOTHEBYS_DOMAIN + "/en/about/contact-us?locale=en",
  },
};

const notificationsByTypeByCollectionSlug: {
  [key: string]: { [key: string]: NotificationProps };
} = {
  lfc: {
    completeYourProfile: {
      notificationText:
        "You are eligible to purchase Limited Edition items. To bid on auctions, click %link% and complete your profile.",
      onboardingText: "",
      linkText: "here",
      buttonText: "Complete your Profile to Submit Bid",
      link: notificationsByType.completeYourProfile.link,
    },
  },
};

export const useUserRoleFactory = (): UserRoleContext => {
  const { organization } = useOrganization();
  const { slug } = useCollection();

  useEffect(() => {
    switch (slug) {
      case "lfc":
        {
          Object.assign(
            notificationsByType,
            notificationsByTypeByCollectionSlug.lfc
          );
        }
        break;
    }
  }, [slug]);

  const currentNotificationKey = Object.keys(
    organization?.notifications ?? []
  ).find(
    (key) =>
      organization?.notifications?.[
        key as keyof IMojitoProfileUserOrg["notifications"]
      ]
  ) as keyof INotificationByType;

  const userRoleContext = notificationsByType[currentNotificationKey];

  const notificationContext = {
    ...userRoleContext,
    component: notificationComponent(userRoleContext),
  };

  const buttonContext = {
    ...userRoleContext,
    component: buttonComponent(userRoleContext),
  };

  const onboardingContext = {
    ...userRoleContext,
    component: onboardingComponent(userRoleContext),
  };

  return {
    notificationContext,
    buttonContext,
    onboardingContext,
  };
};

function onboardingComponent(options: NotificationProps) {
  return (props: Omit<HTMLProps<HTMLElement>, "children">) => {
    const _text = options && options.onboardingText.split("%br%");

    return options ? (
      <>
        {(options.onboardingText.includes("%br%") || !_text[1]) && (
          <b {...props}>{_text[0]}</b>
        )}
        {_text[1]}
      </>
    ) : null;
  };
}

function notificationComponent(options: NotificationProps) {
  const _text = options && options.notificationText.split("%link%");

  return (props: Omit<HTMLProps<HTMLAnchorElement>, "children">) => {
    return options ? (
      <>
        {_text[0]}
        <a href={options.link} {...props}>
          {options.linkText}
        </a>
        {_text[1]}
      </>
    ) : null;
  };
}

function buttonComponent(options: NotificationProps) {
  return (props: IButton) => {
    return options ? (
      <BaseButton
        onClick={() => (window.location.href = options.link)}
        {...props}>
        {options.buttonText}
      </BaseButton>
    ) : null;
  };
}

export default useUserRoleFactory;
