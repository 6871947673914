import { Grid } from "@material-ui/core";
import { formatContractAddress } from "@utils";
import moment from "moment";
import { FC, useContext } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { CollectionItemContext } from "../state/auctionItemContext";
import { EHistoryIcons, HistoryIcon } from "./historyIcon";

const YearList = styled((props) => <Grid {...props} />)`
  && {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 27px;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        270deg,
        rgba(85, 224, 255, 1),
        rgba(191, 135, 232, 1)
      );
    }
  }
`;

const YearContainer = styled((props) => <Grid {...props} />)<{
  $active: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    color: ${({ $active }) => !$active && "#C8C8C8"};
    padding-bottom: 15px;

    ${HistoryIcon} {
      fill: ${({ $active }) => !$active && "#C8C8C8"};
    }
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: "MercuryDisplay", serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 55px;
  margin-bottom: 30px;
  position: relative;
  svg {
    fill: black;
    width: 24px;
    margin-left: 10px;
  }
`;

const Year = styled.div<{ $active: boolean }>`
  font-size: 12px;
  margin-bottom: 28px;
  color: ${({ $active }) => $active && "#bf87e8"};

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:after {
    top: 25px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${({ $active }) => ($active ? "#BA71DB" : "#C8C8C8")};
  }
`;
const Name = styled.div`
  margin-top: 15px;
  font-size: 18px;
  font-family: "MercuryDisplay", serif;
`;
const Price = styled.div`
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 90px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Address = styled.div`
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

export const HistoryLine: FC = () => {
  const { item } = useContext(CollectionItemContext);
  const lotData = item.contentfulData;

  return lotData.history ? (
    <>
      <SectionTitle>
        History <SVG src="/icons/info.svg" />
      </SectionTitle>
      <YearList container>
        {[...lotData.history]
          .sort((a, b) => +new Date(a.date) - +new Date(b.date))
          .map((e, i) => (
            <YearContainer
              item
              xs
              key={i}
              $active={i === lotData.history.length - 1}>
              <Year $active={i === lotData.history.length - 1}>
                {moment(new Date(e.date)).year()}
              </Year>
              <HistoryIcon
                type={e.price.toLowerCase() as keyof typeof EHistoryIcons}
              />
              <Name>{e.buyerName}</Name>
              <Price>{e.price}</Price>
              <Address>
                {/* <a
                  href={`https://etherscan.io/tx/${e.smartContractAddress}`}
                  rel="noreferrer"
                  target="_blank"> */}
                <span> {formatContractAddress(e.smartContractAddress)}</span>
                {/* </a> */}
              </Address>
            </YearContainer>
          ))}
      </YearList>
    </>
  ) : null;
};
