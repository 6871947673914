import { createTheme } from "@material-ui/core";
import { configResponsive } from "ahooks";

declare module "@material-ui/core/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    desktop: true;
    large: true;
  }
}

configResponsive({
  outRanged: 0,
  isMobile: 576,
  isTablet: 768,
  isDesktop: 992,
  isLarge: 1200,
});

export const muiTheme = createTheme({
  typography: {
    fontFamily: "BentonSans",
    htmlFontSize: 14,
    fontSize: 14,
    //   h1: {
    //     fontSize: 14,
    //   },
    //   h2: {
    //     fontSize: 14,
    //   },
    //   h3: {
    //     fontSize: 14,
    //   },
    //   h4: {
    //     fontSize: 14,
    //   },
    //   h5: {
    //     fontSize: 14,
    //   },
    //   h6: {
    //     fontSize: 14,
    //   },
    //   subtitle1: {
    //     fontSize: 14,
    //   },
    //   subtitle2: {
    //     fontSize: 14,
    //   },
    //   body1: {
    //     fontSize: 14,
    //   },
    //   body2: {
    //     fontSize: 14,
    //   },
    //   button: {
    //     fontSize: 14,
    //   },
    //   caption: {
    //     fontSize: 14,
    //   },
    //   overline: {
    //     fontSize: 14,
    //   },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      mobile: 576,
      tablet: 768,
      desktop: 992,
      large: 1200,
    },
  },
});


export const palette = {
  white: '#FFFFFF',
  lightWhite: '#FAFAFA',
  secondaryWhite: "#F5F5F5",
  black: '#000000',
  lightBlack: '#222222',
  navyBlue: '#042439',
  grayBlue:"rgba(4, 36, 57, 0.6)",
  gray: 'rgba(0, 0, 0, 0.5)',
  light_gray: '#EEEEEE',
  sligt_gray: 'rgba(0, 0, 0, 0.6)',
  border_gray: '#808080',
  green: '#05FF00',
  primaryGreen: '#15812C',
  success: "#31A136",
  successBackground: "#E5F2E5",
  warningBackground: "#FFDAD5",
  warning: "#BD2200",
  hoverColor: 'rgba(0, 0, 0, 0.2)',
  paginationHover: '#212121',
  paginationText: '#BDBDBD',
  primary_wallet: 'rgba(4, 36, 57, 0.3)',
  primary_wallet_color: 'rgba(4, 36, 57, 0.3)',
  ligt_black: '#E3E3E3',
  gray_black: '#838383',
  mildGray: '#DDDDDD',
  error: "#90030F",
  footer: '#042439',
  list_item_grey: "#BDBDBD"
}
