/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from './client'
import { IArtistEntryiesFields } from '@metaverse/types'

export const getArtistDetailsEntry = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<IArtistEntryiesFields>({
    content_type: 'Artist_entryies',
    ...query,
  })

  return response
}

export const getArtistDetails = async (
  id: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getArtistDetailsEntry({
    "sys.id": id,
    limit: 1,
    ...query,
  })

  return response?.items?.[0] || null
}
