import { UseSecondaryWalletMojito } from "./useMojito";
import { EMojitoSecondaryWalletQueries } from "@state";

interface IInvoiceDownload {
  invoiceID: string;
  orderID: string;
  activityName: string;
  type?: string;
}

const downloadPDF = (pdfBase64String: string, fileName: string, type = "base64") => {
  if (!pdfBase64String) return;

  let downloadLink: HTMLAnchorElement | null = document.createElement("a");
  downloadLink.href = type === 'pdf' ? `${pdfBase64String}?alt=media` : `data:application/pdf;base64,${pdfBase64String}`;
  if(type === 'base64') downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  downloadLink = null;
};
export function getInvoiceDownload(obj: IInvoiceDownload, ready = false): any {
  const { data, loading, refetch } =  UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getInvoiceDownload,
    variables: {
      invoiceID: obj?.invoiceID,
      orderID: obj?.orderID,
      activityName: obj?.activityName,
      invoiceResType: obj?.type ?? 'base64'
    },
    ready
  });
  if(data){
    downloadPDF(
        data?.generateSecondaryInvoice,
        `invoice-${obj?.invoiceID}.pdf`,
        obj?.type ?? 'base64'
    );
  }
  return{
    invoiceData: data,
    refetchInvoice : refetch,
    invoiceLoading : loading
  }
}

export function useInvoiceDownload(invoiceId: string, ready = false): any {

  const { data, loading, refetch } =  UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.generatePrimaryInvoice,
    variables: {
      invoiceID: `${invoiceId}`,
      invoiceResType: 'pdf'
    },
    ready
  });
  if(data){
    downloadPDF(
        data?.generatePrimaryInvoice,
        `invoice-${invoiceId}.pdf`,
        'pdf'
    );
  }
  return{
    downloadInvoicePrimary: data,
    refetchInvoice : refetch,
    downloadInvoiceLoading : loading
  }

}
