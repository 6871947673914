import { EViewSize } from "@enums";
import styled from "styled-components";

export const SwitchGridButton = styled.button`
  margin-bottom: 30px;
  max-width: 160px;
  border: 2px solid var(--icon-btn-border-color);
  border-radius: 7px;
  background-color: transparent;
  padding: 0 10px;
  height: 40px;
  color: var(--text-color);
  transition: background 0.35s, color 0.35s;

  &:hover {
    background: var(--base-btn-hover-bg);
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin: 0 0 20px;
  }
`;
