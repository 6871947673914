import { Loader } from "@components";
import { SaleType } from "@enums";
import { useMarketplaceCollectionsSlugWithItemsId } from "@hooks";
import {
  IContentfulLotData,
  IMojitoCollection,
  IMojitoCollectionItem,
} from "@interfaces";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { GridLot, GridRenderer } from "./gridRenderer";

const GridAccordion = styled((props) => <Accordion {...props} />)`
  && {
    background-color: transparent;
    box-shadow: none;
    color: #fff;
  }

  &:before {
    display: none;
  }

  &:last-of-type {
    border-radius: 0;
  }

  &.Mui-expanded {
    margin: 0;

    .ExpandIcon {
      width: 16px;
      height: 16px;
      fill: #fff;
      transform: rotate(45deg);
    }
  }

  .CollectionTitle {
    font-size: 22px;
    line-height: 26px;
    min-height: 48px;
    padding-left: 0;
    font-family: "Mercury Text G1", serif;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .Mui-expanded {
      margin: 12px 0;
    }
  }

  .CollectionContent {
    padding: 15px 0 0;
  }
`;

interface IGridCollection {
  mojitoLotsList: any;
  type?: SaleType | null;
  lots: IContentfulLotData[];
  collection: IMojitoCollection;
}

export const GridCollection = styled(
  ({ lots, collection, type }: IGridCollection) => {
    const _contentfulLotsId = lots.map((lot) => lot.mojitoId);
    const collectionHasContentfulLots = collection.items.some(
      (item: IMojitoCollectionItem<any>) => _contentfulLotsId.includes(item.id)
    );

    // TODO: Filter grid renderer based on collection slug
    /*let _saleType = null;
    switch (collection.slug) {
      case 'lfc': _saleType = SaleType.Auction; break;
    }*/

    return collection.items?.length && collectionHasContentfulLots ? (
      <GridAccordion defaultExpanded>
        <AccordionSummary
          className="CollectionTitle"
          expandIcon={
            <SVG
              src="/icons/plus.svg"
              width="16px"
              height="16px"
              className="ExpandIcon"
            />
          }>
          {collection?.name}
        </AccordionSummary>
        <AccordionDetails className="CollectionContent">
          <GridRenderer lots={lots} collection={collection} type={type} />
        </AccordionDetails>
      </GridAccordion>
    ) : null;
  }
)``;

interface IMultipleGridRenderer {
  lots: IContentfulLotData[];
  onlyMultiLotCollections?: boolean;
}

export const MultipleGridRenderer = styled(
  ({ lots, ...props }: IMultipleGridRenderer) => {
    const { marketplaceCollectionsSlugWithItemsId } =
      useMarketplaceCollectionsSlugWithItemsId();

    return !marketplaceCollectionsSlugWithItemsId ? (
      <Loader />
    ) : marketplaceCollectionsSlugWithItemsId?.length ? (
      <div {...props}>
        {marketplaceCollectionsSlugWithItemsId.map(
          (collection: IMojitoCollection, index) => {
            return (
              <GridCollection
                key={`${collection?.id}_${index}`}
                mojitoLotsList={collection?.items}
                collection={collection}
                lots={lots}
                type={null}
                {...props}
              />
            );
          }
        )}
      </div>
    ) : null;
  }
)`
  ${GridLot} {
    &:last-child {
      &:before,
      &:after {
        display: none;
      }
    }
  }
`;
