import { EViewSize } from "@enums";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import { ReactNode } from "react";
import styled from "styled-components";
import { BaseButton } from "./button";

const CancelButton = styled.div`
  width: fit-content;
  text-decoration: underline;
  color: var(--text-color);
  font-family: "BentonSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

interface IModalContinueButton {
  children: ReactNode;
  returnTo: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: any;
  final?: boolean;
}

export const ModalContinueButton = styled(
  ({
    children,
    returnTo,
    final,
    disabled,
    loading,
    ...props
  }: IModalContinueButton) => {
    const { dispatch } = useOverlayContext();

    const closeOverlay = () => {
      dispatch({
        type: EOverlayContextActionTypes.hide,
      });
    };

    return (
      <div {...props}>
        {children && (
          <BaseButton
            disabled={disabled}
            isLoading={loading}
            onClick={() => final && closeOverlay()}>
            {children}
          </BaseButton>
        )}
        <CancelButton
          onClick={(e) => {
            e.stopPropagation();
            closeOverlay();
          }}>
          {final
            ? `Return to ${returnTo}`
            : `or Cancel and Return to ${returnTo}`}
        </CancelButton>
      </div>
    );
  }
)`
  margin: 10px auto 20px;

  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${BaseButton} {
    padding: 0;
    height: 50px;

    &[disabled] {
      background: grey;
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  svg {
    margin-left: 8px;
  }

  ${CancelButton} {
    margin: 10px auto 0;
    padding: 0 10px;
  }
`;
