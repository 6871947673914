/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  getLot,
  getLots,
  getLotState,
  getState,
  getTraits,
  getHomePage,
  getArtistDetails,
  getOnChainAuctions
} from "@metaverse/cms";
import { useQuery } from "react-query";

export const useGetState = (slug?: string) => {
  return useQuery(["state", slug], () => (slug ? getState(slug) : null), {
    enabled: !!slug,
  });
};

export const useOnChainCollection = (slug?: string) => {
  return useQuery(["onChainCollection", slug], () => (slug ? getOnChainAuctions(slug) : null), {
    enabled: !!slug,
  });
};

export const useGetLot = (slug?: string) => {
  return useQuery(["lots", slug], () => (slug ? getLot(slug) : null), {
    enabled: !!slug,
  });
};

export const useGetLotState = (id?: string) => {
  return useQuery(["lotState", id], () => (id ? getLotState(id) : null), {
    enabled: !!id,
  });
};

export const useGetHomaPage = (id?: string) => {
  return useQuery(["Secondary_Market_Homepage", id], () => (id ? getHomePage(id) : null), {
    enabled: !!id,
  });
};

export const useGetArtistDetails = (id?: string) => {
  return useQuery(["Artist_entryies", id], () => (id ? getArtistDetails(id) : null), {
    enabled: !!id,
  });
};

export const useGetTraits = () => {
  return useQuery(["traits"], () => getTraits());
};

export const useGetLots = () => {
  return useQuery(["lots"], () => getLots());
};

