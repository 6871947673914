import { Button, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { EViewSize } from "@enums";

export interface IButton {
  icon?: string;
  width?: string;
  disabled?: boolean | string;
  secondary?: boolean | string;
  active?: boolean | string;
  outlined?: boolean | string;
  transparent?: boolean | string;
  isLoading?: boolean;
  className?: string;
  onClick?: (event: any) => void;
}

export const BaseButton = styled(
  ({
    icon,
    children,
    outlined,
    transparent,
    secondary,
    active,
    disabled,
    isLoading,
    ...props
  }: IButton & { children: ReactNode; style?: CSSProperties }) => {
    outlined = outlined ? "outlined" : undefined;
    transparent = transparent ? "transparent" : undefined;
    secondary = secondary ? "secondary" : undefined;
    active = active ? "active" : undefined;
    disabled = disabled ? "disabled" : undefined;
    props.className = classNames("common-button", props.className, {
      disabled,
      outlined,
      transparent,
      secondary,
      active,
    });

    return (
      <Button
        {...props}
        disabled={!!disabled}
        style={{
          width: props.width ?? "100%",
        }}>
        {icon && !isLoading && (
          <SVG className="icon" src={`/icons/${icon}.svg`} />
        )}
        {isLoading ? <CircularProgress className="progress" /> : children}
      </Button>
    );
  }
)`
  .pl-2 {
    padding-left: 10px;
  }

  && {
    font-family: "BentonSans", "Roboto", "Helvetica", "Arial", sans-serif;
    height: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;
    font-weight: 700;
    background: var(--base-btn-primary-bg);
    color: var(--text-white);
    border-radius: 7px;
    border-color: var(--base-btn-border-color);
    user-select: none;
    transition: background 0.5s, border 0.35s;
    overflow: hidden;

    .progress {
      color: inherit;
      width: 20px !important;
      height: 20px !important;
    }

    .icon {
      margin-right: 20px;
    }

    &[disabled] {
      color: #ffffff;
      opacity: 0.5;
      cursor: disabled;
    }

    &.secondary {
      background: var(--base-btn-secondary-bg);
      color: var(--text-dark);
      &.active {
        color: var(--text-white);
      }
    }

    &.active {
      background: var(--base-btn-primary-active-bg);
    }

    &.outlined {
      background: var(--base-btn-outlined-bg);
      color: var(--text-dark);
      border-color: var(--outlined-btn-border-color);
    }

    &.transparent {
      border-radius: 3px;
      border: solid 1px rgba(255, 255, 255, 0.3);
      background: none;
      font-family: "BentonSans Bold", sans-serif;
      font-size: 15px;
      font-weight: 500;
      height: 55px;
      max-width: fit-content;
      display: block;
      text-align: center;
      padding: 21px;
      min-width: 290px;
      @media screen and (max-width: ${EViewSize.tablet}) {
        min-width: auto;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
`;

export const IconButton = styled.button<{ withoutOutline?: boolean }>`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid
    ${({ withoutOutline }) =>
      withoutOutline ? "transparent" : "var(--icon-btn-border-color)"};
  border-radius: 7px;
  width: 40px;
  height: 40px;
  color: var(--icon-btn-color);
  transition: background 0.35s, color 0.35s, border 0.35s;

  &:hover {
    background: var(--icon-btn-hover-bg);
  }

  svg {
    fill: var(--icon-btn-color);
    transition: fill 0.35s;
    width: 20px;
  }
`;
