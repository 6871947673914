import { useDeviceResponsive } from "@hooks";
import { Skeleton } from "@material-ui/core";
import { useInViewport } from "ahooks";
import { default as NextImage, ImageProps as NextImageProps } from "next/image";
import { memo, useRef, useState } from "react";
import styled from "styled-components";
import { Loader } from "./loader";
import { simpleImageLoader } from "@utils";

export interface IImage extends NextImageProps {
  loadSamples?: number;
  showLoader?: boolean;
  skeletonLoader?: boolean;
  quality?: number;
  initialQuality?: number;
  maxQuality?: number;
}

const _cache: { [key: string]: number } = {};

export const Image = styled(
  memo(
    ({
      width,
      height,
      className,
      showLoader = true,
      loadSamples = 2,
      layout = "intrinsic",
      skeletonLoader = false,
      initialQuality = 60,
      maxQuality = 100,
      ...props
    }: IImage) => {
      const { isMobile } = useDeviceResponsive();
      const imageRef = useRef(null);
      const _lotLinInViewPort = useInViewport(imageRef);
      const [loading, setLoading] = useState(true);
      const dynamicQualityStep = useRef(
        (maxQuality - (props.quality ?? initialQuality)) / (loadSamples - 1)
      );

      const removeSampling =
        props.src.toString().search(/\.gif/) != -1 || loadSamples <= 1;
      const [dynamicQuality, setDynamicQuality] = useState(
        _cache[props.src.toString()] ?? removeSampling
          ? maxQuality
          : props.quality ?? initialQuality
      );
      let _src = props.src;
      if (
        layout !== "fill" &&
        typeof props.src === "string" &&
        (_src as string).includes("ctfassets.net")
      ) {
        _src = `${_src}?w=${width}&h=${height}`;
      }

      return (
        <div className={className} ref={imageRef}>
          {showLoader &&
            loading &&
            !_lotLinInViewPort &&
            !_cache[props.src.toString()] &&
            (!skeletonLoader ? (
              <Loader />
            ) : (
              <Skeleton
                animation={isMobile ? false : "wave"}
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  bgcolor: "var(--skeleton-color)",
                }}
              />
            ))}

          <NextImage
            {...props}
            src={_src}
            loader={simpleImageLoader}
            quality={Math.ceil(dynamicQuality)}
            layout={layout}
            width={layout === "fill" ? undefined : width}
            height={layout === "fill" ? undefined : height}
            onLoadingComplete={() => {
              setLoading(false);
              _cache[props.src.toString()] = dynamicQuality;
              if (dynamicQuality <= (props.quality ?? 100))
                setDynamicQuality(
                  Math.min(
                    dynamicQuality + dynamicQualityStep.current,
                    maxQuality
                  )
                );
            }}
          />
        </div>
      );
    }
  )
)`
  pointer-events: none;
  height: 100%;
  ${Loader} {
    position: relative;
    margin: auto;
  }
`;
