import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const ToggleWrapper = styled.div`
  border: 2px solid var(--icon-btn-border-color);
  background: rgba(255, 255, 255, 0.03);
  border-radius: 7px;
  padding: 2px;
  width: 50px;
  height: 30px;
  cursor: pointer;
`;

export const ToggleBody = styled.div<{
  $active: boolean;
  $alwaysShowGradient?: boolean;
}>`
  position: relative;

  width: 100%;
  height: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, #93db71 0%, rgba(85, 224, 255, 0) 100%);
    top: 0;
    left: 0;
    border-radius: 6px;
    opacity: ${({ $active, $alwaysShowGradient }) =>
      $active || $alwaysShowGradient ? "1" : "0"};
    transition: opacity 0.5s ease;
  }
`;

export const ToggleSelector = styled.div<{ $active: boolean }>`
  position: absolute;
  background: rgba(240, 240, 240, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  width: 50%;
  height: 100%;
  left: ${({ $active }) => ($active ? "50%" : "0")};
  transition: background 0.5s, left 0.35s ease-in-out;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    left: calc(50% - 4px / 2);
    top: calc(50% - 4px / 2);
    box-shadow: -3px -3px 6px rgba(255, 255, 255, 0.207441),
      1px 1px 2px rgba(0, 0, 0, 0.2),
      -6px -6px 12px rgba(255, 255, 255, 0.59711),
      9px 9px 12px rgba(0, 0, 0, 0.6),
      inset 1px 1px 1px rgba(255, 255, 255, 0.241013);
    border-radius: 18px;
    transition: background 0.5s !important;
    background: ${({ $active }) => ($active ? "#f0f0f0" : "#000")};
  }

  &:hover {
    background: rgba(240, 240, 240, 0.1);
  }
`;

interface IToggleButton {
  className?: string;
  handleToggle: (value: boolean) => void;
  alwaysShowGradient?: boolean;
  defaultValue?: boolean;
}

export const ToggleButton: React.FC<IToggleButton> = ({
  className,
  handleToggle,
  alwaysShowGradient,
  defaultValue = false,
}) => {
  const [toggleStatus, setToggleStatus] = useState<boolean>(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setToggleStatus(defaultValue);
    }
  }, [defaultValue]);

  const handleOnClick = () => {
    handleToggle(!toggleStatus);
    setToggleStatus((prevState) => !prevState);
  };

  return (
    <div className={className}>
      <ToggleWrapper onClick={handleOnClick}>
        <ToggleBody
          $alwaysShowGradient={alwaysShowGradient}
          $active={toggleStatus}>
          <ToggleSelector $active={toggleStatus} />
        </ToggleBody>
      </ToggleWrapper>
    </div>
  );
};
