import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
  IMojitoCollectionItemBuyNowLot,
} from "@interfaces";
import { useMemo } from "react";
import { UseQueryOptions } from "react-query";
import { useCollection } from "./useCollection";
import { usePageType } from "./usePageType";

export function useMojitoItem<
  TItem = IMojitoCollectionItemAuctionLot | IMojitoCollectionItemBuyNowLot
>(
  props: {
    url?: string;
    slug?: string;
    collectionSlug?: string;
    id?: string;
    options?: UseQueryOptions<any>;
  } = {}
): {
  slug: string;
  isLoading: boolean;
  mojitoItem:
    | IMojitoCollectionItem<
        TItem extends IMojitoCollectionItemAuctionLot
          ? IMojitoCollectionItemAuctionLot
          : TItem extends IMojitoCollectionItemBuyNowLot
          ? IMojitoCollectionItemBuyNowLot
          : IMojitoCollectionItemAuctionLot | IMojitoCollectionItemBuyNowLot
      >
    | undefined;
} {
  const { collection, isAuction, isFakeAuction, isLoading } = useCollection({
    url: props.url,
    slug: props.collectionSlug,
    options: props.options,
  });
  const { _path } = usePageType({ url: props?.url });
  const itemSlug = props?.slug ?? _path?.[3];
  const mojitoItemByPath = useMemo(
    () =>
      props?.id
        ? collection?.items?.find((e) => e.id == props.id)
        : collection?.items?.find((e) => e.slug == itemSlug),
    [itemSlug, props?.id, collection?.items]
  );

  return {
    slug: isAuction || isFakeAuction ? itemSlug : "",
    isLoading,
    mojitoItem: mojitoItemByPath,
  };
}
