import React, { ReactElement } from "react";

// INIT ACTION KEYS
export enum EOverlayContextActionTypes {
  show = "show",
  hide = "hide",
}

// INIT ACTION PROPS
interface IAction {
  type: EOverlayContextActionTypes;
  child?: ReactElement | null | undefined;
}
// INIT STATE TYPE
interface State {
  child: ReactElement | null | undefined;
}

// INIT STATE
const initialState: State = { child: null };

function Reducer(state: State, action: IAction) {
  switch (action.type) {
    case EOverlayContextActionTypes.show: {
      return {
        ...state,
        child: action.child,
      };
    }
    case EOverlayContextActionTypes.hide: {
      return { ...state, child: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const OverlayProvider = Provider;
export const useOverlayContext = useContext;

// ======================================================== //
// ================== IGNORE ALL BELOW ==================== //
// ======================================================== //

type IDispatch = (action: IAction) => void;

const StateContext = React.createContext<{
  state?: State;
  dispatch?: IDispatch;
}>({
  state: undefined,
  dispatch: undefined,
});

function Provider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = React.useReducer<React.Reducer<State, IAction>>(
    Reducer,
    initialState
  );

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
}

function useContext(): {
  state: State;
  dispatch: IDispatch;
} {
  const context = React.useContext(StateContext);
  if (context.dispatch === undefined || context.state === undefined) {
    throw new Error("useContext must be used within a Provider");
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
  };
}
