import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { createContext, useContext } from "react";
import { network, price } from "./wallet";

export interface IconnectMetaMask {
  networkID: network;
  price: price,

}
export interface ConnectType {
  connected?: boolean;
  chainId?: number | null;
  signer?: Signer | null | undefined;
  provider?: Provider | null | undefined | any;
  account?: string | null;
  wethOrWmaticBalance?: string | null;
  ethOrMaticBalance?: string | null;
  currency?: string | null;
  networkID?: network | null;
  price?: price;
  openMetaMask?: boolean | null;
  onDisConnect?: boolean | null;
}
export interface ContextType {
  connect: ConnectType;
  setConnect(f: ConnectType | ((prev: ConnectType) => ConnectType)): void;
}
const Context = createContext<ContextType>({} as ContextType);

export default Context;

export function useWeb3(): ContextType {
  return useContext(Context);
}
