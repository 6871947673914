/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from './client'
import { ISecondaryMarketHomepageFields } from '@metaverse/types'

export const getHomePageEntry = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<ISecondaryMarketHomepageFields>({
    content_type: 'Secondary_Market_Homepage',
    include: 2,
    ...query,
  })

  return response
}

export const getHomePage = async (
  id: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getHomePageEntry({
    "sys.id": id,
    limit: 1,
    ...query,
  })

  return response?.items?.[0] || null
}
