import { UseSecondaryWalletMojito, UseSecondaryWalletMojitoArr } from "@hooks";
import { EMojitoSecondaryWalletQueries } from "@state";
import { createContext, useContext } from "react";
import { isEmpty } from "./numberFormet.util";
import axios from "axios";
import { BigNumber } from "ethers";
import { config } from "@constants";


// market place Contract address against chainID
// Ethereum Mainnet - 1
// Ropsten Testnet- 3
// Rinkeby Testnet - 4
// Goerli Testnet - 5
// Kovan Testnet - 42
// Polugon Mainnet - 137
// Mumbai Testnet - 80001

export interface IAllPrice {
  buyNowPrice: NFTPrice[];
  lastPurchasedPrice: NFTPrice[];
  makeOfferHighestPrice: NFTPrice[];
  makeOfferLatestPrice: NFTPrice[];
}

export interface WalletInfoType {
  isTokenOwner: boolean;
  tokens: number[];
}
export interface WalletContextType {
  wallet: WalletInfoType;
  setWallet(f: WalletInfoType | ((prev: WalletInfoType) => WalletInfoType)): void;
}
const WalletContext = createContext<WalletContextType>({} as WalletContextType);

export default WalletContext;

export function useWallet(): WalletContextType {
  return useContext(WalletContext);
}
export interface WalletConnectType {
  connected?: boolean;
  chainId?: number | null;
  provider?: any;
  account: string;
  wethOrWmaticBalance?: string | null;
  ethOrMaticBalance?: string | null;
  currency?: string | null;
  openWalletConnect: boolean;
  onDisConnect: boolean;
}
export interface ContextType {
  connect: WalletConnectType;
  setConnect(f: WalletConnectType | ((prev: WalletConnectType) => WalletConnectType)): void;
}
export const WalletConnectContext = createContext<ContextType>({} as ContextType);

export function useWeb3(): ContextType {
  return useContext(WalletConnectContext);
}

export interface getSignature {
  orgID: string,
  walletAddress: string,
  networkID: string,
}
export function useGetApiSignature(
  obj: getSignature | undefined, ready?: boolean
): { getSignatureData: null | string, getSignatureLoading: boolean, getSignatureError: unknown, refetchSignature: any } {
  const { data, loading, error, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getSignature,
    variables: {
      orgID: obj?.orgID, //isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
      walletAddress: obj?.walletAddress,
      networkID: obj?.networkID,
    },
    ready
  });
  return {
    getSignatureData: data?.getSignatureMessage ?? null,
    getSignatureLoading: loading,
    getSignatureError: error,
    refetchSignature: refetch
  };
}
export interface tokens {
  contractAddress: string
  id: string;
  network: string;
  networkID: string;
  metadata: metamask;
  tokenType: string;
  nftTokenId: string;
  balance: string;
  title: string;
  description: string;
  tokenURI: string;
  timeLastUpdated: string;
  mintedAt: string;
  contractName: string;
  status: TokenStatus;
  listedAt: string;
  artistName: string;
  mediaSourceURL: string;
  mediaSourceExtension: string;
  price: {
    buyNowPrice: NFTPrice[];
    lastPurchasedPrice: NFTPrice[];
    makeOfferHighestPrice: NFTPrice[];
    makeOfferLatestPrice: NFTPrice[];
  };
  favouriteCount?: number;
  owner?: string;
  editionNumber: number;
  latestOffer: {
    price: NFTPrice[];
  }
  listedOrderInfo: {
    id: string;
    listedCurrency: {
      contractAddress: string;
    };
    price: NFTPrice[];
  }
  isBuyNowEnabled: boolean;
  isMakeOfferEnabled: boolean;
  isOfferExist: boolean;
}
export enum TokenStatus {
  NEW = "NEW",
  ENQUIRY_PENDING = "ENQUIRY_PENDING",
  OPEN_FOR_SALE = "OPEN_FOR_SALE",
  SALE_PENDING = "SALE_PENDING",
}
export interface metamask {
  name: string;
  description: string;
  image: string;
  animationURL?: string;
  openSeaImageURL?: string;
}
export interface NFTPrice {
  value: number;
  unit: string;
  type: string;
}
export interface reqobj {
  networkID: string;
  prevPageKey: string
  nextPageKey: string;
  count: string;
  totalCount: string;
  offset: string;
}
export interface IwalletDetails {
  networkId: string | undefined;
  walletAddress: string | undefined;
  nextPageKey: string | undefined;
  filters: { search: string; sort: string };
  orgID: string;
  refreshCache: boolean;
}
export function usegetActiveWalletsContent(
  obj: IwalletDetails, ready?: boolean
): { walletList: [tokens] | null, requestObj: reqobj | null, ActiveWalletError: any, refetchWalletContent: any, ActiveWalletStatus: boolean } {
  const variables: any = {
    walletAddress: obj?.walletAddress,
    networkId: obj?.networkId,
    filters: { sort: obj?.filters?.sort },
    orgID: obj?.orgID,
    refreshCache: obj?.refreshCache
  }
  if (!isEmpty(obj?.nextPageKey)) {
    variables['nextPageKey'] = obj?.nextPageKey;
  }
  if (!isEmpty(obj?.filters?.search)) {
    variables['filters']['search'] = obj?.filters?.search;
  }
  const { data, loading, error, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getActiveWalletsContent,
    variables: {
      ...variables,
    },
    ready
  });

  const list: [tokens] | null = data?.getActiveWalletsContent?.tokens;
  const requestObj: reqobj | null = data?.getActiveWalletsContent?.requestObj;
  return {
    walletList: list,
    requestObj: requestObj,
    refetchWalletContent: refetch,
    ActiveWalletError: error,
    ActiveWalletStatus: loading
  }
};

export interface network {
  id: string;
  name: string;
  chainID: number;
  isTestnet: boolean;
}

export function usegetSupportedNetworks(obj: { orgId: string }, ready?: boolean): { NetworkList: [network] | null } {
  const { data } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getSupportedNetworks,
    variables: {
      orgId: obj?.orgId,
      includeTestnets: true,
    },
    onlyAuthenticated: false,
    ready
  });

  const networkData: [network] | null = data?.getSupportedNetworks ? data?.getSupportedNetworks : null;

  return {
    NetworkList: networkData
  }
};

interface LoginWithSignatureQueryParams{
  message: string;
  signature: string;
  address: string;
  networkID: string;
  orgID: string;
}
interface LoginWithSignatureResponseParams {
  data: any;
  error: any;
  loading: boolean;
  refetchExternalWallet: any;
}
// $signature: String!
//       $message: String!
//       $address: String!
//       $orgID: UUID1!
//       $networkID: UUID1!
export function useConnectExternalWallet({ message, signature, networkID, orgID, address }: LoginWithSignatureQueryParams, ready?: boolean): LoginWithSignatureResponseParams {
  const { data, error, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.connectExternalWallet,
    variables: {
      message,
      signature,
      networkID,
      address,
      orgID,
    },
    onlyAuthenticated: false,
    ready
  });

  const externalWalletData: any = data?.connectExternalWallet ? data?.connectExternalWallet : null;
  return { data: externalWalletData, error, loading, refetchExternalWallet: refetch };
};

export interface price {
  amount?: string;
  base?: string;
  currency?: string;
}

export function UsegetUSDPrice(obj?: { crypto: string }, ready?: boolean): { getUSDPrice: price | undefined, onrefectchUSDPrice: any } {
  const { data, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getUSDPrice,
    variables: {
      CryptoCurrenyCode: obj?.crypto,
    },
    ready
  });

  const price: price | undefined = data?.getUSDPrice;

  return {
    getUSDPrice: price,
    onrefectchUSDPrice: refetch
  }
}

export interface MetadataAttributes {
  traitType: string;
  value: {
    intValue?: number;
    floatValue?: number;
    stringValue?: string;
    boolValue?: string;
    __typename?: string;
  };
  displayType: string;
  maxValue: number;
  prevalance?: number;
}

export interface ERC721Metadata {
  name: string;
  description: string;
  image: string;
  attributes: MetadataAttributes[];
  externalURL: string;
  backgroundColor: string;
  animationURL: string;
  timestamp: number;
  language: string;
  openSeaImageURL?: string;
  tags: string[];
  yearCreated: string;
  createdBy: string;
}

export interface INFTDetails {
  contractAddress: string;
  tokenId: BigInt;
  network: string;
  owner: string;
  contractName: string;
  tokenType: string;
  mintedAt: string;
  status: TokenStatus;
  metadata: ERC721Metadata;
  artist: Artist;
  isMakeOfferEnabled: boolean;
  isBuyNowEnabled: boolean;
  isOfferExist: boolean;
  mediaSourceExtension: string;
  mediaSourceType: string;
  mediaSourceURL: string;
  listedOrderInfo: {
    id: string;
    listedCurrency: {
      contractAddress: string;
    }
    price: NFTPrice[];
  }
  latestOffer: {
    price: NFTPrice[];
  }
  balance: number;
  price: {
    buyNowPrice: NFTPrice[];
    lastPurchasedPrice: NFTPrice[];
    makeOfferHighestPrice: NFTPrice[];
    makeOfferLatestPrice: NFTPrice[];
  };
  isFavorite: boolean;
  nftTokenId: string;
  tokenURI: string;
  networkID: string;
  tokenOwnerAvatar: string;
  tokenOwnerAddress: string;
  tokenOwnerUsername: string;
  editions: number;
  editionNumber: number;
}

export interface Artist {
  id: string
  description: string
  artistName: string
  artistLocation: string
  artistContactEmail: string
  artistContactNumber: string
  artistWebsite: string
  slug?: string
}

export interface IDetailsParams {
  networkId?: string;
  contractAddress?: string;
  onChainTokenID?: number | BigInt;
  nftTokenId?: string;
  orgID?: string;
  editionNumber?: string;
  ownerAddress?: string;
  buyerAddress?: string;
  limit?: number;
  offset?: number;
}

export function usegetNFTDetails(obj: IDetailsParams, ready?: boolean): { NFTDetails: INFTDetails | null, NFTDetailsFetch: any, NFTDetailsLoading: boolean } {

  let variables: any = {};
  if (obj?.nftTokenId && obj?.nftTokenId !== "") {
    variables = {
      nftTokenId: obj?.nftTokenId,
      orgId: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
    };
  } else {
    variables = {
      contractAddress: obj?.contractAddress,
      orgId: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
    }
    if (!isEmpty(String(obj?.onChainTokenID))) {
      variables["onChainTokenID"] = obj?.onChainTokenID;
    }
    if (!isEmpty(obj?.ownerAddress)) {
      variables["ownerAddress"] = obj?.ownerAddress;
    }
    if (!isEmpty(obj?.networkId)) {
      variables["networkId"] = obj?.networkId;
    }
    if (!isEmpty(obj?.editionNumber)) {
      variables["editionNumber"] = obj?.editionNumber;
    }
  }
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getNFTDetails,
    variables: { ...variables },
    ready,
    onlyAuthenticated: false,
  });

  const NFTDetails: INFTDetails | null = data?.getNFTDetails

  return {
    NFTDetails: NFTDetails,
    NFTDetailsFetch: refetch,
    NFTDetailsLoading: loading
  }
}
export enum ActivityName {
  Listed_Item,
  Listed_Item_Removed,
  Sold_Item,
  Offered,
  Offer_Cancelled,
  Offer_Rejected,
  Offer_Expired,
  Bought_Item
}
export interface NFTPrice {
  value: number
  unit: string
}
export interface Wallet {
  id: string
  name: string
  address: string
}
export enum OrderStatus {
  PENDING,
  CANCELLED,
  REJECTED,
  FAILED,
  COMPLETED,
  EXPIRED
}
export interface getOrderActivity {
  ID: string
  ActivityName: ActivityName
  TokenName: string
  TokenID: number
  NFTTokenID: string
  ContractName: string
  ContractAddress: string
  Price: NFTPrice[]
  NetworkID: string
  TransactionHash: string
  FromUserName: string
  ToUserName: string
  FromUserAvatar: string
  ToUserAvatar: string
  FromWallet: Wallet
  ToWallet: Wallet
  InvoiceURL: string
  InvoiceID: string
  Status: OrderStatus
  CreatedAt: any
  cryptoTax: ITaxResponse
  order: {
    creatorFee: number;
    platformFee: number;
  }
  nftImageUrl?: string;
}
export function useGetOrderActivity(orgId: string, filters: string, offset = 1, limit = 10, ready: boolean): { totalCount: number, getOrderActivity: getOrderActivity[], getOrderActivityLoading: boolean, getOrderActivityRefetch: any } {
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getOrderActivity,
    variables: { orgId: orgId, filter: filters, offset: offset, limit },
    ready
  })
  const orderActivityData: getOrderActivity[] = data?.getUserOrderActivity?.data;
  const totalCount: number = data?.getUserOrderActivity?.totalCount ?? 0;

  return {
    totalCount,
    getOrderActivity: orderActivityData,
    getOrderActivityLoading: loading,
    getOrderActivityRefetch: refetch
  }
}

export function usegetNFTDetailsByTokenId(obj: { nftTokenId?: string, orgId?: string }, ready?: boolean): { NFTDetailsByTokenId: INFTDetails | null, NFTDetailsByTokenTokenFetch: any, NFTDetailsByTokenIdLoading: boolean } {

  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getNFTDetailsByTokenId,
    variables: { nftTokenId: obj?.nftTokenId, orgId: obj?.orgId },
    ready
  });

  const NFTDetailsByTokenId: INFTDetails | null = data?.getNFTDetailsByTokenId

  return {
    NFTDetailsByTokenId: NFTDetailsByTokenId,
    NFTDetailsByTokenTokenFetch: refetch,
    NFTDetailsByTokenIdLoading: loading
  }
}

export interface ERC1155Metadata {
  tokenId: BigNumber;
  value: number;
}

export interface INFTHistory {
  totalCount: number;
  data: IHistory[] | null;
}

export interface IHistory {
  blockNum: number;
  hash: string;
  from: string;
  to: string;
  value: number;
  erc1155Metadata: [ERC1155Metadata];
  tokenId: BigNumber;
  category: string;
  asset: string;
  price: {
    value: number;
    unit: string;
    type: string;
  };
  blockTimestamp: string;
  eventType: NFTTxEventType;
  fromUserAvatar: string;
  fromUserName: string;
  toUserAvatar: string;
  toUserName: string;
}

export enum NFTTxEventType {
  Transfer,
  Offer
}


export function usegetNFTHistory(obj: IDetailsParams, ready?: boolean): { NFTHistory: INFTHistory | null, NFTHistoryLoading: boolean, NFTHistoryRefetch: any } {

  const variables: any = {
    contractAddress: obj?.contractAddress,
    tokenId: obj?.onChainTokenID,
    networkId: obj?.networkId,
    orgId: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
    ownerAddress: obj?.ownerAddress,
    limit: obj?.limit,
    offset: obj?.offset,
  }
  if (!isEmpty(obj?.nftTokenId)) {
    variables['nftTokenId'] = obj?.nftTokenId;
  }
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getNFTHistory,
    variables: { ...variables },
    ready,
    onlyAuthenticated: false,
  });

  const NFTHistory: INFTHistory | null = data?.getNFTHistoryV2

  return {
    NFTHistory: NFTHistory,
    NFTHistoryLoading: loading,
    NFTHistoryRefetch: refetch,
  }
}

export interface IRegistryTokens {
  data: IAllRegistryTokens[];
  totalCount: number;
  user: User | null;
  latestSoldPrice?: NFTPrice[];
  highestSoldPrice?: NFTPrice[];
  lowestSoldPrice?: NFTPrice[];
  offsets?: { offset: number; limit: number, total: number }[];
}

export interface IAllRegistryTokens {
  collectionSlug?: string;
  ID: string;
  TokenName: string;
  TokenID: number;
  MetaData: metamask;
  TokenType: string;
  ListingStatus: string;
  OwnersList: string[];
  Owners: string;
  RegistryId: string;
  Registry: Registry;
  CreatedBy: string;
  CreatedAt: string;
  UpdatedAt: string;
  Status: string;
  mediaSourceURL: string
  mediaSourceExtension: string
  Price: {
    buyNowPrice: NFTPrice[];
    lastPurchasedPrice: NFTPrice[];
    makeOfferHighestPrice: NFTPrice[];
    makeOfferLatestPrice: NFTPrice[];
  };
  TokenURI: string;
  NFTTokenID: string;
  MintedAt: string;
  networkID: string;
  editionNumber: number;
  TokenOwnerAddress: string;
  nftContractName: string;
  networkName: string;
  latestOffer: {
    price: NFTPrice[];
  }
  listedOrderInfo: {
    id: string;
    listedCurrency: {
      contractAddress: string;
    };
    price: NFTPrice[];
  }
  isBuyNowEnabled: boolean;
  isMakeOfferEnabled: boolean;
  isOfferExist: boolean;
  Artist: Artist;
}

export interface Registry {
  ID: string
  NetworkID: string;
  Network: {
    name: string;
  }
  OrganizationID: string;
  CollectionName: string;
  ContractAddress: string;
  IsAllTokensApproved: boolean;
  MarketplaceID: string;
  TotalApproved: number;
  CollectionTotal: number;
  ArtistID: string;
  Artist: Artist;
  CategoryID: string
  CreatedByUserID: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface IRegistry {
  orgID: string;
  marketplaceID: string;
  owner?: string;
  offset: number;
  limit: number;
  searchKey?: string;
  filters?: string;
  status?: string;
  categoryID?: string;
  buyerAddress?: string;
  artistID?: string;
  artistSlug?: string;
  registryID?: string;
  categorySlug?: string;
  currentPage?: number;
}

export interface CountIRegistry {
  totalCount?: number;
  floorPrice?: NFTPrice[] | undefined;
  latestSoldPrice?: NFTPrice[] | undefined;
}

export interface User {
  id: string;
  username: string;
  name: string;
  email: string;
  avatar: string;
}

interface IGlobalSearch {
  orgID: string | undefined;
  searchKey: string;
  filterBy: string;
  offset: number;
  limit: number;
}

export function globalSearchFilter(obj: IGlobalSearch, ready = false): any {
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.globalSearch,
    variables: {
      orgID: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
      marketplaceID: config.SECONDARY_MARKETPLACE_ID,
      searchKey: obj?.searchKey,
      filterBy: obj?.filterBy,
      offset: obj?.offset,
      limit: obj?.limit
    },
    ready
  });

  return {
    globalSearchList: data?.globalSearch,
    refetchGlobalSearch: refetch,
    globalSearchLoading: loading
  }
}

export function getAllRegistryTokensArr(objArray: any[], ready?: boolean): any {
  const queryConfigs = objArray.map(obj => {
    const variables: any = {
      orgID: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
      marketplaceID: obj?.marketplaceID,
      offset: obj?.offset,
      limit: obj?.limit,
      artistSlug: obj?.artistSlug,
    };

    if (obj?.owner && obj?.owner !== "") variables["owner"] = obj?.owner;
    if (obj?.searchKey && obj?.searchKey !== "") variables["searchKey"] = obj?.searchKey;
    if (obj?.filters && obj?.filters !== "") variables["filters"] = obj?.filters;
    if (obj?.categoryID && obj?.categoryID !== "") variables["categoryID"] = obj?.categoryID;
    if (obj?.buyerAddress && obj?.buyerAddress !== "") variables["buyerAddress"] = obj?.buyerAddress;
    if (obj?.artistID) variables["artistID"] = obj?.artistID;
    if (obj?.registryID) variables["registryID"] = obj?.registryID;
    if (obj?.categorySlug) variables["categorySlug"] = obj?.categorySlug;

    return {
      query: EMojitoSecondaryWalletQueries.getAllRegistryTokens,
      variables: variables,
      onlyAuthenticated: false,
      ready: ready
    };
  });

  const { data, loading, refetch } = UseSecondaryWalletMojitoArr({
    queries: queryConfigs,
    onlyAuthenticated: false,
    ready
  });

  const AllRegistryTokens: IRegistryTokens[] | null = data;

  return {
    AllRegistryTokens: AllRegistryTokens,
    AllRegistryLoading: loading,
    AllRegistryRefetch: refetch
  }
}

export function getAllRegistryTokens(obj: IRegistry, ready?: boolean): { AllRegistryTokens: IRegistryTokens | null, AllRegistryLoading: boolean, AllRegistryRefetch: any } {
  const variables: any = {
    orgID: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
    marketplaceID: obj?.marketplaceID,
    offset: obj?.offset,
    limit: obj?.limit,
  };

  if (obj?.owner && obj?.owner !== "") variables["owner"] = obj?.owner;
  if (obj?.searchKey && obj?.searchKey !== "") variables["searchKey"] = obj?.searchKey;
  if (obj?.filters && obj?.filters !== "") variables["filters"] = obj?.filters;
  if (obj?.categoryID && obj?.categoryID !== "") variables["categoryID"] = obj?.categoryID;
  if (obj?.buyerAddress && obj?.buyerAddress !== "") variables["buyerAddress"] = obj?.buyerAddress;
  if (obj?.artistID) variables["artistID"] = obj?.artistID;
  if (obj?.artistSlug) variables["artistSlug"] = obj?.artistSlug;
  if (obj?.registryID) variables["registryID"] = obj?.registryID;
  if (obj?.categorySlug) variables["categorySlug"] = obj?.categorySlug;

  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getAllRegistryTokens,
    variables: variables,
    onlyAuthenticated: false,
    ready
  });

  const AllRegistryTokens: IRegistryTokens | null = data?.getAllRegistryTokens;

  return {
    AllRegistryTokens: AllRegistryTokens,
    AllRegistryLoading: loading,
    AllRegistryRefetch: refetch
  }
}

export function getAllCountTokens(obj: IRegistry, ready?: boolean): { AllCountTokens: CountIRegistry | null, AllCountLoading: boolean, AllCountRefetch: any } {
  const variables: any = {
    orgID: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
    marketplaceID: obj?.marketplaceID,
  };

  if (obj?.categoryID && obj?.categoryID !== "") variables["categoryID"] = obj?.categoryID;
  if (obj?.artistID) variables["artistID"] = obj?.artistID;
  if (obj?.artistSlug) variables["artistSlug"] = obj?.artistSlug;
  if (obj?.registryID) variables["registryID"] = obj?.registryID;
  if (obj?.categorySlug) variables["categorySlug"] = obj?.categorySlug;

  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getAllCountTokens,
    variables: variables,
    onlyAuthenticated: false,
    ready
  });

  const AllCountTokens: CountIRegistry | null = data?.getFloorAndLatestPrice;

  return {
    AllCountTokens: AllCountTokens,
    AllCountLoading: loading,
    AllCountRefetch: refetch
  }
}

export interface IGetOffer {
  id: string;
  offerExpiryDate: string;
  nftOwnerAddress: string;
  tokenType: string;
  tokenId: string
  tokenContract: string;
  price: NFTPrice[];
  createdBy: { id: string, username: string };
  createdByUserOrganization: { username: string };
  orderStatus: string;
  buyerAddress: string;
  buyerTax: number;
  fixedPrice: number;
  updatedAt: string;
  createdAt: string;
};
export function getSignatureForOfferApproval(obj: { orgId?: string, orderId?: string, creatorFee: number | null }, ready?: boolean): { offerApprovalData: any, offerApprovalError: any, offerRefetch: any, isError: boolean } {

  const { data, error, refetch, isError } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getSignatureForOfferApproval,
    variables: {
      orderId: obj?.orderId,
      orgId: obj?.orgId,
      creatorFee: obj?.creatorFee
    },
    ready
  });

  return {
    offerApprovalData: data,
    offerApprovalError: error,
    offerRefetch: refetch,
    isError: isError
  }
}

export interface IOfferParams {
  buyerAddress?: string;
  orgId?: string;
  nftTokenId?: string;
}

export function getOffers(obj: IOfferParams, ready?: boolean): { OffersData: IGetOffer[] | null, OfferLoading: boolean, OfferRefetch: any } {

  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getOffers,
    variables: {
      nftTokenId: obj?.nftTokenId,
      orgId: obj?.orgId,
    },
    ready
  });

  const OffersData: IGetOffer[] | null = data?.getOffers;

  return {
    OffersData: OffersData,
    OfferLoading: loading,
    OfferRefetch: refetch
  }
}

interface IImageData {
  name: string;
  description: string;
  image: string;
  animation_url: string;
}
export const ImageDetails = async (tokenURI: string): Promise<{ data: IImageData }> => {
  const data = await axios.get(tokenURI);
  return { data: data?.data };

}
export interface ISavedItems {
  orgID: string;
  offset: number;
  limit: number;
  searchKey?: string;
  filters?: string;
  buyerAddress?: string;
  currentPage?: number
}
export interface ISavedTokens {
  data: tokens[];
  totalCount: number;
}
export function getAllSavedItems(obj: ISavedItems, ready?: boolean): { AllRegistryTokens: ISavedTokens | null, AllRegistryLoading: boolean, AllRegistryRefetch: any } {
  const variables: any = {
    orgID: obj?.orgID,
    offset: obj?.offset,
    limit: obj?.limit,
  };
  if (obj?.searchKey !== "") variables["searchKey"] = obj?.searchKey;
  if (obj?.filters !== "") variables["filters"] = obj?.filters;
  if (obj?.buyerAddress !== "") variables["buyerAddress"] = obj?.buyerAddress;
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getAllSavedItems,
    variables: variables,
    ready,
  });

  const AllRegistryTokens: ISavedTokens | null = data?.getNFTFavouriteListByUser;

  return {
    AllRegistryTokens: AllRegistryTokens,
    AllRegistryLoading: loading,
    AllRegistryRefetch: refetch,
  };
}

export interface IEstamateTax {
  orgId: string;
  orderId?: string;
  estimateType: string;
  nftTokenId?: string;
  price?: number;
  country?: string;
  postalCode?: string;
}

export interface IEstamateTaxItems {
  taxPercentage: number;
  royaltyFee: number;
  platformFee: number;
  taxResponse?: ITaxResponse;
}

export interface ITraits {
  contractAddress: string;
  tokenID: string;
  networkID: string;
}

export interface ITraitsItems {
  traitType: number;
  value: {
    AttributeValueString: string;
    AttributeValueInt: number;
    AttributeValueFloat: number;
    AttributeValueBool: boolean;
  }
  displayType: string;
  maxValue: number;
  prevalance: number;
}

export function getNFTAttributesRarity(obj: ITraits): { traits: ITraitsItems[] | null, traitsLoading: boolean, traitsRefetch: any } {
  const variables: any = {
    contractAddress: obj?.contractAddress,
    tokenID: obj?.tokenID,
    networkID: obj?.networkID,
    refreshCatch: false
  };
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getNFTAttributesRarity,
    variables: variables,
  });

  const traits: ITraitsItems[] | null = data?.getNFTAttributesRarity;

  return {
    traits: traits,
    traitsLoading: loading,
    traitsRefetch: refetch
  };
}
export function getAllRegistryTokensByUser(obj: IRegistry, ready?: boolean): { AllRegistryTokens: IRegistryTokens | null, AllRegistryLoading: boolean, AllRegistryRefetch: any } {

  const variables: any = {
    orgID: obj?.orgID,
    marketplaceID: obj?.marketplaceID,
    offset: obj?.offset,
    limit: obj?.limit,
  };

  if (obj?.searchKey !== "") variables["searchKey"] = obj?.searchKey;
  if (obj?.filters !== "") variables["filters"] = obj?.filters;
  if (obj?.status !== "") variables["status"] = obj?.status;
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getAllRegistryTokensByUser,
    variables: variables,
    ready
  });

  const AllRegistryTokens: IRegistryTokens | null = data?.getAllRegistryTokensByUser;

  return {
    AllRegistryTokens: AllRegistryTokens,
    AllRegistryLoading: loading,
    AllRegistryRefetch: refetch
  }
}


export interface supportedCurrenciesProps {
  orgId: string,
  nftTokenId: string,
}

export interface ISupportedCurrenciesList {
  id: string;
  name: string;
  networkId: string;
  symbol: string;
  contractAddress: string;
  secondaryMarketplaceContractAddress: string;
  network: {
    id: string;
    name: string;
    chainID: number;
    wethAddress: string;
    paymentCurrency: string;
  }
}

export function getSupportedCurrencies(obj: supportedCurrenciesProps): { supportedCurrencies: ISupportedCurrenciesList[], supportedCurrenciesLoading: boolean, supportedCurrenciesRefetch: any } {

  const variables: any = {
    orgId: obj?.orgId,
    nftTokenId: obj?.nftTokenId,
  }
  const { data, isLoading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getSupportedCurrencies,
    variables: variables,
  });

  const supportedCurrencies: ISupportedCurrenciesList[] = data?.getSupportedCurrencies;

  return {
    supportedCurrencies: supportedCurrencies,
    supportedCurrenciesLoading: isLoading,
    supportedCurrenciesRefetch: refetch
  };
}

interface ITaxQuote {
  street1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  currencyCode: string;
  orgID: string;
  taxablePrice: number;
}

export interface IGetTax {
  verifiedAddress: {
    street1: string;
    city: string;
    state: string;
  }
  taxablePrice: number;
  totalTaxedPrice: number;
  totalTaxAmount: number;
}

export function getTaxQuote(obj: ITaxQuote): { getTaxData: IGetTax | null, getTaxDataIsLoading: boolean } {

  const variables: any = {
    street1: obj?.street1,
    city: obj?.city,
    state: obj?.state,
    postalCode: obj?.postalCode,
    country: obj?.country,
    currencyCode: obj?.currencyCode,
    orgID: obj?.orgID,
    taxablePrice: obj?.taxablePrice
  }

  const { data, loading } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getTaxQuote,
    variables: variables
  });

  const getTaxData: IGetTax | null = data?.getTaxQuote;

  return {
    getTaxData: getTaxData,
    getTaxDataIsLoading: loading
  }
}

export interface IHightlights {
  orgID: string;
  marketplaceID: string;
  offset: number;
  limit: number;
  categoryID?: string;
  buyerAddress?: string;
  column: string;
  type: string;
}

export function getAllHighlightsTokens(obj: IHightlights, ready?: boolean): { AllRegistryTokens: IRegistryTokens | null, AllRegistryLoading: boolean, AllRegistryRefetch: any } {
  const variables: any = {
    orgID: isEmpty(obj?.orgID) ? process.env.NEXT_PUBLIC_API_ORGANIZATION_ID : obj?.orgID,
    marketplaceID: obj?.marketplaceID,
    offset: obj?.offset,
    limit: obj?.limit,
  };
  if (obj?.categoryID && obj?.categoryID !== "") variables["categoryID"] = obj?.categoryID;
  if (obj?.buyerAddress && obj?.buyerAddress !== "") variables["buyerAddress"] = obj?.buyerAddress;
  if (obj?.column) variables["column"] = obj?.column;
  if (obj?.type) variables["type"] = obj?.type;
  const { data, loading, refetch } = UseSecondaryWalletMojito({
    query: EMojitoSecondaryWalletQueries.getHighlightsTokens,
    variables: variables,
    onlyAuthenticated: false,
    ready
  });

  const AllRegistryTokens: IRegistryTokens | null = data?.getAllRegistryTokens;

  return {
    AllRegistryTokens: AllRegistryTokens,
    AllRegistryLoading: loading,
    AllRegistryRefetch: refetch
  }
}

export interface ITaxResponse {
  cryptoTaxPrice: number;
  cryptoTotalPrice: number;
  USDUnitprice: number;
  taxPercentage: number;
  creatorFee?: number;
}
