import { useAuth0 } from "@auth0/auth0-react";
import { BaseButton } from "@components";
import { EEventNames } from "@enums";
import {
  useFavorites,
  useMojitoMutation,
  useTrackingEventFactory,
} from "@hooks";
import { EMojitoMutations } from "@state";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

const FavoriteButtonMemo = styled(
  memo(
    ({
      collectionItemId,
      icon,
      ...props
    }: {
      collectionItemId: string;
      icon: boolean;
    }) => {
      const { isAuthenticated, loginWithRedirect } = useAuth0();
      const [savedFavorite, setSavedFavorite] = useState(false);
      const [loading, setLoading] = useState(false);
      const trackEvent = useTrackingEventFactory();

      const [saveToFavorite] = useMojitoMutation<{
        collectionItemId: string;
      }>(EMojitoMutations.saveCollectionItemToFavorites);

      const [removeFromFavorites] = useMojitoMutation<{
        collectionItemId: string;
      }>(EMojitoMutations.removeCollectionItemFromFavorites);

      const { favorites, refetch: refetchFavorites } = useFavorites();

      const handleClickFavorite = async () => {
        if (!isAuthenticated) {
          loginWithRedirect({
            appState: {
              returnTo: window.location.pathname,
              query: { save: true },
            },
          });
        } else {
          await updateFavorite();
        }
        if (!collectionItemId) {
          console.error("mojitoId is empty");
          return;
        }
      };

      const updateFavorite = async () => {
        try {
          setLoading(true);
          if (savedFavorite) {
            await removeFromFavorites({ collectionItemId });
            trackEvent(EEventNames.ItemUnsaved);
          } else {
            await saveToFavorite({ collectionItemId });
            trackEvent(EEventNames.ItemSaved);
          }
          refetchFavorites();
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      };

      useEffect(() => {
        if (loading) setLoading(false);
        if (favorites?.length) {
          setSavedFavorite(favorites.some((e) => e.id == collectionItemId));
        } else if (favorites?.length === 0) {
          setSavedFavorite(false);
        }
      }, [favorites]);

      return (
        <BaseButton
          secondary
          onClick={handleClickFavorite}
          isLoading={loading}
          active={savedFavorite}
          {...props}>
          {savedFavorite ? (
            icon ? (
              <SVG src="../icons/heart.svg" fill={"#ffff"} />
            ) : (
              "Saved"
            )
          ) : icon ? (
            <SVG src="../icons/heart.svg" fill={"none"} />
          ) : (
            "Save"
          )}
        </BaseButton>
      );
    },
    (p, n) => p.collectionItemId == n.collectionItemId
  )
)``;

export const FavoriteButton = ({
  collectionItemId,
  icon,
}: {
  collectionItemId?: string;
  icon?: boolean;
}): JSX.Element | null =>
  collectionItemId ? (
    <FavoriteButtonMemo
      collectionItemId={collectionItemId}
      icon={icon === undefined ? false : icon}
    />
  ) : null;
