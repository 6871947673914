/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from "./client";
import { IStateFields } from "@metaverse/types";

export const getLotStates = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<IStateFields>({
    content_type: "blockLotPurchase",
    include: 2,
    ...query,
  });

  return response;
};

export const getLotState = async (
  id: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getLotStates({
    "sys.id": id,
    limit: 1,
    ...query,
  });

  return response?.items?.[0] || null;
};
