import moment from "moment";

export const auctionDurationFormatter = (start: string, end: string): string => {
  const _start = moment(start);
  const _end = moment(end);
  const isSameMonth = _start.month() === _end.month();
  const isSameYear = _start.year() === _end.year();
  const startFormatted = !isSameYear
    ? "DD MMMM yyyy"
    : !isSameMonth
    ? "DD MMMM"
    : "DD";

  return moment().diff(_start, "days") < -2
    ? "Coming soon"
    : _start.format(startFormatted) + " — " + _end.format("DD MMMM yyyy");
};
