import { EViewSize } from "@enums";
import {
  useCollection,
  useCollectionItemBidsList,
  useContentfulFullLot,
  useProfile,
} from "@hooks";
import { IMojitoCollectionItemAuctionLot } from "@interfaces";
import { Grid } from "@material-ui/core";
import { premiumCalculator } from "@utils";
import Link from "next/link";
import React, { memo, useContext } from "react";
import styled from "styled-components";
import { ItemInfo } from "../../itemInfo";
import { CollectionItemContext } from "../state/auctionItemContext";
import { AuctionLotWinnerPlaceholder } from "./auctionLotWinnerPlaceholder";
import { LotPreviewContent } from "./lotPreviewContent";

const PreviewDescription = styled.div`
  width: 100%;
  min-height: 60px;
  padding-top: 6px;
  display: flex;
  align-items: center;
  color: var(--text-color);

  @media screen and (max-width: ${EViewSize.tablet}) {
    height: auto;
    margin-top: 10px;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding: 0 16px;
    margin-top: 0px;
    background: var(--text-reversed);
  }
`;

const AlignGrid = styled((props) => <Grid {...props} />)<{
  $isColumn?: boolean;
  $reversed?: boolean;
}>`
  display: flex;
  align-items: ${({ $isColumn }) => ($isColumn ? "flex-start" : "center")};
  flex-direction: ${({ $isColumn, $reversed }) =>
    $isColumn
      ? $reversed
        ? "column-reverse"
        : "column"
      : $reversed
      ? "row-reverse"
      : "row"};
  gap: 15px;
  white-space: nowrap;

  @media screen and (max-width: ${EViewSize.tablet}) {
    align-items: flex-start;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding-top: 10px;
    gap: 0;
  }

  &:first-child {
    @media screen and (max-width: ${EViewSize.tablet}) {
      padding-right: 48px;
    }

    @media screen and (max-width: ${EViewSize.mobile}) {
      padding-right: 22px;
    }
  }

  &:last-child {
    @media screen and (max-width: ${EViewSize.tablet}) {
      padding-left: 48px;
    }

    @media screen and (max-width: ${EViewSize.tablet}) {
      padding-left: 22px;
    }
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-family: "MercuryDisplay", serif;

  @media screen and (max-width: ${EViewSize.tablet}) {
    white-space: normal;
  }
`;

const PreviewWrapper = styled.div<{
  $lotPreviewBackgroundColor?: string;
  $winnerIsYou: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 10px;
  height: 552px;
  max-height: calc(
    100vh - (var(--style-header-height) + var(--style-footer-height) + 25px)
  );
  max-width: 1000px;
  background: ${({ $lotPreviewBackgroundColor }) =>
    $lotPreviewBackgroundColor || "#060606"};

  overflow: hidden;

  @media screen and (max-width: ${EViewSize.tablet}) {
    max-width: 604px;
    height: ${({ $winnerIsYou }) => ($winnerIsYou ? "480px" : "552px")};
    margin: 0 auto;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    border-radius: 0;
    height: ${({ $winnerIsYou }) =>
      $winnerIsYou ? "calc(100% - 200px)" : "calc(100% - 110px)"};
  }
`;

interface ILotPreviewCardMemo {
  lotData: any;
  mojitoItemDetails?: IMojitoCollectionItemAuctionLot;
  activeSlide: number;
}

const LotPreviewCardMemo = memo(
  ({ mojitoItemDetails, activeSlide, lotData }: ILotPreviewCardMemo) => {
    const { profile } = useProfile();
    const { slug } = useCollection();
    const { bids } = useCollectionItemBidsList(lotData?.mojitoId as string);
    const bid = bids?.[0];
    const isYou = profile?.id === bid?.marketplaceUser.id;
    const isCardClosed = mojitoItemDetails?.saleView?.isPostSale ?? false;

    let bidSoldAmount = bid?.amount;
    if (bidSoldAmount && mojitoItemDetails?.feeStructure) {
      const fee = premiumCalculator(
        bidSoldAmount,
        mojitoItemDetails.feeStructure
      );

      bidSoldAmount += fee.buyersPremiumRate + fee.overheadPremiumRate;
    }

    return (
      <>
        <PreviewWrapper
          $winnerIsYou={isCardClosed && isYou}
          $lotPreviewBackgroundColor={lotData?.lotPreviewBackgroundColor}>
          {isCardClosed && mojitoItemDetails && (
            <AuctionLotWinnerPlaceholder
              mojitoId={mojitoItemDetails?.marketplaceCollectionItemId}
            />
          )}
          <LotPreviewContent activeSlide={activeSlide} lotData={lotData} />
        </PreviewWrapper>
        <PreviewDescription>
          <Grid container>
            <AlignGrid
              item
              xs={6}
              $isColumn
              $reversed={slug == "gifted-twitter-140"}>
              {lotData?.author?.slug && lotData?.author?.name && (
                <Link href={`/authors/${lotData?.author?.slug}`} passHref>
                  <Title>
                    Lot {lotData?.lotId}: {lotData?.author?.name}
                  </Title>
                </Link>
              )}
              {lotData?.title && (
                <ItemInfo
                  itemName={lotData?.title}
                  createdDate={lotData?.createdAt}
                  $uppercase
                />
              )}
            </AlignGrid>
          </Grid>
        </PreviewDescription>
      </>
    );
  },
  (prevProps: ILotPreviewCardMemo, nextProps: ILotPreviewCardMemo) => {
    return (
      prevProps.activeSlide === nextProps.activeSlide &&
      prevProps.mojitoItemDetails?.id === nextProps.mojitoItemDetails?.id
    );
  }
);

export const LotPreviewCard = styled(
  ({
    mojitoItemDetails,
    activeSlide,
    ...props
  }: {
    mojitoItemDetails?: IMojitoCollectionItemAuctionLot;
    activeSlide: number;
  }) => {
    const { item } = useContext(CollectionItemContext);
    const { lot } = useContentfulFullLot(item.id);

    return lot?.sys?.publishedAt ? (
      <div {...props}>
        <LotPreviewCardMemo
          mojitoItemDetails={mojitoItemDetails}
          activeSlide={activeSlide}
          lotData={lot}
        />
      </div>
    ) : null;
  }
)`
  position: relative;
  height: 100%;
`;
