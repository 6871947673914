export enum EEventNames {
  TermsAccepted = "Terms Accepted",
  BidPlaced = "Bid Placed",
  ItemSaved = "Item Saved",
  ItemUnsaved = "Item Unsaved",
  BidStarted = 'Bid Started',
  BidError = "Bid Error",
  ErrorOccurred = "Error Occurred",
  ExitLink = "Exit Link",
  PlayVideo = "Play Video",
  CheckoutStepCompleted = "Checkout Step Completed",
  OrderCompleted = "Order Completed",
  makeOfferStarted = "Make an Offer Clicked",
  makeOfferCheckOut = "Make Offer Checkout started",
  makeOfferApprovalCompleted = "Make Offer currency  Approval Step Completed",
  makeOfferApprovalRejected = "Make Offer currency  Approval Step Rejected",
  makeOfferMetaMaskCompleted = "Make offer signature step Completed",
  makeOfferCompleted = "Make Offer Completed",
  listItemStarted = "List Item Started",
  listItemApprovalCompleted = "List Item Contract Approval Step Completed",
  listItemApprovalRejected = "List Item Contract Approval Step Rejected",
  listItemMetaMaskCompleted = "List Item Signature Step Completed",
  listItemCompleted = "List Item Completed",
  buyNowStarted = "Buy Now Clicked",
  buyNowCheckOut = "Buy Now Checkout started",
  buyNowApprovalCompleted = "Buy now currency  Approval Step Completed",
  buyNowApprovalRejected = "Buy now currency  Approval Step Rejected",
  buyNowMetaMaskCompleted = "Buy Now Transaction  Step Completed",
  buyNowCompleted = "Buy Now Completed",
  acceptOfferClicked = "Offer Clicked",
  acceptOfferStarted = "Offer Started",
  acceptOfferApprovalCompleted = "Offer Contract Approval Step Completed",
  acceptOfferMetaMaskCompleted = "Offer Transaction step Completed",
  acceptOfferMetaMaskRejected = "Offer Transaction step Rejected",
  acceptOfferCompleted = "Offer Completed",
  removeFavourite = "Item Unfollowed",
  addFavourite = "Item Followed",
  errorEvent = "Error Occurred",
  removeListItem = "List Item Removed",
  rejectOffer = "Offer Rejected",
  cancelOffer = "Offer Canceled",
  swapAmount = "Swap Completed",
  userRejectedTransaction = "Transaction Rejected",
  WalletAddress = "Wallet Address",
}
