import { config } from "@constants";
import { EPagePath, EEventNames } from "@enums";
import { palette } from "@styles/theme";
import Link from "next/link";
import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { useTrackingEventFactory, useDeviceResponsive } from "@hooks";

export const FooterLinks = styled((props) => {
  return (
    <div {...props}>
      <div className="footerNav">
        <Link href={EPagePath.PrivacyPolicy} passHref>
          <a>Privacy Policy</a>
        </Link>
        <Link href={EPagePath.TermsOfService} passHref>
          <a>Terms Of Service</a>
        </Link>
        <Link href={EPagePath.GuideToBuying} passHref>
          <a>Guide to Buying</a>
        </Link>
        {/* <Link href={EPagePath.ConditionsOfBusiness} passHref>
        <a>Conditions of Business</a>
        </Link> */}
        <Link href={EPagePath.DoNotSellData} passHref>
          <a>Do Not Sell My Personal Information</a>
        </Link>
        <Link href={EPagePath.FAQ} passHref>
          <a>FAQ</a>
        </Link>
      </div>
    </div>
  );
})`
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-end;
  .footerNav {
    width: calc(100% - 0px);
    display: flex;
    flex-wrap: wrap;
    a {
      color: ${palette.black};
      font-family: "BentonSans";
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.0025em;
      white-space: nowrap;
      margin: 5px 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  //responsive
  @media screen and (max-width: 767px) {
    .footerNav {
      margin-left: -8px;
      a {
      }
    }
  }
`;

export const FooterSocials = styled((props) => {
  const trackEvent = useTrackingEventFactory();

  const handleExitEvent = (link: string) => () => {
    trackEvent(EEventNames.ExitLink, {
      link,
    });
  };
  return (
    <div {...props}>
      <a
        onClick={handleExitEvent("https://twitter.com/Sothebysverse")}
        href="https://twitter.com/Sothebysverse"
        target="_blank"
        rel="noreferrer">
        <SVG src="/icons/twitter_black.svg" width={18} />
      </a>
    </div>
  );
})`
  min-width: 60px;
  display: flex;
  flex-direction: row;
  a {
    color: var(--text-color);
    line-height: 25px;

    & + a {
      margin-left: 16px;
    }
  }
`;

export const Footer = styled((props) => {
  const { isEqualOrBelow } = useDeviceResponsive();

  return isEqualOrBelow("desktop") ? (
    <div className="footerMobile">
      <div {...props} className="footer-container">
        <FooterLinks className="ftlnk" />
        <FooterSocials />
      </div>
    </div>
  ) : (
    <div className="footerdesktop">
      <div {...props} className="footer-container">
        <FooterLinks className="ftlnk" />
        <FooterSocials />
      </div>
    </div>
  );
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: 1600px;
  margin: 0 auto;
  z-index: 100;
  position: relative;
  padding: 0 35px;
  width: 100%;
  min-height: 40px;
  .footerdesktop {
    width: 100%;
  }
  .ftlnk {
    width: 100%;
  }
  a {
    color: var(--text-color);
    line-height: 25px;

    & + a {
      margin-left: 16px;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: ${palette.white};
    width: 100vw;
    height: 100%;
    left: calc(50% - 50vw);
    top: 0;
    z-index: -1;
  }
`;
