import { EPagePath, EViewSize } from "@enums";
import { usePageType } from "@hooks";
import classNames from "classnames";
import Link from "next/link";
import React from "react";
import styled from "styled-components";

interface IHeader {
  navigation: {
    title: string;
    path: EPagePath | string;
    parentPath?: Array<EPagePath> | EPagePath;
  }[];
}

const NavigationWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 30px;

  .navigation-item {
    min-width: 90px;
    padding: 0 16px;
    height: 42px;
    margin: 0 8px;
    border-radius: 5px 5px 0 0;
    text-transform: uppercase;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    &.active {
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(12px);
    }

    a {
      font-family: "BentonSans", sans-serif;
      color: var(--text-white);
      font-size: 14px;
      line-height: 17px;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    &-item {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    &-item {
      padding: 0 6px;
      margin: 0 4px;
      min-width: auto;
    }
  }
`;

export const TabsNavigation = styled(({ navigation }: IHeader) => {
  const { _resolvedUrl } = usePageType();
  return navigation.length > 0 ? (
    <NavigationWrapper className="navigation">
      {navigation.map((item, i) => (
        <div
          key={i}
          className={classNames(
            "navigation-item",
            (_resolvedUrl === item.path ||
              _resolvedUrl.startsWith(`${item.parentPath}`)) &&
              "active"
          )}>
          <Link href={item.path} shallow passHref>
            {item.title}
          </Link>
        </div>
      ))}
    </NavigationWrapper>
  ) : (
    <></>
  );
})``;
