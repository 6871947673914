import React, { useState, ReactNode } from "react";
import WalletConnectContext, {
  WalletInfoType,
} from "../../utils/wallet/wallet";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => {

  const [wallet, setWallet] = useState<WalletInfoType>({
    isTokenOwner: false,
    tokens: [],
  });

  return (
    <React.Fragment>
        <WalletConnectContext.Provider value={{ wallet, setWallet }}>
          {children}
        </WalletConnectContext.Provider>
    </React.Fragment>
  );
};
