import {
  AuctionGridItemImageWrapper,
  FavoriteButton,
  Image,
} from "@components";
import { BaseButton } from "../../button";
import { EViewSize } from "@enums";
import {
  useCollection,
  useCollectionItemCurrentBids,
  useContentful,
  useCountDown,
  useLotType,
  usePlaceBidMutation,
} from "@hooks";
import {
  IBrightcovePlayer,
  IContentfulLotData,
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import * as Sentry from "@sentry/react";
import BidStatus from "@shared/lot/components/bidStatus";
import { bidIncrement, formatCurrency, formatDateToUS } from "@utils";
import { useIsomorphicLayoutEffect } from "ahooks";
import classNames from "classnames";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import Link from "next/link";
import React, { MutableRefObject, useRef, useState } from "react";
import styled from "styled-components";
import { LotTimerAndStatus } from "@shared/lotTimerAndStatus";
import SVG from "react-inlinesvg";
import { VideoIdPreview } from "@shared/lot/components/lotPreviewContent";
import { EContentfulQueries } from "@state";

const A = styled.a`
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: block;
`;

export const LotTitle = styled.div`
  font-family: "MercuryDisplay", serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 26px;
  margin: 10px 0;
`;

export const LotNumber = styled.div`
  font-family: "MercuryDisplay", serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 26px;
`;

const ItemSpecifics = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  font-family: "BentonSans", serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: ${EViewSize.mobile}) {
    white-space: normal;
  }
`;

const BidTopText = styled.div<{ uppercase?: boolean; color?: string }>`
  text-transform: ${(props) => props.uppercase && "uppercase"};
  color: ${(props) => props.color && props.color};
`;

export const BidCountdown = styled(
  ({
    startDate,
    endDate,
    hasBid,
    ...props
  }: {
    startDate?: string;
    endDate?: string;
    hasBid: boolean;
  }) => {
    //TODO remove this component and use LotTimerAndStatus
    const [timeLeft, , formattedRes] = useCountDown({
      targetDate: endDate,
      dateToMs: (date) => moment(date).valueOf(),
    });
    const { days, hours, minutes, seconds } = formattedRes;
    const hot = days == 0 && hours == 0 && minutes <= 5;
    if (hot) (props as any).className += " hot";

    const startTime = startDate && new Date(startDate);
    const endTime = endDate && new Date(endDate);
    const now = new Date();
    const formattedStartDate =
      startDate &&
      momentTimeZone(startDate)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MMMM Do, h:mmA z");
    const formattedEndDate =
      endDate &&
      momentTimeZone(endDate)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MMMM Do, h:mmA z");

    // now + 2h
    const nowPlusTwoH = moment(now).add(2, "h").toDate();

    // if lotStatus is active and startTime is in the future
    if (startTime && startTime > now) {
      return (
        <div {...props}>
          <BidTopText>Opens {formattedStartDate}</BidTopText>
        </div>
      );
      // if endTime has past and there were no bids
    } else if (endTime && endTime < now && !hasBid) {
      return (
        <div {...props}>
          <BidTopText uppercase={true}>Lot Closed</BidTopText>
        </div>
      );
      // if endTime is greater than now+2h
    } else if (nowPlusTwoH && endTime && endTime > nowPlusTwoH) {
      return (
        <div {...props}>
          <BidTopText>Closes on {formattedEndDate}</BidTopText>
        </div>
      );
    } else
      return endDate && timeLeft > 0 ? (
        <div {...props}>
          {days ? (
            <>
              {days} days {hours} hours left
            </>
          ) : hours ? (
            <>
              {hours} hours {minutes} min left
            </>
          ) : (
            <>
              {minutes} minutes {seconds} sec left
            </>
          )}
        </div>
      ) : null;
  }
)`
  font-family: "BentonSans";
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  color: var(--text-light);
  z-index: 8;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 3px;
  letter-spacing: 0;
  text-align: left !important;
  top: -30px;
  left: 0;
  &.hot {
    color: #fe15bc;
  }
`;

enum OutbidStatusState {
  idle = "idle",
  active = "active",
  complete = "complete",
}

const Error = styled.div`
  color: #fe15bc;
  font-size: 12px;
  margin-top: 2px;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-bottom: -10px;
  }
`;

const SothebysRibbon = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 30px;
`;

interface IBidStatusQuickBid {
  mojitoItem: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
  setError: any;
  // onChange: (state: OutbidStatusState) => void;
}

const BidStatusQuickBid = styled(
  ({ mojitoItem, setError, ...props }: IBidStatusQuickBid) => {
    const [placeBid] = usePlaceBidMutation(mojitoItem.contentfulData);
    const [outbidState, setOutbidState] = useState<OutbidStatusState>(
      OutbidStatusState.idle
    );
    const { currentBids } = useCollectionItemCurrentBids(mojitoItem.id);
    // const { isMobile } = useDeviceResponsive();
    // const { refetch: collectionRefetch } = useCollection();

    useIsomorphicLayoutEffect(() => {
      if (outbidState == OutbidStatusState.complete) {
        setTimeout(() => setOutbidState(OutbidStatusState.idle), 5000);
      }
      // if (isMobile) onChange(outbidState);
    }, [outbidState]);

    const onSubmit = () => {
      placeBid({
        amount:
          currentBids?.details.currentBid?.isOutbid &&
          currentBids?.details.currentBid?.nextBidIncrement,
        marketplaceAuctionLotId: currentBids?.details.id,
      })
        .then(() => {
          // collectionRefetch();
          setOutbidState(OutbidStatusState.complete);
        })
        .catch((e: Error) => {
          if (
            currentBids?.details.currentBid?.isLost &&
            e.message.includes("auction has not yet started")
          ) {
            setError("Bidding Has Closed");
          } else {
            setError(e?.message);
          }

          Sentry.captureMessage(`Bidding button error: ${e?.message || ""}`);

          setTimeout(() => setError(null), 8000);
        });
    };

    return currentBids?.details.currentBid?.isHold ||
      currentBids?.details.currentBid?.isOutbid ? (
      <div
        {...props}
        className={classNames((props as any).className, outbidState)}>
        {outbidState == OutbidStatusState.idle && (
          <BaseButton
            secondary
            onClick={() => setOutbidState(OutbidStatusState.active)}>
            Quick bid
          </BaseButton>
        )}
        {outbidState == OutbidStatusState.active && (
          <div style={{ display: "flex" }}>
            <BaseButton onClick={onSubmit}>
              {`Bid ${formatCurrency(
                currentBids?.details.currentBid?.nextBidIncrement
              )}`}
            </BaseButton>
            <BaseButton
              secondary
              onClick={() => setOutbidState(OutbidStatusState.idle)}>
              Cancel
            </BaseButton>
          </div>
        )}
        {outbidState == OutbidStatusState.complete && (
          <BaseButton>Bid placed!</BaseButton>
        )}
      </div>
    ) : null;
  }
)`
  @media screen and (max-width: ${EViewSize.tablet}) {
    &.active,
    &.complete {
      width: 100%;

      ${BaseButton}:first-child {
        margin-left: 0;
        flex-grow: 1;
        width: auto !important;
      }
    }

    &.complete {
      ${BaseButton}:first-child {
        width: 100% !important;
      }
    }
  }
`;

const LotDesc = styled.div<{ $reversed: boolean }>`
  display: flex;
  flex-direction: ${({ $reversed }) =>
    $reversed ? "column-reverse" : "column"};
  margin-top: 20px;
  gap: 4px;
  height: 210px;
`;

const LotFooter = styled.div`
  margin-top: 10px;
`;

export const AuctionLotLinkQuickActionButtons = styled(
  ({
    mojitoItem,
    single,
    ...props
  }: {
    mojitoItem: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
    single?: boolean;
  }) => {
    const [error, setError] = useState<any>(null);
    const { slug } = useCollection();
    const { currentBids } = useCollectionItemCurrentBids(mojitoItem.id);
    return currentBids ? (
      <div {...props}>
        {currentBids?.details.currentBid?.isHold &&
          currentBids?.details.currentBid?.amount <
            bidIncrement[bidIncrement.length - 1] && (
            <Link
              href={
                single
                  ? `/${slug}/bidding`
                  : `/${slug}/lots/${mojitoItem.slug}#bid`
              }
              passHref>
              <BaseButton secondary>Increase bid</BaseButton>
            </Link>
          )}
        {currentBids?.details.currentBid?.isOutbid &&
          currentBids?.details.currentBid?.amount <
            bidIncrement[bidIncrement.length - 1] && (
            <BidStatusQuickBid mojitoItem={mojitoItem} setError={setError} />
          )}

        {error && <Error>{error}</Error>}
      </div>
    ) : null;
  }
)`
  margin-top: 10px;

  .common-button {
    width: 134px !important;
    box-shadow: none;
    border: 1px solid;
    letter-spacing: 0.07rem;
    border-radius: 5px;
    color: var(--text-color) !important;

    & + .common-button {
      margin-left: 10px;
    }
  }
`;

interface ILotDesc {
  number?: string;
  title?: string;
  specific?: string;
  reverse?: boolean;
}

export const AuctionLotLink = styled(
  ({
    lot,
    collectionSlug,
    mojitoItem,
    single,
    ...props
  }: {
    lot: IContentfulLotData;
    mojitoItem: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
    collectionSlug: string;
    single: boolean;
  }) => {
    const { data } = useContentful<any>(EContentfulQueries.fullLot, {
      mojitoId: lot.mojitoId,
    });
    const lotFullData = data
      ? (Object.values(data)[0] as IContentfulLotData)
      : lot;

    const {
      title,
      subtitle,
      author,
      lotId,
      imagesCollection,
      createdAt,
      gridPreviewImage,
    } = lot;
    const [lotDesc, setLotDesc] = useState<ILotDesc>({});
    const { isFakeAuction } = useCollection();

    useIsomorphicLayoutEffect(() => {
      const _createdAt = createdAt
        ? `, ${formatDateToUS(createdAt, "YYYY")}`
        : "";
      switch (collectionSlug) {
        case "gifted-twitter-140":
          {
            setLotDesc({
              title: `Lot ${lotId}: ${title}`,
              specific: author.name + _createdAt,
              reverse: true,
            });
          }
          break;
        case "lfc":
          {
            setLotDesc({
              number: `${lotId}`,
              title: `${title}`,
              specific: subtitle,
            });
          }
          break;
        default: {
          setLotDesc({
            title: `Lot ${lotId}: ${author.name}`,
            specific: title + _createdAt,
          });
        }
      }
    }, [collectionSlug]);

    const imgRef = useRef<HTMLElement>(
      null
    ) as MutableRefObject<HTMLDivElement>;
    const { areImagesValid } = useLotType(lot);

    const firstImage = imagesCollection?.items[0];
    const hasPreviewImage = areImagesValid && firstImage;
    const player = useRef<IBrightcovePlayer>();

    return (
      <div {...props}>
        <Link
          href={
            single && !isFakeAuction
              ? `/${collectionSlug}`
              : `/${collectionSlug}/lots/${mojitoItem.slug}`
          }
          passHref>
          <A>
            <AuctionGridItemImageWrapper
              ref={imgRef}
              className={"image"}
              $lotPreviewBackgroundColor={lot.lotPreviewBackgroundColor}
              $text={!hasPreviewImage ? "No Image :-(" : null}>
              {hasPreviewImage ? (
                <>
                  <SothebysRibbon>
                    <SVG
                      className="sothebys-ribbon"
                      src="/icons/sothebys-ribbon.svg"
                    />
                  </SothebysRibbon>
                  <Image
                    src={gridPreviewImage?.url ?? firstImage?.url}
                    alt={firstImage?.title}
                    width={350}
                    height={350}
                    objectFit={"cover"}
                    initialQuality={80}
                    skeletonLoader
                  />
                  {lotFullData?.nftVideoIds && (
                    <VideoIdPreview
                      activeItem={lotFullData?.nftVideoIds[0]}
                      player={(c: IBrightcovePlayer) => (player.current = c)}
                    />
                  )}
                </>
              ) : null}
            </AuctionGridItemImageWrapper>
            <LotDesc className={"content"} $reversed={!!lotDesc.reverse}>
              {lotDesc.number && (
                <LotNumber className={"number"}>
                  Lot #{lotDesc.number}
                </LotNumber>
              )}
              <LotTitle className={"name"}>{lotDesc.title}</LotTitle>
              <ItemSpecifics className={"desc"}>
                {lotDesc.specific}
              </ItemSpecifics>
            </LotDesc>
          </A>
        </Link>
        <LotFooter className={"footer"}>
          {/*<div className={"author"}>
                <Image
                  objectPosition="center top"
                  width={30}
                  height={30}
                  alt={lot.author.name}
                  src={lot.author.avatar.url}
                />
                <div>
                  <label>OWNED BY:</label>
                  <span>{lot.author.name}</span>
                </div>
              </div>*/}
          <LotTimerAndStatus
            highlightHot
            refetchOnEnd
            mojitoId={mojitoItem.id}
          />
          <FavoriteButton
            collectionItemId={lot.lotId?.toString()}
            icon={true}
          />
        </LotFooter>
        {mojitoItem && (
          <>
            <BidStatus mojitoItem={mojitoItem} />
            <AuctionLotLinkQuickActionButtons
              mojitoItem={mojitoItem}
              single={single}
            />
          </>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${LotTimerAndStatus} {
    font-family: "BentonSans";
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    color: var(--text-light);
    z-index: 8;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    border-radius: 3px;
    letter-spacing: 0;
    text-align: left !important;
    top: -30px;
    left: 0;
  }
`;
