import { EViewSize, SaleType } from "@enums";
import {
  useCollection,
  useColorScheme,
  useDeviceResponsive,
  useMojitoCollectionSubscription,
} from "@hooks";
import {
  IContentfulLotData,
  IMojitoCollection,
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
  IMojitoCollectionItemBuyNowLot,
} from "@interfaces";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { AuctionLotLink } from "./components/auctionLotLink";
import { BuyNowLotLink } from "./components/buyNowLotLink";
import React from "react";
import { ItemGrid } from "../grids";

export const GridLot = styled((props: { $isDark?: boolean } & any) => {
  const { isMobile } = useDeviceResponsive();

  return isMobile ? (
    <div {...props}>{props.children}</div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      {...props}>
      {props.children}
    </motion.div>
  );
})`
  width: 100%;
  align-self: start;
  justify-self: center;
  position: relative;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 100vw;
    height: 2px;
    background-color: ${({ $isDark }) => ($isDark ? "#422945" : "#EDD4F0")};
  }
  img {
    transition: transform 0.3s ease;
  }

  @media (min-width: 880px) {
    img {
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(1.2);
      }
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding: 10px 0;
    &:after {
      bottom: -5px;
    }
  }
`;

interface IGridRenderer {
  collection: IMojitoCollection;
  lots: IContentfulLotData[];
  type?: SaleType | null;
}

interface IGridRendererMemo extends IGridRenderer {
  mojitoItemsList: {
    [key: string]:
      | IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>
      | IMojitoCollectionItem<IMojitoCollectionItemBuyNowLot>;
  };
  isDark: boolean;
}

const GridRendererMemo = ({
  lots,
  mojitoItemsList,
  collection,
  isDark,
}: IGridRendererMemo) => {
  return (
    <AnimatePresence>
      {lots.map((lot, index) => {
        let auctionLotLink;
        switch (mojitoItemsList[lot.mojitoId]?.saleType) {
          case SaleType.Auction:
            {
              auctionLotLink = (
                <AuctionLotLink
                  lot={lot}
                  collectionSlug={collection.slug}
                  single={!collection.hasMultipleLots}
                  mojitoItem={
                    mojitoItemsList[
                      lot.mojitoId
                    ] as IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>
                  }
                />
              );
            }
            break;
          case SaleType.BuyNow: {
            auctionLotLink = (
              <BuyNowLotLink
                lot={lot}
                collectionSlug={collection.slug}
                single={!collection.hasMultipleLots}
                mojitoItem={
                  mojitoItemsList[
                    lot.mojitoId
                  ] as IMojitoCollectionItem<IMojitoCollectionItemBuyNowLot>
                }
              />
            );
          }
        }

        return mojitoItemsList[lot.mojitoId] && auctionLotLink ? (
          <GridLot
            key={
              lot.mojitoId +
              JSON.stringify(mojitoItemsList[lot.mojitoId]?.details?.saleView) +
              index
            }
            $isDark={isDark}>
            {auctionLotLink}
          </GridLot>
        ) : null;
      })}
    </AnimatePresence>
  );
};

export const GridRenderer = styled(
  ({
    collection: { slug, id },
    lots,
    type = SaleType.Auction,
    ...props
  }: IGridRenderer) => {
    const { isDark } = useColorScheme();
    const { collection, isLoading } = useCollection({ slug });
    useMojitoCollectionSubscription(slug, id);
    let mojitoItemsList: any = {};

    if (collection?.items) {
      const _contentfulLotsId = lots.map((lot) => lot.mojitoId);
      mojitoItemsList = collection.items.reduce(
        (_lots: any, item: IMojitoCollectionItem<any>) =>
          Object.assign(
            _lots,
            _contentfulLotsId.includes(item?.id) &&
              (type ? item.saleType == type : true)
              ? {
                  [item.id]: item,
                }
              : {}
          ),
        {}
      );
    }

    lots = lots.sort((a, b) => (a.lotId < b.lotId ? -1 : 1));

    return !isLoading && collection ? (
      <ItemGrid {...props}>
        <GridRendererMemo
          lots={lots}
          collection={collection}
          mojitoItemsList={mojitoItemsList}
          isDark={isDark}
        />
      </ItemGrid>
    ) : null;
  }
)``;
