import { useDeviceResponsive } from "@hooks";
import { IMojitoCollectionItemAuctionLot } from "@interfaces";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import styled from "styled-components";

const HACK_IB_LOT_NUMBERS = [10, 12, 18];

export const LotNotices = styled(
  ({
    mojitoItemDetails,
  }: {
    mojitoItemDetails: IMojitoCollectionItemAuctionLot;
  }) => {
    const { dispatch } = useOverlayContext();

    const openLotNoticeModal = (elem: React.ReactElement) => {
      dispatch({
        type: EOverlayContextActionTypes.show,
        child: elem,
      });
    };

    if (!mojitoItemDetails) {
      return null;
    }
    // this is an array because we'll need to add "Reserves" and a couple other things in the future
    let chips: JSX.Element[] = [];
    if (HACK_IB_LOT_NUMBERS.indexOf(mojitoItemDetails.lotNumber) > -1) {
      const irrevocableBid = (
        <InfoChip
          onClick={() => openLotNoticeModal(<IrrevocableBidCopyModal />)}>
          <strong>⋑</strong> <span>Irrevocable Bid</span>
        </InfoChip>
      );
      chips = chips.concat(irrevocableBid);
    }
    return <div>{chips}</div>;
  }
)``;

const InfoChip = styled.span`
  display: inline-block;
  background-color: rgba(85, 224, 255, 0.1);
  color: #0c687c;
  padding: 0 4px;
  margin: 6px 6px 6px 0;
  border-radius: 5px;
  cursor: pointer;
  > strong {
    font-size: 16px;
  }
  > span {
    font-size: 12px;
    text-decoration: underline;
  }
`;

const ModalInner = styled.div<{ $isMobile?: boolean }>`
  background-color: white;
  padding: 10px;
  max-width: 500px;
  font-size: 12px;
  ${({ $isMobile }) => ($isMobile ? `margin-top: 100px;` : null)}
`;

const IrrevocableBidCopyModal = () => {
  const { isMobile } = useDeviceResponsive();

  return (
    <ModalInner $isMobile={isMobile}>
      <span>
        Lots with this symbol indicate that a party has provided Sotheby’s with
        an irrevocable bid on the lot that will be executed during the sale at a
        value that ensures that the lot will sell. The irrevocable bidder, who
        may bid in excess of the irrevocable bid, may be compensated for
        providing the irrevocable bid by receiving a contingent fee, a fixed fee
        or both. From time to time, a Sotheby’s shareholder may be an
        irrevocable bidder. If the irrevocable bidder is the successful bidder,
        any contingent fee, fixed fee or both (as applicable) for providing the
        irrevocable bid may be netted against the irrevocable bidder’s
        obligation to pay the full purchase price for the lot and the purchase
        price reported for the lot shall be net of any such fees. From time to
        time, Sotheby’s may enter into irrevocable bid agreements that cover
        multiple lots. In such instances, the compensation Sotheby&apos;s will
        pay the irrevocable bidder is allocated to the lots for which the
        irrevocable bidder is not the successful purchaser. Under such
        circumstances, the total compensation to the irrevocable bidder will not
        exceed the total buyer&apos;s premium and other amounts paid to
        Sotheby’s in respect of any lots for which the irrevocable bidder is not
        the successful bidder. If this auction has a printed catalogue, a PDF
        catalogue or both and the irrevocable bid is not secured until after
        such catalogue is finalized or if there is no printed catalogue or PDF
        catalogue and the irrevocable bid is not secured until after the digital
        catalogue is initially available for viewing, then Sotheby&apos;s will
        notify bidders that there is an irrevocable bid on the lot by one or
        more of the following means: the lot&apos;s specific webpage will be
        updated to include the irrevocable bid symbol or a notice will be added
        to the Sotheby’s webpage for the auction. From time to time, Sotheby’s
        or any affiliated company may provide the irrevocable bidder with
        financing related to the irrevocable bid. In addition, from time to
        time, an irrevocable bidder may have knowledge of the amount of a
        guarantee. If the irrevocable bidder is advising anyone with respect to
        the lot, Sotheby’s requires the irrevocable bidder to disclose his or
        her financial interest in the lot. If an agent is advising you or
        bidding on your behalf with respect to a lot identified as being subject
        to an irrevocable bid, you should request that the agent disclose
        whether or not he or she has a financial interest in the lot.
      </span>
    </ModalInner>
  );
};
