export const LOCAL_STORAGE_STATE: any = {
  secondary_wallet: {
    nft_list: "nftList",
    nft_list_options: "nft_list_options",
    nft_list_search: "nft_list_search",
    connect_wallet: "connect_wallet",
    is_connect_wallet: "is_connect_wallet",
    connect_wallet_account: "connect_wallet_account",
    connect_external_wallet: "connect_external_wallet",
    connect_wallet_message: "connect_wallet_message",
    connect_wallet_signature: "signature",
    store_network_details: "store_network_details",
    wait_Msg: "waitMsg",
    providerType: "providerType",
    address_details: "userDetails"
  },
  home_page: {
    view_all: "view_all"
  }
}

export function clearStorage(stateList: { [k: string]: any }): void {
  Object.values(stateList).map((item: any) => {
    window.localStorage.removeItem(item);
    return null;
  });
}
