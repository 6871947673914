import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@constants";
import { usePageType, useCollection } from "@hooks";
import { useRouter } from "next/router";
import { useIsomorphicLayoutEffect } from "ahooks";
import { useEffect } from "react";
import qs from "query-string";
import isBrowser from "@utils/isBrowser";
import { IContentfulAuction } from "@interfaces";
import { useAuction } from "src/pages/gen-art-vera-molnar/hooks/useAuction";
import { useAccount } from "wagmi";

function trackPageView(
  path: string[],
  collection: any,
  partyId?: string,
  walletAddress?: string
) {
  console.log("trackPageView function");

  const qsParsed: any = qs.parse(location.search);
  if (qsParsed.redirect_url) return;

  const name = path[3] ?? path[2] ?? path[1] ?? "";
  const { contentfulData } = collection || {};
  const allowedPaths = [
    "lfc",
    "salgado",
    "aegirls",
    "snow-crash",
    "gen-art-vera-molnar",
  ];
  const isAllowedPath = allowedPaths.includes(path[1]);

  const properties: any = {
    category: "Page",
    page_name: __dirname,
    url: location.href,
    department_category: "NFT",
    ...(isAllowedPath
      ? {
          sale_id: (contentfulData?.saleId as IContentfulAuction) ?? "",
          sale_name: (contentfulData?.title as IContentfulAuction) ?? "",
          customer_id: partyId ?? "",
          name,
        }
      : {}),
  };

  const options: any = {
    context: {
      wallet_address: walletAddress ?? "",
    },
  };

  if (qsParsed.cmp) properties.cmp_trackingcode = qsParsed.cmp;
  if (isBrowser && window.analytics)
    window.analytics.page(name, properties, options);
}

// Track client-side page views with Segment

export const PageviewTracker = (): any => {
  const { collection } = useCollection();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { _path } = usePageType();
  const router = useRouter();
  const firstRoute = router.asPath.split("#")[0].split("?")[0].split("/")[1];
  const auction = useAuction(firstRoute);
  const { address } = useAccount();

  // track pageviews on route changes
  useEffect(() => {
    if (!router.isReady) return;

    if (firstRoute !== "gen-art-vera-molnar") {
      trackPageView(_path, collection, undefined, address);
    } else if (auction) {
      trackPageView(_path, auction, undefined, address);
    }
  }, [router]);

  useIsomorphicLayoutEffect(() => {
    if (isAuthenticated) {
      trackIdentify();
    }
  }, [isAuthenticated]);

  async function trackIdentify() {
    const claims = await getIdTokenClaims();
    if (claims) {
      const partyId = claims[config.APP_METADATA_URL]?.customerData?.partyId;
      window.analytics.identify(claims.sub, { partyId });
    }
  }

  return null;
};
