export const getChainExplorer = (
  chainId?: number
): { name: string; url: string } => {
  switch (chainId) {
    case 1:
      return { name: "Etherscan", url: "https://etherscan.io" };
    case 4:
      return { name: "Etherscan Rinkeby", url: "https://rinkeby.etherscan.io" };
    case 137:
      return { name: "Polygonscan", url: "https://polygonscan.com" };
    case 80001:
      return {
        name: "Polygonscan Mumbai",
        url: "https://mumbai.polygonscan.com",
      };
    case 5: return { name: "Etherscan Goerli", url: "https://goerli.etherscan.io" };
    case 11155111: return {name: "Sepolia", url:"https://sepolia.etherscan.io/"}
    default:
      return { name: "Etherscan", url: "https://etherscan.io" };
  }
};
