import { useDeviceResponsive } from "@hooks";
import { formatContractAddress } from "@utils";
import SVG from "react-inlinesvg";
import styled from "styled-components";

interface ISmartContractAddress {
  address: string;
  token?: string;
  color?: string;
  walletText?: string;
}

export const AddressStyledLink = styled.div<{ $color?: string }>`
  margin-left: 5px;
  display: inline-block;
  background-color: rgba(191, 135, 232, 0.2);
  border-radius: 7px;
  padding: 7px;
  color: #6e3e90;
  text-decoration: underline;
  cursor: pointer;
  transition: background-color 0.25s;
  &:hover {
    background-color: rgba(191, 135, 232, 0.25);
  }
  &:active {
    background-color: rgba(191, 135, 232, 0.3);
  }
  svg {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    path {
      fill: #6e3e90;
    }
  }
`;

export const SmartContractAddress = styled(
  ({ address, token, color, walletText, ...props }: ISmartContractAddress) => {
    const { isMobile } = useDeviceResponsive();

    if (!address) return null;
    const formattedAddress = formatContractAddress(
      address || "smartContractAddress"
    );

    return (
      <div {...props}>
        <span>
          {!isMobile && (walletText ?? "Smart Contract Address")}
          <a
            href={`https://etherscan.io/address/${address}`}
            rel="noreferrer"
            target="_blank">
            <AddressStyledLink $color={color}>
              {formattedAddress}
              <SVG src="/icons/copy.svg" />
            </AddressStyledLink>
          </a>
        </span>
        {!isMobile && token && (
          <span style={{ marginTop: "10px" }}>
            Token ID #{token || "Tokenid"}
          </span>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-align: right;
`;
