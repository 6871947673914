import styled from "styled-components";
import DOMPurify from "dompurify";
import { marked } from "marked";

interface IMarkdown {
  text: string;
  className?: string;
}

export const Markdown = styled(({ text, ...props }: IMarkdown) => {
  if (!text) return null;
  try {
    const __html = DOMPurify.sanitize(marked.parse(text));

    return <div dangerouslySetInnerHTML={{ __html }} {...props}></div>;
  } catch (e) {
    console.error(e);
    return null;
  }
})`
  display: inline-block;
  white-space: pre-line;
  font-size: 14px;
  line-height: 20px;

  img,
  canvas,
  iframe,
  video,
  svg,
  select,
  textarea {
    max-width: 100%;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  ol {
    padding-left: 15px;
  }

  blockquote {
    border-left: 8px solid #afafaf;
    padding: 1rem;
  }

  pre,
  code {
    background-color: #afafaf;
  }
`;
