
import { gql } from "graphql-request";

export enum EMojitoSecondaryWalletQueries {
    getSignature,
    getActiveWalletsContent,
    getSupportedNetworks,
    getUSDPrice,
    getNFTDetails,
    getNFTDetailsByTokenId,
    getNFTHistory,
    getAllRegistryTokens,
    getAllCountTokens,
    getOffers,
    getSignatureForOfferApproval,
    getAllSavedItems,
    getNFTAttributesRarity,
    getAllRegistryTokensByUser,
    getOrderActivity,
    getSupportedCurrencies,
    getInvoiceDownload,
    globalSearch,
    getTaxQuote,
    getHighlightsTokens,
    generatePrimaryInvoice,
    connectExternalWallet

}
export const secondaryWalletQuery = {
    [EMojitoSecondaryWalletQueries.globalSearch]: gql`
    query(
        $orgID: UUID1!
        $marketplaceID: UUID1!
        $searchKey: String
        $filterBy: GlobalFilterInput
        $offset: Int
        $limit: Int
        ){
            globalSearch(
                orgID: $orgID
                marketplaceID: $marketplaceID
                searchKey: $searchKey
                filterBy: $filterBy
                offset: $offset
                limit: $limit
                ){
                    artists{
                        id
                        artistName
                        description
                        artistCollection
                        slug
                    }
                    items{
                        id
                        nftTokenName
                        collectionAddress
                        collectionName
                        onChainTokenId
                    }
                    collections{
                        collectionName
                        collectionAddress
                        collectionTotal
                        artistId
                        collectionId
                        artistSlug
                        categorySlug
                    }
                }
        }
    `,
    [EMojitoSecondaryWalletQueries.getInvoiceDownload]: gql`
    query(
        $invoiceID: UUID1!
        $orderID: UUID1!
        $activityName: ActivityName!
        $invoiceResType: InvoiceResType
        ){
            generateSecondaryInvoice(
                invoiceID: $invoiceID
                orderID: $orderID
                activityName: $activityName
                invoiceResType: $invoiceResType
                )
        }
    `,
    [EMojitoSecondaryWalletQueries.getOrderActivity]: gql`
    query ($orgId: UUID1!, $filter: OrderActivityFilters, $limit: Int
        $offset: Int){
        getUserOrderActivity(
            orgId: $orgId
            filter: $filter
            limit: $limit
            offset: $offset
            ){
                totalCount
                data{
                CreatedAt
                nftImageUrl
                ActivityName
                TokenName
                TokenID
                NFTTokenID
                ContractName
                ContractAddress
                Price{
                    value
                    unit
                    }
                NetworkID
                TransactionHash
                FromUserName
                ToUserName
                FromUserAvatar
                ToUserAvatar
                FromWallet{
                    id
                    name
                    address
                }
                ToWallet{
                    id
                    name
                    address
                }
                InvoiceURL
                InvoiceID
                OrderID
                Status
                cryptoTax {
                    cryptoTaxPrice
                    cryptoTotalPrice
                    USDUnitprice
                    taxPercentage
                }
                order {
                    platformFee
                    creatorFee
                }
                }
                
            }
    }
    `,
    [EMojitoSecondaryWalletQueries.getSignatureForOfferApproval]: gql`
    query ($orderId: UUID1!, $orgId: UUID1!, $creatorFee: Float){
        getSignatureForOfferApproval(
            orderId: $orderId
            orgId: $orgId
            creatorFee: $creatorFee
            ){
                proofOfApproval
                order{
                    id
                    nftTokenId
                    tokenId
                    tokenType
                    quantity
                    nftOwnerAddress
                    fixedPrice
                    price{
                        value
                        unit
                        type
                    }
                    buyerAddress
                    buyerTax
                    paymentToken
                    orderType
                    orderStatus
                    offerExpiryDate
                    createdByUserId
                    createdBy{
                        id    
                        username
                        name
                        email
                    }
                    createdAt
                    updatedAt
                    }
                        Network{
                        id
                        name
                        chainID
                        rpcURL
                        nftAPIURL
                        openSeaProxyAddress
                        wethAddress
                        safeMasterContractAddress
                        safeFactoryAddress
                        safeFallbackHandler
                        defaultCurrency
                        isTestnet
                        paymentCurrency
                        }
                        }
            
    }
    `,
    [EMojitoSecondaryWalletQueries.getSignature]: gql`
        query ($orgID: UUID1!, $walletAddress: String!, $networkID: UUID1!  ) {
            getSignatureMessage(orgID: $orgID, walletAddress: $walletAddress, networkID: $networkID)
        }   
    `,
    [EMojitoSecondaryWalletQueries.getActiveWalletsContent]: gql` 
        query ($walletAddress: String!, $networkId: UUID1!, $orgID: UUID1!, $nextPageKey: String, $filters: ActiveWalletFilterInput!, $refreshCache: Boolean) {
            getActiveWalletsContent(
                walletAddress: $walletAddress,
                networkId: $networkId,
                nextPageKey: $nextPageKey,
                filters: $filters,
                orgID: $orgID,
                refreshCache: $refreshCache
                ){
                tokens {
                    contractAddress
                    id
                    network
                    networkID
                    tokenType
                    balance
                    title
                    description
                    tokenURI
                    timeLastUpdated
                    nftTokenId
                    mintedAt
                    status
                    listedAt
                    nftTokenId
                    editionNumber
                    mediaSourceURL
                    mediaSourceExtension
                    metadata {
                        name
                        description
                        image
                        animationURL
                        openSeaImageURL
                    }
                    contractName
                    artistName
                    price {
                        buyNowPrice {
                            value
                            unit
                            type
                        }
                        lastPurchasedPrice {
                            value
                            unit
                            type
                        }
                        makeOfferHighestPrice {
                            value
                            unit
                            type
                        }
                        makeOfferLatestPrice {
                            value
                            unit
                            type
                        }
                    }
                    listedOrderInfo {
                        id
                        listedCurrency {
                            contractAddress
                        }
                        price {
                            value
                            unit
                            type
                        }
                    }
                }
                requestObj {
                    networkID
                    prevPageKey
                    nextPageKey
                    count
                    totalCount
                  }
            }
        }`,
    [EMojitoSecondaryWalletQueries.getSupportedNetworks]: gql`
        query ($orgId: UUID1!, $includeTestnets: Boolean!) {
            getSupportedNetworks(
                orgId: $orgId,
                includeTestnets: $includeTestnets
                ){
                    id
                    name
                    chainID
                    isTestnet
                }
        }`,
    [EMojitoSecondaryWalletQueries.getUSDPrice]: gql`
        query ($CryptoCurrenyCode: CryptoCurrenyCode!){
            getUSDPrice(
                cryptoCurrencyCode: $CryptoCurrenyCode
                ){
                    amount
                    currency
                    base
                }
        }
    `,
    [EMojitoSecondaryWalletQueries.getNFTDetails]: gql`
        query ($networkId: UUID1, $contractAddress: String, $onChainTokenID: BigInt, $orgId: UUID1!, $nftTokenId: UUID1, $editionNumber: Int, $ownerAddress: String, $buyerAddress: String) {
            getNFTDetails(
                networkId: $networkId,
                contractAddress: $contractAddress, 
                onChainTokenID: $onChainTokenID,
                nftTokenId: $nftTokenId,
                orgId: $orgId,
                editionNumber: $editionNumber,
                ownerAddress: $ownerAddress,
                buyerAddress: $buyerAddress
                ) {
                    contractAddress
                    tokenId
                    network
                    networkID
                    owner
                    contractName
                    tokenType
                    mintedAt
                    status
                    balance
                    editions
                    mediaSourceExtension
                    mediaSourceType
                    mediaSourceURL
                    price {
                        buyNowPrice {
                            value
                            unit
                            type
                        }
                        lastPurchasedPrice {
                            value
                            unit
                            type
                        }
                        makeOfferHighestPrice {
                            value
                            unit
                            type
                        }
                        makeOfferLatestPrice {
                            value
                            unit
                            type
                        }
                    }
                    latestOffer {
                        price {
                            value
                            unit
                            type
                        }
                    }
                    tokenURI
                    editionNumber
                    isFavorite
                    nftTokenId
                    tokenOwnerAvatar
                    tokenOwnerAddress
                    tokenOwnerUsername
                    isBuyNowEnabled
                    isMakeOfferEnabled
                    isOfferExist
                    listedOrderInfo {
                        id
                        listedCurrency {
                            contractAddress
                        }
                        price {
                            value
                            unit
                            type
                        }
                    }
                    artist {
                        id
                        description
                        artistName
                        artistLocation
                        artistContactEmail
                        artistContactNumber
                        artistWebsite
                        slug
                    }
                    metadata {
                        name
                        description
                        image
                        animationURL
                        openSeaImageURL
                        tags
                        yearCreated
                        createdBy
                        attributes {
                            traitType
                            displayType
                            maxValue
                            value {
                                __typename
                                ... on AttributeValueString {
                                    stringValue
                                }
                                ... on AttributeValueInt {
                                    intValue
                                }
                                ... on AttributeValueFloat {
                                    floatValue
                                }
                            }
                        }
                    }
            }
        }
    `,
    [EMojitoSecondaryWalletQueries.getNFTDetailsByTokenId]: gql`
        query ($nftTokenId: UUID1!, $orgId: UUID1!) {
            getNFTDetailsByTokenId(
                nftTokenId: $nftTokenId,
                orgId: $orgId,
                ) {
                    contractAddress
                    tokenId
                    network
                    networkID
                    owner
                    contractName
                    tokenType
                    mintedAt
                    status
                    balance
                    price {
                        buyNowPrice {
                            value
                            unit
                            type
                        }
                        lastPurchasedPrice {
                            value
                            unit
                            type
                        }
                        makeOfferHighestPrice {
                            value
                            unit
                            type
                        }
                        makeOfferLatestPrice {
                            value
                            unit
                            type
                        }
                    }
                    isFavorite
                    ownerAddress
                    nftTokenId
                    listedOrderInfo {
                        id
                    }
                    artist {
                        id
                        description
                        artistName
                        artistLocation
                        artistContactEmail
                        artistContactNumber
                        artistWebsite
                    }
                    tokenURI
                    tokenOwnerAvatar
                    tokenOwnerAddress
                    tokenOwnerUsername
                    metadata {
                        name
                        description
                        image
                        animationURL
                        attributes {
                            traitType
                            displayType
                            maxValue
                            value {
                                __typename
                                ... on AttributeValueString {
                                    stringValue
                                }
                                ... on AttributeValueInt {
                                    intValue
                                }
                                ... on AttributeValueFloat {
                                    floatValue
                                }
                            }
                        }
                    }
            }
        }
    `,
    [EMojitoSecondaryWalletQueries.getNFTHistory]: gql`
        query ($networkId: String!, $contractAddress: String!, $tokenId: BigInt!, $nftTokenId: UUID1, $filterBy: NFTTxEventType,  $orgId: UUID1!, $ownerAddress: String, $limit: Int, $offset: Int){
            getNFTHistoryV2(
                networkId: $networkId, 
                contractAddress: $contractAddress,
                tokenId: $tokenId,
                nftTokenId: $nftTokenId
                filterBy: $filterBy
                orgId: $orgId
                ownerAddress: $ownerAddress
                offset: $offset
                limit: $limit
            ) {
                totalCount
                data {
                    blockNum
                    hash
                    from
                    to
                    value
                    tokenId
                    category
                    asset
                    price {
                        value
                        unit
                        type
                    }
                    blockTimestamp
                    eventType
                    fromUserAvatar
                    fromUserName
                    toUserAvatar
                    toUserName
                    erc1155Metadata {
                        tokenId
                        value
                    }
                }
            }
        }
    `,

    [EMojitoSecondaryWalletQueries.getAllCountTokens]: gql`
    query(
        $orgID: UUID1!
        $marketplaceID: UUID1!
        $categoryID: UUID1
        $artistID: UUID1
        $registryID: UUID1
        $categorySlug: String
        $artistSlug: String
      ) {
        getFloorAndLatestPrice(
          orgID: $orgID
          marketplaceID: $marketplaceID
          categoryID: $categoryID
          artistID: $artistID
          registryID: $registryID
          categorySlug: $categorySlug
          artistSlug: $artistSlug
        ) {
                    totalCount
                    floorPrice {
                        value
                        unit
                        type
                      }
                      latestSoldPrice {
                        value
                        unit
                        type
                      }
              }
        }
    `,

    [EMojitoSecondaryWalletQueries.getAllRegistryTokens]: gql`
    query(
        $orgID: UUID1!
        $marketplaceID: UUID1!
        $owner: String
        $offset: Int
        $limit: Int
        $searchKey: String
        $filters: RegistryTokenFilterInput
        $categoryID: UUID1
        $artistID: UUID1
        $registryID: UUID1
        $buyerAddress: String
        $artistSlug: String
        $categorySlug: String
      ) {
        getAllRegistryTokens(
          orgID: $orgID
          marketplaceID: $marketplaceID
          owner: $owner
          offset: $offset
          limit: $limit
          searchKey: $searchKey
          filters: $filters
          categoryID: $categoryID
          buyerAddress: $buyerAddress
          artistID: $artistID
          registryID: $registryID
          categorySlug: $categorySlug
          artistSlug: $artistSlug
        ) {
                data {
                    ID
                    TokenName
                    TokenID
                    Status
                    TokenURI
                    NFTTokenID
                    mediaSourceExtension
                    mediaSourceURL
                    Price {
                        buyNowPrice {
                            value
                            unit
                            type
                        }
                        lastPurchasedPrice {
                            value
                            unit
                            type
                        }
                        makeOfferHighestPrice {
                            value
                            unit
                            type
                        }
                        makeOfferLatestPrice {
                            value
                            unit
                            type
                        }
                    }
                    MetaData {
                      name
                      image
                      description
                      animationURL
                      openSeaImageURL
                    }
                    networkID
                    latestOffer {
                        price {
                            value
                            unit
                            type
                        }
                    }
                    listedOrderInfo {
                        id
                        listedCurrency {
                            contractAddress
                        }
                        price {
                            value
                            unit
                            type
                        }
                    }
                    isBuyNowEnabled
                    isMakeOfferEnabled
                    editionNumber
                    TokenType
                    ListingStatus
                    OwnersList
                    Owners
                    RegistryId
                    MintedAt
                    TokenOwnerAddress
                    nftContractName
                    networkName
                    Registry{
                        ID
                        NetworkID
                        Network {
                            name
                        }
                        OrganizationID
                        CollectionName
                        ContractAddress
                        IsAllTokensApproved
                        MarketplaceID
                        TotalApproved
                        CollectionTotal
                        ArtistID
                        Artist {
                            id
                            description
                            artistName
                            artistLocation
                            artistContactEmail
                            artistContactNumber
                            artistWebsite
                        }
                        CategoryID
                        CreatedByUserID
                        CreatedAt
                        UpdatedAt
                    }
                    Artist {
                        id
                        description
                        artistName
                        artistLocation
                        artistContactEmail
                        artistContactNumber
                        artistWebsite
                    }
                    isOfferExist
                    CreatedBy
                    CreatedAt
                    UpdatedAt
                  }
                  totalCount
                  user {
                    id
                    username
                    name
                    email
                    avatar
                  }
                  lowestSoldPrice {
                    value
                    unit
                    type
                  }
                  highestSoldPrice {
                    value
                    unit
                    type
                  }
                  latestSoldPrice {
                    value
                    unit
                    type
                  }
              }
        }
    `,
    [EMojitoSecondaryWalletQueries.getAllRegistryTokensByUser]: gql`
    query ($orgID: UUID1!, $marketplaceID: UUID1!, $status:TokenStatus, $offset: Int, $limit: Int, $searchKey: String, $filters: RegistryTokenFilterInput
        ){
        getAllRegistryTokensByUser(
            orgID: $orgID,
            marketplaceID: $marketplaceID,  
            offset: $offset,
            limit: $limit
            searchKey: $searchKey,
            filters: $filters,
            status: $status
        ) {
            data {
                ID
                TokenName
                TokenID
                Status
                TokenURI
                NFTTokenID
                mediaSourceURL
                mediaSourceExtension
                Price {
                    buyNowPrice {
                        value
                        unit
                        type
                    }
                    lastPurchasedPrice {
                        value
                        unit
                        type
                    }
                    makeOfferHighestPrice {
                        value
                        unit
                        type
                    }
                    makeOfferLatestPrice {
                        value
                        unit
                        type
                    }
                }
                MetaData {
                  name
                  image
                  description
                  animationURL
                  openSeaImageURL
                }
                networkID
                editionNumber
                TokenType
                ListingStatus
                OwnersList
                Owners
                RegistryId
                nftContractName
                networkName
                MintedAt
                Registry{
                    ID
                    NetworkID
                    OrganizationID
                    CollectionName
                    ContractAddress
                    IsAllTokensApproved
                    MarketplaceID
                    TotalApproved
                    CollectionTotal
                    ArtistID
                    Artist {
                        id
                        description
                        artistName
                        artistLocation
                        artistContactEmail
                        artistContactNumber
                        artistWebsite
                    }
                    CategoryID
                    CreatedByUserID
                    CreatedAt
                    UpdatedAt
                }
                Artist {
                    id
                    description
                    artistName
                    artistLocation
                    artistContactEmail
                    artistContactNumber
                    artistWebsite
                }
                CreatedBy
                CreatedAt
                UpdatedAt
                listedOrderInfo {
                    id
                    listedCurrency {
                        contractAddress
                    }
                    price {
                        value
                        unit
                        type
                    }
                }
              }
              totalCount
              user {
                id
                username
                name
                email
                avatar
              }
          }
    }
`,
    [EMojitoSecondaryWalletQueries.getOffers]: gql`
        query ($nftTokenId: UUID1!, $orgId: UUID1!){
            getOffers(
                orgId: $orgId, 
                nftTokenId: $nftTokenId
            ) {
                id
                offerExpiryDate
                nftOwnerAddress
                tokenType
                tokenId
                tokenContract
                orderStatus
                buyerTax
                buyerAddress
                fixedPrice
                updatedAt
                createdAt
                price {
                    value
                    unit
                    type
                }
                createdBy {
                    id
                    username
                }
                createdByUserOrganization {
                    username
                }
            }
        }
    `,
    [EMojitoSecondaryWalletQueries.getAllSavedItems]: gql`
    query ($orgID: UUID1!, $offset: Int, $limit: Int, $searchKey: String, $filters: SavedNFTFilterInput, $buyerAddress: String
        ){
        getNFTFavouriteListByUser(
            orgId: $orgID, 
            offset: $offset,
            limit: $limit
            searchKey: $searchKey,
            filter: $filters,
            buyerAddress: $buyerAddress
        ) {
            data {
                id
                title
                status
                network
                networkID
                tokenURI
                balance
                nftTokenId
                tokenType
                timeLastUpdated
                description
                mintedAt
                listedAt
                contractAddress
                contractName
                artistName
                favouriteCount
                owner
                mediaSourceURL
                mediaSourceExtension
                latestOffer {
                    price {
                        value
                        unit
                        type
                    }
                }
                listedOrderInfo {
                    id
                    listedCurrency {
                        contractAddress
                    }
                    price {
                        value
                        unit
                        type
                    }
                }
                isBuyNowEnabled
                isMakeOfferEnabled
                isOfferExist
                price {
                    buyNowPrice {
                        value
                        unit
                        type
                    }
                    lastPurchasedPrice {
                        value
                        unit
                        type
                    }
                    makeOfferHighestPrice {
                        value
                        unit
                        type
                    }
                    makeOfferLatestPrice {
                        value
                        unit
                        type
                    }
                }
                metadata {
                  name
                  image
                  description
                  animationURL
                  openSeaImageURL
                }
              }
              totalCount
          }
    }
`,

    [EMojitoSecondaryWalletQueries.getNFTAttributesRarity]: gql`
    query ($contractAddress: String!, $tokenID: BigInt!, $networkID: UUID1!, $refreshCatch: Boolean){
        getNFTAttributesRarity(
            contractAddress: $contractAddress, 
            tokenID: $tokenID,
            networkID: $networkID,
            refreshCatch: $refreshCatch
        ) {
            traitType
            value {
                __typename
                ... on AttributeValueString {
                    stringValue
                }
                ... on AttributeValueInt {
                    intValue
                }
                ... on AttributeValueFloat {
                    floatValue
                }
                ... on AttributeValueBool {
                    boolValue
                }
            }
            displayType
            maxValue
            prevalance
        }
    }
`,
    [EMojitoSecondaryWalletQueries.getSupportedCurrencies]: gql`
    query ($nftTokenId: UUID1!, $orgId: UUID1!){
        getSupportedCurrencies(
            orgId: $orgId, 
            nftTokenId: $nftTokenId,
        ) {
            id
            name
            networkId
            symbol
            contractAddress
            secondaryMarketplaceContractAddress
            network {
                id
                name
                chainID
                wethAddress
                paymentCurrency
            }
        }
    }
`,
    [EMojitoSecondaryWalletQueries.getTaxQuote]: gql`
    query (
      $street1: String!
      $city: String!
      $state: String!
      $postalCode: String!
      $country: String!
      $currencyCode: String!
      $orgID: UUID1!
      $taxablePrice: Float!
    ) {
      getTaxQuote(
        input: {
          address: {
            street1: $street1
            city: $city
            state: $state
            postalCode: $postalCode
            country: $country
            currencyCode: $currencyCode
          }
          orgID: $orgID
          taxablePrice: $taxablePrice
        }
      ) {
        verifiedAddress {
          street1
          state
          postalCode
          country
          currencyCode
        }
        taxablePrice
        totalTaxedPrice
        totalTaxAmount
      }
    }
  `,
    [EMojitoSecondaryWalletQueries.getHighlightsTokens]: gql`
  query ($orgID: UUID1!, $marketplaceID: UUID1!, $offset: Int, $limit: Int, $categoryID: UUID1, $buyerAddress: String, $column: RegistryTokenColumnType!, $type: orderType!
      ){
      getAllRegistryTokens(
          orgID: $orgID,
          marketplaceID: $marketplaceID,
          offset: $offset,
          limit: $limit,
          categoryID: $categoryID,
          buyerAddress: $buyerAddress,
          sort: {
            column: $column,
            type: $type
          }
      ) {
          data {
              ID
              TokenName
              TokenID
              Status
              TokenURI
              NFTTokenID
              Price {
                  buyNowPrice {
                      value
                      unit
                      type
                  }
                  lastPurchasedPrice {
                      value
                      unit
                      type
                  }
                  makeOfferHighestPrice {
                      value
                      unit
                      type
                  }
                  makeOfferLatestPrice {
                      value
                      unit
                      type
                  }
              }
              MetaData {
                name
                image
                description
                animationURL
              }
              networkID
              latestOffer {
                  price {
                      value
                      unit
                      type
                  }
              }
              listedOrderInfo {
                  id
                  listedCurrency {
                      contractAddress
                  }
                  price {
                      value
                      unit
                      type
                  }
              }
              isBuyNowEnabled
              isMakeOfferEnabled
              editionNumber
              TokenType
              ListingStatus
              OwnersList
              Owners
              RegistryId
              MintedAt
              TokenOwnerAddress
              nftContractName
              networkName
              Registry{
                  ID
                  NetworkID
                  Network {
                      name
                  }
                  OrganizationID
                  CollectionName
                  ContractAddress
                  IsAllTokensApproved
                  MarketplaceID
                  TotalApproved
                  CollectionTotal
                  ArtistID
                  Artist {
                      id
                      description
                      artistName
                      artistLocation
                      artistContactEmail
                      artistContactNumber
                      artistWebsite
                  }
                  CategoryID
                  CreatedByUserID
                  CreatedAt
                  UpdatedAt
              }
              Artist {
                  id
                  description
                  artistName
                  artistLocation
                  artistContactEmail
                  artistContactNumber
                  artistWebsite
              }
              isOfferExist
              CreatedBy
              CreatedAt
              UpdatedAt
            }
            totalCount
            user {
              id
              username
              name
              email
              avatar
            }
            lowestSoldPrice {
              value
              unit
              type
            }
            highestSoldPrice {
              value
              unit
              type
            }
            latestSoldPrice {
              value
              unit
              type
            }
        }
  }
`,
    [EMojitoSecondaryWalletQueries.generatePrimaryInvoice]: gql`
    query(
        $invoiceID: UUID1!
        $invoiceResType: InvoiceResType!
        ){
            generatePrimaryInvoice(
                invoiceID: $invoiceID
                invoiceResType: $invoiceResType
                )
        }
    `,
    [EMojitoSecondaryWalletQueries.connectExternalWallet]: gql`
    mutation ConnectExternalWallet(
      $signature: String!
      $message: String!
      $address: String!
      $orgID: UUID1!
      $networkID: UUID1!
    ) {
      connectExternalWallet(
        signature: $signature
        message: $message
        address: $address
        orgID: $orgID
        networkID: $networkID
      )
    }
  `
}
