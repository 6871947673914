import { useAuth0 } from "@auth0/auth0-react";
import { EEventNames, EViewSize, SaleType } from "@enums";
import {
  useCollectionItemCurrentBids,
  useOrganization,
  useProfile,
  useTrackingEventFactory,
  useUserRoleFactory,
} from "@hooks";
import { useMojitoItem } from "@hooks/useMojitoItem";
import { IMojitoCollectionItemAuctionLot } from "@interfaces";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import { bidIncrement } from "@utils";
import isBrowser from "@utils/isBrowser";
import { useIsomorphicLayoutEffect } from "ahooks";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { BaseButton } from "../../button";
import { BidModal } from "./bidModal";
import { LoginOrRegisterButton } from "./general";

export const PlaceBidButton = styled((props) => {
  const { mojitoItem } = useMojitoItem<IMojitoCollectionItemAuctionLot>();
  const { currentBids } = useCollectionItemCurrentBids(mojitoItem?.id);
  const { organization } = useOrganization();
  const { profile } = useProfile();
  const { dispatch } = useOverlayContext();
  const router = useRouter();
  const { isAuthenticated } = useAuth0();
  const trackEvent = useTrackingEventFactory();
  const [userAvailableMinBid, setUserAvailableMinBid] = useState<number>(0);
  const { buttonContext } = useUserRoleFactory();

  useIsomorphicLayoutEffect(() => {
    if (
      currentBids?.details?.currentBid &&
      !currentBids?.details?.currentBid.isStart
    ) {
      const nextIncrementID =
        bidIncrement.findIndex(
          (e) => e === currentBids?.details?.currentBid?.maximumBid
        ) + 1;

      if (
        currentBids?.details?.currentBid?.nextBidIncrement <
        bidIncrement[nextIncrementID]
      ) {
        setUserAvailableMinBid(bidIncrement[nextIncrementID]);
      } else {
        setUserAvailableMinBid(
          currentBids?.details?.currentBid?.nextBidIncrement
        );
      }
    }
  }, []);

  useEffect(() => {
    if (currentBids) {
      setUserAvailableMinBid(currentBids?.details?.currentBid?.amount);
    }
  }, [!!currentBids]);

  useEffect(() => {
    if (router.asPath.split("#")[1] === "bid" && profile) {
      openPlaceBidModal();
      router.push(router.asPath.split("#")[0], undefined, {
        shallow: true,
      });
    }
  }, []);

  function openPlaceBidModal() {
    if (!mojitoItem) return;

    trackEvent(EEventNames.BidStarted);
    dispatch({
      type: EOverlayContextActionTypes.show,
      child: (
        <BidModal
          mojitoItemDetails={mojitoItem?.details}
          lotData={mojitoItem.contentfulData}
          minBid={userAvailableMinBid}
        />
      ),
    });
  }
  return useMemo(
    () =>
      currentBids?.details?.saleView?.isDuringSale &&
      mojitoItem?.saleType === SaleType.Auction ? (
        <>
          {(() => {
            if (isAuthenticated) {
              if (!organization?.hasNotifications) {
                return userAvailableMinBid <
                  bidIncrement[bidIncrement.length - 1] ? (
                  <BaseButton onClick={openPlaceBidModal} {...props}>
                    {currentBids?.details?.currentBid?.isHold
                      ? "Increase Bid"
                      : "Bid now"}
                  </BaseButton>
                ) : null;
              } else {
                return (
                  <buttonContext.component
                    onClick={() =>
                      isBrowser &&
                      (window.location =
                        buttonContext.link as unknown as Window["location"])
                    }
                    {...props}
                  />
                );
              }
            }
            return <LoginOrRegisterButton />;
          })()}
        </>
      ) : null,
    [
      isAuthenticated,
      mojitoItem?.id,
      currentBids?.details?.currentBid?.isHold,
      currentBids?.details?.saleView?.isDuringSale,
    ]
  );
})`
  ${BaseButton} {
    width: 200px;

    @media screen and (max-width: ${EViewSize.mobile}) {
      width: 100%;
      box-shadow: none;
    }
  }
`;
