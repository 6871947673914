import { EPagePath, EViewSize } from "@enums";
import { useCollection, useCollectionItemCurrentBids } from "@hooks";
import {
  IMojitoCollectionItemAuctionLot,
  IMojitoCollectionItemCurrentBids,
} from "@interfaces";
import {
  formatCurrency,
  formatDateToUS,
  premiumCalculator,
  simpleImageLoader,
} from "@utils";
import { useIsomorphicLayoutEffect } from "ahooks";
import moment from "moment";
import React, { ReactElement, useMemo, useState } from "react";
import { BidCountdown } from "src/components/shared/gridRenderer/components/auctionLotLink";
import styled from "styled-components";
import { IImage, Image } from "./image";
import SVG from "react-inlinesvg";
import { TabsNavigation } from "@shared/tabsNavigation";

interface IAuctionInfo {
  showCountdown?: boolean;
  showStatus?: boolean;
  preSaleStatusText?: string;
}

const AuctionInfo = ({
  showCountdown,
  preSaleStatusText,
  showStatus = true,
}: IAuctionInfo) => {
  const { collection } = useCollection();
  const { allCurrentBids } = useCollectionItemCurrentBids();
  const highestCurrentBidDetails = allCurrentBids?.reduce<
    [IMojitoCollectionItemCurrentBids | null, number]
  >(
    (acc, item: any) =>
      acc[1] < item.details?.currentBid?.amount
        ? [item, item.details?.currentBid?.amount]
        : acc,
    [null, 0]
  )[0]?.details;
  const mojitoItemDetails = collection?.items.find(
    (e) => e.details.id === highestCurrentBidDetails?.id
  )?.details as IMojitoCollectionItemAuctionLot;
  const bidAmount = highestCurrentBidDetails?.currentBid?.amount;
  const saleView = mojitoItemDetails?.saleView;
  const feeStructure = mojitoItemDetails?.feeStructure;
  const [fee, setFee] = useState({
    buyersPremiumRate: 0,
    overheadPremiumRate: 0,
  });

  useIsomorphicLayoutEffect(() => {
    if (feeStructure != undefined && bidAmount != undefined)
      setFee(premiumCalculator(bidAmount, feeStructure));
  }, [feeStructure, bidAmount]);

  return mojitoItemDetails ? (
    <>
      {showCountdown && (
        <>
          <BidCountdown
            key={mojitoItemDetails.endTimestamp}
            startDate={mojitoItemDetails.startDate}
            endDate={mojitoItemDetails.endDate}
            hasBid={!!highestCurrentBidDetails?.currentBid}
          />{" "}
          |
        </>
      )}
      {showStatus &&
        (saleView?.isPreSale
          ? preSaleStatusText ?? "Estimate Upon Request"
          : saleView?.isDuringSale
          ? `Current bid: ${formatCurrency(bidAmount || 0)}`
          : saleView?.isPostSale
          ? `Sold: ${formatCurrency(
              (bidAmount || 0) + fee.buyersPremiumRate + fee.overheadPremiumRate
            )}`
          : "")}
    </>
  ) : null;
};

export const AuctionHeaderItem = styled((props) => (
  <div className={props.className}>{props.children}</div>
))``;

interface IHeader {
  className?: string;
  auctionBidInfoConfig?: IAuctionInfo;
  children: ReactElement | ReactElement[];
  bgImage: IImage;
  parallaxImage?: IImage;
  navigation: {
    title: string;
    path: EPagePath | string;
    parentPath?: Array<EPagePath> | EPagePath;
  }[];
}

export const AuctionHeader = styled(
  ({
    children,
    bgImage,
    parallaxImage,
    auctionBidInfoConfig,
    navigation,
    ...props
  }: IHeader) => {
    const { collection } = useCollection();
    const { startDate, endDate } = useMemo(
      () => Object.assign({}, collection?.contentfulData, collection),
      [collection?.id]
    );
    const isSameYear = moment(startDate).isSame(moment(), "year");

    useIsomorphicLayoutEffect(() => {
      [children].flat().forEach((child) => {
        if ((child.type as any).name !== AuctionHeaderItem.name) {
          throw Error("Only AuctionHeaderItem components allowed as children.");
        }
      });
    }, [children]);

    return (
      <div {...props}>
        <SVG className="sothebys-ribbon" src="/icons/sothebys-ribbon.svg" />
        {bgImage && (
          <Image
            {...bgImage}
            loader={simpleImageLoader}
            alt={bgImage.alt}
            src={bgImage.src}
            className="bg"
            unoptimized={true}
            priority
          />
        )}
        {parallaxImage && (
          <Image
            {...parallaxImage}
            loader={simpleImageLoader}
            alt={parallaxImage.alt}
            src={parallaxImage.src}
            className="parallax"
            priority
          />
        )}
        {startDate && (
          <div className="duration">
            {formatDateToUS(
              startDate,
              `DD MMMM${isSameYear ? "" : ", YYYY"} • ha`,
              true,
              false,
              true
            )}
            {endDate && (
              <>
                <div>–</div>
                {formatDateToUS(
                  endDate,
                  `DD MMMM${isSameYear ? "" : ", YYYY"} • ha`,
                  true,
                  false,
                  true
                )}
              </>
            )}
          </div>
        )}
        <div className="container">{children}</div>
        {auctionBidInfoConfig && (
          <div className="auctionInfo">
            <AuctionInfo {...auctionBidInfoConfig} />
          </div>
        )}
        <TabsNavigation navigation={navigation} />
      </div>
    );
  }
)`
  font-family: "BentonSans", serif;
  text-align: center;
  color: #ffffff;

  position: relative;
  z-index: 1;
  flex: 0 0 426px;
  height: 426px;
  width: 100vw;
  margin: 0 -40px;

  display: flex;
  align-items: center;
  flex-direction: column;

  & > * {
    margin: 0 auto;
  }

  .title {
    font-family: "MercuryDisplay", serif;
    font-size: 40px;
    line-height: 48px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
    margin: 16px auto 0;
  }

  .spacer {
    height: 54px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }

  .auctionInfo {
    font-size: 14px;
    line-height: 17px;
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .duration {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 42px;
    font-weight: 400;
    margin: 15px 0;

    div {
      width: 30px;
      margin: 0 auto;
    }
  }

  .sothebys-ribbon {
    position: absolute;
    left: 75px;
    width: 30px;
    height: auto;
    @media screen and (max-width: ${EViewSize.mobile}) {
      width: 24px;
      left: 45px;
    }
  }

  ${Image} {
    width: 100%;
    height: 100%;
    position: absolute;

    &.bg {
      z-index: -2;
    }

    &.parallax {
      z-index: -1;
    }
  }

  @media screen and (max-width: ${EViewSize.large}) {
    flex: 0 0 346px;
    height: 346px;
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    .title {
      font-size: 32px;
      line-height: 38px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    width: 100vw;
    flex: 0 0 458px;
    height: 458px;
    margin: 0 -16px;

    .title {
      font-size: 24px;
      line-height: 29px;
      margin-top: 0;
    }

    .duration {
      line-height: 16px;
      margin: 25px 0 5px;
    }
  }

  @media screen and (min-width: ${EViewSize.mobile}) {
    .duration > div {
      display: inline-block;
    }
  }
`;
