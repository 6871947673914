export const ImageCompressed = (url: string, width?: string | number, quality?: number): string => {
  let baseUrl

  if (url) {
    baseUrl = `${url}?q=${quality || 35}`;
    if (width) baseUrl = `${baseUrl}&w=${width}`;

    return baseUrl;
  }

  return url;
}
