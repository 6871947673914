import { setTypewriterOptions } from "@analytics";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader, PageviewTracker, Scrollbar } from "@components";
import { config } from "@constants";
import { Footer } from "@core/footer";
import { Header } from "@core/header";
import { Overlay } from "@core/overlay";
import { EPagePath, EViewSize } from "@enums";
import { useMojitoMutation, useProtectedApi } from "@hooks";
import { useCollection } from "@hooks/useCollection";
import { usePageType } from "@hooks/usePageType";
import { useFavorites, useOrganization, useProfile } from "@hooks/useProfile";
import { IMojitoMarketplaceResponse } from "@interfaces";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import * as snippet from "@segment/snippet";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { EMojitoMutations, EMojitoQueries } from "@state";
import { ColorSchemeProvider } from "@state-context/colorScheme.contex";
import { MenuProvider } from "@state-context/menu.context";
import { OnboardingProvider } from "@state-context/onboarding.context";
import { OverlayProvider } from "@state-context/overlay.context";
import { muiTheme, palette } from "@styles/theme";
import { ThemeStyles } from "@styles/variables";
import { queryClient } from "@utils";
import getDehydratedState from "@utils/getDehydratedState.util";
import isBrowser from "@utils/isBrowser";
import { useCounter } from "ahooks"; // useRafTimeout
import Head from "next/head";
import { Router, useRouter } from "next/router";
import Script from "next/script";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { hydrate, Hydrate, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "src/data/authProvider";
import styled from "styled-components";
import { Layout } from "../../src/components/shared/layout";
import {
  DynamicContextProvider,
  FilterAndSortWallets,
} from "@dynamic-labs/sdk-react";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import useLocalStorageState from "use-local-storage-state";
import { LOCAL_STORAGE_STATE } from "@utils/wallet/clearLocalStorage";

import "../styles/dynamic.css";

// Tailwind CSS
import "../styles/globals.css";
import { WalletContextProvider } from "src/data/walletProvider";

const {
  SENTRY_ENV,
  SENTRY_DSN,
  SEGMENT_ANALYTICS_ENABLE,
  ONE_TRUST_KEY,
  ONE_TRUST_HELPER_URL,
} = config;

if (SENTRY_ENV && SENTRY_DSN) {
  Sentry.init({
    environment: SENTRY_ENV,
    dsn: SENTRY_DSN,

    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({ levels: ["error"] }),
    ],

    // Submit all errors:
    sampleRate: 1,

    // But only 10% of transactions:
    tracesSampleRate: 0.1,

    // Avoid fetch events from BrowserTracing and SentryLink:
    // beforeBreadcrumb: excludeGraphQLFetch,
  });

  // In case we want to peak at Sentry's context data:
  if (process.browser && config.ENVIRONMENT === "dev")
    (window as any).Sentry = Sentry;
} else {
  console.error(
    `Did you forget to configure Sentry? (SENTRY_ENV = "${SENTRY_ENV}", SENTRY_DSN = "${SENTRY_DSN}")`
  );
}

setTypewriterOptions({
  onViolation: (message, violations) =>
    console.error(`Typewriter Violation found in ${message.event}`, violations),
});

function renderSnippet() {
  const isAnalyticsEnabled = SEGMENT_ANALYTICS_ENABLE;
  const disableOpts = {
    load: false,
    apiKey: config.SEGMENT_WRITE_KEY,
    page: false,
  };
  const enableOpts = {
    apiKey: config.SEGMENT_WRITE_KEY,
    page: false,
  };

  return config.ENVIRONMENT === "dev"
    ? snippet.max(isAnalyticsEnabled ? enableOpts : disableOpts)
    : snippet.min(isAnalyticsEnabled ? enableOpts : disableOpts);
}

export function checkIs3DSPage(pathname: string): boolean {
  return [
    "/payments/success",
    "/payments/error",
    "/payments/failure",
    "/sales",
    "/salgado",
    "/aegirls",
  ].includes(pathname);
}

const PageByType = styled(
  ({ children, router, ...props }: { router: Router; children: ReactNode }) => {
    const {
      isOnboarding,
      isOnboardingCompleted,
      isAuctionPath,
      isOneLot,
      _path,
      _resolvedUrl,
    } = usePageType();

    useEffect(() => {
      getDehydratedState(
        { asPath: _resolvedUrl },
        { loadSingleLotPageFullInfo: isOneLot }
      ).then(({ dehydratedState }) => {
        hydrate(queryClient, dehydratedState);

        if (isBrowser && isAuctionPath) {
          const data = queryClient.getQueryData<IMojitoMarketplaceResponse>([
            `Mojito ${
              EMojitoQueries[
                EMojitoQueries.marketplaceCollectionsInfoWithItemsIdAndSlug
              ]
            }`,
            { id: config.MARKETPLACE_ID },
          ]);

          data?.marketplace?.collections?.forEach((collection) => {
            document.body.classList.remove(collection.slug);
          });
        }

        if (_path[1]) document.body.classList.add(_path[1]);
      });
    }, [router.route]);

    return (
      <div {...props}>
        {isOnboarding || isOnboardingCompleted ? (
          <div className="page-content-wrapper">{children}</div>
        ) : (
          <>
            <Header />
            <Scrollbar
              key={router.route}
              isPageScroll
              isStatic={
                isOneLot &&
                (_path[1] == "natively-digital" ||
                  _path[1] == "gifted-twitter-140")
              }>
              <div className="overall-container">
                <div className="page-content-wrapper">{children}</div>
                <div className="mobile-spacer" />
                <div className="footer btm-footer">
                  <Footer />
                </div>
              </div>
            </Scrollbar>
          </>
        )}
      </div>
    );
  }
)`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${palette.white};
  .mobile-spacer {
    min-height: 100px;
  }
  .footer{
    position:absolute;
    bottom:0;
    right:0;
    left:0;
    z-index:9999999;
    width: calc(100% - 14px);
    padding-bottom: 14px;
    background:#fff;
  }
  .overall-container{
    display: flex;
    flex-direction: column;
    max-width: 100%
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    overflow:hidden;
    overflow-y:auto;
    padding-top: 65px;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    .footer{
      // position:relative;
      position:fixed;
      bottom:0;
      left:0;
      width:100%;
      z-index:999;
    }
    .page-content-wrapper {
      justify-content: flex-start;
    }

    .mobile-spacer {
      min-height: 100px;
    }
  }
`;

export const FetchProfileInBackground: FC = () => {
  const { isAuthenticated } = useAuth0();
  const { refetch } = useCollection({ options: { enabled: false } });

  useProfile();
  useFavorites();

  useEffect(() => {
    if (isAuthenticated) refetch();
  }, [isAuthenticated]);

  return null;
};

const Authentication: FC<any> = ({ children }) => {
  const { isAuthenticated, error, user } = useAuth0();
  const { data, firstLoad } = useProtectedApi(config.SYNC_REGISTRATION_URL, {
    audience: config.CUSTOMER_AUDIENCE,
    scope: config.CUSTOMER_SCOPE,
  });
  const { organization, refetch } = useOrganization({ enabled: false });
  const [errorCounter, { inc: incErrorCounter }] = useCounter(0);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const [updateUserSettings] = useMojitoMutation(
    EMojitoMutations.updateUserOrgSettings
  );
  const [reportSent, setReportSend] = useState(false);

  if (organization == undefined && errorCounter < 3) {
    incErrorCounter();
    setTimeout(() => refetch(), 150 * errorCounter);
  }

  useEffect(() => {
    if (data.status == "change" || firstLoad) {
      refetch();
    }
  }, [data, firstLoad]);

  useEffect(() => {
    if (organization) {
      if (!organization?.avatar || !organization?.username) {
        router.replace(EPagePath.onBoarding);
        setTimeout(() => setLoading(false), 700);
      } else {
        if (!reportSent) {
          const _settings = organization?.settings;

          try {
            const _org = { ...organization };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete _org.settings;

            updateUserSettings({
              userOrgId: organization.id,
              settingsJson: JSON.stringify(
                Object.assign({}, _settings, {
                  debugReport: {
                    timestamp: new Date().getTime(),
                    user: user?.sub,
                    org: _org,
                  },
                })
              ),
            });
            setReportSend(true);
          } catch (err) {
            console.log(err);
          }
        }

        setLoading(false);
      }
    } else if (!isAuthenticated) setLoading(false);
  }, [organization]);

  useEffect(() => {
    if (isAuthenticated) refetch();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) setLoading(false);
  }, [error, isAuthenticated]);

  return loading ? <Loader /> : children;
};

const GlobalLoader: FC<any> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const skipLoader = checkIs3DSPage(router.pathname);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        (async () => {
          try {
            await getAccessTokenSilently();
          } catch (e) {
          } finally {
            setLoading(false);
          }
        })();
      } else {
        setLoading(false);
      }
    }
  }, [isAuthenticated, isLoading]);

  return loading && !skipLoader ? <Loader /> : children;
};

const PageLoader: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  return loading ? <Loader /> : children;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OneTrustHandler: FC<any> = () => {
  const handleOneTrustEvents = async () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "staging") {
      window.location.reload();
    }
  };

  const onOneTrustChanged = () => {
    handleOneTrustEvents().catch((error) =>
      console.error("Error in OneTrust event handling:", error)
    );
  };

  useEffect(() => {
    const sothebysShared = (window as any).SothebysShared;
    if (sothebysShared && sothebysShared.findSegmentIntegrations) {
      sothebysShared
        .findSegmentIntegrations(config.SEGMENT_WRITE_KEY)
        .then((integrations: any) => {
          analytics.load(config.SEGMENT_WRITE_KEY, {
            integrations: integrations,
          });
        })
        .catch((error: any) => {
          console.error("Error in Sotheby's integration:", error);
        });

      window.addEventListener("OneTrustGroupsUpdated", onOneTrustChanged);
      window.addEventListener("OTConsentApplied", onOneTrustChanged);
    }

    return () => {
      window.removeEventListener("OneTrustGroupsUpdated", onOneTrustChanged);
      window.removeEventListener("OTConsentApplied", onOneTrustChanged);
    };
  }, []);

  return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MyApp = ({
  Component,
  pageProps: { dehydratedState, ...pageProps },
  router,
}: any): JSX.Element => {
  const [, setConnectWallet] = useLocalStorageState<boolean>(
    LOCAL_STORAGE_STATE.secondary_wallet.is_connect_wallet,
    { defaultValue: false }
  );

  const dynamicConfig = {
    environmentId: config.DYNAMIC_ENVIRONMENT_ID,
    appName: "Sothebys Metaverse",
    appLogoUrl: "https://metaverse.sothebys.com/favicon-light.png",
    walletsFilter: FilterAndSortWallets([
      "metamask",
      "kresus",
      "coinbase",
      "walletconnect",
    ]),
    eventsCallbacks: {
      onAuthSuccess: () => setConnectWallet(true),
      onLogout: () => setConnectWallet(false),
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Hydrate state={dehydratedState}>
        <div style={{ height: "100vh" }}>
          <Head>
            <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
            <title>{"Sotheby's Metaverse"}</title>
            <meta
              name="description"
              content="Sotheby’s is the premier destination for curated digital artworks and collectibles. Discover top Artists. Browse upcoming sales. Buy and sell rare digital works."
            />
            <meta
              property="og:image"
              content="https://images.ctfassets.net/fu9did2d8yaw/4FvZ87T2cLhR8eeFlKQVTk/68db253f73d6ecabdd0e35a9e161802f/Sotheby-s_Metaverse_Brand_Banner.jpg"></meta>
          </Head>
          <ThemeStyles />

          <Script
            id="segment-snippet"
            dangerouslySetInnerHTML={{ __html: renderSnippet() }}
            strategy="lazyOnload"
          />
          <Script
            src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
            data-domain-script={ONE_TRUST_KEY}
            strategy="lazyOnload"
          />
          <Script
            src="https://cdn.checkout.com/js/framesv2.min.js"
            data-domain-script={ONE_TRUST_KEY}
            strategy="lazyOnload"
          />
          <Script src={ONE_TRUST_HELPER_URL} strategy="lazyOnload" />

          <ThemeProvider theme={muiTheme}>
            <DynamicContextProvider
              settings={{
                ...dynamicConfig,
                initialAuthenticationMode: "connect-only",
                displaySiweStatement: false,
              }}>
              <DynamicWagmiConnector>
                <ColorSchemeProvider>
                  <CssBaseline />
                  <AuthProvider>
                    <WalletContextProvider>
                      <Layout>
                        <PageviewTracker />
                        <OnboardingProvider>
                          <OverlayProvider>
                            <MenuProvider>
                              <>
                                <Overlay />
                                <GlobalLoader>
                                  <Authentication>
                                    {/* <OneTrustHandler /> */}
                                    <FetchProfileInBackground />
                                    <PageByType router={router}>
                                      <PageLoader>
                                        <Component {...pageProps} />
                                      </PageLoader>
                                    </PageByType>
                                  </Authentication>
                                </GlobalLoader>
                              </>
                            </MenuProvider>
                          </OverlayProvider>
                        </OnboardingProvider>
                      </Layout>
                    </WalletContextProvider>
                  </AuthProvider>
                </ColorSchemeProvider>
              </DynamicWagmiConnector>
            </DynamicContextProvider>
          </ThemeProvider>
        </div>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
