import { EColorScheme } from "@enums/colorScheme.enum";
import { useLocalStorageState } from "ahooks";
import React, { FC } from "react";

// INIT ACTION KEYS
export enum EColorSchemeContextActionTypes {
  setColorScheme = "setColorScheme",
}

// INIT ACTION PROPS
interface IAction {
  type: EColorSchemeContextActionTypes;
  colorScheme?: EColorScheme;
}

// INIT STATE TYPE
interface State {
  colorScheme: EColorScheme;
}

// INIT STATE
const initialState: State = {
  colorScheme: EColorScheme.dark,
};

function Reducer(state: State, action: IAction) {
  const [, setStorageColorScheme] =
    useLocalStorageState<EColorScheme>("colorSchema");

  switch (action.type) {
    case EColorSchemeContextActionTypes.setColorScheme: {
      const _color = action.colorScheme ?? state.colorScheme;
      setStorageColorScheme(_color);

      return {
        ...state,
        colorScheme: action.colorScheme ?? state.colorScheme,
      };
    }
  }
}

export const ColorSchemeProvider: FC<any> = (props) => {
  const [storageColorScheme] = useLocalStorageState<EColorScheme | any>(
    "colorSchema",
    {
      defaultValue: EColorScheme.dark,
    }
  );

  initialState.colorScheme = storageColorScheme;

  return <Provider>{props.children}</Provider>;
};
export const useColorSchemeContext = useContext;

// ======================================================== //
// ================== IGNORE ALL BELOW ==================== //
// ======================================================== //

type IDispatch = (action: IAction) => void;

const StateContext = React.createContext<{
  state?: State;
  dispatch?: IDispatch;
}>({
  state: undefined,
  dispatch: undefined,
});

function Provider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = React.useReducer<React.Reducer<State, IAction>>(
    Reducer,
    initialState
  );

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
}

function useContext(): {
  state: State;
  dispatch: IDispatch;
} {
  const context = React.useContext(StateContext);
  if (context.dispatch === undefined || context.state === undefined) {
    throw new Error("useContext must be used within a Provider");
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
  };
}
