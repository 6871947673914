import moment from "moment-timezone";

export function formatDateToUS(
  date: string,
  format?: string,
  keepLocalTime = true,
  withTime = false,
  zone = false
): string | null {
  if (!date) return null;
  const _date = moment(date);
  const _zone = _date.tz(moment.tz.guess()).format("z");

  return (
    _date
      .utc(keepLocalTime)
      .format(
        format ||
          (_date.isSame(moment(), "year") ? "MMMM DD" : "MMMM DD, YYYY") +
            (withTime ? " HH:mm A" : "")
      ) + (zone ? ` ${_zone}` : "")
  );
}
