import { EViewSize } from "@enums";
import { useColorScheme } from "@hooks";
import { IContentfulAuthor } from "@interfaces";
import { Avatar, Grid } from "@material-ui/core";
import SVG from "react-inlinesvg";
import styled from "styled-components";

const UserInfoAvatar = styled(Avatar)`
  border-radius: 50%;
  overflow: hidden;
  width: 91px;
  height: 91px;
  margin-right: 17px;

  @media screen and (max-width: ${EViewSize.mobile}) {
    width: 60px;
    height: 60px;
  }
`;

const UserInfoName = styled.div`
  font-family: "MercuryDisplay", serif;
  font-size: 32px;
  line-height: 38px;

  @media screen and (max-width: ${EViewSize.mobile}) {
    font-size: 20px;
    line-height: 22px;
    overflow-wrap: break-word;
  }
`;

type UserInfoHeader = Partial<
  Pick<IContentfulAuthor, "name" | "avatar" | "twitterLink">
>;

export const UserInfoHeader = styled(
  ({ user, ...props }: { user: UserInfoHeader }) => {
    const { isDark } = useColorScheme();

    return (
      <div {...props}>
        <Grid container>
          {user.avatar?.url && (
            <Grid item>
              <UserInfoAvatar src={user.avatar.url} alt={user.name} />
            </Grid>
          )}
          <Grid item alignSelf="center" flexGrow={1}>
            <UserInfoName>{user.name}</UserInfoName>
          </Grid>
          {user.twitterLink && (
            <Grid item alignSelf="center">
              <a
                href={user.twitterLink}
                target="_blank"
                rel="noopener noreferrer">
                <SVG
                  src="/icons/twitter.svg"
                  height={18}
                  style={{
                    marginTop: "14px",
                    fill: isDark ? "white" : "black",
                  }}
                />
              </a>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
)`
  width: 100%;
  flex-wrap: nowrap;
`;
