import { EViewSize } from "@enums";
import { useCollection, useProfile, useDeviceResponsive } from "@hooks";
import { Box, Grid } from "@material-ui/core";
import { palette } from "@styles/theme";
import { globalSearchFilter } from "@utils/wallet/wallet";
//import { EMojitoMutations } from "@state";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import SearchInput from "src/components/secondary-wallet/SearchInput";
import styled from "styled-components";
import { ActiveBidsBadge, IconButton, Logo, Navbar } from "../../shared";
import { AuthMenu } from "./authMenu";
import Router from "next/router";
import Image from "next/image";
import { Footer } from "@core/footer";
import { auctionDurationFormatter } from "@utils";
import { isEmpty, PDPURL } from "@utils/wallet/numberFormet.util";

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${IconButton} {
    margin-right: 10px;
  }
  @media screen and (max-width: ${EViewSize.mobile}) {
    // padding: 8px 16px;
    // flex-direction: column;
    // align-items: flex-start;
    // > button {
    //   margin: 3px 0;
    // }
    // > div:last-child {
    //   margin: 3px 0;
    // }
  }
`;

export const Header = styled((props) => {
  const { slug, collection } = useCollection();
  const { profile } = useProfile();
  const [isActive, setIsActive] = useState(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [gSearchShow, setgSearchShow] = useState<boolean>(false);
  const notificationbar = false;
  const [mobileNavSide, setMobileNavSide] = useState<any>("");
  const [mobileNavbarData, setMobileNavbarData] = useState<any>([]);
  const myRef = useRef<HTMLInputElement>(null);
  const { isIOS } = useDeviceResponsive();

  const handleClickOutside = (e: any) => {
    if (!myRef?.current?.contains(e.target)) {
      setgSearchShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const [globalSearchObj, setGlobalSearchObj] = useState({
    orgID: profile?.userOrgs?.[0]?.organizationId,
    searchKey: globalSearch,
    filterBy: "ALL",
    offset: 0,
    limit: 3,
  });
  useEffect(() => {
    if (globalSearch != undefined) {
      const delayDebounceFn = setTimeout(async () => {
        setGlobalSearchObj({
          ...globalSearchObj,
          searchKey: globalSearch,
        });
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [globalSearch]);

  const { globalSearchList, globalSearchLoading } = globalSearchFilter(
    globalSearchObj,
    true
  );
  const handleClickInside = (event:any) => {
    sessionStorage.setItem("collecionShow",event.target.innerText)
    sessionStorage.setItem("artistCollectionFlag","true")
    setgSearchShow(false);
    document.body.classList.remove("search-open"); // open-hamburger
    // document.classList.remove("open-hamburger");
  };

  const handleClick = () => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  const handleCloseMobileNav = () => {
    // 👇️ toggle isActive state on click
    setIsActive(false);
  };
  /*const [updateUserSettings] = useMojitoMutation(
    EMojitoMutations.updateUserOrgSettings
  );*/

  // const NotificationHandler = (val: boolean) => {
  //   setNotificationBar(val);
  // };

  const mobileSidebar = (val: boolean) => {
    setIsActive(val);
  };

  const checkNullVal = (arr: any) => {
    return arr.every((element: any) => element === null);
  };
  const onClickSearchClose = () => {
    document.body.classList.remove("search-open");
    // setIsLightTheme(false);
  };

  const getGlobalSearchData = (val: any, index: number) => {
    if(Object.keys(globalSearchList)[index] === "artists") {
      return val?.artistName;
    } else if(Object.keys(globalSearchList)[index] === "items") {
        return !isEmpty(val?.nftTokenName) ? val?.nftTokenName :
              !isEmpty(val?.onChainTokenId) ? "Token " +
              "#" +
              ( val?.onChainTokenId?.length > 4 ? 
                val?.onChainTokenId?.substr(0, 4) + "****" +
                val?.onChainTokenId?.substr(val?.onChainTokenId?.length - 4) : 
                val?.onChainTokenId
              ) : 
              !isEmpty(val?.collectionName) ? val?.collectionName :"collection " +
              ( val?.collectionAddress?.length > 4 ? 
                val?.collectionAddress?.substr(0, 4) + "****" +
                val?.collectionAddress?.substr(val?.collectionAddress?.length - 4) : 
                val?.collectionAddress)
    } else {
        return !isEmpty(val?.collectionName) ? val?.collectionName :
                "collection " +
                ( val?.collectionAddress?.length > 4 ? 
                  val?.collectionAddress?.substr(0, 4) + "****" +
                  val?.collectionAddress?.substr(val?.collectionAddress?.length - 4) : 
                  val?.collectionAddress)
    }
  }

  const getSearchData = () => {
    return globalSearchLoading ? (
      <Box className={"noData"}>
        {" "}
        <Image
          unoptimized={true}
          src={"/images/loading.gif"}
          alt={"loading"}
          width={16}
          height={16}
        />
      </Box>
    ) : globalSearchList && !checkNullVal(Object.values(globalSearchList)) ? (
      Object.keys(globalSearchList)?.map((item: string, i: number) => {
        return (
          <>
            {i !== 0 && <Box className="border-line"></Box>}
            {globalSearchList[Object.keys(globalSearchList)[i]] !== null ? (
              <Box className="g-s-name">{Object.keys(globalSearchList)[i]}</Box>
            ) : (
              ""
            )}
            <Box key={i} className="border-line"></Box>
            {globalSearchList &&
              globalSearchList[Object.keys(globalSearchList)[i]]?.length !== 0 ? (
                <>
                  {Object.keys(globalSearchList)[i] === item &&
                    globalSearchList[Object.keys(globalSearchList)[i]]?.map(
                      (val: any, num: any) => {
                        return (
                          <>
                            <Box
                              className="g-s-name-1"
                              onClick={() => {
                                if (
                                  Object.keys(globalSearchList)[i] === "artists"
                                ) {
                                  setgSearchShow(false);
                                  Router.push(`/artist/${val?.slug}`);
                                } else if (
                                  Object.keys(globalSearchList)[i] === "items"
                                ) {
                                  setgSearchShow(false);
                                  Router.push(
                                    PDPURL(val?.collectionAddress, val?.onChainTokenId, "", "")
                                  );
                                } else if (
                                  Object.keys(globalSearchList)[i] ===
                                  "collections"
                                ) {
                                  setgSearchShow(false);
                                  sessionStorage.setItem("collectionID", val?.collectionId);
                                  Router.push(
                                    `/artist/${val?.artistSlug}/${val?.categorySlug}`
                                  );
                                }
                              }}>
                              {getGlobalSearchData(val, i)}
                            </Box>
                            {!(
                              globalSearchList[
                                Object.keys(globalSearchList)[i]
                              ].slice(0, -1)?.length === num
                            ) ? (
                              <Box className="border-line1"></Box>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    )}
                </>
              ) : (
                <Box className={"noData"}>No Data Found</Box>
              )}
          </>
        );
      })
    ) : (
      <Box className={"noData"}>No Data Found</Box>
    );
  };

  return (
    <div
      className={
        "headerBlock" +
        " " +
        (notificationbar && "notificationNavbar") +
        " " +
        (mobileNavSide && "menu-view")
      }>
      {/* <Notification NotificationHandler={NotificationHandler} className="notificationCom" /> */}
      <Grid
        container
        component="header"
        alignItems="center"
        justifyContent={{ sm: "space-between", xs: "flex-start" }}
        padding={{ xs: "0 15px", lg: "0 35px" }}
        {...props}>
        <Grid
          item
          xs={7}
          display="flex"
          justifyContent="flex-start"
          alignItems="center">
          {/* <MobileMenu /> */}
          <div
            className={isActive ? "open-hamburger header-logo" : "header-logo"}>
            <div className="mobileMenu">
              <div className="hamburger" onClick={handleClick}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
              </div>
              <Logo />
            </div>
            {/* <Navbar mobileSidebar={mobileSidebar} /> */}
            <section className="mob-view-menu-nav ">
              <div className="mob-menu-wrapr ">
                <div className="mobile-view-label-icon-block">
                  <p>
                    <span
                      onClick={() => {
                        setMobileNavSide("");
                      }}
                      className="close-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="8"
                        viewBox="0 0 4 8"
                        fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M-1.5299e-07 4C-1.56644e-07 3.9164 0.0346616 3.83636 0.0960645 3.77819L3.46448 0.587014C3.58975 0.468341 3.78967 0.471444 3.91102 0.593946C4.03237 0.716448 4.0292 0.911959 3.90393 1.03063L0.769643 4L3.90393 6.96937C4.0292 7.08804 4.03237 7.28355 3.91102 7.40605C3.78967 7.52856 3.58975 7.53166 3.46449 7.41299L0.0960645 4.22181C0.0346616 4.16364 -1.49335e-07 4.08361 -1.5299e-07 4Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    {mobileNavSide}
                  </p>
                </div>
                <ul>
                  {mobileNavSide == "upcomingsales" ? (
                    mobileNavbarData?.map((item: any, key: number) => {
                      return (
                        <li
                          key={key}
                          onClick={() => {
                            Router.push(`/${item?.slug}`, undefined, {
                              shallow: true,
                            });
                          }}>
                          {item?.name}
                          <span
                            onClick={() => {
                              mobileSidebar(false);
                              setMobileNavSide("");
                            }}>
                            {auctionDurationFormatter(
                              item?.startDate,
                              item?.endDate
                            )}
                          </span>
                        </li>
                      );
                    })
                  ) : mobileNavbarData?.length == 1 &&
                    mobileNavbarData[0]?.id == "its_comming" ? (
                    <li  className="navbarSales">Coming soon</li>
                  ) : (
                    mobileNavbarData?.map((item: any, key: number) => {
                      return (
                        item?.name &&
                        <li
                          key={key}
                          className={isIOS ? "artistios" : ""}
                          onClick={() => {
                            // alert(678)
                          }}>
                          <p
                            onClick={() => {
                              mobileSidebar(false);
                              setMobileNavSide("");
                              Router.push(
                                `/artist/${item?.id}`,
                                undefined,
                                { shallow: true }
                              );
                              sessionStorage.setItem("artistmintid", item.mint);
                            }}>
                            {item?.name}
                          </p>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </section>
            <div className="nav-container">
              <Navbar
                mobileSidebar={mobileSidebar}
                setMobileNavbarData={setMobileNavbarData}
                setMobileNavSide={setMobileNavSide}
              />
              <div className="hdr-ftermobile-view show-mobile-view">
                <Footer />
              </div>
            </div>
          </div>
        </Grid>
        {/* {isEqualOrAbove("desktop") && ( )} */}
        <Grid item xs={5} justifyContent="flex-end">
          <div className="loginBlock">
            <Box></Box>

            <HeaderMenu>
              {collection?.hasMultipleLots && slug == "natively-digital" && (
                <Link href={`/${slug}/lots`} passHref>
                  <IconButton>
                    <SVG width={20} src="/icons/grid.svg" />
                  </IconButton>
                </Link>
              )}
              <Box className="g-s-p search-block" ref={myRef}>
                <div className="mobile-view-label-icon-block">
                  <p>
                    <span
                      onClick={() => {
                        onClickSearchClose();
                      }}
                      className="close-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="8"
                        viewBox="0 0 4 8"
                        fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M-1.5299e-07 4C-1.56644e-07 3.9164 0.0346616 3.83636 0.0960645 3.77819L3.46448 0.587014C3.58975 0.468341 3.78967 0.471444 3.91102 0.593946C4.03237 0.716448 4.0292 0.911959 3.90393 1.03063L0.769643 4L3.90393 6.96937C4.0292 7.08804 4.03237 7.28355 3.91102 7.40605C3.78967 7.52856 3.58975 7.53166 3.46449 7.41299L0.0960645 4.22181C0.0346616 4.16364 -1.49335e-07 4.08361 -1.5299e-07 4Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    Search
                  </p>
                </div>
                <SearchInput
                  value={globalSearch ?? ""}
                  extraClass={"g-s"}
                  extraClassSvg={"g-s-i"}
                  onFocusData={() => {
                    setgSearchShow(true);
                  }}
                  onChangeData={(e: any) => {
                    setGlobalSearch(e.target.value);
                  }}
                />
                {gSearchShow && globalSearch.length ? (
                  <Box className="g-s-c" onClick={handleClickInside}>
                    {getSearchData()}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <AuthMenu handleCloseMobileNav={handleCloseMobileNav} />
            </HeaderMenu>
          </div>
        </Grid>
      </Grid>
    </div>
  );
})`
  .noData {
    font-family: "BentonSans";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .g-s-p {
    position: relative;
    right: 10px;
    .g-s-c {
      padding: 0px;
      position: absolute;
      margin-top: 10px;
      width: 355px;
      background: ${palette.white};
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      z-index: 9;
      max-height: 500px;
      overflow: hidden;
      overflow-y: auto;
      .border-line {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
      .border-line1 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin: 0px 13px;
      }
      .g-s-name {
        font-family: "BentonSans";
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.0025em;
        color: ${palette.black};
        opacity: 0.5;
        padding: 16px;
        text-transform: capitalize;
      }
      .g-s-name-1 {
        font-family: "BentonSans";
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.0025em;
        text-transform: capitalize;
        color: ${palette.black};
        padding: 16px;
        cursor: pointer;
      }
      .g-s-name-2 {
        font-family: "BentonSans";
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.0025em;
        color: ${palette.black};
        opacity: 0.5;
        padding: 2px 13px 10px 13px;
      }
    }
    .g-s-i {
      position: absolute;
      top: 10px;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      z-index: 9;
    }
    .g-s {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 12px 8px 9px 42px;
      gap: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 355px;
      height: 40px;
      background: ${palette.white};
      border-radius: 7px;
      font-size: 13px;
      &:focus-visible {
        border: 2px solid rgba(0, 0, 0, 1);
        outline: none;
      }
    }
  }

  position: relative;
  min-height: 60px;
  // z-index: 9999999;
  background: ${palette.white};

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 10%,
      rgba(0, 0, 0, 0.1)
    );
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .search-button {
    border: 2px solid ${palette.hoverColor};
  }
  ${IconButton} {
    position: relative;
    > ${ActiveBidsBadge} {
      position: absolute;
      top: -8px;
      left: -6px;
    }
    svg {
      min-width: 20px;
    }
  }
  ${Logo} {
    z-index: 99;
  }
  @media screen and (min-width: ${EViewSize.mobile}) {
    &:before {
      z-index: -1;
    }
  }
  .header-logo {
    display: flex;
    align-items: center;
    .hamburger {
      cursor: pointer;
      margin-right: 20px;
      min-width: 40px;
      height: 40px;
      border: 1px solid ${palette.paginationText};
      border-radius: 7px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      display: none;
      span {
        width: 100%;
        display: block;
        height: 1.5px;
        background: ${palette.black};
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.line2 {
          position: relative;
          &::before,
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 1.5px;
            background: ${palette.black};
          }
        }
      }
    }
    &.open-hamburger {
      .hamburger {
        span {
          background: transparent;
          &.line1,
          &.line3 {
            opacity: 0;
          }
          &.line2 {
            &::before {
              transform: rotate(45deg);
            }
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
  // responsive
  @media screen and (max-width: 1200px) {
    .g-s-p {
      .g-s-c {
        width: 200px;
      }
      .g-s {
        width: 200px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .g-s-p {
      .g-s-c {
        width: 200px;
      }
      .g-s {
        width: 200px;
      }
    }
    .header-logo {
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      background: ${palette.white};
      width: 0px;
      z-index: 9;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: unset;
      .nav-container {
        width: 100%;
        height: 100%;
        overflow: hidden auto;
        box-shadow: 9px 11px 9px 0px hsl(0deg 0% 0% / 35%);
      }
      .mobileMenu {
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-left: 20px;
        .hamburger {
          display: flex;
        }
        svg {
          flex: 0 0 180px;
        }
      }
      .menunav {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        transform: scaleX(0);
        transform-origin: left;
        min-height: 170px;
        position: relative;
        z-index: 2;
      }
      .hdr-ftermobile-view {
        margin-top: 20px;
        transform-origin: left;
        transform: scaleX(0);
      }
      &.open-hamburger {
        width: 280px;
        .menunav,
        .hdr-ftermobile-view {
          transform: scaleX(1);
        }
      }
    }
    .menunav li ol {
      width: 95%;
      background: #fff;
      li {
        padding: 9px 14px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        white-space: normal;
      }
    }
    .menunav > li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-right: 15px;
    }
    .menunav li {
      .dropdown-icon {
        transform: rotateX(0deg);
      }
      ol {
        transform: scaleY(0);
      }
    }
    .menunav li.open {
      .dropdown-icon {
        transform: rotateX(180deg);
      }
      ol {
        transform: scaleY(1);
      }
    }

    // header bottom footer
    .hdr-ftermobile-view {
      .consumerBlock {
        display: none;
      }
      .footer-container {
        margin-left: 25px;
        .footerNav {
          margin-left: 0;
          flex-direction: column;
          a {
            margin-left: 0;
          }
          // padding: 15px 10px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .search-block {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      padding: 10px;
      background: #fff;
      transform: scale(0);
      .mobile-view-label-icon-block {
        padding: 10px 0;
        p {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            cursor: pointer;
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
      .search-input-box {
        position: relative;
        input {
          width: 100%;
        }
      }
      .g-s-c {
        width: calc(100% - 20px);
        max-height: calc(100vh - 130px);
      }
    }
  }
  @media screen and (max-width: 575px) {
    .g-s-p {
      .g-s-c {
        // width: 200px;
      }
      .g-s {
        width: 50px;
      }
    }
    .header-logo {
      .mobileMenu {
        padding-top: 60px;
        .hamburger {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          padding: 7px;
        }
        svg {
          flex: 0 0 180px;
        }
      }
    }
    .loginBlock {
    }
    .header-logo.open-hamburger {
      width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    .header-logo .mobileMenu {
      // padding-top: 40px;
      svg {
        flex: 0 0 150px;
      }
    }
    .loginBlock {
      button {
        display: none;
        width: 35px;
        height: 40px;
        svg {
          min-width: 15px;
        }
      }
      .profileBlock {
        .profileMenu {
          // height:35px;
          &:first-child div:first-child {
            // height:35px
          }
        }
        p {
        }
      }
    }
  }

  @media screen and (max-width: 430px) {
    .header-logo {
      .mobileMenu {
        padding-left: 10px;
        .hamburger {
        }
        svg {
          flex: 0 0 150px;
        }
      }
    }
    .loginBlock {
      button {
        display: none;
        width: 25px svg {
          min-width: 15px;
        }
      }
      .profileBlock {
        font-size: 12px;
        .profileMenu {
        }
        p {
          // font-size:12px;
        }
      }
    }
  }

  @media screen and (max-width: 380px) {
    .header-logo .mobileMenu svg {
      flex: 0 0 120px;
    }
    .profileBlock {
    }
  }

  .navbarSales {
    font-family: BentonSans;
    font-size: 12px;
    line-height: 22px;
  }
`;
