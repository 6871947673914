export enum EPagePath {
  // General
  Main = "/",
  NotFound = "/404",
  Authors = "/authors",
  Search = "/search",
  FAQ = "/faq",
  PrivacyPolicy = "/privacy-policy",
  TermsOfService = "/terms-of-service",
  ConditionsOfBusiness = "/conditions-of-business",
  GuideToBuying = "/guide-to-buying",
  DoNotSellData = "/do-not-sell-or-share-my-personal-information-for-targeted-advertising",
  onBoarding = "/onboarding",
  onBoardingCompleted = "/onboarding/completed",

  //Profile
  Profile = "/profile",
  ProfileWallet = "/profile/secondary-wallet",
  ProfileSaved = "/profile/saved",
  ProfileActivity = "/profile/activity",
  ProfileInvoices = "/profile/invoices",
  ProfileSettings = "/profile/settings",
  ProfileListings = "/profile/listings",
  ProfileLogout = "/profile/logout",

  // Auctions Specific
  Sostento = "/sostento",

  // NativelyDigital
  NativelyDigital = "/natively-digital",
  NativelyDigitalLots = "/natively-digital/lots",
  NativelyDigitalLot = "/natively-digital/lot/[slug]",

  // ChromieSquiggles
  ChromieSquiggles = "/chromie-squiggle-mint-it",
  ChromieSquigglesBidding = "/chromie-squiggle-mint-it/bidding",

  // Hackatao
  QueensAndKings = "/hackatao-queens-and-kings",
  QueensAndKingsFaq = "/hackatao-queens-and-kings/faq",

  // LFC Heroes
  LFCHeroesLimitedEditions = "/lfc",
  LFCHeroesLots = "/lfc/lots",
  LFCHeroes = "/lfc/overview",
  LFCHeroesAuction = "/lfc/auction",
  LFCHeroesFaq = "/lfc/faq",
  LFCHeroesClaimables = "/lfc/claimables",

  // Tomokazu Matsuyama: Harmless Charm
  MHC = "/matsuyama-harmless-charm",
  MHCLot = "/matsuyama-harmless-charm/lot/[slug]",

  // Salgado Collection
  Salgado = "/salgado",

  // Snowcrash Collection
  Snowcrash = "/snow-crash",

  // Aegirls Collection
  Aegirls = "/aegirls",

  // Sales
  Sales = "/sales",
}

export enum EPageTitle {
  // General
  Main = "",
  NotFound = "404",
  Authors = "Authors",
  Search = "Search",
  FAQ = "Faq",
  PrivacyPolicy = "Privacy Policy",
  TermsOfService = "Terms Of Service",
  ConditionsOfBusiness = "Conditions Of Business",
  onBoarding = "Onboarding",
  onBoardingCompleted = "Onboarding",

  //Profile
  Profile = "Profile",
  ProfileWallet = "secondary-wallet",
  ProfileActivity = "Activity",
  ProfileInvoices = "Invoices",
  ProfileSettings = "Settings",
  ProfileSupport = "Support",
  ProfileListings = "listings",
  ProfileLogout = "logout",

  // Auctions Specific
  Sostento = "Sostento",

  // NativelyDigital
  NativelyDigital = "Natively Digital",
  NativelyDigitalLots = "Natively Digital",
  NativelyDigitalLot = "Natively Digital",

  // ChromieSquiggles
  ChromieSquiggles = "Chromie Squiggle: Mint It",
  ChromieSquigglesBidding = "Chromie Squiggle: Mint It",

  // Hackatao
  QueensAndKings = "Hackatao Queens+Kings",
  QueensAndKingsBuyNow = "Hackatao Queens+Kings",

  // LFC Heroes
  LFCHeroes = "Lfc Heroes",
  LFCHeroesAuction = "Lfc Heroes Auction",
  LFCHeroesOpenEditions = "Lfc Heroes Open Editions",
  LFCHeroesFaq = "Lfc Heroes Faq",
}
