import React from "react";
import styled from "styled-components";

interface IWrapperProps {
  xs?: {
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
}
interface IProps extends IWrapperProps, React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const ItemGrid = styled(({ children, ...props }: IProps) => {
  return <Wrapper {...props}>{children}</Wrapper>;
})``;

const Wrapper = styled.div<IWrapperProps>`
  display: inline-grid;
  grid-template-rows: auto;
  justify-content: space-between;

  column-gap: 36px;
  row-gap: 90px;

  overflow: hidden;

  width: 100%;
  padding: 30px 0 0;

  grid-template-columns: repeat(1, 1fr);

  //TODO: we can use EViewSize instead of breakpoints
  //phone
  @media (min-width: 480px) {
    grid-template-columns: ${({ xs }) => `repeat(${xs?.sm || 2}, 1fr)`};
  }

  // tablet
  @media (min-width: 880px) {
    grid-template-columns: ${({ xs }) => `repeat(${xs?.md || 3}, 1fr)`};
  }

  // desktop
  @media (min-width: 1150px) {
    grid-template-columns: ${({ xs }) => `repeat(${xs?.lg || 4}, 1fr)`};
  }

  // large
  @media (min-width: 1400px) {
    grid-template-columns: ${({ xs }) => `repeat(${xs?.xl || 5}, 1fr)`};
  }

  // extra large
  @media (min-width: 1600px) {
    grid-template-columns: ${({ xs }) => `repeat(${xs?.xxl || 6}, 1fr)`};
  }

  @media (max-width: 480px) {
    row-gap: 30px;
    column-gap: 0;
  }
`;
