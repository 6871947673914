import styled from "styled-components";
import classNames from "clsx";
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import Link from "next/link";
import SVG from "react-inlinesvg";
import { useRouter } from "next/router";

export interface PaginationState {
  itemsTotal: number;
  itemsPerPage: number;
  currentPage: number;
}

export interface PaginationControls {
  prevPage?: number;
  nextPage?: number;
  pagesIndexes: number[];
}

export interface UsePaginationReturn extends PaginationState {
  goPrev: () => void;
  goNext: (totalItems?: number) => void;
  goTo: (page: number, totalItems?: number) => void;
  getPaginationControls: (totalItems?: number) => PaginationControls;
}

export function usePagination(
  initialState?: Partial<PaginationState>
): UsePaginationReturn {
  const [{ itemsTotal, itemsPerPage, currentPage }, setPaginationState] =
    useState<PaginationState>({
      itemsTotal: initialState?.itemsTotal ?? 0,
      itemsPerPage: initialState?.itemsPerPage ?? 4,
      currentPage: initialState?.currentPage ?? 1,
    });

  const goPrev = useCallback(() => {
    setPaginationState((prevPaginationState) => {
      const nextCurrentPage = Math.max(prevPaginationState.currentPage - 1, 1);

      return { ...prevPaginationState, currentPage: nextCurrentPage };
    });
  }, []);

  const goNext = useCallback(
    (totalItems?: number) => {
      const lastPage = totalItems ? Math.ceil(totalItems / itemsPerPage) : 100;

      setPaginationState((prevPaginationState) => {
        const nextCurrentPage = Math.min(
          prevPaginationState.currentPage + 1,
          lastPage
        );

        return { ...prevPaginationState, currentPage: nextCurrentPage };
      });
    },
    [itemsPerPage]
  );

  const goTo = useCallback(
    (page: number, totalItems?: number) => {
      const lastPage = totalItems ? Math.ceil(totalItems / itemsPerPage) : 100;

      setPaginationState((prevPaginationState) => ({
        ...prevPaginationState,
        currentPage: Math.min(Math.max(1, page), lastPage),
      }));
    },
    [itemsPerPage]
  );

  const getPaginationControls = useCallback(
    (totalItems?: number): PaginationControls => {
      const lastPage = totalItems ? Math.ceil(totalItems / itemsPerPage) : 100;

      let pagesIndexes = [];

      if (lastPage <= 7) {
        // Prevent UI scenarios like this:
        // [1, 2, ..., 4, 5] =>  [1, 2, 3, 4, 5]
        // [1, 2, 3, ..., 5, 6] => [1, 2, 3, 4, 5, 6]
        // [1, 2, ..., 4, 5, 6] => [1, 2, 3, 4, 5, 6]
        // [1, 2, ..., 4, ..., 6, 7] => [1, 2, 3, 4, 5, 6, 7]

        pagesIndexes = [1, 2, 3, 4, 5, 6, 7].slice(0, lastPage);
      } else {
        pagesIndexes = [1, 2];

        if (!pagesIndexes.includes(currentPage)) {
          pagesIndexes.push(currentPage);
        }

        if (!pagesIndexes.includes(lastPage - 1)) {
          pagesIndexes.push(lastPage - 1);
        }

        if (!pagesIndexes.includes(lastPage)) {
          pagesIndexes.push(lastPage);
        }

        let toAdd = 7 - pagesIndexes.length;

        if (currentPage > 3 && currentPage < lastPage - 2) toAdd -= 2;
        else if (currentPage <= 3 || currentPage >= lastPage - 2) --toAdd;

        for (
          let followingPage = currentPage + 1;
          toAdd > 0 && followingPage < lastPage - 1;
          ++followingPage
        ) {
          if (!pagesIndexes.includes(followingPage)) {
            pagesIndexes.push(followingPage);
            --toAdd;
          }
        }

        for (
          let precedingPage = currentPage - 1;
          toAdd > 0 && precedingPage > 2;
          --precedingPage
        ) {
          if (!pagesIndexes.includes(precedingPage)) {
            pagesIndexes.push(precedingPage);
            --toAdd;
          }
        }
      }

      pagesIndexes.sort((a, b) => a - b);

      return {
        prevPage:
          currentPage > 1 ? Math.min(currentPage - 1, lastPage) : undefined,
        nextPage: currentPage < lastPage ? currentPage + 1 : undefined,
        pagesIndexes,
      };
    },
    [currentPage, itemsPerPage]
  );

  return {
    itemsTotal,
    itemsPerPage,
    currentPage,
    goPrev,
    goNext,
    goTo,
    getPaginationControls,
  };
}

export const PaginationButton = styled(
  ({
    className,
    href,
    onClick,
    disabled,
    next,
  }: {
    className?: string;
    href: string;
    onClick: React.MouseEventHandler<HTMLElement>;
    disabled?: boolean;
    next?: boolean;
  }) => {
    const iconElement = (
      <SVG
        className="svg"
        width={16}
        color="#424242"
        src={`/icons/arrow-${next ? "right" : "left"}.svg`}
      />
    );

    return disabled ? (
      <span className={classNames(className, "disabled")}>{iconElement}</span>
    ) : (
      <Link href={href}>
        <a className={className} onClick={onClick}>
          {iconElement}
        </a>
      </Link>
    );
  }
)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid #bdbdbd;
  border-radius: 128px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    border: 1px solid #ffffff;
  }

  & > .svg {
    pointer-events: none;
  }
`;

export const PaginationIndex = styled(
  ({
    className,
    href,
    onClick,
    children,
    current,
  }: PropsWithChildren<{
    className?: string;
    href: string;
    onClick: React.MouseEventHandler<HTMLElement>;
    current?: boolean;
  }>) => {
    return (
      <Link href={href}>
        <a
          className={classNames(className, { current: current })}
          onClick={onClick}>
          {children}
        </a>
      </Link>
    );
  }
)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  min-width: 28px;
  cursor: pointer;
  color: #424242;

  &.current {
    font-weight: bold;
    color: #fafafa;
  }

  &:not(.current):hover {
    color: #ffffff;
  }
`;

export interface PaginationProps extends PaginationControls {
  className?: string;
  pathname: string;
  currentPage: number;
  goTo: (page: number, totalItems?: number) => void;
}

export const Pagination = styled(
  ({
    className,
    pathname,
    currentPage,
    goTo,
    prevPage,
    nextPage,
    pagesIndexes,
  }: PaginationProps): JSX.Element => {
    const router = useRouter();

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const href = (e.currentTarget as unknown as HTMLAnchorElement)
          .href as string;
        const hrefParts = href.split("/").filter(Boolean);
        const page = parseInt(hrefParts[hrefParts.length - 1], 10) || 1;

        if (page) {
          // Update pagination index:
          goTo(page);

          // Update URL to match too:
          if (href !== pathname)
            router.push(href, undefined, { shallow: true });

          // TODO: Scroll to top?
        }
      },
      [goTo]
    );

    return (
      <div className={className}>
        <PaginationButton
          className="prevButton"
          href={`${pathname}/${prevPage}`}
          onClick={handleClick}
          disabled={!prevPage}
        />

        {pagesIndexes.map((pageIndex, i) => (
          <Fragment key={pageIndex}>
            <PaginationIndex
              href={`${pathname}/${pageIndex}`}
              onClick={handleClick}
              current={pageIndex === currentPage}>
              {pageIndex}
            </PaginationIndex>

            {i < pagesIndexes.length - 1 &&
              pagesIndexes[i + 1] !== pageIndex + 1 && (
                <PaginationIndex
                  href={`${pathname}/${pageIndex + 1}`}
                  onClick={handleClick}>
                  {pagesIndexes[i + 1] === pageIndex + 2 ? pageIndex + 1 : "…"}
                </PaginationIndex>
              )}
          </Fragment>
        ))}

        <PaginationButton
          className="nextButton"
          href={`${pathname}/${nextPage}`}
          onClick={handleClick}
          disabled={!nextPage}
          next
        />
      </div>
    );
  }
)`
  display: flex;
  justify-content: center;
  user-select: none;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  & > .prevButton {
    margin-right: 8px;
  }

  & > .nextButton {
    margin-left: 8px;
  }
`;
