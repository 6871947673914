/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from './client'
import { IBlockTraitsFields } from '@metaverse/types'

export const getTraits = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<IBlockTraitsFields>({
    content_type: 'blockTraits',
    ...query,
  })

  return response
}