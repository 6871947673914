import styled from "styled-components";

export const AuctionGridItemImageWrapper = styled.div<{
  $text: string | null;
  $lotPreviewBackgroundColor?: string;
}>`
  position: relative;
  height: 171px;
  width: 100%;
  margin-top: 6px;
  border-radius: 5px;
  overflow: hidden;
  background: ${({ $lotPreviewBackgroundColor }) =>
    $lotPreviewBackgroundColor || "transparent"};

  &:before {
    ${({ $text }) => ($text ? `content: "${$text}"` : "")};
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-white);
    font-size: 24px;
  }
`;
