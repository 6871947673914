import { BaseButton } from "@components";
import Link from "next/link";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { EViewSize } from "@enums";
import SVG from "react-inlinesvg";
import { Box, ClickAwayListener, Popper } from "@material-ui/core";

const Info = styled.span`
  font-family: "BentonSans";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.0025em;
  background: rgba(85, 224, 255, 0.4);
  border-radius: 2px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: context-menu;

  svg {
    margin-left: 6px;
  }
`;

const WalletAddress = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-family: "BentonSans";
  font-size: 12px;
  line-height: 14px;
`;

const ItemWithInfo = styled.div`
  position: relative;
  z-index: 1;

  @media (max-width: ${EViewSize.mobile}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PopperBox = styled((props) => <Box {...props} />)`
  background: #292929;
  width: 246px;
  padding: 8px;
  color: var(--text-color);
  border-radius: 5px;
  font-family: "BentonSans", serif;
  font-size: 12px;
  line-height: 16px;
  margin: 10px 0;

  a {
    text-decoration: underline;
  }
`;

interface IWallet {
  walletAddress: string;
  condensed?: boolean;
}

export const WalletAddressContainer = styled(
  ({ walletAddress, condensed = false, ...props }: IWallet) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const smallWalletAddress =
      walletAddress.slice(0, 11) + "..." + walletAddress.slice(-3);
    const [copyBtnText, setCopyBtnText] = useState(
      condensed ? smallWalletAddress : "Copy Wallet Address"
    );

    const handleCopyClick = (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      navigator.clipboard.writeText(walletAddress);
      setCopyBtnText("Copied");
      setTimeout(() => {
        setCopyBtnText(condensed ? smallWalletAddress : "Copy Wallet Address");
      }, 3000);
    };

    return (
      <div {...props}>
        {!condensed && (
          <ItemWithInfo>
            <WalletAddress>{smallWalletAddress}</WalletAddress>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <Info
                aria-describedby="multisig-popper"
                onMouseEnter={(e: any) => setAnchorEl(e.currentTarget)}>
                Multisig
                <SVG
                  width={18}
                  className="multisig-exclamation-icon"
                  src="/icons/info.svg"
                />
                <Popper
                  id="multisig-popper"
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  disablePortal
                  placement="top-start">
                  <PopperBox>
                    This was used to deliver a marketplace purchase. A multisig
                    wallet requires two or more private keys to sign in order to
                    complete a transaction, <Link href="/faq">see our FAQ</Link>
                    .
                  </PopperBox>
                </Popper>
              </Info>
            </ClickAwayListener>
          </ItemWithInfo>
        )}
        <div className="item-with-btn">
          <BaseButton
            className="copy-button"
            onClick={(e) => handleCopyClick(e)}>
            <SVG
              className="copy-icon"
              width={13}
              src="/icons/wallet-copy.svg"
            />
            <span className="copy-text">{copyBtnText}</span>
          </BaseButton>
        </div>
      </div>
    );
  }
)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .multisig-exclamation-icon {
    margin-left: 3px;
  }

  @media (max-width: ${EViewSize.mobile}) {
    display: block;

    .item-with-btn {
      display: flex;
      justify-content: center;
    }
  }

  .copy-button {
    ${(props) => {
      if (props.condensed) {
        return css`
          background: linear-gradient(
            301.04deg,
            rgba(85, 255, 188, 0.15) -66.81%,
            rgba(186, 113, 219, 0.15) 90.61%
          ) !important;
          height: 24px;
          flex-direction: row-reverse;
          border-radius: 4px;
          margin-left: 10px;
          .copy-text {
            margin-right: 6px;
            font-size: 10px;
            line-height: 10px;
          }
          .copy-icon > path {
            fill: #a6a6a6;
          }
        `;
      } else {
        return css`
          background: rgba(240, 240, 240, 0.03) !important;
          border: 2px solid rgba(255, 255, 255, 0.06);
          .copy-icon {
            margin-right: 6px;
          }
          @media screen and (max-width: ${EViewSize.mobile}) {
            margin-top: 20px;
          }
        `;
      }
    }}
  }
`;
