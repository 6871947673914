import { EViewSize } from "@enums";
import React from "react";
import styled from "styled-components";
import { IImage, Image } from "./image";

const Note = styled.div`
  font-family: "MercuryDisplay", serif;
  text-align: center;
  font-size: 24px;
  color: black;

  @media screen and (max-width: ${EViewSize.mobile}) {
    font-size: 20px;
  }
`;

const Author = styled.div`
  font-family: "BentonSans";
  line-height: 17px;
  margin-top: 30px;
  color: black;

  @media screen and (max-width: ${EViewSize.mobile}) {
    font-size: 14px;
    margin-top: 20px;
  }
`;

export const GeneralBanner = styled(
  ({
    note,
    author,
    bgImage,
    ...props
  }: {
    bgImage: IImage & { alt: string };
    note: string;
    author: string;
  }) => {
    return (
      <div {...props}>
        <Image
          {...bgImage}
          alt={bgImage.alt}
          layout="fill"
          objectFit="cover"
          showLoader={false}
        />
        <Note>{note}</Note>
        <Author>{author}</Author>
      </div>
    );
  }
)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex: 0 0 ${({ bgImage }) => (bgImage.height ? bgImage.height : "300px")};
    position: relative;
    padding: 0 16%;
    border-radius: 5px;
    overflow: hidden;

    ${Image} {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
    }

    @media screen and (max-width: ${EViewSize.mobile}) {
      width: 100vw;
      margin: 0 -16px;
      border-radius: 0px;
    }
  }
`;
