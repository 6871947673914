import { IContentfulImageItem } from "@interfaces";
import { useOverlayContext } from "@state-context/overlay.context";
import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { IconButton } from "../../button";
import { ImageMemo } from "../../imageMemo";

const ThumbnailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

const ThumbnailsList = styled.div`
  /* background: rgba(240, 240, 240, 0.2); */
  /* padding: 8px 4px; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ThumbnailNav = styled((props) => <IconButton {...props} />)<{
  $direction: "up" | "down";
}>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);

  ${({ $direction }) => {
    switch ($direction) {
      case "up":
        return "margin-bottom: 10px;";
      case "down":
        return "margin-top: 10px;";
      default:
        return "";
    }
  }}

  svg {
    width: 14px;
  }
`;

const ThumbnailPreview = styled.div`
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 4px;
  opacity: 0.4;
  cursor: pointer;
  transition: transform 0.3s linear, opacity 0.3s linear;
  border: 2px solid var(--icon-btn-border-color);
  transform: scale(0.9);

  &:first-child {
    margin-top: 0;
  }

  &.active {
    transform: scale(1.05);
    opacity: 1;
    border-color: #fff;
  }
`;

interface IPreviewContentItemsNavigations {
  contentItems: IContentfulImageItem[];
  paginate: (current: number) => void;
  activeIndex: number;
  setActive: ([index, direction]: [number, number]) => void;
}

export const PreviewContentItemsNavigations = styled(
  ({
    paginate,
    activeIndex,
    setActive,
    contentItems,
    ...props
  }: IPreviewContentItemsNavigations) => {
    const { state: overlayState } = useOverlayContext();

    return !overlayState.child && contentItems.length > 1 ? (
      <ThumbnailWrapper {...props}>
        <ThumbnailsList>
          <ThumbnailNav
            key="nav-up"
            onClick={() => paginate(-1)}
            $direction="up">
            <SVG src="/icons/up.svg/" />
          </ThumbnailNav>
          {contentItems.map((item: IContentfulImageItem, index) => (
            <ThumbnailPreview
              className={activeIndex === index ? "active" : ""}
              key={item.title + index}
              onClick={() => setActive([index, index > activeIndex ? 1 : -1])}>
              <ImageMemo
                src={item.url}
                alt={item.title}
                objectFit={"cover"}
                size={40}
                quality={75}
              />
            </ThumbnailPreview>
          ))}
          <ThumbnailNav
            key="nav-down"
            onClick={() => paginate(1)}
            $direction="down">
            <SVG src="/icons/down.svg/" />
          </ThumbnailNav>
        </ThumbnailsList>
      </ThumbnailWrapper>
    ) : null;
  }
)``;
