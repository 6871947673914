import { useDeviceResponsive } from "@hooks";
import { Skeleton } from "@material-ui/core";
import Image, { ImageProps } from "next/image";
import React, { memo, useState } from "react";
import styled from "styled-components";

export interface IImageMemo extends ImageProps {
  src: string;
  alt: string;
  quality?: number;
  size?: number;
}

export const ImageMemo = styled(
  memo(
    ({ src, alt, quality, size, objectFit, layout, ...props }: IImageMemo) => {
      const [loaded, setLoaded] = useState(false);
      const { isMobile } = useDeviceResponsive();
      if (!src || !alt) return null;

      const dimensions =
        layout !== "fill"
          ? {
              height: size || 522,
              width: size || 1000,
            }
          : null;

      return (
        <>
          <Image
            src={src}
            alt={alt}
            objectFit={objectFit || "contain"}
            layout={layout || "responsive"}
            quality={quality ?? 75}
            priority={true}
            draggable="false"
            {...props}
            {...dimensions}
            onLoad={() => setLoaded(true)}
          />
          <Skeleton
            animation={isMobile ? false : "wave"}
            variant="rectangular"
            width="100%"
            height={170}
            sx={{
              display: loaded ? "none" : "block",
              position: "absolute",
              bgcolor: "var(--skeleton-color)",
            }}
          />
        </>
      );
    }
  )
)`
  * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    user-drag: none;
  }
`;
