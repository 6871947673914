import { EViewSize } from "@enums";
import { useCollection } from "@hooks";
import { Grid } from "@material-ui/core";
import { FC, useContext } from "react";
import styled from "styled-components";
import { SmartContractAddress } from "../../smartContractAddress";
import { CollectionItemContext } from "../state/auctionItemContext";

const TitleStyled = styled.span`
  line-height: 30px;
`;

const SmartAddressColumn = styled((props) => <Grid {...props} />)`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const CardHeader: FC = styled((props) => {
  const { item } = useContext(CollectionItemContext);
  const { collection } = useCollection();
  const lotData = item.contentfulData;

  return (
    <Grid container {...props}>
      <Grid item xs={6}>
        <TitleStyled>
          {collection?.name} / Lot {lotData.lotId}
        </TitleStyled>
      </Grid>
      <SmartAddressColumn item xs={6}>
        <SmartContractAddress
          address={lotData.smartContractAddress}
          token={lotData.tokenId}
        />
      </SmartAddressColumn>
    </Grid>
  );
})`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;

  @media screen and (max-width: ${EViewSize.tablet}) {
    margin-bottom: 5px;
  }
`;
