import { EViewSize } from "@enums";
import styled from "styled-components";

export const ParagraphSpacer = styled.div`
  width: 100%;
  height: 130px;
  flex: 0 0 130px;

  @media screen and (max-width: ${EViewSize.large}) {
    height: 100px;
    flex: 0 0 100px;
  }

  @media screen and (max-width: ${EViewSize.desktop}) {
    height: 70px;
    flex: 0 0 70px;
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    height: 60px;
    flex: 0 0 60px;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    height: 50px;
    flex: 0 0 50px;
  }
`;
