import React from "react";

// INIT ACTION KEYS
export enum EOnboardingContextActionTypes {
  isStarted = "started",
  navigate = "navigate",
}

// INIT ACTION PROPS
interface IAction {
  type: EOnboardingContextActionTypes;
  page?: "create" | "confirm" | "welcome";
  alias?: string;
  avatarKey?: string;
}
// INIT STATE TYPE
interface State {
  isStarted: boolean;
  page: "create" | "confirm" | "welcome";
  alias: string;
  avatarKey: string;
}

// INIT STATE
const initialState: State = {
  isStarted: false,
  page: "create",
  alias: "",
  avatarKey: "",
};

function Reducer(state: State, action: IAction) {
  switch (action.type) {
    case EOnboardingContextActionTypes.isStarted: {
      return {
        ...state,
        isStarted: true,
      };
    }
    case EOnboardingContextActionTypes.navigate:
      {
        return {
          ...state,
          page: action.page ?? state.page,
          alias: action.alias ?? state.alias,
          avatarKey: action.avatarKey ?? state.avatarKey,
        };
      }
      break;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const OnboardingProvider = Provider;
export const useOnboardingContext = useContext;

// ======================================================== //
// ================== IGNORE ALL BELOW ==================== //
// ======================================================== //

type IDispatch = (action: IAction) => void;

const StateContext = React.createContext<{
  state?: State;
  dispatch?: IDispatch;
}>({
  state: undefined,
  dispatch: undefined,
});

function Provider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = React.useReducer<React.Reducer<State, IAction>>(
    Reducer,
    initialState
  );

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
}

function useContext(): {
  state: State;
  dispatch: IDispatch;
} {
  const context = React.useContext(StateContext);
  if (context.dispatch === undefined || context.state === undefined) {
    throw new Error("useContext must be used within a Provider");
  }
  return {
    state: context.state,
    dispatch: context.dispatch,
  };
}
