import { EPagePath, EViewSize } from "@enums";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import Link from "next/link";
import styled from "styled-components";

export const ModalFooterInfo = styled(({ children, ...props }) => {
  const { dispatch } = useOverlayContext();

  const closeOverlay = () => {
    dispatch({
      type: EOverlayContextActionTypes.hide,
    });
  };

  return (
    <div {...props}>
      {children}{" "}
      <Link href={EPagePath.PrivacyPolicy} passHref>
        <a onClick={closeOverlay}>Privacy Policies</a>
      </Link>{" "}
      and{" "}
      <Link href={EPagePath.TermsOfService} passHref>
        <a onClick={closeOverlay}>Terms & Conditions</a>
      </Link>
    </div>
  );
})`
  padding: 16px 36px 0;
  border-top: 1px solid #ddd;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-top: 20px;
    padding-right: 0;
    padding-left: 0;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }
`;
