import {
  BidMethod,
  bidPlaced,
  BidResult,
  bidStarted,
  checkoutStepCompleted,
  ContentType,
  DepartmentCode,
  errorOccurred,
  exitLinkClicked,
  videoPlaybackStarted,
  itemSaved,
  ItemType,
  itemUnsaved,
  orderCompleted,
  termsAccepted,
  SaleType,
  CheckoutType,
} from "@analytics";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@constants";
import { EEventNames } from "@enums";
import { useCollection, usePageType, useProfile } from "@hooks";
import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import * as Sentry from "@sentry/react";
import { useCallback } from "react";

const PAYMENT_METHODS: any = {
  CreditCard: "credit card",
  ACH: "ach",
  Wire: "wire",
  Crypto: "crypto",
};

export const useTrackingEventFactory = (): ((
  name: string,
  options?: any
) => void) => {
  const { getIdTokenClaims } = useAuth0();
  const { _path } = usePageType();
  const { profile } = useProfile();
  const { slug, collection } = useCollection();
  const mojitoItem = collection?.items?.find(
    (item) => item?.contentfulData?.slug === _path[3]
  ) as IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
  const lot = mojitoItem?.contentfulData;
  const auth0_id = profile?.userOrgs[0]?.externalUserId || "";

  const trackEvent = useCallback(async (name: string, options?: any) => {
    const claims = await getIdTokenClaims();
    const partyId = claims ? claims[config.APP_METADATA_URL]?.customerData?.partyId : null;

    const { productId, saleName } = (['lfc', 'salgado', 'aegirls', 'snow-crash', 'sales'].includes(slug))
      ? { productId: `${collection?.contentfulData?.saleId}${(options?.lotId ?? lot?.lotId)?.toString().padStart(4, "0")}`, saleName: collection?.contentfulData?.title || slug }
      : { productId: "", saleName: "" };

    try {
      switch (name) {
        case EEventNames.TermsAccepted: {
          termsAccepted({
            auth0_id,
            content_type: ContentType.Auctions,
            customer_id: partyId,
            sale_id: collection?.contentfulData?.saleId ?? "",
            sale_name: saleName,
            sale_uuid: "",
            terms: options.terms,
            in_app: false,
          });
          break;
        }
        case EEventNames.BidPlaced: {
          bidPlaced({
            auth0_id,
            bid_amount: options?.amount,
            bid_currency: "USD",
            bid_method: BidMethod.LiveOnline,
            content_type: ContentType.Auctions,
            low_estimate: options?.currentBids?.details?.currentBid?.amount,
            bid_result:
              options?.createMarketplaceAuctionBid.userId === profile?.id
                ? BidResult.BidIsWithYou
                : BidResult.BidTooLow,
            customer_id: partyId,
            department_category: "NFT",
            product_id: productId,
            product_name: options?.slug ?? "",
            product_uuid: options?.mojitoId ?? "",
            sale_id: collection?.contentfulData?.saleId ?? "",
            sale_name: saleName,
            in_app: true,
            sale_uuid: options?.mojitoId ?? "",
          });
          break;
        }
        case EEventNames.ItemSaved:
        case EEventNames.ItemUnsaved: {
          const properties = {
            auth0_id,
            item_type: ItemType.Lot,
            customer_id: partyId,
            product_id: productId,
            sale_id: collection?.contentfulData?.saleId ?? "",
            sale_name: saleName,
            sale_type: SaleType.Nft,
            product_name: lot?.slug ?? "",
            department_category: "NFT",
            in_app: false,
            content_type: ContentType.Auctions,
            sale_uuid: options?.mojitoId
          };
          if (name === EEventNames.ItemSaved) {
            itemSaved(properties);
          } else {
            itemUnsaved(properties);
          }
          break;
        }
        case EEventNames.BidStarted: {
          bidStarted({
            auth0_id,
            bid_amount: options?.amount,
            bid_currency: "USD",
            bid_method: BidMethod.LiveOnline,
            bid_result:
              options?.createMarketplaceAuctionBid.userId === profile?.id
                ? BidResult.BidIsWithYou
                : BidResult.BidTooLow,
            content_type: ContentType.Auctions,
            department_code: DepartmentCode.Enft,
            customer_id: partyId,
            department_category: "NFT",
            product_id: productId,
            product_name: lot?.slug ?? "",
            product_uuid: lot?.mojitoId ?? "",
            sale_id: collection?.contentfulData?.saleId ?? "",
            sale_uuid: collection?.contentfulData?.saleId ?? "",
            sale_name: saleName,
            in_app: false
          });
          break;
        }
        case EEventNames.ErrorOccurred: {
          errorOccurred({
            auth0_id,
            content_type: ContentType.Auctions,
            customer_id: partyId,
            error_msg: options.message,
            error_code: '404',
            error_description: options.message,
            in_app: false,
            sale_type: SaleType.Lot,
            sale_id: collection?.contentfulData?.saleId,
            sale_name: saleName,

          });
          break;
        }
        case EEventNames.CheckoutStepCompleted: {
          checkoutStepCompleted({
            auth0_id,
            cart_id: collection?.contentfulData?.saleId ?? "",
            num_products: options.total,
            content_type: ContentType.Auctions,
            sale_name: saleName,
            in_app: false,
            department_code: DepartmentCode.Enft,
            checkout_type: CheckoutType.Metaverse,
            customer_id: partyId,
            department_category: options.departmentCategory,
            payment_method: PAYMENT_METHODS[options.paymentType],
            revenue: options.revenue,
            shipping_method: options.shippingMethod,
            step: options.step,
            step_name: options.stepName,
            sale_id: collection?.contentfulData?.saleId ?? "",
            products: options.checkoutItems.map(
              ({
                name,
                unitPrice,
                lotID,
                units,
              }: {
                name: string;
                unitPrice: number;
                lotID: string;
                units: number;
              }) => ({
                name,
                price: unitPrice,
                product_id: lotID,
                quantity: units,
                sale_id: collection?.contentfulData?.saleId,
              })
            ),
          });
          break;
        }
        case EEventNames.OrderCompleted: {
          orderCompleted({
            auth0_id,
            cart_id: collection?.contentfulData?.saleId ?? "",
            checkout_type: CheckoutType.Metaverse,
            content_type: ContentType.Auctions,
            currency: options.currency,
            customer_id: partyId,
            department_category: options.departmentCategory,
            department_code: DepartmentCode.Enft,
            fees: options.fees,
            order_id: options.paymentID,
            departments: [],
            payment_method: PAYMENT_METHODS[options.paymentType],
            num_products: options.checkoutItems.length,
            products: options.checkoutItems.map(
              ({
                name,
                unitPrice,
                lotID,
                units,
              }: {
                name: string;
                unitPrice: number;
                lotID: string;
                units: number;
              }) => ({
                name,
                price: unitPrice,
                product_id: lotID,
                quantity: units,
                sale_id: collection?.contentfulData?.saleId,
              })
            ),
            sale_name: saleName,
            revenue: options.revenue,
            shipping: options.shippingMethod,
            tax: options.tax,
            total: options.total,
            sale_id: collection?.contentfulData?.saleId ?? ''
          });
          break;
        }
        case EEventNames.ExitLink: {
          exitLinkClicked({
            auth0_id,
            customer_id: partyId,
            external_link: options.link,
          });
          break;
        }
        case EEventNames.PlayVideo: {
          videoPlaybackStarted({
            auth0_id,
            customer_id: partyId,
            video_title: options.videoTitle,
          });
          break;
        }
        default:
          break;
      }
    } catch (err) {
      Sentry.captureMessage(
        `Error sending event: ${(err as Error)?.message || ""}`
      );
    }

  }, [profile, auth0_id, slug, collection, lot]);

  return trackEvent;
};
