import { FormControl, Input } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import SVG from "react-inlinesvg";
import styled from "styled-components";

interface IFormInput {
  name: string;
  control: any;
  placeholder: string;
  validationOnly?: boolean;
  error?: string;
  autofocus?: boolean;
  onChange?: (...event: any[]) => void;
}

export const InputStyled = styled(Input)<{ $error?: boolean }>`
  border: 1px solid
    ${({ $error }) =>
      $error ? "var(--input-border-error-color)" : "var(--input-border-color)"};
  border-radius: var(--input-border-radius);
  padding: 0 10px;
  height: 40px;

  input {
    text-align: center;
  }

  &:before,
  &:after {
    display: none;
  }
`;

export const FormInput = styled(
  ({
    name,
    control,
    placeholder,
    error,
    autofocus = false,
    validationOnly = false,
    ...props
  }: IFormInput) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl {...props} variant="standard">
            <InputStyled
              autoFocus={autofocus}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              $error={!!error}
            />
            {value && validationOnly ? (
              error ? (
                <SVG src="/icons/close.svg" className="validation-icon" />
              ) : (
                <SVG src="/icons/tick.svg" className="validation-icon" />
              )
            ) : null}
            {error && !validationOnly && (
              <div className="error-message">{error}</div>
            )}
          </FormControl>
        )}
      />
    );
  }
)`
  .validation-icon {
    position: absolute;
    top: 12.5px;
    right: 12px;
    height: 15px;
    fill: ${({ error }) => (error ? "var(--text-error)" : "var(--text-dark)")};
  }

  .error-message {
    position: absolute;
    bottom: -18px;
    font-size: 12px;
    color: var(--text-error);
  }
`;
