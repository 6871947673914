/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import client from './client'
import { IPageContentFields } from '@metaverse/types'

export const getPageContents = async (query?: Record<string, any> | undefined) => {
  const response = await client.getEntries<IPageContentFields>({
    content_type: 'pageContent',
    ...query,
  })

  return response
}

export const getPageContent = async (
  slug: string,
  query?: Record<string, any> | undefined
) => {
  const response = await getPageContents({
    'fields.slug': slug,
    limit: 1,
    ...query,
  })

  return response?.items?.[0] || null
}


