import { EViewSize } from "@enums";
import { formatDateToUS } from "@utils";
import Link from "next/link";
import styled from "styled-components";

interface ICreatedBy {
  authorName: string;
  authorSlug: string;
  createdDate?: string;
  $uppercase?: boolean;
}

export const CreatedBy = styled(
  ({ authorName, authorSlug, createdDate, ...props }: ICreatedBy) => {
    return authorName ? (
      <div {...props}>
        Created by{" "}
        <Link href={`/authors/${authorSlug}`} passHref>
          <a>{authorName}</a>
        </Link>
        {createdDate && ",  " + formatDateToUS(createdDate)}
      </div>
    ) : null;
  }
)`
  font-size: 12px;
  white-space: normal;

  a {
    font-size: 12px;
    text-decoration: underline;
  }

  ${({ $uppercase }) => $uppercase && "text-transform: uppercase;"}

  @media  screen and (max-width: ${EViewSize.mobile}) {
    margin-bottom: 10px;
  }
`;
