import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SecondaryButton } from "../secondary-wallet/secondaryButton";
import { Box } from "@mui/material";
import { useWeb3 } from "@utils/wallet/connectContext";
import { useGetArtistDetails } from "@metaverse/lib/queries";

interface navbarHeader {
  mobileSidebar?: any;
  setMobileNavbarData?: any;
  setMobileNavSide?: any;
}

interface Artist {
  id: string;
  name: string;
  mint: string;
}

export const Navbar = styled((props: navbarHeader) => {
  const router = useRouter();

  const [openartist] = useState<boolean>(false);
  const [openSales] = useState<boolean>(false);
  const [, setArtistDetail] = useState<Artist[]>([]);
  const { isAuthenticated } = useAuth0();
  const { connect, setConnect } = useWeb3();

  const { data } = useGetArtistDetails(config?.CONTENTFUL_SM_ARTIST_ENTRY_ID);
  const fields = data?.fields;

  useEffect(() => {
    const storedArtists = sessionStorage.getItem("ArtistArray");

    if (!storedArtists) {
      if (fields?.mojito_artist_id?.length) {
        const artistsArray: Artist[] = fields.mojito_artist_id.map(
          (artistId: string | undefined, i: number) => ({
            id: fields.artist_mint_id?.[i] || "",
            name: fields.mojito_artist_name?.[i] || "",
            mint: artistId || "",
          })
        );

        sessionStorage.setItem("ArtistArray", JSON.stringify(artistsArray));
        setArtistDetail(artistsArray);
      }
    } else {
      setArtistDetail(JSON.parse(storedArtists));
    }
  }, [fields]);

  const onClickSearch = () => {
    document.body.className = "search-open";
  };

  const onClickConnectWallet = () => {
    setConnect((prev) => ({
      ...prev,
      openMetaMask: true,
    }));
  };

  const onClickDisconnectWallet = () => {
    setConnect((prev) => ({
      ...prev,
      onDisConnect: true,
    }));
  };

  return (
    <Box {...props}>
      <ul className="menunav">
        {isAuthenticated && !connect?.connected && !connect?.account && (
          <li className="mob-view-show">
            <SecondaryButton
              className="btn-wallet connectWallet"
              onClick={onClickConnectWallet}>
              + Connect Wallet
            </SecondaryButton>
          </li>
        )}
        {isAuthenticated && connect?.connected && connect?.account && (
          <li className="mob-view-show">
            <SecondaryButton
              className="btn-wallet connectWallet"
              buttontype="secondary"
              onClick={onClickDisconnectWallet}>
              Disconnect
            </SecondaryButton>
          </li>
        )}

        <li
          onClick={() => {
            router.push("/sales");
            props.mobileSidebar(false);
          }}
          className={openSales ? "open" : ""}>
          <span className="navLink">Sales</span>
        </li>
        <li
          onClick={() => {
            router.push("/marketplace/artist");
            props.mobileSidebar(false);
          }}
          className={openartist ? "open" : ""}>
          <span className="navLink">Artists</span>
        </li>
        <li>
          <span
            className="navLink"
            onClick={() => {
              router.push("/marketplace/viewall");
              props.mobileSidebar(false);
            }}>
            Market
          </span>
        </li>
        {/* <li>
          <span className="navLink" onClick={() => { router.push("/aboutus"); props.mobileSidebar(false) }}>About Us</span>
        </li> */}
        <li className="show-mobile-view-767">
          <span className="navLink" onClick={onClickSearch}>
            Search
          </span>
        </li>
      </ul>
    </Box>
  );
})`
  .connectWallet {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // padding: 8px 16px;
    // gap: 8px;
    // border-radius: 8px;
  }

  .navbarSales {
    font-family: BentonSans;
    font-size: 12px;
    line-height: 22px;
  }

  .navbarDropdownartist {
    padding: 0px;
    li {
      position: relative;
      border: 0px;
      &::before {
        position: absolute;
        content: "";
        width: calc(100% - 20px);
        height: 1px;
        bottom: 0px;
        left: 10px;
        background-color: var(--light-black);
      }
    }
  }
`;
