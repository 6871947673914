import {
  useCollectionItemBidsList,
  useCollectionItemCurrentBids,
  useMojitoMutation,
  useTrackingEventFactory,
} from "@hooks";
import { IContentfulLotData } from "@interfaces";
import { EMojitoMutations } from "@state";
import { useCallback } from "react";
import { EEventNames } from "@enums";

export function usePlaceBidMutation<T = any>(
  lotData: IContentfulLotData
): ReturnType<typeof useMojitoMutation> {
  const { bidsRefetch } = useCollectionItemBidsList(lotData.mojitoId as string);
  const { currentBids } = useCollectionItemCurrentBids(
    lotData.mojitoId as string
  );
  const trackEvent = useTrackingEventFactory();
  const [mutateFunction, mutateStatus] = useMojitoMutation<T>(
    EMojitoMutations.createBid
  );

  const mutateFunctionWithSegment = useCallback(
    (options: Parameters<typeof mutateFunction>[0]) => {
      return mutateFunction(options).then((result) => {
        const { lotId, mojitoId, slug } = lotData;
        if (result) {
          if (currentBids.details.currentBid.isHold) bidsRefetch();
          trackEvent(EEventNames.BidPlaced, {
            ...result,
            ...options,
            lotId,
            mojitoId,
            slug,
            currentBids,
          });
        } else {
          trackEvent(EEventNames.BidError, {
            ...options,
            lotId,
            mojitoId,
            slug,
          });
        }

        return result;
      });
    },
    [
      mutateFunction,
      lotData,
      bidsRefetch,
      currentBids.details.currentBid.isHold,
    ]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [mutateFunctionWithSegment, mutateStatus];
}
