import { RequestDocument, Variables } from "graphql-request/dist/types";
import { GraphQLClient } from "graphql-request";
import { QueryClient } from "react-query";
import isBrowser from "./isBrowser";
import { secondaryEvents } from "../hooks/secondaryTrackingEvent";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: typeof window === "undefined" ? 0 : 180000, // 3min
      cacheTime: Infinity, // disable garbage collection
    },
  },
});

interface IGqlRequestParams {
  query: RequestDocument;
  variables?: Variables;
  normalizerFn?: (data: any, variables?: Variables) => any;
  gqlClient: GraphQLClient;
  pathName?: string | undefined;
}

export async function gqlRequest<T>({
  query,
  variables,
  normalizerFn,
  gqlClient,
  pathName
}: IGqlRequestParams): Promise<any> {
  const { errorEvent } = secondaryEvents();
  return gqlClient.request<T>(query, variables)
    .catch((e) => {
      const status = e?.response?.status;
      const firstError = e?.response?.errors?.[0];
      const errorMessage = firstError?.message ?? "Unknown error";
      const errorPath = firstError?.path?.[0] ?? "Unknown path";

      errorEvent({
        error_code: status ?? "Unknown",
        error_msg: errorMessage,
        page_name: pathName ?? "",
        path: errorPath
      });

      if (isBrowser && status >= 500 && location.pathname !== "/500") {
        document.location.href = "/500";
      } else {
        throw new Error(errorMessage ?? "Network error");
      }
    })
    .then((data) => normalizerFn?.(data, variables));
}

