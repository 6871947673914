export const stateTaxRates = [
  { label: "AK", value: 0 },
  { label: "AL", value: 8 },
  { label: "AR", value: 0 },
  { label: "AZ", value: 0 },
  { label: "CA", value: 0 },
  { label: "CO", value: 0 },
  { label: "CT", value: 6.35 },
  { label: "DC", value: 6 },
  { label: "DE", value: 0 },
  { label: "FL", value: 0 },
  { label: "GA", value: 0 },
  { label: "HI", value: 0 },
  { label: "IA", value: 0 },
  { label: "ID", value: 0 },
  { label: "IL", value: 0 },
  { label: "IN", value: 7 },
  { label: "KS", value: 0 },
  { label: "KY", value: 6 },
  { label: "LA", value: 0 },
  { label: "MA", value: 6.25 },
  { label: "MD", value: 0 },
  { label: "ME", value: 5.5 },
  { label: "MI", value: 6 },
  { label: "MN", value: 0 },
  { label: "MO", value: 0 },
  { label: "MS", value: 0 },
  { label: "MT", value: 0 },
  { label: "NC", value: 0 },
  { label: "ND", value: 0 },
  { label: "NE", value: 0 },
  { label: "NV", value: 0 },
  { label: "NH", value: 0 },
  { label: "NJ", value: 6.625 },
  { label: "NM", value: 0 },
  { label: "NY", value: 0 },
  { label: "OH", value: 0 },
  { label: "OK", value: 0 },
  { label: "OR", value: 0 },
  { label: "PA", value: 6 },
  { label: "RI", value: 7 },
  { label: "SC", value: 0 },
  { label: "SD", value: 0 },
  { label: "TN", value: 0 },
  { label: "TX", value: 0 },
  { label: "UT", value: 0 },
  { label: "VT", value: 0 },
  { label: "VA", value: 0 },
  { label: "WA", value: 0 },
  { label: "WI", value: 0 },
  { label: "WV", value: 0 },
  { label: "WY", value: 0 },
];
