import { EViewSize } from "@enums";
import { useCollectionItemCurrentBids } from "@hooks";
import { useMojitoItem } from "@hooks/useMojitoItem";
import { IMojitoCollectionItemAuctionLot } from "@interfaces";
import { formatWithCommas, premiumCalculator } from "@utils";
import moment from "moment";
import { useState } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { UserAvatar } from "../../userAvatar";

export const AuctionLotWinnerPlaceholder = styled(
  (props?: { mojitoId?: string }) => {
    const { mojitoItem } = useMojitoItem<IMojitoCollectionItemAuctionLot>({
      id: props?.mojitoId,
    });
    const { currentBids } = useCollectionItemCurrentBids(mojitoItem?.id);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const bid = currentBids?.details?.currentBid;

    const closePlaceholder = () => {
      setIsOpen(false);
    };

    let bidSoldAmount = bid?.amount;
    if (bidSoldAmount && mojitoItem?.details) {
      const fee = premiumCalculator(
        bidSoldAmount,
        mojitoItem?.details.feeStructure
      );

      bidSoldAmount += fee.buyersPremiumRate + fee.overheadPremiumRate;
    }

    return isOpen && (bid?.isSold || bid?.isWin || bid?.isLost) ? (
      <div {...props}>
        <div className="win-row">
          <div className="close-btn" onClick={closePlaceholder}>
            <SVG width={16} src="/icons/close.svg" />
          </div>
          {bid && <UserAvatar avatarKey={bid.marketplaceUser.avatar} />}
          {bid ? (
            <div className="winner-text">
              {bid?.isWin
                ? "Congratulations! You’ve won!"
                : `Winner: ${bid.marketplaceUser.username}`}
            </div>
          ) : (
            <div className="winner-text">Lot Closed</div>
          )}
          <div className="winner-info">
            {currentBids?.details?.endDate && (
              <div className="end-date">
                {moment(currentBids.details.endDate).fromNow()}
              </div>
            )}
            {bid && (
              <div className="winner-amount">
                {formatWithCommas(bidSoldAmount)} USD
              </div>
            )}
          </div>
        </div>
        {bid?.isWin && (
          <div className="winner-instructions">
            <span>Here’s what to do next:</span>
            <ul>
              <li>You’ll receive an email with your invoice</li>
              <li>
                If you haven’t already, you’ll need to set up a crypto wallet to
                receive the NFT
              </li>
              <li>
                You can put the piece back up for sale or continue to grow your
                collection here in the Sotheby’s Metaverse
              </li>
            </ul>
          </div>
        )}
      </div>
    ) : null;
  }
)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(
      90deg,
      rgba(191, 135, 232, 0.5) 0%,
      rgba(85, 224, 255, 0) 100%
    );
  z-index: 11;
  padding: 0 30px;
  color: #fff;

  .win-row {
    //display: flex;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .close-btn {
    display: flex;
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-right: 24px;
    cursor: pointer;
  }

  ${UserAvatar} {
    margin-right: 24px;
    min-width: 60px;
    width: 60px;
    height: 60px;
  }

  .winner-text {
    margin-right: auto;
    font-family: "Mercury Text G1", serif;
    font-size: 24px;
    line-height: 30px;
  }

  .winner-info {
    display: flex;
    align-items: center;
  }

  .end-date {
    margin: 0 auto;
    padding: 0 15px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0025em;
    text-transform: uppercase;
  }

  .winner-amount {
    min-width: 180px;
    text-align: right;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 22px;
  }

  .winner-instructions {
    margin: 8px 0 18px 48px;
    font-size: 12px;
    line-height: 18px;

    ul {
      margin: 0;
      list-style-type: decimal;
      padding-left: 12px;
    }
  }

  @media screen and (max-width: ${EViewSize.tablet}) {
    padding: 0 15px;

    ${UserAvatar} {
      min-width: 30px;
      width: 30px;
      height: 30px;
      border-width: 1px;
    }

    .winner-text {
      font-size: 18px;
      line-height: 20px;
    }

    .winner-info {
      flex-direction: column;
      align-items: flex-end;
    }

    .end-date {
      padding: 0;
      margin: 0;
    }

    .winner-amount {
      padding: 0;
      margin-top: 8px;
    }

    .winner-instructions {
      margin-left: 0;
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    .close-btn {
      min-width: 12px;
      width: 12px;
      height: 16px;
      padding: 0;
      margin-right: 12px;
    }

    ${UserAvatar} {
      margin-right: 12px;
    }

    .winner-amount {
      font-size: 15px;
      min-width: 150px;
    }

    .winner-instructions {
      font-size: 11px;
      line-height: 13px;
    }
  }
`;
