import { EViewSize } from "@enums";
import { Avatar } from "@material-ui/core";
import { generateAvatar } from "@utils";
import { useIsomorphicLayoutEffect } from "ahooks";
import React, { useRef } from "react";
import styled from "styled-components";

interface IUserAvatar {
  avatarKey: string | null;
  username?: string;
  // customStyle?: string;
}

const AvatarStyled = styled(Avatar)`
  font-size: 45px !important;
  text-transform: uppercase;
`;

export const UserAvatar = styled(
  React.memo(({ avatarKey, username, ...props }: IUserAvatar) => {
    const avatar = useRef<HTMLDivElement>(null);

    useIsomorphicLayoutEffect(() => {
      if (avatarKey && avatar.current) {
        avatar.current.innerHTML = generateAvatar(avatarKey);
      }
    }, [avatarKey, avatar]);
    return (
      <div {...props} ref={avatar}>
        <AvatarStyled sx={{ width: 142, height: 142 }}>
          {username
            ?.split(" ")
            ?.map((word) => word[0])
            ?.join("")}
        </AvatarStyled>
      </div>
    );
  })
)`
  box-shadow: 0 0 0 2px rgba(240, 240, 240, 0.8);
  border-radius: 50%;
  width: 145px;
  height: 145px;
  overflow: hidden;
  position: relative;
  background-size: cover !important;
  background: rgba(240, 240, 240, 1);

  & svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    width: 105px;
    height: 105px;
  }
`;
