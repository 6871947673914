import moment from "moment";
import { IAllPrice, IGetOffer, ImageDetails, ITaxResponse, metamask, NFTPrice } from "./wallet";
import { Cloudinary } from '@cloudinary/url-gen';
import { config } from "@constants";
import { quality } from "@cloudinary/url-gen/actions/delivery";
import { GetIdTokenClaimsOptions, IdToken } from "@auth0/auth0-react";

export const truncateDecimal = (number: string | number = '', index = 4, removeEmptydot = true): string => {
  // convert number to string
  number += '';
  number = number.toString()
  // cutting the number.
  if (number.indexOf(".") > -1) {
    number = number.slice(0, (number.indexOf(".")) + (index + 1));
  }
  // If the their is no decimal remove the '.'
  if (removeEmptydot && number.indexOf(".") === number.length - 1) {
    number = number.slice(0, number.indexOf("."));
  }
  return number;
}

export const roundUp = (num: number, precision: number): number => {
  precision = Math.pow(10, precision)
  return Math.ceil(num * precision) / precision
}


export const copyToClipboard = (textToCopy: string): Promise<void> => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}

function isString(value: string | number | Array<any> | null | undefined): boolean {
  if (value === null || value === undefined) {
    return false;
  } else if (typeof value === 'string') {
    return true;
  }
  return false;
}

export const isEmpty = (value: string | number | Array<any> | null | undefined): boolean => {
  let _isValid = false;
  if (value === null || value === undefined) {
    _isValid = true;
  } else if (isString(value) && typeof value === 'string') {
    _isValid = (value.length === 0 || value.trim().length === 0);
  } else if (value instanceof Date) {
    _isValid = false;
  } else if (typeof value === 'object') {
    _isValid = Object.keys(value).length === 0;
  } else if (Array.isArray(value)) {
    _isValid = value.length === 0;
  }
  return _isValid;
};

export const truncateAddress = (address: string | null | undefined, front = 6, back = 4): string => {
  if (typeof address === "string")
    return `${address?.substr(0, front)}...${address?.substr(address?.length - back)}`;
  return "";
}

export const getTimeZone = (): string => {
  return moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getImageValueEtherScan = (res: any) => {
  if (!isEmpty(res?.data?.image)) {
    return res?.data?.image
  } else if (!isEmpty(res?.data?.image_url)) {
    return res?.data?.image_url;
  } else if (!isEmpty(res?.data?.imageUrl)) {
    return res?.data?.imageUrl;
  } else {
    return "";
  }
}

export const getImageValue = async (tokenURI: string, metamaskData: metamask, APIStatus = false): Promise<{ data: metamask }> => {
  const data = { ...metamaskData };
  if ((APIStatus && !isEmpty(tokenURI)) || (isEmpty(data?.image) && !isEmpty(tokenURI))) {
    try {
      const res: any = await ImageDetails(tokenURI);
      if (!APIStatus || isEmpty(data?.image)) {
        data.image = getImageValueEtherScan(res);
        data.animationURL = res?.data?.animation_url;
        data.name = res?.data?.name;
        data.description = res?.data?.description;
      }
      if (!isEmpty(res?.data?.media?.uri)) {
        data.animationURL = res?.data?.media?.uri;
      }
      return { data: data }
    } catch (e) {
      return { data: data };
    }
  } else {
    if (isVideo(data?.image)) {
      data.animationURL = data.image;
      data.image = "";
    }
    return { data: data };
  }
}


export const GetIpfsImage = (str: string, type = "CONTENTFULL"): string => {
  let value = str ?? '';
  if (["CONTENTFULL", "DETAILS"].includes(type) && value.includes("https://images.ctfassets")) {
    value = `${value}?q=35`;
  }
  if (value.includes("ipfs:")) {
    value = value.replace("ipfs://", "https://ipfs.io/ipfs/");
  }
  if (value.includes("ipfs/ipfs/")) {
    value = value.replace("ipfs/", "");
  }
  return value;
}

export const getCloudinaryImage = (url: string, isImage?: boolean): string => {

  // CLOUDINARY key empty
  if (isEmpty(config?.CLOUDINARY)) return url;

  const cldInstance = new Cloudinary({ cloud: { cloudName: config?.CLOUDINARY } });

  if (isVideo(url) || isImage === false) {
    return cldInstance.video(url).setDeliveryType("fetch").toURL();
  } else {
    return cldInstance.image(url).setDeliveryType("fetch").delivery(quality("auto")).format("webp").addFlag("animated").toURL();
  }
}

export const getValidImageUrl = (url: string): boolean => {
  const NFTImage = GetIpfsImage(url, "IMAGE");
  return NFTImage.includes("http")
}

export const isVideo = (url: string): boolean => {
  return /\.(mp4|ogv|webm|mov)$/.test(url);
}

export const isImage = (url: string): boolean => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg|glb)$/.test(url);
}

export const isHTML = (source: string): boolean => {
  return /^html/.test(source);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const defaultimg = (ev: any): void => {
  ev.target.src = '/images/image_not_found.png';
}

export const getStatus = (status: string, owner: string, connectMetaMask = "1"): string => {

  if (
    (owner ?? "").toLowerCase() ===
    (connectMetaMask ?? "1").toLowerCase()
  ) {
    switch (status) {
      case "OPEN_FOR_SALE":
        return "List Item";
      case "SALE_PENDING":
        return "Edit Listing";
      default:
        return "View NFT";
    }
  } else {
    switch (status) {
      case "SALE_PENDING":
        return "Buy Now";
      case "OPEN_FOR_SALE":
        return "Make Offer";
      default:
        return "View NFT";
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PDPURL = (contractAddress: string, tokenId: string | number, tokenType: string, ownerAddress: string) => {
  if (tokenType.toUpperCase() === "ERC1155") {
    return `/marketplace/asset/details/${contractAddress}/${tokenId}/${ownerAddress}`;
  }
  return `/marketplace/asset/details/${contractAddress}/${tokenId}`;

}

export const getBuyNowOrMakeOfferPrice = (status: string, price: IAllPrice, listedPrice: NFTPrice[], owners?: string | undefined, account?: string | undefined): NFTPrice[] => {
  const buttonStatus = getStatus(
    status,
    owners ?? "",
    account ?? "1"
  )
  if (buttonStatus === "Make Offer") {
    if (price?.makeOfferHighestPrice) {
      return price?.makeOfferHighestPrice
    } else if (price?.makeOfferLatestPrice) {
      return price?.makeOfferLatestPrice
    } else {
      return []
    }
  } else if (buttonStatus === "Buy Now" || buttonStatus === "Edit Listing") {
    if (listedPrice?.length > 0) {
      return listedPrice
    } else {
      return price?.buyNowPrice
    }
  } else {
    return []
  }
}

export const getTokenAlignmentStatus = (status: string, price: IAllPrice, listedPrice: NFTPrice[]): boolean => {
  if (status === "Make Offer") {
    if (price?.makeOfferHighestPrice?.length > 0 || price?.lastPurchasedPrice?.length > 0) {
      return true;
    } else {
      return false;
    }
  } else if (status === "Buy Now" || status === "Edit Listing") {
    if (listedPrice?.length > 0 || price?.buyNowPrice?.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    if (price?.lastPurchasedPrice?.length > 0 || price?.makeOfferHighestPrice?.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}


export const getTransferedStatusName = (name: string): string => {
  if (name === "Listed_Item") {
    return "Item Listed for Sale"
  } else if (name === "Listed_Item_Removed") {
    return "Item Listed for Sale Removed"
  } else if (name === "Sold_Item") {
    return "Item Sold"
  } else if (name === "Offered") {
    return "New Offer"
  } else if (name === "Offer_Cancelled") {
    return "Offer Cancelled"
  } else if (name === "Offer_Rejected") {
    return "Offer Rejected"
  } else if (name === "Offer_Expired") {
    return "Offer Expired"
  } else if (name === "Bought_Item") {
    return "Item Purchased"
  } else {
    return name;
  }
};

export const WalletAddressSlice = (value: string, from = 2, to = 7): string => {
  return value.slice(from, to).toUpperCase()
}

export const sortDate = (array: IGetOffer[]): IGetOffer[] => {
  array.sort((a: any, b: any) => {
    a.updatedAt = new Date(a.updatedAt);
    b.updatedAt = new Date(b.updatedAt)
    return a.updatedAt.getTime() - b.updatedAt.getTime();
  });
  return array;
}

export const offerSort = (data: IGetOffer[]): IGetOffer[] => {
  const pending: IGetOffer[] = [], other: IGetOffer[] = [];
  data.forEach((ele) => {
    if (ele.orderStatus === "PENDING") {
      pending.push(ele);
    } else {
      other.push(ele)
    }
  })
  return [...sortDate(pending), ...other];
}

interface IActivityPrice {
  usdValue: number;
  usdUnit: string;
  cryptoValue: number;
  cryptUnit: string;
  lable: string;
}

interface IOrder {
  creatorFee: number;
  platformFee: number;
}

export const getActivityPrice = (name: string, taxData: ITaxResponse, price: NFTPrice[], order: IOrder): IActivityPrice => {
  if (name === "Listed_Item" || name === "Listed_Item_Removed") {
    return {
      usdValue: +truncateDecimal(price[0]?.value, 4),
      usdUnit: price[0]?.unit,
      cryptoValue: +truncateDecimal(price[1]?.value, 4),
      cryptUnit: price[1]?.unit,
      lable: "Listed Price"
    }
  } else if (name === "Offered" || name === "Offer_Cancelled" || name === "Offer_Rejected" || name === "Offer_Expired" || name === "Bought_Item") {
    const totalUsdPrice = (taxData?.USDUnitprice * taxData?.cryptoTaxPrice) + price[0]?.value
    return {
      usdValue: +truncateDecimal(totalUsdPrice, 4),
      usdUnit: price[0]?.unit,
      cryptoValue: +truncateDecimal(taxData?.cryptoTotalPrice, 4),
      cryptUnit: price[1]?.unit,
      lable: name === "Bought_Item" ? "Total Price" : "Offer Price"
    }
  } else if (name === "Sold_Item") {
    const totalCrypto = price[1]?.value - (order?.creatorFee + order?.platformFee)
    const totalUsdPrice = price[0]?.value - ((order?.creatorFee * taxData?.USDUnitprice) + (order?.platformFee * taxData?.USDUnitprice))
    return {
      usdValue: +truncateDecimal(totalUsdPrice, 4),
      usdUnit: price[0]?.unit,
      cryptoValue: +truncateDecimal(totalCrypto, 4),
      cryptUnit: price[1]?.unit,
      lable: "Total Receivable"
    }
  } else {
    return {
      usdValue: +truncateDecimal(price[0]?.value, 4),
      usdUnit: price[0]?.unit,
      cryptoValue: +truncateDecimal(price[1]?.value, 4),
      cryptUnit: price[1]?.unit,
      lable: "Price"
    }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPartyId = async (getIdTokenClaims: (options?: GetIdTokenClaimsOptions | undefined) => Promise<IdToken | undefined>) => {
  const claims = await getIdTokenClaims();
  if (claims) {
    return claims[config.APP_METADATA_URL]?.customerData?.partyId;
  }
  return "";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatNumberToUSD = (price: number, maximumFraction = 2, minimumFraction = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFraction,
    maximumFractionDigits: maximumFraction,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return String(formatter.format(price)).slice(1)
}
