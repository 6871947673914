import { GridRenderer } from "@components";
import { SaleType } from "@enums";
import { useCollection } from "@hooks";
import { IMojitoCollectionItem } from "@interfaces";
import { generateFakeMojitoLots } from "@utils";
import React from "react";
import styled from "styled-components";

export const LotsGrid = styled(
  ({ type = SaleType.Auction, ...props }: { type?: SaleType | null }) => {
    const { collection, isFakeAuction } = useCollection();

    if (!collection) return null;

    let lots;
    if (isFakeAuction) {
      lots = generateFakeMojitoLots(collection?.items);
    } else {
      lots =
        collection?.items
          ?.filter(
            (e: IMojitoCollectionItem<any>) =>
              (type ? e.saleType == type : true) && e.contentfulData
          )
          ?.map((e: IMojitoCollectionItem<any>) => e.contentfulData) || [];
    }

    return lots ? (
      <GridRenderer
        key={collection.id}
        lots={lots}
        collection={collection}
        type={type}
        {...props}
      />
    ) : null;
  }
)`
  flex: 1 0 auto;
`;
