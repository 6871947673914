import { Button } from "@material-ui/core";
import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import Image from "next/image";
import { palette } from "@styles/theme";

export interface IButton {
  icon?: string;
  width?: string;
  disabled?: boolean | string;
  isLoading?: boolean;
  buttontype?: string;
  className?: string;
  onClick?: (event: any) => void;
  primaryWalletNftButton?: (name: string) => void;
}

export const SecondaryButton = styled(
  ({
    children,
    disabled,
    isLoading,
    buttontype = "primary",
    ...props
  }: IButton & { children: ReactNode; style?: CSSProperties }) => {
    const primarybtn = buttontype === "primary" ? "primary" : undefined;
    const secondarybtn = buttontype === "secondary" ? "secondary" : undefined;
    const tertiarybtn = buttontype === "tertiary" ? "tertiary" : undefined;
    const errorbtn = buttontype === "error" ? "error" : undefined;
    const noOutline = buttontype === "noOutline" ? "noOutline" : undefined;
    props.className = classNames(
      {
        primarybtn,
        secondarybtn,
        tertiarybtn,
        errorbtn,
        noOutline,
      },
      props.className
    );

    return (
      <Button
        {...props}
        disabled={!!disabled}
        style={{
          width: props.width ?? "100%",
        }}
        endIcon={
          isLoading ? (
            <Image
              unoptimized={true}
              src={"/images/loading.gif"}
              alt={"loading"}
              width={16}
              height={16}
            />
          ) : null
        }>
        {children}
      </Button>
    );
  }
)`
  &.tertiarybtn {
    text-transform: none;
    padding: 16px 10px;
    letter-space: 10px;
    width: 100%;
    height: 50px;
    background-color: ${palette.grayBlue};
    border-radius: 3px;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.white};

    &:hover {
      background-color: ${palette.grayBlue};
    }

    &[disabled] {
      border: 1px solid ${palette.paginationText};
      cursor: not-allowed;
      background: ${palette.white};
      color: ${palette.paginationText};
    }
  }
  &.primarybtn {
    text-transform: none;
    padding: 16px 10px;
    letter-space: 10px;
    width: 100%;
    height: 50px;
    background-color: ${palette.navyBlue};
    border-radius: 3px;
    cursor: pointer;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.white};

    &:hover {
      background-color: ${palette.white};
      color: ${palette.navyBlue};
      border: 1px solid ${palette.paginationText};
    }

    &[disabled] {
      border: 1px solid ${palette.paginationText};
      cursor: not-allowed;
      background: ${palette.white};
      color: ${palette.paginationText};
    }
  }

  &.errorbtn {
    text-transform: none;
    padding: 16px 10px;
    letter-space: 10px;
    width: 100%;
    height: 50px;
    background-color: ${palette.error};
    border-radius: 3px;
    cursor: pointer;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.white};

    &:hover {
      background-color: ${palette.error};
    }
  }

  &.secondarybtn {
    text-transform: none;
    padding: 16px 10px;
    width: 100%;
    height: 50px;
    background: ${palette.white};
    border: 1px solid ${palette.paginationText};
    border-radius: 3px;
    cursor: pointer;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.navyBlue};

    &:hover {
      background-color: ${palette.navyBlue};
      color: ${palette.white};
    }

    &[disabled] {
      color: ${palette.black};
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &.noOutline {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: ${palette.black};
    text-transform: none;
    &:hover {
      background: none;
    }
  }
`;
