import { useAuth0 } from "@auth0/auth0-react";
import { EPagePath, EViewSize } from "@enums";
import { useDeviceResponsive, useOrganization, usePageType } from "@hooks";
import classNames from "classnames";
import Link from "next/link";
import React, { ReactNode, useState, useCallback } from "react";
import styled from "styled-components";
import { ActiveBidsBadge, Loader, UserAvatar } from "../../shared";
import * as Sentry from "@sentry/react";
import {
  clearStorage,
  LOCAL_STORAGE_STATE,
} from "@utils/wallet/clearLocalStorage";
import { Box } from "@material-ui/core";
import { palette } from "@styles/theme";
import { truncateAddress } from "@utils/wallet/numberFormet.util";
import { useAuthenticateConnectedUser } from "@dynamic-labs/sdk-react";
import { useWeb3 } from "@utils/wallet/connectContext";
import { SecondaryButton } from "src/components/secondary-wallet/secondaryButton";

export const MenuWrapper = styled.div`
  transition: border 0.35s;
  height: 40px;
`;

export const MenuOptions = styled.div`
  border: 1px solid ${palette.hoverColor};
  border-radius: 7px;
  height: 40px;
  transition: height 0.35s;
  overflow: hidden;
  background-color: ${palette.white};
  // background-color: var(--auth-menu-bg);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 9;

  ${MenuWrapper}.full &:hover {
    // height: 225px;
  }
`;

export const MenuItem = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: ${palette.black};
  cursor: pointer;
  text-align: right;
  white-space: nowrap;
  padding: 0 10px;
  transition: background 0.35s, color 0.35s;
  font-size: 12px !important;
  @media screen and (max-width: 380px) {
    padding: 0 3px;
    font-size: 12px;
  }
  &:not(:first-child) {
    padding-left: 38px;
  }

  &:hover {
    background: ${palette.hoverColor};
  }

  svg {
    fill: var(--icon-btn-color);
    transition: fill 0.35s;
    margin-right: 10px;
    width: 20px;
  }
`;

export const Avatar = styled((props) => <UserAvatar {...props} />)`
  && {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: none;
  }
`;

export const UserName = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  // height: 1.2em;
  white-space: nowrap;
  font-size: 12px;

  @media screen and (max-width: ${EViewSize.tablet}) {
    max-width: 80px;
  }
`;
const loaderstyle = {
  width: "100%",
  marginTop: "5%",
  "@media screen and (max-width: 768px)": {
    marginTop: "25%",
  },
};
export enum ErrorsType {
  HIGH_RISK = "high risk wallet",
  WRONG_WALLET = "wallet does not belong to the user",
}
export const AuthMenu = styled(
  ({
    noLogout = false,
    children,
    handleCloseMobileNav,
    ...props
  }: {
    children?: ReactNode;
    noLogout?: boolean;
    handleCloseMobileNav: () => void;
  }) => {
    const { isMobile } = useDeviceResponsive();
    const { connect, setConnect } = useWeb3();
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
    const { organization } = useOrganization();
    const pageType = usePageType();
    const [invisible, setInvisible] = useState(false);
    const [menuClicked, setMenuClicked] = useState(false);
    const { isAuthenticating } = useAuthenticateConnectedUser();

    const login = () => {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      }).catch((err) => {
        Sentry.captureMessage(`Authentication error: ${err?.message || ""}`);
      });
    };

    const onClickConnect = useCallback(() => {
      setConnect((prev) => ({
        ...prev,
        openMetaMask: true,
      }));
    }, [setConnect]);

    const onClickDisconnect = useCallback(() => {
      setConnect((prev) => ({
        ...prev,
        onDisConnect: true,
      }));
    }, [setConnect]);

    return (
      <div {...props}>
        <div
          className={
            menuClicked && isAuthenticated
              ? "profileBlock menuclicked"
              : "profileBlock"
          }>
          <MenuWrapper
            className={isAuthenticated ? `full profileMenu` : `profileMenu`}
            onMouseEnter={() => {
              !invisible && setInvisible(true);
              if (!isMobile) setMenuClicked(true);
            }}
            onMouseLeave={() => {
              invisible && setInvisible(false);
              setMenuClicked(false);
            }}
            onClick={() => {
              setInvisible(!invisible);
            }}>
            <MenuOptions className="profileList">
              {organization ? (
                <>
                  <MenuItem
                    onClick={() => setMenuClicked(!menuClicked)}
                    className="profileListText">
                    <div>
                      <Avatar
                        avatarKey={organization.avatar}
                        username={organization.username}
                      />
                    </div>
                    {connect?.connected && connect.account ? (
                      <div className="mainContainer">
                        <UserName>
                          {organization.username ?? "username"}
                        </UserName>
                        <div className="main-box">
                          <div className="add-box" />
                          <p>{truncateAddress(connect.account ?? "")}</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <UserName>
                          {organization.username ?? "username"}
                        </UserName>
                      </div>
                    )}
                  </MenuItem>
                  <Link
                    href={EPagePath.ProfileWallet}
                    as={EPagePath.ProfileWallet}
                    passHref>
                    <MenuItem
                      onClick={() => {
                        setMenuClicked(false);
                        handleCloseMobileNav();
                      }}
                      className={classNames(
                        pageType.isProfileWallet && "isActive"
                      )}>
                      Wallet
                    </MenuItem>
                  </Link>
                  <Link
                    href={EPagePath.ProfileSaved}
                    as={EPagePath.ProfileSaved}
                    passHref>
                    <MenuItem
                      onClick={() => {
                        setMenuClicked(false);
                        handleCloseMobileNav();
                      }}
                      className={classNames(
                        pageType.isProfileSave && "isActive"
                      )}>
                      Saved
                    </MenuItem>
                  </Link>
                  <Link
                    href={EPagePath.ProfileListings}
                    as={EPagePath.ProfileListings}
                    passHref>
                    <MenuItem
                      onClick={() => {
                        setMenuClicked(false);
                        handleCloseMobileNav();
                      }}
                      className={classNames(
                        pageType.isProfileListings && "isActive"
                      )}>
                      Listings
                    </MenuItem>
                  </Link>
                  <Link href={EPagePath.ProfileActivity} passHref>
                    <MenuItem
                      onClick={() => {
                        setMenuClicked(false);
                        handleCloseMobileNav();
                      }}
                      className={classNames(
                        pageType.isProfileActivity && "isActive"
                      )}>
                      {/* <ActiveBidsBadge variant="dot" overlap="circular" /> */}
                      Activity
                    </MenuItem>
                  </Link>
                  {!noLogout && (
                    <MenuItem
                      onClick={() => {
                        clearStorage(LOCAL_STORAGE_STATE.secondary_wallet);
                        window.sessionStorage.removeItem("walletDetails");
                        logout({
                          returnTo: `${window.location.origin}?redirect_url=${window.location.pathname}`,
                        });
                        <Box sx={loaderstyle}>
                          <Loader contained={true} message={"Logging Out"} />
                        </Box>;
                      }}>
                      Log Out
                    </MenuItem>
                  )}
                </>
              ) : (
                <MenuItem
                  className="profileListText login-or-reg"
                  onClick={login}>
                  {/* <SVG src="/icons/profile.svg" /> */}
                  Log In or Register
                </MenuItem>
              )}
            </MenuOptions>
            {/* </ActiveBidsBadge> */}
          </MenuWrapper>
          {connect?.connected && organization && (
            <Box sx={{ height: "40px" }}>
              <SecondaryButton
                onClick={onClickDisconnect}
                disabled={isAuthenticating}
                className="connect-wallet-button"
                buttontype="secondary">
                Disconnect
              </SecondaryButton>
            </Box>
          )}
          {!connect?.connected && organization && (
            <SecondaryButton
              onClick={onClickConnect}
              className="connect-wallet-button"
              disabled={isAuthenticating}>
              + Connect Wallet
            </SecondaryButton>
          )}
          {children}
        </div>
      </div>
    );
  }
)`
  position: relative;

  ${MenuItem} > ${ActiveBidsBadge} {
    position: absolute;
    left: 20px;
  }
  .profileListText,
  .profileListText div {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${palette.black};
  }
  .login-or-reg {
    padding-top: 3px;
  }
  .profileBlock {
    display: flex;
  }
  .connect-wallet-button {
    height: 40px;
    margin-left: 10px;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 12px;
    min-width: 130px;
    position: relative;
    z-index: 9;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .main-box {
      display: flex;
      align-items: center;
      color: ${palette.border_gray};
    }
    .add-box {
      background-color: ${palette.green};
      height: 6px;
      width: 6px;
      border-radius: 10px;
    }
    p {
      margin-left: 3px;
      font-size: 12px;
    }
  }
`;
