import { gql } from "graphql-request";

export enum EMojitoSecondaryWalletMutation {
    connectExternalWallet,
    enquiryList,
    createListItemForSale,
    submitProofApproval,
    renameWallet,
    removeListing,
    addNFTToFavourite,
    removeNFTFromFavourite,
    initiateBuyNFT,
    updateTransactionHash,
    createOffer,
    submitProofOfOffer,
    cancelOffer,
    rejectOffer,
    acceptOffer,
    estimateTaxAndRoyaltyFee
}


export const secondaryWalletMutation = {
    [EMojitoSecondaryWalletMutation.connectExternalWallet]: gql`
        mutation ConnectExternalWallet(
            $signature: String!,
            $message: String!,
            $address: String!,
            $orgID: UUID1!,
            $networkID: UUID1!,
        ) {
            connectExternalWallet(
                signature: $signature,
                message: $message,
                address: $address,
                orgID: $orgID,
                networkID: $networkID,
                )
        }
    `,
  [EMojitoSecondaryWalletMutation.enquiryList]:gql`
        mutation createEnquiry(
            $orgId: UUID1!
            $networkId: UUID1!
            $contractAddress: String!
            $tokenId: BigInt!
            $tokenType: TokenType!
            $quantity: Int!
        ){
            createEnquiry(
                orgId: $orgId
                networkId: $networkId
                contractAddress: $contractAddress
                tokenId: $tokenId
                tokenType: $tokenType
                quantity: $quantity
                )
        }
    `,
    [EMojitoSecondaryWalletMutation.createListItemForSale]:gql`
            mutation createSignatureToListNFTForSale(
                $orgId: UUID1!
                $nftTokenId: UUID1!
                $tokenType: TokenType!
                $quantity: Int!
                $nftOwnerAddress: String!
                $fixedPrice: Float!
                $paymentToken: String!
                $currencyId: UUID1!
                ){
                createSignatureToListNFTForSale(
                    orgId: $orgId
                    nftTokenId: $nftTokenId
                    tokenType: $tokenType
                    quantity: $quantity
                    nftOwnerAddress: $nftOwnerAddress
                    fixedPrice: $fixedPrice
                    paymentToken: $paymentToken
                    currencyId: $currencyId
                    ){
                        messageToSign
                        order{
                            id
                            nftTokenId
                            tokenContract
                            tokenId
                            tokenType
                            quantity
                            nftOwnerAddress
                            fixedPrice
                            paymentToken
                            orderType
                            }
                        }
                }
          
    `,
    [EMojitoSecondaryWalletMutation.submitProofApproval]:gql`
        mutation submitProofOfApproval(
            $orgID: UUID1!
            $nftTokenId: UUID1!
            $signature: String!
            $quantity: Int
            $fixedPrice: Float
            $paymentToken: String
            $currencyId: UUID1
            $creatorFee: Float
            ){
                submitProofOfApproval(
                    orgID: $orgID
                    nftTokenId: $nftTokenId
                    signature: $signature
                    quantity: $quantity
                    fixedPrice: $fixedPrice
                    paymentToken: $paymentToken
                    currencyId: $currencyId
                    creatorFee: $creatorFee
                    )
            }
        `,
    [EMojitoSecondaryWalletMutation.renameWallet]:gql`
        mutation renameWallet(
            $walletID: UUID1!
            $name: String!
            ){
                renameWallet(
                    walletID: $walletID
                    name: $name
                )
        }`,
    [EMojitoSecondaryWalletMutation.removeListing]:gql`
        mutation removeListing(
            $orgID: UUID1!
            $nftTokenId: UUID1!
            ){
                removeListing(
                    nftTokenId: $nftTokenId
                    orgID: $orgID
                    )
            }
        `,
    [EMojitoSecondaryWalletMutation.addNFTToFavourite]:gql`
        mutation addNFTToFavourite(
            $orgID: UUID1!
            $tokenId: UUID1!
            ){
                addNFTToFavourite(
                    orgID: $orgID
                    tokenId: $tokenId
                    )
            }
        `,
    [EMojitoSecondaryWalletMutation.removeNFTFromFavourite]:gql`
        mutation removeNFTFromFavourite(
            $orgID: UUID1!
            $tokenId: UUID1!
            ){
                removeNFTFromFavourite(
                    orgID: $orgID
                    tokenId: $tokenId
                    )
            }
        `,
    [EMojitoSecondaryWalletMutation.initiateBuyNFT]:gql`
        mutation initiateBuyNFT(
           $nftTokenId: UUID1!
           $buyerAddress: String!
           $orgId: UUID1!
           $country: String
           $postalCode: String
           $currencyId: UUID1
           $cryptoTax: CryptoTax
           $taxAddress: TaxAddressInput
           ){
               initiateBuyNFT(nftTokenId: $nftTokenId, buyerAddress: $buyerAddress, orgId: $orgId, country: $country, postalCode: $postalCode, currencyId: $currencyId, cryptoTax: $cryptoTax, taxAddress: $taxAddress) {
                   proofOfApproval,
                   Network {
                    id
                    name
                    chainID
                    wethAddress
                   }
                   order {
                       id,
                       nftTokenId
                       tokenId
                       tokenContract
                       quantity
                       nftOwnerAddress
                       fixedPrice
                       price {
                        unit
                        value
                        type
                       }
                       nftToken {
                           id
                           name
                           deployed
                           tokenMetadata {
                               name
                               description
                               image
                           }
                           nftContractID
                       }
                   }
               }
           }
   `,
   [EMojitoSecondaryWalletMutation.updateTransactionHash]:gql`
        mutation updateTransactionHash(
            $nftTokenId: UUID1!
            $orgId: UUID1!
            $transactionHash: String!
            $buyerAddress: String!
            $orderId: UUID1
            ){
                updateTransactionHash(nftTokenId: $nftTokenId, orgId: $orgId, transactionHash: $transactionHash, buyerAddress: $buyerAddress, orderId: $orderId) {
                    success
                    order {
                        id
                        nftTokenId
                        fixedPrice
                        price {
                            value
                            unit
                            type
                        }
                    }
                    newNFTID
                }
            }
    `,
   [EMojitoSecondaryWalletMutation.createOffer]:gql`
        mutation createOffer(
            $nftTokenId: UUID1!
            $orgId: UUID1!
            $buyerAddress: String!
            $offerPrice: Float!
            $expiryDate: Time!
            $paymentToken: String!
            $currencyId: UUID1!
            $cryptoTax: CryptoTax
            $taxAddress: TaxAddressInput
            ){
                createOffer(
                    nftTokenId: $nftTokenId
                    orgId: $orgId
                    buyerAddress: $buyerAddress
                    offerPrice: $offerPrice
                    expiryDate: $expiryDate
                    paymentToken: $paymentToken
                    currencyId: $currencyId
                    cryptoTax: $cryptoTax
                    taxAddress: $taxAddress
                    ){
                        messageToSign
                        order {
                            id
                            nftTokenId
                            tokenId
                            tokenContract
                            quantity
                            nftOwnerAddress
                            fixedPrice
                            nftToken {
                                id
                                name
                                deployed
                                tokenMetadata {
                                    name
                                    description
                                    image
                                }
                                nftContractID
                            }
                        }
                    }
            }
    `,
    [EMojitoSecondaryWalletMutation.submitProofOfOffer]:gql`
        mutation submitProofOfOffer(
            $orderId: UUID1!
            $orgId: UUID1!
            $signature: String!
            ){
                submitProofOfOffer(
                    orderId: $orderId
                    orgId: $orgId
                    signature: $signature
                    )
            }
    `,
    [EMojitoSecondaryWalletMutation.cancelOffer]:gql`
        mutation cancelOffer(
            $orderId: UUID1!
            $orgId: UUID1!
            ){
                cancelOffer(
                    orderId: $orderId
                    orgId: $orgId
                    )
            }
    `,
    [EMojitoSecondaryWalletMutation.rejectOffer]:gql`
        mutation rejectOffer(
            $orderId: UUID1!
            $orgId: UUID1!
            ){
                rejectOffer(
                    orderId: $orderId
                    orgId: $orgId
                    )
            }
    `,
    [EMojitoSecondaryWalletMutation.acceptOffer]:gql`
        mutation acceptOffer(
            $orderId: UUID1!
            $orgId: UUID1!
            $transactionHash: String!
            ){
                acceptOffer(
                    orderId: $orderId
                    orgId: $orgId
                    transactionHash: $transactionHash
                ){
                    success
                    newNFTID
                }
            }
    `,
    [EMojitoSecondaryWalletMutation.estimateTaxAndRoyaltyFee]:gql`
    mutation estimateTaxAndRoyaltyFee(
        $orderId: UUID1, $orgId: UUID1!,
        $nftTokenId: UUID1,
        $price: Float,
        $estimateType: TaxEstimateType!,
        $country: String,
        $postalCode: String
        ){
            estimateTaxAndRoyaltyFee(
                orgId: $orgId, 
                orderId: $orderId,
                nftTokenId: $nftTokenId,
                price: $price,
                estimateType: $estimateType,
                country: $country,
                postalCode: $postalCode
            ){
                taxPercentage
                royaltyFee
                platformFee
                taxResponse {
                    cryptoTaxPrice
                    cryptoTotalPrice
                    USDUnitprice
                    taxPercentage
                }
            }
        }
    `,
}