import {
  useCollection,
  useCollectionItemCurrentBids,
  useCountDown,
} from "@hooks";
import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { IMojitoCollectionItemAuctionLot } from "@interfaces";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { formatCurrency, premiumCalculator } from "@utils";
import styled from "styled-components";

const LotTimerAndStatusMemo = styled(
  ({
    startDate,
    endDate,
    currentBid,
    saleView,

    onlyTimer,
    highlightHot,
    feeStructure,
    refetchOnEnd,
    currentBidsRefetch,
    props,
  }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [timeLeft, setTargetDate, formattedRes] = useCountDown({
      targetDate: endDate,
      dateToMs: (date) => moment(date).valueOf(),
    });

    useEffect(() => {
      setTargetDate(endDate);
    }, [endDate]);
    const { days, hours, minutes, seconds } = formattedRes;

    const endTime = endDate && new Date(endDate);
    const now = new Date();

    const formattedStartDate =
      startDate &&
      momentTimeZone(startDate)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MMMM Do, h:mmA z");

    const formattedEndDate =
      endDate &&
      momentTimeZone(endDate)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MMMM Do, h:mmA z");

    // now + 2h
    const nowPlusTwoH = moment(now).add(2, "h").toDate();
    const nowIsEndTime = endTime
      ? endTime.toString() === now.toString()
      : false;

    const getCountdown = (): string | null => {
      if (days) return `${days} days ${hours} hours left`;
      else if (hours) return `${hours} hours ${minutes} min left`;
      return `${minutes} minutes ${seconds} sec left`;
    };

    if (refetchOnEnd && nowIsEndTime) {
      currentBidsRefetch();
    }

    let inner;
    let isTimer = false;

    if (onlyTimer) {
      inner = getCountdown();
      isTimer = true;
      // if lotStatus is active and startTime is in the future
    } else {
      if (saleView) {
        if (saleView.isPreSale) {
          inner = `Opens ${formattedStartDate}`;
          // if endTime has past and there were no bids
        } else if (saleView.isPostSale && !currentBid) {
          inner = `LOT CLOSED`;
          // if endTime is greater than now+2h
        } else if (nowPlusTwoH && endTime && endTime > nowPlusTwoH) {
          inner = `Closes on ${formattedEndDate}`;
        } else if (saleView.isDuringSale) {
          inner = getCountdown();
          isTimer = true;
        } else if (feeStructure) {
          let soldAmount = currentBid?.amount;
          if (soldAmount) {
            const fee = premiumCalculator(soldAmount, feeStructure);
            soldAmount += fee.buyersPremiumRate + fee.overheadPremiumRate;
            inner = `<span>${formatCurrency(soldAmount)}</span>`;
          }
        } else {
          console.error("feeStructure is missing");
        }
      } else {
        console.error("saleView is missing");
      }
    }

    if (
      isTimer &&
      !props.className.includes("hot") &&
      highlightHot &&
      !nowIsEndTime
    ) {
      const hot = days == 0 && hours == 0 && minutes <= 5;
      if (hot) (props as any).className += " hot";
    } else if (nowIsEndTime) {
      props.className.replace("hot", "");
    }

    return inner ? (
      <div {...props} dangerouslySetInnerHTML={{ __html: inner }} />
    ) : null;
  }
)``;

export const LotTimerAndStatus = styled(
  ({
    mojitoId,
    onlyTimer = false,
    highlightHot = false,
    refetchOnEnd = false,
    ...props
  }: PropsWithChildren<{
    mojitoId: string;
    refetchOnEnd?: boolean;
    onlyTimer?: boolean;
    highlightHot?: boolean;
  }>) => {
    const { currentBids, currentBidsRefetch } =
      useCollectionItemCurrentBids(mojitoId);

    const { collection } = useCollection();

    const feeStructure = useMemo(() => {
      const details = collection?.items.find((e) => e.id === mojitoId)
        ?.details as IMojitoCollectionItemAuctionLot;
      return details?.feeStructure;
    }, [mojitoId]);

    const { startDate, endDate, currentBid, saleView } =
      currentBids?.details ?? {};

    return currentBids ? (
      <LotTimerAndStatusMemo
        {...{
          startDate,
          endDate,
          currentBid,
          saleView,

          feeStructure,
          onlyTimer,
          highlightHot,
          refetchOnEnd,
          currentBidsRefetch,
          props,
        }}
      />
    ) : null;
  }
)`
  &.hot {
    color: #fe15bc !important;
  }
`;
