import { useActiveBids } from "@hooks";
import { BadgeUnstyled } from "@material-ui/core";
import styled from "styled-components";

export const ActiveBidsBadge = styled((props: any) => {
  const { activeBids } = useActiveBids();
  const _children = props.children ?? null;

  return activeBids?.length ? (
    <BadgeUnstyled {...props}>{_children}</BadgeUnstyled>
  ) : (
    _children
  );
})`
  && {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    line-height: 1;

    & .MuiBadge-badge {
      z-index: auto;
      width: 16px;
      height: 16px;
      padding: 0 6px;
      color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      text-align: center;
      background: #fe15bc;
      border-radius: 10px;
      display: block;
      transition: transform 0.3s ease;
    }

    & .MuiBadge-dot {
      padding: 0;
      z-index: auto;
      min-width: 8px;
      width: 8px;
      height: 8px;
      background: #fe15bc;
      border-radius: 100%;
      display: block;
      transition: transform 0.3s ease;
      z-index: 555;
    }

    & .MuiBadge-anchorOriginTopRightCircular {
      position: absolute;
      top: 0;
      right: 0;
      transform: scale(1) translate(50%, -50%);
      transform-origin: 100% 0;
      transition: transform 0.3s ease;

      &.MuiBadge-invisible {
        transform: scale(0) translate(50%, -50%);
      }
    }

    & .MuiBadge-anchorOriginTopLeftCircular {
      position: absolute;
      top: 2px;
      left: 2px;
      transform: scale(1) translate(-50%, -50%);
      transform-origin: 0% 0;
      transition: transform 0.3s ease;

      &.MuiBadge-invisible {
        transform: scale(0) translate(-50%, -50%);
      }
    }
  }
`;
