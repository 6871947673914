import { useCollection } from "@hooks";
import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import { mod } from "@utils";
import { useRouter } from "next/router";

export enum ENavigationDirection {
  prev,
  next,
}

export interface ILotsNavigation {
  prevLot: () => void;
  nextLot: () => void;
  loading: boolean;
  activeLotIdx?: number;
  lots: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>[];
}

export function useLotsNavigation(): ILotsNavigation {
  const router = useRouter();
  const lotSlug = router.query.slug as string;
  const { slug, collection } = useCollection();

  const lots = collection?.items ?? [];
  const navItems = lots.map((item: IMojitoCollectionItem<any>) => item?.slug);
  const currentNavIndex = navItems.indexOf(lotSlug);

  function updateLocation(dir: ENavigationDirection): void {
    let navDestination: number;

    if (dir === ENavigationDirection.next) {
      navDestination = mod(currentNavIndex - 1, navItems.length);
    } else {
      navDestination = mod(currentNavIndex + 1, navItems.length);
    }

    router.push(`/${slug}/lots/${navItems[navDestination]}`);
  }

  return {
    prevLot: () => updateLocation(ENavigationDirection.prev),
    nextLot: () => updateLocation(ENavigationDirection.next),
    loading: currentNavIndex == -1 || !lots,
    activeLotIdx: currentNavIndex >= 0 ? currentNavIndex : undefined,
    lots,
  };
}
