import React, {
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { WalletConnector, useDynamicContext } from "@dynamic-labs/sdk-react";
import { UseSecondaryWalletMojitoMutation, useProfile } from "@hooks";
import {
  network,
  useGetApiSignature,
  usegetSupportedNetworks,
} from "@utils/wallet/wallet";
import { useAccount, useDisconnect } from "wagmi";
import { EMojitoSecondaryWalletMutation } from "@state";
import { ErrorsType } from "@core/header/authMenu";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { SecondaryButton } from "src/components/secondary-wallet/secondaryButton";
import ConnectContext, { ConnectType } from "@utils/wallet/connectContext";

interface LayoutProps {
  children: ReactNode;
}

export const WalletContextProvider = ({
  children,
}: LayoutProps): JSX.Element => {
  const [connect, setConnect] = useState<ConnectType>({
    connected: false,
    chainId: 0,
    provider: null,
    account: "",
    wethOrWmaticBalance: null,
    ethOrMaticBalance: null,
    currency: null,
    onDisConnect: false,
    openMetaMask: false,
    networkID: null,
    price: undefined,
    signer: null,
  });
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { primaryWallet, setShowAuthFlow, walletConnector } =
    useDynamicContext();
  const { profile } = useProfile();
  const { NetworkList } = usegetSupportedNetworks({
    orgId: profile?.userOrgs[0]?.organizationId,
  });
  const [isNewSign, setIsNewSign] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [networkID, setNetworkID] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const isConnectRef = useRef<null | boolean>(null);
  const [error, setError] = useState<string>("");
  const [refetchExternalWallet] = UseSecondaryWalletMojitoMutation(
    EMojitoSecondaryWalletMutation.connectExternalWallet
  );
  const { getSignatureData, getSignatureLoading, refetchSignature } =
    useGetApiSignature({
      networkID,
      orgID: profile?.userOrgs[0]?.organizationId,
      walletAddress,
    });

  const getNetworkDetailsByChainId = useCallback(
    (networkData: network[], chainId: number) => {
      const response = networkData.filter(
        (item: network) => item.chainID === chainId
      );
      return response?.[0];
    },
    []
  );

  //set selected network id
  useEffect(() => {
    if (NetworkList && primaryWallet?.network) {
      const networkDetail = getNetworkDetailsByChainId(
        NetworkList,
        +primaryWallet?.network
      );
      setConnect((prev) => ({
        ...prev,
        networkID: networkDetail,
      }));
      setNetworkID(networkDetail?.id);
    }
  }, [primaryWallet?.network, NetworkList]);

  const handleResetWalletConnect = useCallback(async () => {
    if (typeof window != undefined) {
      window.sessionStorage.removeItem("walletDetails");
    }
    setError("");
    setLoading(false);
    setConnect({
      account: "",
      connected: false,
      openMetaMask: false,
      onDisConnect: false,
      currency: null,
      provider: null,
      wethOrWmaticBalance: null,
      ethOrMaticBalance: null,
      chainId: null,
    });
    await disconnectAsync();
  }, [disconnectAsync]);

  const handleOpenModal = useCallback(async () => {
    if (typeof window != undefined) {
      window.sessionStorage.removeItem("walletDetails");
    }
    setConnect((prev) => ({
      ...prev,
      openMetaMask: false,
    }));
    if (connect?.connected || isConnected) {
      await handleResetWalletConnect();
    }
    await refetchSignature();
    setIsNewSign(true);
    setShowAuthFlow(true);
  }, [connect?.connected, isConnected, refetchSignature, setShowAuthFlow]);

  const getSignatureMessage = useCallback(
    async (connector: WalletConnector, signatureData: string) => {
      try {
        if ((NetworkList?.length ?? 0) > 0) {
          if (signatureData) {
            const signature = await handleSignature(connector, signatureData);
            if (signature) {
              const response = await refetchExternalWallet({
                message: getSignatureData ?? "",
                orgID: profile?.userOrgs[0]?.organizationId,
                signature,
                networkID,
                address: walletAddress,
              });
              if (response?.connectExternalWallet) {
                const netWorkDetail = getNetworkDetailsByChainId(
                  NetworkList ?? [],
                  +(primaryWallet?.network ?? 0)
                );
                setConnect((prev) => ({
                  ...prev,
                  connected: true,
                  account: walletAddress,
                  networkID: netWorkDetail,
                  chainId: netWorkDetail?.chainID,
                  openMetaMask: false,
                  currency:
                    netWorkDetail?.chainID == 80001 ||
                    netWorkDetail?.chainID == 137
                      ? "WMATIC"
                      : "WETH",
                }));
                const walletDetails = {
                  walletAddress,
                  isConnected: true,
                  netWorkDetails: netWorkDetail,
                  currency:
                    netWorkDetail?.chainID == 80001 ||
                    netWorkDetail?.chainID == 137
                      ? "WMATIC"
                      : "WETH",
                  chainId: netWorkDetail?.chainID,
                };
                sessionStorage.setItem(
                  "walletDetails",
                  JSON.stringify(walletDetails)
                );
                setLoading(false);
              }
            }
          } else {
            setLoading(false);
            console.log("Signature Data not found", signatureData);
          }
        } else {
          setLoading(false);
          setError("No supported networks for the organization");
        }
      } catch (err: any) {
        setWalletAddress("");
        setNetworkID("");
        await handleResetWalletConnect();
        setConnect((prev) => ({
          ...prev,
          connected: false,
          account: "",
        }));
        console.log(err, "error");
        setLoading(false);
        if (err?.message?.includes(ErrorsType.WRONG_WALLET)) {
          setError(
            "Due to compliance and security requirements, only one active wallet per user is allowed. Please use the wallet linked to the email address used when registering your Sotheby’s account."
          );
        } else if (err?.message?.includes(ErrorsType.HIGH_RISK)) {
          setError("Unfortunately we cannot complete this transaction, please contact Sotheby’s.");
        } else {
          setError("We’re having trouble connecting to your wallet. Please try again.");
        }
      }
    },
    [
      NetworkList,
      walletAddress,
      getSignatureData,
      profile?.userOrgs[0]?.organizationId,
      networkID,
      walletAddress,
      primaryWallet?.network,
    ]
  );

  const handleWalletConnect = useCallback(
    async (connector: WalletConnector, signatureData: string) => {
      try {
        if (connector && signatureData) {
          await getSignatureMessage(connector, signatureData);
        }
      } catch (err: any) {
        setLoading(false);
        setError(err?.message);
      }
    },
    [getSignatureMessage]
  );

  const handleSignature = useCallback(
    async (connector: WalletConnector, signatureData: string) => {
      try {
        const message = await connector.signMessage(signatureData);
        return message;
      } catch (err: any) {
        let errorMessage = "";
        setLoading(false);
        if (typeof err == "string") {
          errorMessage = err;
        } else {
          errorMessage = err?.message;
        }
        if (errorMessage?.toLowerCase()?.includes("user reject")) {
          setError("User rejected the transaction");
        }
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (primaryWallet?.address) {
        setWalletAddress(primaryWallet?.address);
      }
      if (
        walletConnector &&
        !getSignatureLoading &&
        getSignatureData &&
        primaryWallet?.address &&
        isNewSign
      ) {
        setLoading(true);
        setIsNewSign(false);
        await handleWalletConnect(walletConnector, getSignatureData ?? "");
        setLoading(false);
      }
    })();
  }, [
    walletConnector,
    setConnect,
    getSignatureData,
    primaryWallet?.address,
    getSignatureLoading,
    isNewSign,
    handleWalletConnect,
  ]);

  useEffect(() => {
    (() => {
      if (typeof window != undefined) {
        const sessionData = JSON.parse(
          window.sessionStorage.getItem("walletDetails") ?? "{}"
        );
        if (
          sessionData?.isConnected &&
          sessionData?.walletAddress &&
          isConnected
        ) {
          setConnect((prev) => ({
            ...prev,
            account: sessionData.walletAddress,
            onDisConnect: false,
            connected: true,
            openMetaMask: false,
            chainId: sessionData.netWorkDetails.chainID,
            networkID: sessionData.netWorkDetails,
            currency: sessionData.currency,
          }));
        }
      }
    })();
  }, [isConnected]);

  useEffect(() => {
    (async () => {
      if (connect?.openMetaMask) {
        isConnectRef.current = true;
        await handleOpenModal();
        setLoading(false);
      }
    })();
  }, [connect?.openMetaMask, handleOpenModal]);

  useEffect(() => {
    (async () => {
      if (connect?.onDisConnect) {
        setLoading(true);
        isConnectRef.current = false;
        await handleResetWalletConnect();
        setLoading(false);
      }
    })();
  }, [handleResetWalletConnect, connect?.onDisConnect]);

  const onChainOrAccountChanged: any = useCallback(() => {
    setConnect((prev) => ({
      ...prev,
      openMetaMask: true,
    }));
  }, []);

  const onDisconnectWallet: any = useCallback(() => {
    setConnect((prev) => ({
      ...prev,
      onDisConnect: true,
    }));
  }, []);

  useEffect(() => {
    walletConnector?.setupEventListeners({
      onAccountChange: onChainOrAccountChanged,
      onChainChange: onChainOrAccountChanged,
      onDisconnect: onDisconnectWallet,
    });
    return () => {
      walletConnector?.setupEventListeners({
        onAccountChange: onChainOrAccountChanged,
        onChainChange: onChainOrAccountChanged,
        onDisconnect: onDisconnectWallet,
      });
    };
  }, [walletConnector, onChainOrAccountChanged, onDisconnectWallet]);

  const modalStyle = useMemo(() => {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: {
        lg: "400px",
        md: "400px",
        sm: "400px",
        xs: "calc(100% - 20px)",
      },
      bgcolor: "#fff",
      borderRadius: "4px",
      boxShadow: 24,
      maxHeight: { xs: "calc(100% - 50px)", lg: "calc(100% - 100px)" },
      overflowY: "auto",
      padding: "16px 16px 30px 16px",
    };
  }, []);

  const handleRetry = useCallback(async () => {
    setLoading(true);
    await handleResetWalletConnect();
    setLoading(false);
    setConnect((prev) => ({
      ...prev,
      openMetaMask: true,
    }));
  }, [handleResetWalletConnect]);

  return (
    <React.Fragment>
      <ConnectContext.Provider value={{ connect, setConnect }}>
        {children}
        <Modal open={Boolean(loading || error)}>
          <Box sx={modalStyle}>
            {loading && (
              <Box
                sx={{
                  padding: "40px",
                }}>
                <Typography
                  component="h4"
                  align="center"
                  sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    padding: "24px 0 16px 0",
                  }}>
                  Connecting wallet...
                </Typography>
                <Typography align="center">
                  Please do not leave or reload this window.
                </Typography>
              </Box>
            )}
            {error && (
              <Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    onClick={handleResetWalletConnect}
                  />
                </Box>
                <Box
                  sx={{
                    padding: "30px",
                  }}>
                  <Typography align="center">
                    <ErrorOutlineIcon sx={{ fontSize: "100px" }} />
                  </Typography>
                  <Typography
                    component="h4"
                    align="center"
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      padding: "24px 0 16px 0",
                    }}>
                    Connection Error!
                  </Typography>
                  <Typography sx={{ marginBottom: "15px" }} align="center">
                    {error}
                  </Typography>
                  <SecondaryButton onClick={handleRetry} buttontype="primary">
                    Retry
                  </SecondaryButton>
                </Box>
              </Box>
            )}
          </Box>
        </Modal>
      </ConnectContext.Provider>
    </React.Fragment>
  );
};
