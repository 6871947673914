import { EViewSize } from "@enums";
import { useMojitoMutation, useProfile } from "@hooks";
import { IMojitoWallet, IMojitoWalletToken } from "@interfaces";
import { Box } from "@material-ui/system";
import { EMojitoMutations } from "@state";
import { simpleImageLoader } from "@utils";
import { useLocalStorageState } from "ahooks";
import { useState } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { isAddress } from "web3-utils";
import { Image } from "./image";
import { ModalContinueButton } from "./modalContinueButton";
import { ModalFooterInfo } from "./modalFooterInfo";
import { ETokenTypes } from "@enums/tokenTypes.enum";

const TransferTitle = styled.p`
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 21px;
  }
`;

const AvatarBox = styled((props) => <Box {...props} />)`
  && {
    position: relative;
    width: 280px;
    height: 280px;

    @media screen and (max-width: ${EViewSize.mobile}) {
      width: 100%;
      height: auto;
      max-width: 280px;
    }

    &.processed {
      padding: 10px;
      width: 240px;
      height: 240px;
      border: 2px solid #55e0ff;
      border-radius: 50%;

      img {
        border-radius: 50%;
      }
    }
  }
`;

const AvatarDescription = styled.p`
  margin-top: 36px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-top: 22px;
  }
`;

const WalletEdit = styled.div`
  position: relative;
  margin-top: 30px;
  padding: 22px 16px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #fff;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-top: 30px;
  }
`;

const WalletLabel = styled.label`
  position: absolute;
  z-index: 0;
  top: 18px;
  left: 16px;
  font-size: 14px;
  line-height: 24px;
  transition: 300ms ease all;
  color: #6b6b6b;
`;

const WalletEditInputError = styled.div`
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: calc(100% + 2px);
  color: var(--text-error);
`;

const WalletEditInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  z-index: 10;
  background: transparent;

  &:active
    ~ ${WalletLabel},
    &:focus
    ~ ${WalletLabel},
    &:not(:placeholder-shown)
    ~ ${WalletLabel} {
    font-size: 12px;
    top: 6px;
  }
`;

const WalletAddress = styled.div`
  margin-top: 22px;
  padding: 20px;
  background: #f8f8f8;
  color: #858585;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  word-break: break-word;
`;

const WalletEditButton = styled.button`
  display: block;
  margin-top: 10px;
  margin-left: auto;
  padding: 8px 18px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(
      301.04deg,
      rgba(85, 255, 188, 0.3) -66.81%,
      rgba(186, 113, 219, 0.3) 90.61%
    );
  border: none;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  color: #fff;

  svg {
    margin-right: 4px;
    height: 10px;
  }
`;

enum ETransferStatus {
  Edit,
  Confirm,
  Process,
  Error,
}

interface ITransferModal {
  wallet: IMojitoWallet;
  token: IMojitoWalletToken;
  avatarImage: string;
  setNftTxHash: (value: string) => void;
  chainExplorer: { name: string; url: string };
}

export const TransferModal = styled(
  ({
    wallet,
    token,
    avatarImage,
    setNftTxHash,
    chainExplorer,
    ...props
  }: ITransferModal) => {

    const { profile } = useProfile();
    const [status, setStatus] = useState<ETransferStatus>(ETransferStatus.Edit);
    const [transferToAddress, setTransferToAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [txhash, setTxHash] = useLocalStorageState("txhash-token" + token.id);
    const [transferToken] = useMojitoMutation(EMojitoMutations.transferToken);
    const continueButtonDisabled =
      (transferToAddress.length > 0 && !isAddress(transferToAddress)) ||
      transferToAddress.length == 0;
    //0x66aE50e07EC2D54878F956A26f8409d2Dd73fd24

    const onConfirmTransferClicked = async () => {
      try {
        setLoading(true);
        const res: any = await transferToken({
          walletId: wallet.id,
          orgId: profile?.userOrgs?.[0].organizationId,
          tokenId: token.id,
          contractAddress: token.contractAddress,
          transferTo: transferToAddress,
          tokenType: ETokenTypes.ERC721,
        });
        setNftTxHash(res.transferToken);
        setTxHash(res.transferToken);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }

      setStatus(ETransferStatus.Process);
    };

    return (
      <div {...props}>
        <TransferTitle>Transfer {token?.metadata?.name}</TransferTitle>
        <AvatarBox
          m="auto"
          className={status == ETransferStatus.Process ? "processed" : ""}>
          <Image
            loader={simpleImageLoader}
            loadSamples={1}
            src={avatarImage}
            width={280}
            height={280}
            alt=""
            objectFit="cover"
          />
        </AvatarBox>
        {status === ETransferStatus.Edit && (
          <>
            <AvatarDescription>
              You are transferring your {token?.metadata?.name} to another
              wallet. We will send you an email once transfer is completed
            </AvatarDescription>
            <WalletEdit>
              <WalletEditInput
                defaultValue={transferToAddress}
                placeholder=" "
                required
                type="text"
                onChange={(e) => {
                  setTransferToAddress(e.currentTarget.value.trim());
                }}
              />
              {transferToAddress.length > 0 && continueButtonDisabled && (
                <WalletEditInputError>
                  This address is not a valid ethereum address
                </WalletEditInputError>
              )}
              <WalletLabel>Wallet Address</WalletLabel>
            </WalletEdit>
            <ModalContinueButton
              disabled={continueButtonDisabled}
              onClick={() =>
                !continueButtonDisabled && setStatus(ETransferStatus.Confirm)
              }
              loading={loading}
              returnTo="Wallet">
              Continue to Transfer
            </ModalContinueButton>
          </>
        )}
        {status === ETransferStatus.Confirm && (
          <>
            <AvatarDescription>
              You are about to transfer your {token?.metadata?.name} to the
              wallet you provided below:
            </AvatarDescription>
            <WalletAddress>{transferToAddress}</WalletAddress>
            <WalletEditButton onClick={() => setStatus(ETransferStatus.Edit)}>
              <SVG width="10px" fill="white" src="/icons/edit.svg" />
              Edit Wallet Address
            </WalletEditButton>
            <ModalContinueButton
              onClick={onConfirmTransferClicked}
              returnTo="Wallet">
              Confirm Transfer to Wallet
            </ModalContinueButton>
          </>
        )}
        {status === ETransferStatus.Process && (
          <>
            <AvatarDescription>
              Transfer is currently being processed and will appear in the
              receiving wallet after the transaction is confirmed
            </AvatarDescription>
            <ModalContinueButton final returnTo="Wallet">
              {txhash ? (
                <a
                  href={`${chainExplorer.url}/tx/${txhash}`}
                  target="_blank"
                  rel="noreferrer">
                  View on {chainExplorer.name}
                </a>
              ) : (
                "An error occurred. Please try again."
              )}
            </ModalContinueButton>
          </>
        )}

        <ModalFooterInfo>
          By placing a transfer you affirm that you have read, understood, and
          consent to the
        </ModalFooterInfo>
      </div>
    );
  }
)`
  width: calc(100% - 20px);
  max-width: 500px;
  background: #292929;
  border-radius: 4px;
  padding: 30px;
  margin: 20px 10px;
  display: block;
  color: var(--text-color);

  @media screen and (max-width: ${EViewSize.mobile}) {
    padding: 20px;
  }
`;
