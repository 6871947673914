import { useLazyMojitoOneLot } from "@hooks";
import {
  IMojitoCollectionItem,
  IMojitoCollectionItemAuctionLot,
} from "@interfaces";
import { useIsomorphicLayoutEffect } from "ahooks";
import React, { useState } from "react";
import styled from "styled-components";
import { CollectionItemContext } from "../state/auctionItemContext";
import { BidCard } from "./bidCard";
import { Carousel } from "./carousel";
import { LotInfoCard } from "./lotInfoCard";
import { LotPreviewCard } from "./lotPreviewCard";

interface ILotCarousel {
  item: IMojitoCollectionItem<IMojitoCollectionItemAuctionLot>;
  isActiveLot: boolean;
  animationActive?: boolean;
}

export const LotCarousel = styled(
  ({ item, isActiveLot, animationActive, ...props }: ILotCarousel) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [activeCard, setActiveCard] = useState(0);
    const [getMojitoItemDetails, { mojitoItemDetails }] = useLazyMojitoOneLot({
      marketplaceAuctionLotId: item?.details?.id,
    });

    item.details = mojitoItemDetails || item.details;

    useIsomorphicLayoutEffect(() => {
      switch (window.location.hash) {
        case "#preview":
          setActiveCard(0);
          break;
        case "#info":
          setActiveCard(1);
          break;
        case "#bid":
          setActiveCard(2);
          break;
        default:
          setActiveCard(0);
          break;
      }
    }, []);

    useIsomorphicLayoutEffect(() => {
      if (isActiveLot && item) {
        getMojitoItemDetails();
      }
    }, [item?.id, isActiveLot]);

    return (
      <div {...props}>
        <CollectionItemContext.Provider value={{ item, isActiveLot }}>
          <Carousel
            key={item.id}
            collapse={animationActive}
            onSlideChange={(index) => setActiveSlide(index)}
            slide={activeCard}
            slides={[
              <LotPreviewCard
                mojitoItemDetails={mojitoItemDetails}
                activeSlide={activeSlide}
                key={0}
              />,
              <LotInfoCard key={1} />,
              <BidCard mojitoItem={item} key={2} />,
            ]}
          />
        </CollectionItemContext.Provider>
      </div>
    );
  }
)`
  height: 100%;
  width: 100%;
`;
