import styled from "styled-components";
import SVG from "react-inlinesvg";
import React from "react";
import { palette } from "@styles/theme";

interface ISearch {
  value?: string;
  onChangeData?: any;
  extraClass?: string;
  extraClassSvg?: string;
  placeholder?: string;
  onFocusData?: () => void;
  onBlurData?: () => void;
}

const SearchInput = styled(
  ({
    value,
    onChangeData,
    placeholder = "Search",
    extraClass,
    extraClassSvg,
    onFocusData,
    onBlurData,
    ...props
  }: ISearch) => {
    return (
      <div className="search-input-box">
      <div {...props} >
        <SVG
          src="/icons/searchBlack.svg"
          className={extraClassSvg ? extraClassSvg : "search"}
        />
        <input
          type="text"
          className={extraClass ? extraClass : "inputClass"}
          placeholder={placeholder}
          value={value}
          onFocus={(e) => {
            e.preventDefault();
            if (onFocusData) {
              onFocusData();
            }
          }}
          onBlur={(e) => {
            e.preventDefault();
            if (onBlurData) {
              onBlurData();
            }
          }}
          onChange={(e) => {
            onChangeData(e);
          }}
        />
      </div>
      </div>
    );
  }
)`
  .inputClass {
    background: ${palette.white};
    border: 1px solid #bdbdbd;
    border-radius: 40px;
    padding: 16px 32px 16px 64px;
    gap: 10px;
    width: 100%;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: -0.1px;
    color: ${palette.black};
  }
  .inputClass:focus-visible {
    border: 2px solid rgba(0,0,0,1);
    outline: none;
  }
  .search {
    width: 15px;
    position: absolute;
    margin-left: 32px;
  }

  input {
    font-size: 12px;
  }

`;

export default SearchInput;
