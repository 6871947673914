import { EViewSize } from "@enums";
import { Backdrop, ClickAwayListener } from "@material-ui/core";
import {
  EOverlayContextActionTypes,
  useOverlayContext,
} from "@state-context/overlay.context";
import React from "react";
import styled from "styled-components";

export const Overlay = styled((props) => {
  const { state, dispatch } = useOverlayContext();

  const closeOverlay = () =>
    dispatch({
      type: EOverlayContextActionTypes.hide,
    });

  return (
    <Backdrop open={!!state.child} {...props}>
      {state.child && (
        <ClickAwayListener
          onClickAway={closeOverlay}
          mouseEvent={"onMouseUp"}
          touchEvent={false}>
          <div className="wrapper">{state.child}</div>
        </ClickAwayListener>
      )}
    </Backdrop>
  );
})<{
  $isBlurred: boolean;
}>`
  z-index: 10000000;
  background: ${({ $isBlurred }) => !$isBlurred && "rgba(0, 0, 0, 0.8)"};

  @media screen and (max-width: ${EViewSize.tablet}) {
    .wrapper {
      max-height: 100%;
      overflow-y: auto;
    }
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    align-items: center;
  }
`;
