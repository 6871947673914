import { EEventNames } from "@enums";

interface IerrorEvent {
  error_code: string;
  error_msg: string;
  page_name: string;
  path: string;
}

interface ImakeOfferEvent {
  price: number;
  currency: string;
  product_id: string;
  token_type: string;
  owner_address: string;
  auth0_id: string;
  customer_id: string;
  party_id: number;
  eventName: string;
}

interface IbuyNowEvent {
  owner_address: string;
  buyer_address: string;
  product: {
    product_id: string;
    product_price: number;
    product_name: string;
    currency: string;
    token_type: string;
  },
  order_id: string;
  total: number;
  eventName: string;
  auth0_id: string;
  customer_id: string;
  party_id: number;
  tax?: string | undefined;
}

interface IfavouriteEvent {
  owner_address: string;
  product: {
    product_id: string;
    product_name: string;
    token_type: string;
  },
  auth0_id: string;
  customer_id: string;
  party_id: number;
  eventName: string;
}

interface ISwapEvent {
  owner_address: string;
  product: {
    product_id: string;
    token_type: string;
    currency: string;
  },
  auth0_id: string;
  customer_id: string;
  party_id: number;
  swap_amount: number;
}

interface IrejectTransaction {
  buyer_address: string;
  product_id: string;
  auth0_id: string;
  customer_id: string;
  party_id: number;
}

export const secondaryEvents = (): any => {
  const errorEvent = ({ error_code, error_msg, page_name, path }: IerrorEvent) => {
    if (typeof window !== 'undefined' && window.analytics) {
      window.analytics.track(EEventNames.errorEvent, {
        error_code: error_code,
        error_msg: error_msg,
        page_name: page_name,
        path: path,
      })
    }
  };
  const makeOfferEvent = ({ currency, price, owner_address, product_id, token_type, auth0_id, customer_id, party_id, eventName }: ImakeOfferEvent) => {
    window.analytics.track(eventName, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      department_category: 'NFT',
      department_code: 'ENFT',
      price: price,
      currency: currency,
      product_id: product_id,
      token_type: token_type,
      owner_address: owner_address,
    })
  };

  const listitemEvent = ({ currency, price, owner_address, product_id, token_type, auth0_id, customer_id, party_id, eventName }: ImakeOfferEvent) => {
    window.analytics.track(eventName, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      price: price,
      currency: currency,
      department_category: 'NFT',
      department_code: 'ENFT',
      product_id: product_id,
      token_type: token_type,
      owner_address: owner_address,
    })
  };

  const buyNowEvent = ({ buyer_address, order_id, owner_address, product, tax, total, eventName, auth0_id, customer_id, party_id }: IbuyNowEvent) => {
    window.analytics.track(eventName, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      department_category: 'NFT',
      department_code: 'ENFT',
      owner_address: owner_address,
      buyer_address: buyer_address,
      product: {
        product_id: product?.product_id,
        product_price: product?.product_price,
        product_name: product?.product_name,
        currency: product?.currency,
        token_type: product?.token_type,
      },
      tax: tax,
      order_id: order_id,
      payment_method: 'Onchain Payment',
      total: total
    })
  };

  const acceptOfferEvent = ({ buyer_address, order_id, owner_address, product, total, eventName, auth0_id, customer_id, party_id }: IbuyNowEvent) => {
    window.analytics.track(eventName, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      department_category: 'NFT',
      department_code: 'ENFT',
      owner_address: owner_address,
      buyer_address: buyer_address,
      product: {
        product_id: product?.product_id,
        product_price: product?.product_price,
        product_name: product?.product_name,
        currency: product?.currency,
        token_type: product?.token_type,
      },
      order_id: order_id,
      payment_method: 'Onchain Payment',
      total: total
    })
  };

  const favouriteItemEvent = ({ owner_address, product, auth0_id, customer_id, party_id, eventName }: IfavouriteEvent) => {
    window.analytics.track(eventName, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      department_category: 'NFT',
      department_code: 'ENFT',
      owner_address: owner_address,
      item_type: 'Secondary',
      product: {
        product_id: product?.product_id,
        product_name: product?.product_name,
        token_type: product?.token_type,
      },
    })
  };

  const swapCompletedEvent = ({ auth0_id, customer_id, party_id, owner_address, product, swap_amount }: ISwapEvent) => {
    window.analytics.track(EEventNames.swapAmount, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      department_category: 'NFT',
      department_code: 'ENFT',
      owner_address: owner_address,
      item_type: 'Secondary',
      swap_amount: swap_amount,
      product: {
        product_id: product?.product_id,
        token_type: product?.token_type,
        currency: product?.currency,
      },
    })
  }

  const userRejectedTransaction = ({ auth0_id, customer_id, party_id, buyer_address, product_id }: IrejectTransaction) => {
    window.analytics.track(EEventNames.userRejectedTransaction, {
      auth0_id: auth0_id,
      customer_id: customer_id,
      party_id: party_id,
      department_category: 'NFT',
      department_code: 'ENFT',
      item_type: 'Secondary',
      buyer_address: buyer_address,
      product_id: product_id,
    })
  }

  return {
    errorEvent: errorEvent,
    makeOfferEvent: makeOfferEvent,
    listitemEvent: listitemEvent,
    buyNowEvent: buyNowEvent,
    acceptOfferEvent: acceptOfferEvent,
    favouriteItemEvent: favouriteItemEvent,
    swapCompletedEvent: swapCompletedEvent,
    userRejectedTransaction: userRejectedTransaction
  };
}
