import { useAuth0 } from "@auth0/auth0-react";
import { BaseButton } from "@components";
import { EViewSize } from "@enums";
import { IContentfulLotData } from "@interfaces";
import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

export const ItemTitle = styled.div`
  font-family: "MercuryDisplay", serif;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 30px;
  max-width: 450px;

  @media screen and (max-width: ${EViewSize.mobile}) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
`;

export const Estimate = styled(
  ({ lotData, ...props }: { lotData: IContentfulLotData }) => {
    return lotData.estimatePrice ? (
      <div {...props}>
        {!!lotData.estimatePrice && "Estimate:"}{" "}
        {lotData.estimatePrice || "Estimate upon request"}
      </div>
    ) : null;
  }
)`
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const SubsectionTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const LotWrapper = styled((props) => <Box {...props} />)`
  && {
    position: relative;
    background: #f1f1f1;
    padding: 55px 40px;
    border: 1px solid #fff;
    height: 582px;
    border-radius: 10px;

    @media screen and (max-width: ${EViewSize.tablet}) {
      padding: 30px 16px;
    }

    @media screen and (max-width: ${EViewSize.mobile}) {
      padding: 15px 16px 24px;
      border-radius: 0;
      height: calc(100% - 40px);
    }
  }
`;

export const LoginOrRegisterButton = styled((props) => {
  const { loginWithRedirect } = useAuth0();

  const login = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };

  return (
    <div {...props}>
      <BaseButton onClick={login}>Log In or Register to Bid</BaseButton>
    </div>
  );
})``;
