import { EViewSize } from "@enums";
import {
  useCollection,
  useDeviceResponsive,
  useLotsNavigation,
  useOrganization,
} from "@hooks";
import { mod } from "@utils";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { isSafari } from "react-device-detect";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { IconButton } from "../button";
import { LotCarousel } from "./components/lotCarousel";

interface ISlideData {
  lotIdx: number;
  yPos: number;
  duration: number;
  active: boolean;
  visible: boolean;
  scale: number;
}

const Navigation = styled((props) => <IconButton {...props} />)<{
  $position: "top" | "bottom";
  $clipTop?: boolean;
  $isMobile?: boolean;
}>`
  position: absolute;
  z-index: 555;
  width: 88px;
  height: 66px;
  left: calc(50% - 44px);
  background: rgba(240, 240, 240, 0.03);

  ${({ $position }) =>
    $position == "top"
      ? `top: 0; border-top: 0; border-radius: 0 0 7px 7px;`
      : "bottom: 0; border-bottom: 0; border-radius: 7px 7px 0 0;"};

  @media screen and (max-width: 1024px) {
    ${({ $position, $isMobile }) =>
      $isMobile && $position === "bottom" ? "bottom: 0;" : ""}
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    ${() => (isSafari ? "top: 0; bottom: unset;" : "top: unset;bottom: 20px;")}
    height: 40px;
    width: 40px;
    ${({ $position }) =>
      $position === "top" ? "left: calc(50% + 7px)" : "right: calc(50% + 7px)"};
    border: 2px solid var(--icon-btn-border-color);
    border-radius: 7px;
  }

  svg {
    min-width: 18px;
  }
`;

const Slider = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SlideWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const SlideWrapperMobile = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const SlideMobile = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${EViewSize.mobile}) {
    /* height: calc(100% - ${() => (isSafari ? "40px" : "70px")}); */
  }
`;

const NavigationInfoMobile = styled.div`
  font-size: 12px;
  line-height: 45px;
  color: white;
`;

const NavigationBtns = styled.div`
  position: absolute;
  ${() => (isSafari ? "top: 0; right: 45px;" : "top: 60px; right: 45px;")}
`;

const NavContainer = styled.div`
  position: relative;
  height: 50px;
`;

const NavigationWrapper = styled((props) => {
  const { lotId, auctionName, auctionSlug } = props;
  const { isMobile } = useDeviceResponsive();

  return isMobile ? (
    <NavContainer>
      <NavigationInfoMobile {...props}>
        <Link href={`/` + auctionSlug}>{auctionName}</Link> / Lot {lotId}
      </NavigationInfoMobile>
      <NavigationBtns {...props}>{props.children}</NavigationBtns>
    </NavContainer>
  ) : (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5 }}
      {...props}>
      {props.children}
    </motion.span>
  );
})`
  a {
    text-decoration: underline;
  }
`;

export const Lot = styled(
  ({ gridAnimationActive, ...props }: { gridAnimationActive?: boolean }) => {
    const { prevLot, nextLot, activeLotIdx, lots } = useLotsNavigation();
    const [slidesData, setSlidesData] = useState<ISlideData[]>();
    const { organization } = useOrganization();
    const { collection } = useCollection();
    const { isMobile } = useDeviceResponsive();

    useEffect(() => {
      if (activeLotIdx == undefined) return;
      setSlidesData([
        {
          lotIdx: mod(activeLotIdx - 1, lots.length),
          yPos: -100,
          duration: 0,
          scale: 1,
          active: false,
          visible: false,
        },
        {
          lotIdx: mod(activeLotIdx, lots.length),
          yPos: 0,
          duration: 0,
          scale: 1,
          active: false,
          visible: true,
        },
        {
          lotIdx: mod(activeLotIdx + 1, lots.length),
          yPos: 100,
          duration: 0,
          scale: 1,
          active: false,
          visible: false,
        },
      ]);
    }, []);

    useEffect(() => {
      if (typeof activeLotIdx === "undefined") {
        return;
      }
      setSlidesData([
        {
          lotIdx: mod(activeLotIdx - 1, lots.length),
          yPos: -100,
          duration: 0,
          scale: 1,
          active: false,
          visible: false,
        },
        {
          lotIdx: mod(activeLotIdx, lots.length),
          yPos: 0,
          duration: 0,
          scale: 1,
          active: false,
          visible: true,
        },
        {
          lotIdx: mod(activeLotIdx + 1, lots.length),
          yPos: 100,
          duration: 0,
          scale: 1,
          active: false,
          visible: false,
        },
      ]);
    }, [lots.length]);

    const _moveDown = () => {
      if (activeLotIdx == undefined) return;

      setSlidesData(
        slidesData?.map((slide) => {
          Object.assign(slide, {
            duration: 0.8,
            scale: 0.8,
            active: true,
          });
          if (slide.yPos == -100) {
            Object.assign(slide, {
              yPos: 100,
              duration: 0,
              lotIdx: mod(activeLotIdx + 2, lots.length),
              visible: false,
            });
          } else slide.yPos -= 100;
          return slide;
        })
      );
      prevLot();
    };

    const _moveUp = () => {
      if (activeLotIdx == undefined) return;

      setSlidesData(
        slidesData?.map((slide) => {
          Object.assign(slide, {
            duration: 0.8,
            scale: 0.8,
            active: true,
          });
          if (slide.yPos == 100) {
            Object.assign(slide, {
              yPos: -100,
              duration: 0,
              lotIdx: mod(activeLotIdx - 2, lots.length),
              visible: false,
            });
          } else slide.yPos += 100;
          return slide;
        })
      );
      nextLot();
    };

    const _onComplete = (definition: { top: string }) => {
      if (definition.top == "0vh")
        setSlidesData(
          slidesData?.map((slide) => {
            Object.assign(slide, {
              active: slide.yPos != 0,
              scale: slide.yPos != 0 ? 0.8 : 1,
              visible: true,
            });
            return slide;
          })
        );
    };

    return (
      <>
        {!gridAnimationActive && (
          <AnimatePresence>
            <NavigationWrapper
              auctionName={collection?.name}
              auctionSlug={collection?.slug}
              lotId={
                activeLotIdx != undefined
                  ? lots[activeLotIdx]?.contentfulData?.lotId
                  : ""
              }>
              <Navigation
                key="nav-up"
                $position="top"
                $isMobile={isMobile}
                onClick={_moveUp}
                $clipTop={!organization?.hasNotifications}>
                <SVG src="/icons/up.svg/" />
              </Navigation>
              <Navigation
                key="nav-down"
                $position="bottom"
                $isMobile={isMobile}
                onClick={_moveDown}>
                <SVG src="/icons/down.svg/" />
              </Navigation>
            </NavigationWrapper>
          </AnimatePresence>
        )}

        <Slider {...props}>
          {slidesData?.map((slide, i) => {
            const _lot = lots[slide.lotIdx];
            const _isActiveLot = slide.lotIdx == activeLotIdx;

            return _lot ? (
              !isMobile ? (
                <SlideWrapper
                  key={_lot.id}
                  style={{
                    display:
                      slide.visible && !gridAnimationActive ? "block" : "none",
                    visibility:
                      gridAnimationActive && i !== 1 ? "hidden" : "hidden",
                  }}
                  animate={{
                    top: `${slide.yPos}vh`,
                  }}
                  transition={{ duration: slide.duration, delay: 0.2 }}
                  onAnimationComplete={_onComplete}>
                  <Slide
                    animate={{ scale: isMobile ? 1 : slide.scale }}
                    transition={{ duration: slide.duration / 2 }}>
                    <LotCarousel
                      item={_lot}
                      isActiveLot={_isActiveLot}
                      animationActive={gridAnimationActive || slide.active}
                    />
                  </Slide>
                </SlideWrapper>
              ) : (
                <SlideWrapperMobile
                  key={_lot.id}
                  style={{ display: _isActiveLot ? "block" : "none" }}>
                  <SlideMobile>
                    <LotCarousel
                      item={_lot}
                      isActiveLot={_isActiveLot}
                      animationActive={gridAnimationActive || slide.active}
                    />
                  </SlideMobile>
                </SlideWrapperMobile>
              )
            ) : null;
          })}
        </Slider>
      </>
    );
  }
)``;
