import { IContentfulImageItem, IContentfulLotData } from "@interfaces";
import { useMemo } from "react";

const acceptedImageTypes = new RegExp(".jpg|png|svg|jpeg|gif|webp$");

export interface ILotType {
  isNFTLink: boolean;
  isVideo: boolean;
  isImages: boolean;
  isUnknownType: boolean;
  areImagesValid: boolean;
}

export const useLotType = ({
  nftLink,
  nftVideoIds,
  imagesCollection,
}: IContentfulLotData): ILotType => {
  const lotImages = imagesCollection?.items;

  return useMemo(() => {
    const areImagesValid = lotImages?.every((image: IContentfulImageItem) =>
      acceptedImageTypes.test(image.url)
    );

    const result = {
      isNFTLink: false,
      isVideo: false,
      isImages: false,
      isUnknownType: false,
      areImagesValid,
    };

    switch (true) {
      case !!nftLink:
        result.isNFTLink = true;
        break;

      case nftVideoIds?.length > 0:
        result.isVideo = true;
        break;

      case areImagesValid:
        result.isImages = true;
        break;

      case !areImagesValid:
        result.isUnknownType = true;
        break;
    }

    return result;
  }, [lotImages, nftLink, nftVideoIds]);
};
