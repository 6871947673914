import { MultipleGridRenderer } from "@components";
import { IContentfulLotData } from "@interfaces";
import { ReactNode } from "react";
import styled from "styled-components";
import { SubsectionTitle } from "./lot/components/general";

interface IMoreFromCollection {
  lots: IContentfulLotData[];
  children: ReactNode;
}

export const MoreFromCollection = styled(
  ({ lots, children, ...props }: IMoreFromCollection) => {
    return lots?.length ? (
      <div {...props}>
        <SubsectionTitle>{children}</SubsectionTitle>
        <MultipleGridRenderer lots={lots} />
      </div>
    ) : null;
  }
)`
  width: 100%;
`;
