import styled from "styled-components";
import SVG from "react-inlinesvg";

export enum EHistoryIcons {
  bought = "house",
  transferred = "opposite-arrows",
  merged = "merge-arrows",
  imported = "contained-arrow",
  minted = "two-chains",
  created = "pencil",
}

interface IHistoryIcon {
  type: keyof typeof EHistoryIcons;
}

export const HistoryIcon = styled(({ type, ...props }: IHistoryIcon) => {
  const activeIcon = EHistoryIcons[type];
  return (
    <SVG
      width={24}
      height={24}
      src={`/icons/${activeIcon || EHistoryIcons.bought}.svg`}
      {...props}
    />
  );
})``;
