import { useCollectionItemCurrentBids, useOrganization } from "@hooks";
import { bidIncrement, formatCurrency } from "@utils";
import React from "react";
import styled from "styled-components";

export const NextBid = styled(
  ({ mojitoItemId, ...props }: { mojitoItemId?: string }) => {
    const { organization } = useOrganization();
    const { currentBids } = useCollectionItemCurrentBids(mojitoItemId);

    return (
      <div {...props}>
        {!currentBids?.details?.saleView?.isPostSale &&
          !organization?.notifications?.contactUs && (
            <div className="next-bid">
              {currentBids?.details?.currentBid
                ? "Next bid: "
                : "Starting bid: "}
              {formatCurrency(
                currentBids?.details?.currentBid?.nextBidIncrement ||
                  currentBids?.details?.startingBid ||
                  bidIncrement[0]
              )}
            </div>
          )}
      </div>
    );
  }
)`
  .next-bid {
    position: absolute;
    top: 100%;
    width: 190px;
    margin-top: 10px;
    font-family: "BentonSans";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.0025em;
    text-align: center;

    @media screen and (max-width: 1280px) {
      min-width: 130px;
      width: calc(50% - 8px);
    }
  }
`;
